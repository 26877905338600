import { useNavigate } from 'react-router-dom';
import { Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import {
  OrdersHistoryList,
  PROPS_DATA_TABLE,
  getOrderRouteByType,
} from 'shared';

type Props = {
  ordersHistory: OrdersHistoryList[];
  page: number;
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const OrdersHistory = ({
  ordersHistory,
  page,
  total,
  setPage,
}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <Text size="lg" weight={700}>
        Order History
      </Text>
      <DataTable
        {...PROPS_DATA_TABLE}
        page={page}
        onPageChange={setPage}
        records={ordersHistory}
        totalRecords={total}
        onRowClick={({ id, type, service_class }) =>
          navigate(getOrderRouteByType({ id, type, service_class }))
        }
        columns={[
          {
            accessor: 'number',
            title: 'Order #',
            width: '14%',
          },
          {
            accessor: 'closed_at',
            title: 'Date',
            width: '14%',
          },
          {
            accessor: 'processed_in',
            title: 'Processed in',
            width: '14%',
          },
          {
            accessor: 'delivery_method',
            title: 'Delivery',
            width: '16%',
          },
          {
            accessor: 'total',
            title: 'Total',
            width: '14%',
          },
          {
            accessor: 'status',
            title: 'Status',
            width: '14%',
          },
          {
            accessor: 'note',
            title: 'Note',
            width: '14%',
          },
        ]}
      />
    </>
  );
};
