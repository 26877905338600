import * as Yup from 'yup';
import { drop } from 'rambda';
import { requiredStringValidationRule } from './common';

const PRICE_MSG = 'Should be more then 0';

const checkPrice0 = (value?: string) => {
  if (value) {
    const integerValue = +(
      value.startsWith('$') ? drop(1, value) : value
    ).split('.')[0];
    return integerValue >= 0;
  }
  return true;
};

const sharedSkuFields = {
  product_name: requiredStringValidationRule('Product Name'),
  is_enabled: Yup.bool(),
  hide_from_pos: Yup.bool(),
  is_non_inventory: Yup.bool(),
  inventory_type: Yup.string()
    .nullable()
    .when('saved_as_draft', {
      is: false,
      then: () => requiredStringValidationRule('Inventory Type'),
    }),
  category_id: Yup.string().nullable(),
  subcategory_id: Yup.string().nullable(),
  cost: Yup.string()
    .test('test-price', PRICE_MSG, checkPrice0)
    .nullable()
    .notRequired()
    .when('saved_as_draft', {
      is: false,
      then: () =>
        Yup.string()
          .test('test-price', PRICE_MSG, checkPrice0)
          .required('Cost is required')
          .nullable(),
    }),
  price: Yup.string()
    .notRequired()
    .nullable()
    .matches(/^-?\$\d|^\d\d%$|^\d%$|^100%$/, {
      message: 'Must contain % or $',
      excludeEmptyString: true,
    })
    .when('saved_as_draft', {
      is: false,
      then: () =>
        Yup.string()
          .required('Price is required')
          .matches(/^-?\$\d|^\d\d%$|^\d%$|^100%$/, 'Must contain % or $'),
    }),
  tax_rate: requiredStringValidationRule('Tax Rate'),
  vendor: Yup.string().nullable(),
  processing_time: requiredStringValidationRule('Processing Time'),
  pickup_only: Yup.bool(),
  members_only: Yup.bool(),
  ships_separately: Yup.bool(),
  width: Yup.number().nullable(),
  height: Yup.number().nullable(),
  depth: Yup.number().nullable(),
  weight: Yup.number().nullable(),
  per_order_limit: Yup.string(),
  product_file: Yup.string().nullable(),
  e_gift_card: Yup.bool(),
  product_description: Yup.string(),
  comment: Yup.string(),
  saved_as_draft: Yup.bool(),
  sku_type: Yup.string(),
};

const isDraft = Yup.object().shape({
  abv: Yup.string().nullable(),
  bottle_size: Yup.string().nullable(),
  varietal: Yup.string().nullable(),
  glasses_per_bottle: Yup.string().nullable(),
  is_available_by_glass: Yup.boolean().nullable(),
  is_available_only_by_glass: Yup.boolean().nullable(),
  has_sample: Yup.boolean().nullable(),
  items: Yup.array(),
});

const categoryOptionSchema = Yup.object().shape({
  // required: Yup.boolean(),
  // value: Yup.string()
  //   .when(['requred'], {
  //     is: (required: boolean) => required,
  //     then: requiredStringValidationRule(),
  //   })
  //   .nullable(),
  value: requiredStringValidationRule(),
});

export const skuSchema = Yup.object({
  ...sharedSkuFields,
  is_kit: Yup.bool(),
  calculated_margin: Yup.number(),
  specific_information: Yup.object()
    .when(['saved_as_draft', 'inventory_type', 'is_kit'], {
      is: (saved_as_draft: boolean, inventory_type: string, is_kit: boolean) =>
        !saved_as_draft && inventory_type === 'alcohol' && !is_kit,
      then: () =>
        Yup.object().shape({
          abv: Yup.string().required('ABV is required'),
          bottle_size: Yup.string()
            .required('Bottle Size is required')
            .nullable(),
          varietal: Yup.string().nullable(),
          glasses_per_bottle: Yup.string()
            .nullable()
            .required('Glasses Per Bottle Size is required'),
          is_available_by_glass: Yup.boolean(),
          is_available_only_by_glass: Yup.boolean(),
          has_sample: Yup.boolean(),
        }),
      otherwise: () => isDraft,
    })
    .nullable()
    .when(['is_non_inventory'], {
      is: (is_non_inventory: boolean) => is_non_inventory,
      then: () =>
        Yup.object().shape({
          locations: Yup.array().of(
            Yup.object().shape({
              location: Yup.object().shape({
                id: Yup.string().required('Location  is required').nullable(),
              }),
              inventory_location: Yup.object().shape({
                id: Yup.string().required('Inventory  is required').nullable(),
              }),
            }),
          ),
        }),
      otherwise: () => isDraft,
    }),

  btg: Yup.object()
    .when(
      [
        'specific_information.is_available_by_glass',
        'specific_information.is_available_only_by_glass',
      ],
      {
        is: (
          is_available_by_glass: boolean,
          is_available_only_by_glass: boolean,
        ) => is_available_by_glass || is_available_only_by_glass,
        then: () =>
          Yup.object().shape({
            btg_tax_rate: Yup.string().nullable(),
            btg_cost: Yup.number().required('BTG Cost is required').nullable(),
            btg_price: Yup.string().required('BTG Price is required'),
          }),
        otherwise: () =>
          Yup.object().shape({
            btg_tax_rate: Yup.string().nullable(),
            btg_cost: Yup.number().nullable(),
            btg_price: Yup.string().nullable(),
          }),
      },
    )
    .nullable(),
  categoryOptions: Yup.array().of(categoryOptionSchema),
});

const modifierSchema = Yup.object().shape({
  name: requiredStringValidationRule('Modifier Name'),
  price: Yup.number().nullable(),
  max_quantity: Yup.number().nullable(),
  hide_from_pos: Yup.bool(),
  mandatory: Yup.bool(),
  on_the_side: Yup.bool(),
  lite: Yup.bool(),
  exclude: Yup.bool(),
  is_variants: Yup.bool(),
  variants: Yup.array().of(
    Yup.object().shape({
      name: requiredStringValidationRule('Variant Name'),
      price: Yup.number().nullable(),
    }),
  ),
});

export const culinarySkuSchema = Yup.object({
  ...sharedSkuFields,
  price: Yup.string()
    .matches(/^-?\$\d/, {
      message: 'Must contain $',
      excludeEmptyString: true,
    })
    .test('test-price', PRICE_MSG, checkPrice0)
    .notRequired()
    .nullable()
    .when('saved_as_draft', {
      is: false,
      then: () =>
        Yup.string()
          .matches(/^-?\$\d/, { message: 'Must contain $' })
          .required('Price is required')
          .test('test-price', PRICE_MSG, checkPrice0)
          .nullable(),
    }),
  show_in_ecommerce: Yup.bool(),
  specific_information: Yup.object({
    items: Yup.array()
      .of(modifierSchema)
      .when('saved_as_draft', {
        is: true,
        then: () =>
          Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().nullable(),
                price: Yup.number().nullable(),
                max_quantity: Yup.number().nullable(),
                hide_from_pos: Yup.bool(),
                mandatory: Yup.bool(),
                on_the_side: Yup.bool(),
                lite: Yup.bool(),
                exclude: Yup.bool(),
                is_variants: Yup.bool(),
                variants: Yup.array().of(
                  Yup.object().shape({
                    name: Yup.string().nullable(),
                    price: Yup.number().nullable(),
                  }),
                ),
              }),
            )
            .nullable(),
      }),
  }),
});
