import { Grid, Select, Text, Badge } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import {
  CycleOrderPayload,
  DATE_FORMAT_SLASH,
  SelectOption,
  SalesOrderPaymentStatus,
} from 'shared';

type TypeProps = {
  form: UseFormReturnType<CycleOrderPayload>;
  cards: SelectOption[];
  status: SalesOrderPaymentStatus;
  isBlocked: boolean;
};

export const PaymentDetails = ({
  form,
  cards,
  status,
  isBlocked,
}: TypeProps) => {
  const hasPaymentError = status === SalesOrderPaymentStatus.ERROR;
  return (
    <Grid mb={48} gutter="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          Payment Information
          {hasPaymentError && (
            <Badge variant="filled" color="red" size="sm" ml={'md'} mb={4}>
              Payment: Error
            </Badge>
          )}
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <DatePicker
          required
          disabled={isBlocked}
          inputFormat={DATE_FORMAT_SLASH}
          placeholder="mm/dd/yyyy"
          label="Billing Date"
          {...form.getInputProps('billing_datetime')}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <DatePicker
          disabled={isBlocked}
          required
          inputFormat={DATE_FORMAT_SLASH}
          placeholder="mm/dd/yyyy"
          label="Re-Billing Date"
          {...form.getInputProps('re_billing_datetime')}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Text weight={600} mb="sm">
          Payment Method
        </Text>
        <Text>Card</Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          disabled={isBlocked}
          required
          clearable
          label="Card"
          data={cards}
          {...form.getInputProps('customer_card_id')}
        />
      </Grid.Col>
    </Grid>
  );
};
