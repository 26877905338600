import { useMemo } from 'react';
import { reject } from 'rambda';

import { InventoryService } from 'App/api';
import { useLocations } from 'App/contexts';
import { useApiQuery } from 'hooks';
import { getSelectOptions } from 'shared';

type TPayload = {
  locationId?: string | null;
  inventoryLocationsIds?: (string | undefined)[];
};

export const useNonInventory = ({
  locationId,
  inventoryLocationsIds,
}: TPayload) => {
  const { actualLocationOptions } = useLocations();

  const { data: inventoryLocations, isLoading } = useApiQuery(
    ['inventory-locations', locationId],
    () =>
      InventoryService.getLocations({
        location_id: locationId ?? undefined,
        show_in_pos: true,
      }),
    {
      enabled: !!locationId,
    },
  );

  const available = useMemo(() => {
    if (inventoryLocations?.items && inventoryLocationsIds) {
      return reject(
        ({ id }) => inventoryLocationsIds.includes(id),
        inventoryLocations?.items,
      );
    }
    return [];
  }, [inventoryLocations?.items, inventoryLocationsIds]);

  return {
    actualLocationOptions,
    inventoryLocationOptions: available && getSelectOptions(available),
    isLoading: isLoading && locationId,
  };
};
