const createRegExp = (str: TemplateStringsArray, opts = '') =>
  new RegExp(str.raw[0].replace(/\s/gm, ''), opts || '');

export const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/im;

export const zipCodeRegex = /^\d{5}(?:-\d{4})?$/;

export const passwordRegex = createRegExp`
    ^(?=(.*[a-z])+)(?=(.*[A-Z])+)
    (?=(.*\d)+)[A-Za-z\d@$!%*#?&].{7,}$`;
