import { reject, isEmpty, keys, reduce } from 'rambda';
import { randomId } from '@mantine/hooks';

import {
  InventoryListRequest,
  InventoryUnitDto,
  InventoryUnitOutput,
  InventoryUnit,
  capitalizeText,
} from 'shared';

type ProductData = { quantity: number; cost: number };

export const getInventoryListParams = ({
  location,
  ...rest
}: InventoryListRequest): InventoryListRequest =>
  reject(isEmpty, {
    ...rest,
    ...(isEmpty(location) ? {} : { location }),
  } as Record<string, unknown>);

export const getUnitOutput = (
  data: InventoryUnit,
  level: 0 | 1 | 2 = 0,
): InventoryUnitOutput => {
  const { stock, available, committed, name = '', id } = data;
  return {
    stock,
    available,
    committed,
    name: capitalizeText(name),
    id: id ?? name,
    level,
  };
};

export const getInventoryUnitOutput = (
  data: InventoryUnitDto,
): InventoryUnitOutput[] =>
  reduce(
    (acc, cur: string) => {
      const item = data[cur];
      if (item) {
        acc.push(getUnitOutput({ ...item, name: cur }));
      }
      if (item?.locations) {
        item.locations.forEach((l) => {
          acc.push(getUnitOutput(l, 1));
          if (l.inventory_locations) {
            l.inventory_locations.forEach((il) => {
              acc.push(getUnitOutput(il, 2));
            });
          }
        });
      }
      return acc;
    },
    [] as InventoryUnitOutput[],
    keys(data),
  );

export const getNewSkuItem = () => ({
  sku_id: '',
  quantity: 0,
  cost: 0,
  key: randomId(),
});

export function getTotalCost<T extends ProductData>(data: T[]) {
  return reduce(
    (acc, { cost, quantity }: ProductData) => {
      return acc + cost * quantity;
    },
    0,
    data,
  ).toFixed(2);
}
