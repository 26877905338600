import {
  CreateLibrary,
  LibraryListItemDto,
  PaginatedRequest,
  PaginatedResponse,
} from 'shared';
import { INVENTORY_LIBRARY_PATH } from '../../endpoints';
import request from '../../request';

export default class InventoryLibraryService {
  static readonly create = (data: CreateLibrary) => {
    return request<LibraryListItemDto>({
      url: `${INVENTORY_LIBRARY_PATH}`,
      method: 'post',
      data,
    });
  };

  static readonly release = ({
    id,
    data,
  }: {
    id: string;
    data: { quantity: number; comment: string };
  }) => {
    return request({
      url: `${INVENTORY_LIBRARY_PATH}/${id}/release`,
      method: 'post',
      data,
    });
  };

  static readonly cancel = (id: string) => {
    return request({
      url: `${INVENTORY_LIBRARY_PATH}/${id}/cancel`,
      method: 'post',
    });
  };

  static readonly getCollection = (params: PaginatedRequest) => {
    return request<PaginatedResponse<LibraryListItemDto>>({
      url: `${INVENTORY_LIBRARY_PATH}`,
      params,
      method: 'get',
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<LibraryListItemDto>({
      url: `${INVENTORY_LIBRARY_PATH}/${id}`,
      method: 'get',
    });
  };
}
