import { Badge, useMantineTheme } from '@mantine/core';
import { PaymentStatus, paymentStatusValue } from 'shared';

export const PaymentStatusBadge = ({ status }: { status: PaymentStatus }) => {
  const theme = useMantineTheme();

  if (status === PaymentStatus.failed)
    return (
      <Badge variant="filled" style={{ backgroundColor: theme.colors.red[4] }}>
        {paymentStatusValue[status]}
      </Badge>
    );

  if (status === PaymentStatus.pending)
    return (
      <Badge
        variant="filled"
        style={{ backgroundColor: theme.colors.halterBlue[4] }}
      >
        {paymentStatusValue[status]}
      </Badge>
    );

  return <></>;
};
