import { useMemo, useEffect } from 'react';
import { TextInput, Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { CreateSku, ProductCategoryBaseDto, CategoryOption } from 'shared';

type PropType = {
  form: UseFormReturnType<CreateSku, (values: CreateSku) => CreateSku>;
  category: ProductCategoryBaseDto | null;
  initialOptions: CategoryOption[];
};

export const CategoryOptions = ({
  form,
  category,
  initialOptions,
}: PropType) => {
  const {
    setFieldValue,
    values: { category_options },
  } = form;
  const options = useMemo(() => {
    if (!category?.product_options) {
      return null;
    }
    return category.product_options.map(({ id, name, required }) => ({
      id,
      name,
      required,
      value: initialOptions?.find((o) => o.id === id)?.value ?? '',
    }));
  }, [category, initialOptions]);

  useEffect(() => {
    setFieldValue('category_options', options);
  }, [options, setFieldValue]);

  return category_options ? (
    <Grid gutter="xl" mb="md">
      {category_options.map(({ name, required }, i) => (
        <Grid.Col span={6} key={name}>
          <TextInput
            required={required}
            label={name}
            {...form.getInputProps(`category_options.${i}.value`)}
          />
        </Grid.Col>
      ))}
    </Grid>
  ) : null;
};
