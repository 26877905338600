import { useParams, useNavigate, Link } from 'react-router-dom';
import { Text } from '@mantine/core';

import { useInventoryLocation } from '../../hooks';
import { ContentLayout, BackButton, ConfirmModal } from 'components';

import { Form } from '../form';
import { DELETE_BTN, getViewPath } from 'shared';

export const Edit = ({
  rootPath,
  onExit,
  canDelete,
}: {
  rootPath: string;
  onExit: () => void;
  canDelete: boolean;
}): JSX.Element => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const viewPath = getViewPath(rootPath, id);
  const {
    data,
    isLoading,
    isError,
    processing,
    isUpdated,
    isDeleted,
    deleteLocation,
    updateLocation,
  } = useInventoryLocation(id);

  if (isError || isDeleted) {
    onExit();
  }

  if (isUpdated) {
    navigate(viewPath);
  }

  const initialValues = data && {
    name: data.name,
    show_in_pos: data.show_in_pos,
    location_id: data.location.id,
  };

  return (
    <ContentLayout
      title={`Edit ${data?.name ?? 'Inventory Location'}`}
      isLoading={isLoading}
      processing={processing}
      leftAction={<BackButton to={viewPath} component={Link} />}
    >
      {initialValues && (
        <Form
          handleSubmit={(v) => updateLocation({ id, data: v })}
          initialValues={initialValues}
        >
          {canDelete && (
            <ConfirmModal
              type="button"
              variant="outline"
              fullWidth
              loading={processing}
              onConfirm={() => deleteLocation({ id })}
              label="Delete"
              data-testid={DELETE_BTN}
            >
              <Text>Are you sure you want to delete the location?</Text>
            </ConfirmModal>
          )}
        </Form>
      )}
    </ContentLayout>
  );
};
