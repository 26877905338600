import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useApiQuery } from 'hooks';
import { ProductCategoryService } from 'App/api';
import { getSelectOptions, sortByMenuPosition } from 'shared';
import { getNestedOptions } from '../helpers';

const GET_CATEGORIES_KEY = 'getCategories';

export const useCategories = () => {
  const { data, isLoading } = useApiQuery(
    [GET_CATEGORIES_KEY],
    ProductCategoryService.getAll,
  );

  const client = useQueryClient();

  const refetch = () => {
    client.invalidateQueries([GET_CATEGORIES_KEY]);
  };

  const categories = useMemo(() => {
    if (!data) {
      return [];
    }
    return sortByMenuPosition(data.items);
  }, [data]);

  const categoryOptions = useMemo(
    () => getSelectOptions(data?.items ?? []),
    [data?.items],
  );

  const categoryNestedOptions = useMemo(
    () => getNestedOptions(data?.items ?? []),
    [data?.items],
  );

  return {
    categoryOptions,
    categoryNestedOptions,
    isLoading,
    categories,
    refetch,
  };
};
