import { EmployeeService } from 'App/api';
import { useApiMutation } from 'hooks';
import { EmployeeFormValues } from 'shared';
import { getEmployeePayload } from '../helpers';

const createEmployeeKey = 'createEmployee';

export const useCreateEmployee = (
  onSuccess: (id?: string) => void = () => null,
): {
  handleSubmit: (values: EmployeeFormValues) => void;
  isLoading: boolean;
} => {
  const { mutate: createEmployee, isLoading } = useApiMutation(
    [createEmployeeKey],
    EmployeeService.createEmployee,
    {
      onSuccess: ({ id }) => {
        onSuccess(id);
      },
    },
  );

  const handleSubmit = (values: EmployeeFormValues) => {
    createEmployee(getEmployeePayload(values));
  };

  return {
    handleSubmit,
    isLoading,
  };
};
