import { UseFormReturnType } from '@mantine/form';
import { Container } from '@mantine/core';

import { ClubTierDto, WineSelection, WineSelectionDto } from 'shared';
import { WineSelectionType } from './WineSelectionType';
import { WineSelectionBottles } from './WineSelectionBottles';
import { useCopyPaste } from 'pages/membersClub/shipmentCycle/hooks';

export const WineSelectSection = ({
  tier,
  form,
  isEdit,
}: {
  isEdit?: boolean;
  tier?: ClubTierDto;
  form: UseFormReturnType<WineSelectionDto>;
}) => {
  const { copy, setCopy, paste } = useCopyPaste<WineSelection>();
  const { name, bottle_quantity_allowed, wine_types_allowed } = tier ?? {};

  return (
    <Container size="xl">
      {wine_types_allowed?.map((type) => (
        <WineSelectionType key={type} type={type} name={name}>
          <WineSelectionBottles
            type={type}
            form={form}
            copy={copy}
            isEdit={isEdit}
            paste={paste}
            setCopy={setCopy}
            bottleQuantityAllowed={bottle_quantity_allowed}
          />
        </WineSelectionType>
      ))}
    </Container>
  );
};
