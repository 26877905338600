import { useSession } from 'App/session';
import { Employee, EmployeeStatus, getFullName, LocationTypes } from 'shared';

const shortEmployee = {
  first_name: '',
  last_name: '',
  email: '',
};

const location = {
  id: '',
  name: '',
  address: '',
  zip_code: '',
  city: '',
  state: '',
  phone_number: '',
  issued_at: '',
  issued_by: shortEmployee,
  type: LocationTypes.INTERNAL,
};

const defaultEmployee: Employee = {
  id: '',
  ...shortEmployee,
  status: EmployeeStatus.INTRO,
  start_date: '',
  issued_at: '',
  issued_by: {
    ...shortEmployee,
  },
  primary_location: {
    ...location,
  },
  role: {
    ...location,
    permissions: [],
  },
};

export function useCurrentEmployee(): Employee {
  const userData = useSession().user ?? defaultEmployee;
  return { ...userData, full_name: getFullName(userData) };
}
