import { Container, Text } from '@mantine/core';

import { ConfirmModal } from 'components';

type Props = {
  canBeDeleted: boolean;
  deleteCycle: () => void;
  isDeleting: boolean;
};

export const DeleteButton = ({
  canBeDeleted,
  deleteCycle,
  isDeleting,
}: Props) => {
  if (!canBeDeleted) {
    return null;
  }
  return (
    <Container size="xs" mt={-24}>
      <ConfirmModal
        type="button"
        variant="subtle"
        fullWidth
        loading={isDeleting}
        mt={26}
        onConfirm={deleteCycle}
        label="Delete Cycle"
      >
        <Text data-testid="delete-cycle-btn">
          Are you sure you want to delete the Shipment Cycle?
        </Text>
      </ConfirmModal>
    </Container>
  );
};
