import { ReactNode } from 'react';
import { Text, Grid, Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { CycleOrderPayload } from 'shared';
import { Product } from './Product';

type PropType = {
  form: UseFormReturnType<CycleOrderPayload>;
  children: ReactNode;
  isBlocked: boolean;
};

export const AddProduct = ({ children, form, isBlocked }: PropType) => {
  const items = form.values.items;

  return (
    <Box>
      <Grid gutter="xl" columns={24}>
        <Grid.Col span={7}>
          <Text size="sm" weight={600} sx={{ display: 'flex', gap: 5 }}>
            Product Name and SKU# <Text color="red"> *</Text>
          </Text>
        </Grid.Col>
        <Grid.Col span={2} offset={3}>
          <Text size="sm" weight={600} sx={{ display: 'flex', gap: 5 }}>
            Qty <Text color="red"> *</Text>
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text size="sm" weight={600} align="end">
            Retail Price
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" weight={600} align="end">
            Discount
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600} align="end">
            Discounted Price
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" weight={600} align="end">
            Total
          </Text>
        </Grid.Col>
      </Grid>

      <Box>
        {items?.map(({ key, id, price, discount }, index) => (
          <Product
            key={key ?? id}
            id={id}
            form={form}
            index={index}
            newItemId={key}
            price={price}
            isBlocked={isBlocked}
            discount={discount}
          />
        ))}
      </Box>
      {children}
    </Box>
  );
};
