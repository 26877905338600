import { omit } from 'rambda';
import { InventoryTransferService } from 'App/api';
import { useApiMutation, useApiQuery } from 'hooks';
import {
  InventoryTransferDto,
  InventoryTransferPayload,
  InventoryTransferStatus,
  normalizeTransferlValue,
} from 'shared';
import { getTotal } from '../components/form';

const getInventoryTransfer = 'getInventoryTransfer';

export const useEditInventoryTransfer = ({
  id,
  onCancel,
  onCreate = () => null,
}: {
  id: string;
  onCancel?: () => void;
  onCreate?: (id: string) => void;
}) => {
  const { data, isLoading } = useApiQuery(
    [getInventoryTransfer, id],
    () => InventoryTransferService.getOne({ id }),
    {
      cacheTime: 0,
    },
  );

  const { mutate: cancelTransfer, isLoading: isCancelled } = useApiMutation(
    [getInventoryTransfer, id],
    () => InventoryTransferService.cancel(id),
    {
      onSuccess: onCancel,
    },
  );

  const { mutate: updateTransfer, isLoading: isUpdating } = useApiMutation(
    [getInventoryTransfer, id],
    InventoryTransferService.update,
    {
      onSuccess: ({ id }) => onCreate(id),
    },
  );

  const { mutate: receiveTransfer, isLoading: isReceiving } = useApiMutation(
    [getInventoryTransfer, id],
    InventoryTransferService.receive,
    {
      onSuccess: ({ id }) => onCreate(id),
    },
  );

  const handleSubmit = (id: string, value: InventoryTransferPayload) => {
    const valueTransfer = omit(['origin', 'destination'], value);

    const payload = {
      id,
      data: {
        ...valueTransfer,
        items: valueTransfer.items.map(omit(['key'])),
        total: getTotal(valueTransfer.items),
        comment: '',
      },
    };

    updateTransfer(payload);
  };

  const handleReceivedTransfer = ({
    status,
    value,
  }: {
    status: InventoryTransferStatus;
    value: InventoryTransferDto;
  }) => {
    const { id, destination_inventory_location, origin_inventory_location } =
      value;
    const notAdjustment =
      status !== InventoryTransferStatus.fully_received
        ? omit(['adjustment_type'], value)
        : value;
    const valueReceive = omit(
      ['id', 'destination_inventory_location', 'origin_inventory_location'],
      notAdjustment,
    );

    const payload = {
      id,
      data: {
        ...valueReceive,
        destination_inventory_location_id: destination_inventory_location.id,
        origin_inventory_location_id: origin_inventory_location.id,
        status,
        items: valueReceive.items.map((i) => ({
          ...i,
          to_receive_quantity: i.to_receive_quantity ?? 0,
        })),
      },
    };

    receiveTransfer(payload);
  };

  const initialValues = data && normalizeTransferlValue(data);

  return {
    data,
    initialValues,
    isLoading,
    cancelTransfer,
    isCancelled,
    handleSubmit,
    isUpdating,
    handleReceivedTransfer,
    isReceiving,
  };
};
