import { filter } from 'rambda';
import { useMemo } from 'react';

import { usePermissions } from './usePermissions';
import { authenticatedRoutes, RouteConfig } from 'App/routes';

export const useAllowedRoutes = (): RouteConfig[] => {
  const { checkAccess } = usePermissions();

  const allowedRoutes = useMemo(
    () =>
      filter(
        ({ permission }: RouteConfig) => checkAccess(permission),
        authenticatedRoutes,
      ),
    [checkAccess],
  );

  return allowedRoutes;
};
