import { useCallback, useEffect, useState } from 'react';
import { move } from 'rambda';

import { useApiMutation } from 'hooks';
import { ProductCategoryService } from 'App/api';
import { ProductCategoryDto } from 'shared';
import { getOrderedCategories, getReorderedCategories } from '../helpers';

export const useOrderedCategories = ({
  onSuccess = () => null,
  categories,
}: {
  categories: ProductCategoryDto[];
  onSuccess?: () => void;
}) => {
  const [output, setOutput] = useState<ProductCategoryDto[]>([]);

  const { mutate, isLoading } = useApiMutation(
    ['reorder'],
    ProductCategoryService.reorder,
    {
      onSuccess,
    },
  );

  const reorder = useCallback(() => {
    mutate(getReorderedCategories(output));
  }, [mutate, output]);

  const restore = useCallback(() => {
    setOutput(getOrderedCategories(categories));
  }, [categories]);

  const onOrderChange = useCallback(
    ({
      oldIndex,
      newIndex,
      parentId,
    }: {
      oldIndex: number;
      newIndex: number;
      parentId?: string;
    }) => {
      if (parentId) {
        setOutput((prev) => [
          ...prev.map(({ id, subcategories, ...rest }) => ({
            ...rest,
            id,
            subcategories:
              id === parentId
                ? move(oldIndex, newIndex, subcategories)
                : subcategories,
          })),
        ]);
      } else {
        setOutput(move(oldIndex, newIndex, output));
      }
    },
    [output],
  );

  useEffect(() => {
    restore();
  }, [restore]);

  return {
    isLoading,
    output,
    restore,
    reorder,
    onOrderChange,
  };
};
