import { useCallback } from 'react';

import { LocationService } from 'App/api';
import { useApiMutation, useApiQuery } from 'hooks';
import { FeesPayload, FeeValues } from 'shared';

interface Props {
  id: string;
  onUpdate?: () => void;
}

export const useEditFees = ({ id, onUpdate }: Props) => {
  const { data, isLoading, isError } = useApiQuery(
    ['getLocalTaxes', id],
    () => LocationService.getLocalTaxes(id),
    {
      cacheTime: 0,
    },
  );

  const { mutate: update, isLoading: processing } = useApiMutation(
    ['updateFees'],
    (data: FeesPayload) => LocationService.updateFees(id, data),
    {
      onSuccess: onUpdate,
    },
  );

  const handleSubmit = useCallback(
    (values: FeeValues) => {
      const payload = {
        ...values,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fees: values.fees.map(({ id, ...fee }) => ({ ...fee })),
      };
      update(payload);
    },
    [update],
  );

  return {
    data,
    isLoading,
    isError,
    handleSubmit,
    processing,
  };
};
