import { Location } from './locations';
import { BaseDto } from '../common';

export enum Permissions {
  admin = 'admin',
  admin_panel_access = 'admin_panel_access',
  admin_panel_locations_view = 'admin_panel_locations_view',
  admin_panel_locations_create = 'admin_panel_locations_create',
  admin_panel_locations_edit = 'admin_panel_locations_edit',
  admin_panel_locations_delete = 'admin_panel_locations_delete',
  admin_panel_categories_view = 'admin_panel_categories_view',
  admin_panel_categories_create = 'admin_panel_categories_create',
  admin_panel_categories_edit = 'admin_panel_categories_edit',
  admin_panel_categories_delete = 'admin_panel_categories_delete',
  admin_panel_roles_view = 'admin_panel_roles_view',
  admin_panel_roles_create = 'admin_panel_roles_create',
  admin_panel_roles_edit = 'admin_panel_roles_edit',
  admin_panel_roles_delete = 'admin_panel_roles_delete',
  admin_panel_local_taxes_view = 'admin_panel_local_taxes_view',
  admin_panel_local_taxes_edit = 'admin_panel_local_taxes_edit',
  admin_panel_employees_access = 'admin_panel_employees_access',
  admin_panel_employees_view = 'admin_panel_employees_view',
  admin_panel_employees_create = 'admin_panel_employees_create',
  admin_panel_employees_edit = 'admin_panel_employees_edit',
  admin_panel_employees_disable = 'admin_panel_employees_disable',
  admin_panel_employees_activate = 'admin_panel_employees_activate',
  admin_panel_sku_view = 'admin_panel_sku_view',
  admin_panel_sku_create = 'admin_panel_sku_create',
  admin_panel_sku_edit = 'admin_panel_sku_edit',
  admin_panel_sku_delete = 'admin_panel_sku_delete',
  admin_panel_vendor_view = 'admin_panel_vendor_view',
  admin_panel_vendor_create = 'admin_panel_vendor_create',
  admin_panel_vendor_edit = 'admin_panel_vendor_edit',
  admin_panel_vendor_delete = 'admin_panel_vendor_delete',
  admin_panel_inventory_items_view = 'admin_panel_inventory_items_view',
  admin_panel_inventory_items_create = 'admin_panel_inventory_items_create',
  admin_panel_inventory_locations_view = 'admin_panel_inventory_locations_view',
  admin_panel_inventory_locations_create = 'admin_panel_inventory_locations_create',
  admin_panel_inventory_locations_edit = 'admin_panel_inventory_locations_edit',
  admin_panel_inventory_locations_delete = 'admin_panel_inventory_locations_delete',
  admin_panel_inventory_edit = 'admin_panel_inventory_edit',
  admin_panel_inventory_delete = 'admin_panel_inventory_delete',
  admin_panel_inventory_transfer_view = 'admin_panel_inventory_transfer_view',
  admin_panel_inventory_transfer_create = 'admin_panel_inventory_transfer_create',
  admin_panel_inventory_transfer_edit = 'admin_panel_inventory_transfer_edit',
  admin_panel_inventory_transfer_cancel = 'admin_panel_inventory_transfer_cancel',
  admin_panel_inventory_transfer_receive = 'admin_panel_inventory_transfer_receive',
  admin_panel_adjustments_view = 'admin_panel_adjustments_view',
  admin_panel_adjustments_create = 'admin_panel_adjustments_create',
  admin_panel_adjustments_cancel = 'admin_panel_adjustments_cancel',
  admin_panel_partner_view = 'admin_panel_partner_view',
  admin_panel_partner_create = 'admin_panel_partner_create',
  admin_panel_partner_edit = 'admin_panel_partner_edit',
  admin_panel_partner_delete = 'admin_panel_partner_delete',
  admin_panel_library_view = 'admin_panel_library_view',
  admin_panel_library_create = 'admin_panel_library_create',
  admin_panel_library_cancel = 'admin_panel_library_cancel',
  admin_panel_library_release = 'admin_panel_library_release',
  admin_panel_culinary_sku_view = 'admin_panel_culinary_sku_view',
  admin_panel_culinary_sku_create = 'admin_panel_culinary_sku_create',
  admin_panel_culinary_sku_edit = 'admin_panel_culinary_sku_edit',
  admin_panel_culinary_sku_delete = 'admin_panel_culinary_sku_delete',
  admin_panel_culinary_menu_view = 'admin_panel_culinary_menu_view',
  admin_panel_culinary_menu_create = 'admin_panel_culinary_menu_create',
  admin_panel_culinary_menu_edit = 'admin_panel_culinary_menu_edit',
  admin_panel_culinary_menu_delete = 'admin_panel_culinary_menu_delete',
  admin_panel_customer_create = 'admin_panel_customer_create',
  admin_panel_customer_view = 'admin_panel_customer_view',
  admin_panel_customer_edit = 'admin_panel_customer_edit',
  admin_panel_customer_delete = 'admin_panel_customer_delete',
  admin_panel_club_tier_view = 'admin_panel_club_tier_view',
  admin_panel_shipment_cycle_view = 'admin_panel_shipment_cycle_view',
  admin_panel_shipment_cycle_create = 'admin_panel_shipment_cycle_create',
  admin_panel_shipment_cycle_edit = 'admin_panel_shipment_cycle_edit',
  admin_panel_shipment_cycle_delete = 'admin_panel_shipment_cycle_delete',
  admin_panel_shipment_cycle_detail_view = 'admin_panel_shipment_cycle_detail_view',
  admin_panel_shipment_cycle_sales_order_view = 'admin_panel_shipment_cycle_sales_order_view',
  admin_panel_shipment_cycle_sales_order_edit = 'admin_panel_shipment_cycle_sales_order_edit',
  admin_panel_shipment_sales_order_view = 'admin_panel_shipment_sales_order_view',
  admin_panel_shipment_sales_order_edit = 'admin_panel_shipment_sales_order_edit',
  admin_panel_discounts_create = 'admin_panel_discounts_create',
  admin_panel_discounts_view = 'admin_panel_discounts_view',
  admin_panel_discounts_edit = 'admin_panel_discounts_edit',
  admin_panel_discounts_delete = 'admin_panel_discounts_delete',
  pos_access = 'pos_access',
  pos_menu_view = 'pos_menus_view',
  pos_order_view = 'pos_order_view',
  pos_order_create = 'pos_order_create',
  pos_order_edit = 'pos_order_edit',
  pos_order_delete_ordered_items = 'pos_order_delete_ordered_items',
  pos_device_settings_management = 'pos_device_settings_management',
  pos_table_map_create = 'pos_table_map_create',
  pos_table_map_read = 'pos_table_map_read',
  pos_table_map_edit = 'pos_table_map_edit',
  pos_table_map_delete = 'pos_table_map_delete',
  pos_sales_order_view = 'pos_sales_order_view',
  pos_sales_order_create = 'pos_sales_order_create',
  pos_sales_order_edit = 'pos_sales_order_edit',
  pos_order_add_remove_helper_wine_advisor = 'pos_order_add_remove_helper_wine_advisor',
  pos_order_change_default_wine_advisor = 'pos_order_change_default_wine_advisor',
}

export interface RoleDto extends RoleListDto {
  location: Location;
}

export interface RoleListDto extends BaseDto {
  description?: string;
  permissions: Permissions[];
}

export interface CreateRole {
  name: string;
  description?: string;
  permissions: Permissions[];
  location_id: string;
}

export interface EditRole extends Omit<CreateRole, 'location_id'> {
  role_id: string;
}

export type RoleValues = Omit<CreateRole, 'location_id'>;

export interface RoleNode {
  value: string;
  label: string;
  children?: Node[];
}
