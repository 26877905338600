import { CustomerService } from 'App/api';
import { useApiMutation } from 'hooks';
import {
  ProfilePayload,
  ClubMembershipValues,
  dateInputOutputFormat,
} from 'shared';
import { getClubMemberPayload } from '../helpers';

type Props = {
  onExit: () => void;
};

type SubmitData = {
  profile: ProfilePayload;
  clubMember: ClubMembershipValues;
};

export const useCreateCustomer = ({ onExit }: Props) => {
  const { mutate, isLoading } = useApiMutation(
    ['createCustomer'],
    CustomerService.create,
    {
      onSuccess: onExit,
    },
  );

  const createCustomer = async ({ profile, clubMember }: SubmitData) => {
    const payload = {
      profile: {
        ...profile,
        date_of_birth: dateInputOutputFormat(
          profile.date_of_birth as string,
          'output',
        ),
      },
      addresses: [],
      wallets: [],
      club_membership: getClubMemberPayload(clubMember),
    };
    mutate(payload);
  };

  return {
    isLoading,
    createCustomer,
  };
};
