import { Fragment } from 'react';
import { Button, Divider, Grid, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import {
  ActionButtons,
  ChangeHistory,
  ComplianceHistory,
  CorrectionPayment,
  DeliveryData,
  DeliveryInformation,
  GeneralInfo,
  InventoryDetailsTable,
  LinkOrders,
  PaymentActions,
  PaymentInformation,
  PdfDocumentOrder,
  ShipcompliantStatus,
} from 'components';
import { useGeneratePdf } from 'hooks';
import {
  OrderDto,
  CycleOrderPayload,
  getOrderSchema,
  SalesOrderStatus,
  SalesOrderPaymentStatus,
  Payments,
  LinkOrderOptions,
  SalesOrderSource,
  AccountGroup,
  SupplyType,
  SendReceiptPayload,
  SalesOrderComplianceStatus,
  RefundType,
  RefundItemRequest,
  normalizePaymentItemsTable,
  getPaymentSummary,
  TransactionType,
  getPaymentMethod,
  RecalculateRequest,
  WalletSelectOption,
  getSelectOptions,
  PaymentMethod,
} from 'shared';
import { Mode } from '../../constants';

type PropType = {
  mode: Mode;
  goBack: () => void;
  goChangeHistory: () => void;
  initialValues: CycleOrderPayload;
  order: OrderDto;
  payments: Payments[];
  cards: WalletSelectOption[];
  linkOrders?: LinkOrderOptions;
  isUpdating: boolean;
  isResending: boolean;
  isRefunding: boolean;
  generationPDFPaymentId: string | null;
  resend: ({ id }: { id: string }) => void;
  handleRefund: ({
    type,
    paymentData,
  }: {
    type: RefundType;
    paymentData: {
      payment_id: string;
      items: RefundItemRequest[];
    };
  }) => void;
  handleRegenerateReceipt: (id: string) => void;
  handleSendReceipt: (data: SendReceiptPayload) => void;
  handleSubmit: (values: CycleOrderPayload) => void;
  handleRecalculatePaidOrder: (data: RecalculateRequest) => void;
};

export const SalesOrderForm = ({
  mode,
  initialValues,
  order,
  cards,
  payments,
  linkOrders,
  isUpdating,
  isResending,
  isRefunding,
  generationPDFPaymentId,
  resend,
  goBack,
  goChangeHistory,
  handleSubmit,
  handleRefund,
  handleSendReceipt,
  handleRecalculatePaidOrder,
  handleRegenerateReceipt,
}: PropType) => {
  const form = useForm({
    initialValues,
    validate: yupResolver(getOrderSchema()),
    validateInputOnBlur: true,
  });
  const { handleOpenPDF } = useGeneratePdf({
    pdfDocument: <PdfDocumentOrder order={order} />,
  });

  const defaultPickupLocation =
    order?.deplete_location && getSelectOptions([order.deplete_location])[0];

  const { items } = form.values;
  const {
    id,
    shipping_address,
    supply_type,
    status,
    source,
    customer,
    compliance_status,
    payment_status,
  } = order;

  const isProcessingOrder = SalesOrderStatus.PROCESSING === status;
  const isClosed = [SalesOrderStatus.CLOSED, SalesOrderStatus.CANCEL].includes(
    status,
  );

  const isCash = payments[0]?.method === PaymentMethod.cash;

  const isOrderRefunded = payment_status === SalesOrderPaymentStatus.REFUND;
  const isPaymentStatusError = payment_status === SalesOrderPaymentStatus.ERROR;
  const isThreeDSConfirmation =
    SalesOrderPaymentStatus.THREE_D_SECURE_CONFIRMATION === payment_status;

  const isEcommerce = source === SalesOrderSource.ECOMMERCE;
  const isUnknownUser =
    customer.account_group === AccountGroup.UNREGISTERED_CUSTOMER;

  const isInHouse = supply_type === SupplyType.IN_HOUSE;
  const isShipping = status === SalesOrderStatus.SHIPPING;
  const isDeliveryShipping = initialValues.delivery_method === 'Shipping';

  const isErrorComplianceStatus =
    compliance_status === SalesOrderComplianceStatus.ERROR;

  const saveDisabled =
    !form.isValid() || isProcessingOrder || isThreeDSConfirmation;

  if (Mode.HISTORY === mode)
    return <ComplianceHistory goBack={goBack} orderId={id} />;

  if (Mode.CHANGE_HISTORY === mode)
    return <ChangeHistory goBack={goBack} orderId={id} />;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {isShipping && isDeliveryShipping && (
        <ShipcompliantStatus
          action={
            isErrorComplianceStatus ? (
              <Button
                variant="outline"
                loading={isResending}
                onClick={() => resend({ id })}
              >
                Resend to Shipcompliant
              </Button>
            ) : null
          }
          complianceStatus={compliance_status}
        />
      )}
      <GeneralInfo order={order} form={form} />

      {payments.map(
        ({
          id: paymentId,
          key,
          transactions,
          payer,
          payer_name,
          method,
          card_brand,
          card_last4,
          s3_receipt_path,
          signature_url,
          to_refund,
          status,
          change,
        }) => {
          const paymentMethod = getPaymentMethod({
            method,
            card_brand,
            card_last4,
          });

          return (
            <Fragment key={key}>
              {transactions.map(
                ({
                  id,
                  type,
                  status: transactionStatus,
                  amount,
                  created_at,
                  succeeded_at,
                  detalization,
                  is_correction,
                }) => {
                  const isTransactionRefunded = type === TransactionType.REFUND;

                  const orderProducts = detalization.items.map(
                    normalizePaymentItemsTable,
                  );
                  const summary = getPaymentSummary(detalization);

                  return (
                    <Fragment key={id}>
                      {!is_correction && isTransactionRefunded && (
                        <Divider my="sm" />
                      )}
                      {!is_correction && (
                        <InventoryDetailsTable
                          orderProducts={orderProducts}
                          summary={summary}
                          corrections={order.corrections}
                          isRefunded={isTransactionRefunded}
                        />
                      )}
                      {!isTransactionRefunded && !is_correction && (
                        <LinkOrders linkOrders={linkOrders} />
                      )}
                      <PaymentInformation
                        key={id}
                        payer={payer}
                        status={transactionStatus}
                        paymentMethod={paymentMethod}
                        isRefunded={isTransactionRefunded}
                        method={method}
                        succeededAt={succeeded_at}
                        createAt={created_at}
                        change={change}
                        amount={amount}
                      >
                        <PaymentActions
                          transactionId={id}
                          paymentStatus={status}
                          paymentId={paymentId}
                          method={method}
                          payer={payer}
                          payerName={payer_name}
                          paymentMethod={paymentMethod}
                          s3ReceiptPath={s3_receipt_path}
                          signatureUrl={signature_url}
                          toRefund={to_refund}
                          isCorrection={is_correction}
                          isRefunded={isTransactionRefunded}
                          isRefunding={isRefunding}
                          isOrderRefunded={isOrderRefunded}
                          generationPDFIds={[generationPDFPaymentId ?? '']}
                          orderNumber={order.number.toString()}
                          handleSendReceipt={handleSendReceipt}
                          handleRefund={handleRefund}
                          handleRegenerateReceipt={handleRegenerateReceipt}
                          productItems={
                            items as {
                              id: string;
                              quantity: number;
                              payment_status: SalesOrderPaymentStatus;
                            }[]
                          }
                        />
                      </PaymentInformation>
                    </Fragment>
                  );
                },
              )}
            </Fragment>
          );
        },
      )}

      {(order?.calculated_correction || isPaymentStatusError) && (
        <CorrectionPayment
          form={form}
          isCash={isCash}
          paymentCard={cards}
          totalDueCorrection={order.calculated_correction?.total_due_correction}
        />
      )}

      {isClosed ? (
        <DeliveryInformation order={order} />
      ) : (
        <DeliveryData
          form={form}
          customerId={order.customer.id}
          defaultAddress={shipping_address?.address_line_1}
          shippingCost={order.shipping_cost}
          defaultPickupLocation={defaultPickupLocation}
          handleRecalculatePaidOrder={handleRecalculatePaidOrder}
          anonymus={
            isEcommerce &&
            isUnknownUser && (
              <>
                <Grid.Col span={4} />
                <Grid.Col span={4}>
                  <Text weight={600} mb="sm">
                    Email
                  </Text>
                  <Text>{order.customer?.email}</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text weight={600} mb="sm">
                    Phone Number
                  </Text>
                  <Text>{order.customer?.phone_number}</Text>
                </Grid.Col>
              </>
            )
          }
        />
      )}

      {isInHouse && isShipping && (
        <Button onClick={handleOpenPDF} variant="white">
          Print Packing List
        </Button>
      )}

      <ActionButtons
        marginTop={20}
        disabled={saveDisabled}
        loading={isUpdating}
        type="submit"
        label="Save"
        aria-label="save"
        secondary={
          <Button fullWidth onClick={goChangeHistory}>
            See change history
          </Button>
        }
      />
    </form>
  );
};
