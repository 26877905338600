import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text } from '@mantine/core';

import { ContentLayout, BackButton, EditButton } from 'components';
import { getEditPath, getProductFileName } from 'shared';
import { useEditSkus } from '../../hooks';
import { Kit, SpecificInformation } from './detailsSection';

export const Details = ({
  canEdit,
  rootPath,
}: {
  canEdit: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { sku, isLoading, vendorName } = useEditSkus({ id });

  const isKit = sku?.skuType === 'kit';
  const fullWidth = { width: '100%' };
  const gap = 50;

  return (
    <ContentLayout
      title={sku?.productName}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
      isLoading={isLoading}
      rightAction={
        canEdit && (
          <EditButton component={Link} to={getEditPath(rootPath, id)} />
        )
      }
    >
      <Box sx={{ marginBottom: 63 }}>
        <Text weight={600} size="lg" mb={20}>
          Product Profile
        </Text>
        <Text weight={600} size="sm">
          Product Name
        </Text>
        <Flex align="center" mb="lg" gap={gap}>
          <Box sx={fullWidth}>
            <Text size="sm" color="gray.5">
              {sku?.productName}
            </Text>
          </Box>
          <Flex justify="space-between" sx={fullWidth}>
            <Text weight={600} size="sm">
              {sku?.status}
            </Text>
            <Text weight={600} size="sm">
              {sku?.hideFromPOS}
            </Text>
            <Text weight={600} size="sm">
              {sku?.show_in_ecommerce}
            </Text>
          </Flex>
        </Flex>

        <Flex align="center" mb="lg" gap={gap}>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              Inventory Type
            </Text>
            <Text size="sm" color="gray.5">
              {sku?.inventoryType}
            </Text>
          </Box>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              Products
            </Text>
            <Text data-testid="added-sku-value" size="sm" color="gray.5">
              {sku?.skuNumber}
            </Text>
          </Box>
        </Flex>

        <Flex align="center" mb="lg" gap={gap}>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              Category
            </Text>
            <Text size="sm" color="gray.5">
              {sku?.category}
            </Text>
          </Box>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              Sub-Category
            </Text>
            <Text size="sm" color="gray.5">
              {sku?.subcategory}
            </Text>
          </Box>
        </Flex>

        <Flex align="start" mb="lg" gap={gap}>
          <Flex sx={fullWidth}>
            <Box sx={fullWidth}>
              <Text weight={600} size="sm">
                Cost
              </Text>
              <Text size="sm" color="gray.5">
                {sku?.cost}
              </Text>
            </Box>
            <Box sx={fullWidth}>
              <Text weight={600} size="sm">
                Price
              </Text>
              <Text size="sm" color="gray.5">
                {sku?.price}
              </Text>
            </Box>
          </Flex>

          <Flex sx={fullWidth} justify="space-between">
            <Text size="sm">Calculated Margin</Text>
            <Text size="sm" color="gray.5">
              {sku?.margin}
            </Text>
          </Flex>
        </Flex>

        <Flex align="center" mb="lg" gap={gap}>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              Tax Rate
            </Text>
            <Text size="sm" color="gray.5">
              {sku?.taxRate}
            </Text>
          </Box>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              Vendor
            </Text>
            <Text size="sm" color="gray.5">
              {vendorName ?? 'Not Assigned'}
            </Text>
          </Box>
        </Flex>
      </Box>

      {sku?.specificInformation && !isKit ? (
        <SpecificInformation
          specificInformation={sku?.specificInformation}
          btg={sku.btg}
        />
      ) : null}

      {isKit ? <Kit specificInformation={sku?.specificInformation} /> : null}

      <Box sx={{ marginBottom: 63 }}>
        <Text weight={600} size="lg" mb={20}>
          Shipping Settings
        </Text>

        <Flex align="end" mb="lg" gap={gap}>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              Processing Time
            </Text>
            <Text size="sm" color="gray.5">
              {sku?.processingTime}
            </Text>
          </Box>
          <Flex justify="space-between" sx={fullWidth}>
            <Text weight={600} size="sm">
              {sku?.pickupOnly}
            </Text>
            <Text weight={600} size="sm">
              {sku?.membersOnly}
            </Text>
          </Flex>
        </Flex>

        <Flex align="end" mb="lg" gap={gap}>
          <Flex sx={fullWidth} justify="space-between">
            <Box>
              <Text weight={600} size="sm">
                Width (in)
              </Text>
              <Text size="sm" color="gray.5">
                {sku?.width}
              </Text>
            </Box>
            <Box>
              <Text weight={600} size="sm">
                Height (in)
              </Text>
              <Text size="sm" color="gray.5">
                {sku?.height}
              </Text>
            </Box>
            <Box>
              <Text weight={600} size="sm">
                Depth (in)
              </Text>
              <Text size="sm" color="gray.5">
                {sku?.depth}
              </Text>
            </Box>
          </Flex>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              {sku?.shipsSeparately}
            </Text>
          </Box>
        </Flex>

        <Flex
          align="center"
          gap="lg"
          justify="space-between"
          sx={{ width: '50%', paddingRight: gap / 2 }}
        >
          <Box>
            <Text weight={600} size="sm">
              Weight (lb)
            </Text>
            <Text size="sm" color="gray.5">
              {sku?.weight}
            </Text>
          </Box>
          <Box>
            <Text weight={600} size="sm">
              Per Order Limit (pc)
            </Text>
            <Text size="sm" color="gray.5">
              {sku?.perOrderLimit}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box>
        <Text weight={600} size="lg" mb={20}>
          Additional Information
        </Text>
        <Flex align="center" mb="lg" gap={gap}>
          <Box sx={fullWidth}>
            {sku?.productFile ? (
              <Text size="sm" component="a" href={sku.productFile}>
                {getProductFileName(sku?.productFile)}
              </Text>
            ) : null}
          </Box>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              {sku?.eGiftCard}
            </Text>
          </Box>
        </Flex>

        <Flex align="center" mb="lg" gap={gap}>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              Product Description
            </Text>
            <Text size="sm" color="gray.5">
              {sku?.productDescription}
            </Text>
          </Box>
          <Box sx={fullWidth}>
            <Text weight={600} size="sm">
              Comment (not shown in POS)
            </Text>
            <Text size="sm" color="gray.5">
              {sku?.comment}
            </Text>
          </Box>
        </Flex>
      </Box>
    </ContentLayout>
  );
};
