import { FC, ReactNode } from 'react';
import { TextInput, Switch, Select, Container, Grid } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { useLocations } from 'App/contexts';
import { ActionButtons } from 'components';
import { InventoryLocationPayload, LOCATION_DROPDOWN } from 'shared';
import { validationSchema } from './validation';

export type Props = {
  handleSubmit: (values: InventoryLocationPayload) => void;
  initialValues: InventoryLocationPayload;
  children?: ReactNode;
};

export const Form: FC<Props> = ({
  handleSubmit,
  initialValues,
  children,
}): JSX.Element => {
  const form = useForm({
    initialValues,
    validate: yupResolver(validationSchema),
    validateInputOnBlur: true,
  });
  const { actualLocationOptions: options } = useLocations();

  return (
    <Container size={'xs'}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid gutter="xl">
          <Grid.Col span={12}>
            <Select
              required
              data-testid={LOCATION_DROPDOWN}
              label="Primary Location"
              data={options}
              {...form.getInputProps('location_id')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              required
              data-testid="location-name-input"
              label="Location Name"
              {...form.getInputProps('name')}
            />
          </Grid.Col>
          <Grid.Col span={12} pt={0}>
            <Switch
              label="Show in POS"
              labelPosition="right"
              {...form.getInputProps('show_in_pos', { type: 'checkbox' })}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <ActionButtons
              disabled={!form.isValid()}
              label="Save"
              fullWidth
              aria-label="save"
              secondary={children}
            />
          </Grid.Col>
        </Grid>
      </form>
    </Container>
  );
};
