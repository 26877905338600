import { useCallback, useMemo } from 'react';
import { useApiQuery, useQueryParams } from 'hooks';
import { DEFAULT_PAGE_SIZE, getParams, normalizeCulinaryMenu } from 'shared';
import { CulinaryMenuService } from 'App/api';

export const useMenu = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';
  const location = searchParams.getAll('location') as string[];
  const status = searchParams.get('status') ?? '';

  const params = useMemo(() => {
    return {
      page,
      page_size: DEFAULT_PAGE_SIZE,
      ...getParams({ status, search_string, location }),
    };
  }, [location, page, search_string, status]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getCulinaryMenus', key], () =>
    CulinaryMenuService.getCollection(params),
  );

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ ...data, page: '1' });
    },
    [handleSetSearchParams],
  );

  return {
    page,
    filters: { search_string, status, location },
    output: data?.items.map((i) => normalizeCulinaryMenu(i)),
    isLoading,
    totalRecords: data?.total ?? 0,
    updateFilters,
    handleSetSearchParams,
  };
};
