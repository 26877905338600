import { useForm, yupResolver } from '@mantine/form';
import { generalInformationSchema } from '../../forms/schemes';
import { ProfileForm } from '../../forms/ProfileForm';
import { ProfilePayload } from 'shared';
import { Container } from '@mantine/core';

type Props = {
  initialValues?: ProfilePayload;
  processing: boolean;
  canEdit?: boolean;
  errors: JSX.Element;
  isSendingPassword: boolean;
  handleResetPassword: () => void;
  handleSubmit: (values: ProfilePayload) => void;
};

export const ProfileTab = ({
  initialValues,
  processing,
  canEdit,
  errors,
  isSendingPassword,
  handleSubmit,
  handleResetPassword,
}: Props) => {
  const profileForm = useForm({
    initialValues: initialValues,
    validate: yupResolver(generalInformationSchema),
    validateInputOnBlur: true,
  });

  return (
    <>
      <Container size="xs">{errors}</Container>

      <ProfileForm
        isEdit
        form={profileForm}
        canEdit={canEdit}
        processing={processing}
        isSendingPassword={isSendingPassword}
        handleResetPassword={handleResetPassword}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
