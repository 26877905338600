import { Container, Flex, Grid, Text } from '@mantine/core';
import { ShipmentCycleInventoryDetails } from 'shared';

type Props = {
  inventoryDetails?: ShipmentCycleInventoryDetails;
  isAllCycles?: boolean;
};

export const InventoryCheck = ({ inventoryDetails, isAllCycles }: Props) => {
  const { summary, details, total_quantity } = inventoryDetails ?? {};

  const cycleUsersTitle = isAllCycles ? 'All Cycles' : 'Cycle';
  const cycleInventoryTitle = isAllCycles ? 'All Cycles' : 'the Cycle';

  return (
    <Container fluid px={0}>
      <Grid gutter="xl">
        <Grid.Col span={6}>
          <Flex justify="space-between">
            <Text size={14} weight={600}>
              Active Users in {cycleUsersTitle} (members)
            </Text>

            <Text size={14}>{summary?.active_members}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={6}>
          {details?.map(({ name, active_members }) => (
            <Flex key={name} justify="space-between" mb={33}>
              <Text size={14} weight={600}>
                {name} bottles
              </Text>

              <Text size={14}>{active_members}</Text>
            </Flex>
          ))}
        </Grid.Col>
        <Grid.Col span={12}>
          <Text size={18} weight={600}>
            Inventory to Cover {cycleInventoryTitle}:
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Flex justify="space-between">
            <Text size={14} weight={600}>
              Bottles quantity total:
            </Text>

            <Text size={14}>{total_quantity}</Text>
          </Flex>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
