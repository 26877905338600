import { BackButton, ContentLayout } from 'components';
import { ShipmentCycleInventoryDetails } from 'shared';
import { InventoryCheck } from './InventoryCheck';
import { Published } from './Published';

type Props = {
  inventoryDetails?: ShipmentCycleInventoryDetails;
  title: string;
  goBack: () => void;
  processing?: boolean;
  isLoading: boolean;
  isAllCycles?: boolean;
  action?: JSX.Element;
};

export const InventoryCheckDetails = ({
  inventoryDetails,
  title,
  goBack,
  action,
  isLoading,
  isAllCycles = false,
  processing,
}: Props) => {
  return (
    <ContentLayout
      title={title}
      isLoading={isLoading}
      leftAction={<BackButton onClick={goBack} />}
      rightAction={action}
      processing={processing}
      footer={<Published goBack={goBack} inventoryDetails={inventoryDetails} />}
    >
      <InventoryCheck
        isAllCycles={isAllCycles}
        inventoryDetails={inventoryDetails}
      />
    </ContentLayout>
  );
};
