import { Grid, Paper, Text } from '@mantine/core';

export const FilteredLists = () => (
  <Grid grow>
    <Grid.Col span={4}>
      <Paper p="lg">
        <Text>Off Today</Text>
      </Paper>
    </Grid.Col>
    <Grid.Col span={4}>
      <Paper p="lg">
        <Text>Top Sellers</Text>
      </Paper>
    </Grid.Col>
    <Grid.Col span={4}>
      <Paper p="lg">
        <Text>Top Wine Club Convert</Text>
      </Paper>
    </Grid.Col>
  </Grid>
);
