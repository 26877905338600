import { fireEvent, screen } from '@testing-library/react';

export const checkLabelText = (values: string[]) =>
  values.map((value) =>
    expect(screen.getByLabelText(value)).toBeInTheDocument(),
  );

export const touchInput = (input: Element | Element[]) => {
  if (Array.isArray(input)) {
    input.forEach((i) => {
      fireEvent.focus(i);
      fireEvent.blur(i);
    });
  } else {
    fireEvent.focus(input);
    fireEvent.blur(input);
  }
};
