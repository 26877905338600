import { Outlet } from 'react-router-dom';
import { AppShell } from '@mantine/core';
import { Header, SideMenu } from './components';

export const Layout = () => {
  return (
    <AppShell
      padding="md"
      fixed={true}
      sx={{ minWidth: '1440px' }}
      navbar={<SideMenu />}
      header={<Header />}
    >
      <Outlet />
    </AppShell>
  );
};
