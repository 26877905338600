import { SelectItem, Tabs } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { CycleSettingForm } from '../../forms/CycleSettingForm';
import { getSettingSchema, wineSelectionSchema } from '../../forms/schemas';
import {
  ClubTierDto,
  ShipmentCycleDto,
  ShipmentCycleStatus,
  ShippingDetails,
} from 'shared';
import { WineSelectionForm } from '../../forms/WineSelectionForm';
import { SuggestionsForm } from '../../forms/SuggestionsForm';
import {
  calculateInitialValue,
  calculateSuggestionInitialValue,
  rejectEmptyItems,
} from 'pages/membersClub/shipmentCycle/helpers';
import { EditButtons, DeleteButton } from '../../forms/components';
import { ShippingDetailsForm } from '../../forms/ShippingDetailsForm';

type Props = {
  initialValues?: ShipmentCycleDto;
  processing: boolean;
  canBeDeleted: boolean;
  isStartAfterSave: boolean;
  deleteCycle: () => void;
  handleSubmit: (values: ShipmentCycleDto) => void;
  tierOptions: SelectItem[];
  setIsInventoryCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStartAfterSave: React.Dispatch<React.SetStateAction<boolean>>;
  addonTiers: ClubTierDto[];
};

export const CycleTabs = ({
  tierOptions,
  processing,
  initialValues,
  isStartAfterSave,
  handleSubmit,
  setIsInventoryCheck,
  setIsStartAfterSave,
  addonTiers,
  canBeDeleted,
  deleteCycle,
}: Props) => {
  const {
    club_tier: clubTier,
    wine_selections: wineSelection,
    suggestions,
    freight_company,
    freight_method,
  } = initialValues ?? {};
  const wineSelectionInitialValues = {
    wine_selections: wineSelection ?? [],
  };

  const suggestionsInitialValues = {
    suggestions: suggestions ?? [],
  };

  const shippingDetailsInitialValues: ShippingDetails = {
    freight_company,
    freight_method,
  };

  const calculateValues = calculateInitialValue(clubTier);
  const initialWineSelection = rejectEmptyItems({
    mockValue: calculateValues,
    value: wineSelectionInitialValues.wine_selections,
  });

  const initialSuggestions = calculateSuggestionInitialValue(
    clubTier ? [...addonTiers, clubTier] : addonTiers,
  );

  const settingForm = useForm({
    initialValues,
    validate: yupResolver(getSettingSchema(isStartAfterSave)),
    validateInputOnBlur: true,
  });

  const wineSelectionForm = useForm({
    initialValues: {
      wine_selections: [
        ...wineSelectionInitialValues.wine_selections,
        ...initialWineSelection,
      ],
    },
    validate: yupResolver(wineSelectionSchema),
    validateInputOnBlur: true,
  });

  const suggestionsForm = useForm({
    initialValues: {
      suggestions: [
        ...suggestionsInitialValues.suggestions,
        ...initialSuggestions,
      ],
    },
  });

  const shippingDetailsForm = useForm({
    initialValues: shippingDetailsInitialValues,
  });

  const isValidAsDraft =
    !!settingForm.values.name && !!settingForm.values.club_tier_id;
  const isValid = settingForm.isValid() && wineSelectionForm.isValid();

  return (
    <>
      <Tabs.Panel value="settings" pt={32}>
        <CycleSettingForm
          isEdit
          form={settingForm}
          tierOptions={tierOptions}
          processing={processing}
          handleSubmit={handleSubmit}
          isStartAfterSave={isStartAfterSave}
          setIsStartAfterSave={setIsStartAfterSave}
          setIsInventoryCheck={setIsInventoryCheck}
        />
      </Tabs.Panel>

      <Tabs.Panel value="wineSelection" pt={32}>
        <WineSelectionForm isEdit tier={clubTier} form={wineSelectionForm} />
      </Tabs.Panel>

      <Tabs.Panel value="suggestions" pt={32}>
        <SuggestionsForm
          tiers={clubTier ? [clubTier, ...addonTiers] : addonTiers}
          form={suggestionsForm}
        />
      </Tabs.Panel>

      <Tabs.Panel value="shippingDetails" pt={32}>
        <ShippingDetailsForm form={shippingDetailsForm} />
      </Tabs.Panel>

      <EditButtons
        loading={processing}
        isValid={isValid}
        isValidDraft={isValidAsDraft}
        handleSubmit={(status: ShipmentCycleStatus) =>
          handleSubmit({
            ...settingForm.values,
            ...wineSelectionForm.values,
            ...suggestionsForm.values,
            ...shippingDetailsForm.values,
            status,
          })
        }
      />

      <DeleteButton
        canBeDeleted={canBeDeleted}
        deleteCycle={deleteCycle}
        isDeleting={processing}
      />
    </>
  );
};
