import { useRef, useState } from 'react';
import { GroupBase } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';
import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  NumberInput,
  Switch,
  Text,
  Grid,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';

import { CreateSku, SelectOption, SkusRequest } from 'shared';
import { SelectAsync } from 'components';

type OptionType = {
  value: string;
  label: string;
};

type PropType = {
  form: UseFormReturnType<CreateSku, (values: CreateSku) => CreateSku>;
  skuOptions: OptionType[];
  isDisabledKit: boolean;
  isLoadingSkus: boolean;
  hasMoreSku: boolean;
  setPageSku: React.Dispatch<React.SetStateAction<number>>;
  updateFilters: (data: SkusRequest) => void;
};

export const Kit = ({
  form,
  skuOptions,
  isDisabledKit,
  hasMoreSku,
  isLoadingSkus,
  setPageSku,
  updateFilters,
}: PropType) => {
  const selectRef =
    useRef<Select<OptionType, true, GroupBase<OptionType>>>(null);

  const [skusKit, setSkusKit] = useState<SelectOption[] | null>(null);
  const [kitQuantity, setKitQuantity] = useState<number | undefined>(1);

  const isKit = form.values.is_kit ?? false;
  const skuItems = form.values.specific_information?.items ?? [];
  const fullWidth = { width: '100%' };

  return (
    <Box>
      <Box mt={'xl'}>
        <Switch
          label="Kit"
          labelPosition="right"
          disabled={isDisabledKit}
          sx={{ fontWeight: 600, marginBottom: 30 }}
          {...form.getInputProps('is_kit', { type: 'checkbox' })}
        />
      </Box>
      <Collapse in={isKit} transitionDuration={1000}>
        <Grid gutter={'xl'}>
          <Grid.Col span={12}>
            <Text weight={600} size="lg">
              Add Products to a Kit
            </Text>
          </Grid.Col>

          <Grid.Col span={12}>
            <Grid gutter={'xl'} align="flex-end">
              <Grid.Col span={6}>
                <SelectAsync
                  isMulti
                  isSearchable
                  inputRef={selectRef}
                  setPage={setPageSku}
                  hasMore={hasMoreSku}
                  isLoading={isLoadingSkus}
                  searchByString={updateFilters}
                  onChange={(item) => setSkusKit(item as SelectOption[])}
                  options={skuOptions.filter(
                    (option) =>
                      !skuItems.map((i) => i.id).includes(option.value),
                  )}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <NumberInput
                  label="Quantity"
                  min={1}
                  sx={fullWidth}
                  value={kitQuantity}
                  onChange={setKitQuantity}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Button
                  disabled={!skusKit?.length || !kitQuantity}
                  onClick={() => {
                    skusKit?.forEach(({ value, label }) => {
                      const matches = label.match(/\[([^[\]]*)\]\s*([^[\]]*)/);

                      form.insertListItem('specific_information.items', {
                        id: value,
                        sku_number: matches?.[1],
                        product_name: matches?.[2],
                        quantity: kitQuantity,
                      });
                    });

                    setKitQuantity(1);
                    selectRef.current?.clearValue();
                    setSkusKit(null);
                  }}
                  sx={fullWidth}
                >
                  Add Products
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={6} offset={6}>
            {skuItems.map((item, index) => (
              <Grid gutter={'xl'} justify="space-between" key={item.id}>
                <Grid.Col span="auto">
                  <Text size={14} component="span">
                    [{item.sku_number}] {item.product_name}
                  </Text>{' '}
                  <Text size={14} component="span">
                    x {item.quantity}
                  </Text>
                </Grid.Col>
                <Grid.Col span="content">
                  <ActionIcon
                    color="gray"
                    onClick={() =>
                      form.removeListItem('specific_information.items', index)
                    }
                  >
                    <IconTrash size={16} />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            ))}
          </Grid.Col>
        </Grid>
      </Collapse>
    </Box>
  );
};
