import { useState } from 'react';
import { Button, Flex, Text, TextInput } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';

import { Payer, SendReceiptPayload } from 'shared';

type TypeProps = {
  name: string;
  orderNumber?: string;
  receiptPath: string | null;
  handleSubmit: (data: SendReceiptPayload) => void;
  payer: Payer | null;
};

export const PartialReceiptModal = ({
  name,
  payer,
  receiptPath,
  orderNumber,
  handleSubmit,
}: TypeProps) => {
  const [unknownPayer, setUnknownPayer] = useState({
    email: '',
    phoneNumber: '',
  });

  const customerEmail = payer?.email;
  const customerPhoneNumber = payer?.phone_number;

  const emailText = customerEmail
    ? `Send email ${customerEmail}`
    : 'Send email';

  const phoneNumberText = customerPhoneNumber
    ? `Send SMS ${customerPhoneNumber}`
    : 'Send SMS';

  const handleSend = (
    deliveryType: SendReceiptPayload['delivery_type'],
    deliveryTarget: SendReceiptPayload['delivery_target'],
  ) => {
    handleSubmit({
      delivery_type: deliveryType,
      delivery_target: deliveryTarget,
    });
    closeAllModals();
  };

  return (
    <Flex direction="column" gap="xl">
      <Text size="sm" weight={400} align="center">
        Select the way you want to get the receipt for <br />
        order #{orderNumber}, {name}
      </Text>
      <Button component="a" href={receiptPath ?? ''} target="_blanck">
        Print as PDF
      </Button>
      {!customerEmail && (
        <TextInput
          label="Email"
          value={unknownPayer.email}
          onChange={({ currentTarget }) =>
            setUnknownPayer((info) => ({ ...info, email: currentTarget.value }))
          }
        />
      )}
      <Button
        data-testid="send-email-btn"
        disabled={!customerEmail && !unknownPayer.email}
        onClick={() => handleSend('email', customerEmail ?? unknownPayer.email)}
      >
        {emailText}
      </Button>
      {!customerPhoneNumber && (
        <TextInput
          label="Phone Number"
          value={unknownPayer.phoneNumber}
          onChange={({ currentTarget }) =>
            setUnknownPayer((info) => ({
              ...info,
              phoneNumber: currentTarget.value,
            }))
          }
        />
      )}
      <Button
        data-testid="send-sms-btn"
        disabled={!customerPhoneNumber && !unknownPayer.phoneNumber}
        onClick={() =>
          handleSend('sms', customerPhoneNumber ?? unknownPayer.phoneNumber)
        }
      >
        {phoneNumberText}
      </Button>
    </Flex>
  );
};
