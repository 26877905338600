import * as Yup from 'yup';

import { requiredStringValidationRule } from 'shared';

const productSchema = Yup.object().shape({
  sku_id: requiredStringValidationRule('Product'),
  quantity: Yup.number().required('Required').min(1, ''),
});

export const transferSchema = Yup.object({
  origin: requiredStringValidationRule('Origin Primary Location'),
  destination: requiredStringValidationRule('Destination Primary Location'),
  origin_inventory_location_id: requiredStringValidationRule(
    'Origin Inventory Location',
  ),
  destination_inventory_location_id: requiredStringValidationRule(
    'Destination Inventory Location',
  ),
  is_library: Yup.bool(),
  items: Yup.array().of(productSchema),
});
