import { Grid, Switch, Text } from '@mantine/core';
import { ClubMembershipValues, ClubTierDto } from 'shared';
import { UseFormReturnType } from '@mantine/form';

type Props = {
  form: UseFormReturnType<ClubMembershipValues>;
  selectedAddon: ClubTierDto | undefined;
  selectedTier: ClubTierDto | undefined;
};

export const ClubMemberTierFields = ({
  form,
  selectedTier,
  selectedAddon,
}: Props): JSX.Element => {
  const { values, setFieldValue } = form;

  return (
    <Grid gutter={'xl'} mt="xs">
      <Grid.Col span={6}>
        <Grid gutter={'xl'}>
          <Grid.Col pb={0}>
            <Text size={18} weight={700}>
              Tier Preferences
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Switch
              data-testid="red-wine-type-switch"
              checked={values.tier.wine_type === 'reds'}
              disabled={!selectedTier?.wine_types_allowed.includes('reds')}
              label="Reds Only"
              onChange={() => setFieldValue('tier.wine_type', 'reds')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Switch
              data-testid="mixed-wine-type-switch"
              checked={values.tier.wine_type === 'mixed'}
              disabled={!selectedTier?.wine_types_allowed.includes('mixed')}
              label="Mixed Wines"
              onChange={() => setFieldValue('tier.wine_type', 'mixed')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Switch
              data-testid="wine-count-six-switch"
              checked={values.tier.bottle_quantity === 6}
              onChange={() => setFieldValue('tier.bottle_quantity', 6)}
              disabled={!selectedTier?.bottle_quantity_allowed.includes(6)}
              label="6 Bottles"
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Switch
              data-testid="wine-count-twelve-switch"
              checked={values.tier.bottle_quantity === 12}
              onChange={() => setFieldValue('tier.bottle_quantity', 12)}
              disabled={!selectedTier?.bottle_quantity_allowed.includes(12)}
              label="12 Bottles"
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      {!!selectedAddon && (
        <Grid.Col span={6}>
          <Grid gutter={'xl'}>
            <Grid.Col pb={0}>
              <Text size={18} weight={700}>
                Add-On Preferences
              </Text>
            </Grid.Col>
            <Grid.Col>
              <Text size={'sm'} weight={700} mt="15px">
                Silver Label Only
              </Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Switch
                data-testid="wine-count-six-switch"
                checked={values.add_on?.bottle_quantity === 6}
                onChange={() => setFieldValue('add_on.bottle_quantity', 6)}
                label="6 Bottles"
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Switch
                data-testid="wine-count-twelve-switch"
                checked={values.add_on?.bottle_quantity === 12}
                onChange={() => setFieldValue('add_on.bottle_quantity', 12)}
                label="12 Bottles"
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      )}
    </Grid>
  );
};
