import {
  dependentStringValidationRule,
  requiredStringValidationRule,
} from 'shared';
import * as yup from 'yup';

export const categoryFormSchema = yup.object({
  name: requiredStringValidationRule('Category Name'),
  parent_id: dependentStringValidationRule({
    dependencyFormAttribute: 'is_main_category',
    dependencyValue: false,
    dependentFieldName: 'Parent Category',
  }),
  is_main_category: yup.boolean(),
  active: yup.boolean(),
  hide_from_pos: yup.boolean(),
  members_only: yup.boolean(),
  pickup_only: yup.boolean(),
  paid_delivery_only: yup.boolean(),
});
