import { useNavigate } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { getEditPath, Permissions } from 'shared';
import { Edit, List } from './components';
import { NestedRouteComponent } from 'components';

export const Taxes = (): JSX.Element => {
  const navigate = useNavigate();
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.TaxesFees,
  });
  const canEdit = checkAccess(Permissions.admin_panel_local_taxes_edit);

  const onExit = useGotoRoute(RouteName.TaxesFees);
  const goToEdit = (id: string) => navigate(getEditPath(rootPath, id));

  const ListComponent = <List goToEdit={goToEdit} canEdit={canEdit} />;
  const EditComponent = <Edit onExit={onExit} />;

  return (
    <NestedRouteComponent
      showEdit={canEdit}
      showCreate={false}
      showDetails={false}
      rootPath={rootPath}
      ListComponent={ListComponent}
      Edit={EditComponent}
    />
  );
};
