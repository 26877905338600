import { useQueryClient } from '@tanstack/react-query';
import { LocationService } from 'App/api';
import { reject } from 'rambda';
import * as storage from 'App/session/PersistentStorage';
import { useApiQuery, useCurrentEmployee } from 'hooks';
import {
  getSelectOptions,
  Location,
  SelectOption,
  LocationTypes,
  HALTER_RANCH_LOCATION_ID,
  LOCATION_KEY,
} from 'shared';
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  useCallback,
} from 'react';

type TLocationsContextValues = {
  locations: Location[];
  isLoading: boolean;
  options: SelectOption[];
  actualLocationOptions: SelectOption[];
  refetch: () => void;
  currentLocation: string;
  selectLocation: (id: string) => void;
};

type LocationsProviderProps = {
  children: ReactNode;
};

const LocationsContext = createContext<TLocationsContextValues>({
  locations: [],
  isLoading: false,
  options: [],
  actualLocationOptions: [],
  refetch: () => null,
  currentLocation: '',
  selectLocation: () => null,
});

const { Provider } = LocationsContext;

const LocationsProvider = ({
  children,
}: LocationsProviderProps): JSX.Element => {
  const [locations, setLocations] = useState<Location[]>([]);

  const { isLoading } = useApiQuery(
    [LOCATION_KEY, LocationTypes.HALTER_RANCH],
    () =>
      LocationService.getLocations({
        types: [LocationTypes.HALTER_RANCH, LocationTypes.INTERNAL],
      }),
    {
      onSuccess: (data) => setLocations(data.items),
    },
  );

  const {
    primary_location: { id: primary_location_id },
  } = useCurrentEmployee();

  const [currentLocation, setCurrentLocation] = useState<string>(
    storage.get(LOCATION_KEY) || primary_location_id,
  );

  const selectLocation = useCallback((id: string) => {
    setCurrentLocation(id);
    storage.set({ [LOCATION_KEY]: id });
  }, []);

  const client = useQueryClient();

  const refetch = () => {
    client.invalidateQueries([LOCATION_KEY]);
  };

  const options = useMemo(() => getSelectOptions(locations), [locations]);

  const actualLocationsOptions = useMemo(
    () => reject(({ value }) => value === HALTER_RANCH_LOCATION_ID, options),
    [options],
  );

  const value: TLocationsContextValues = {
    locations,
    options,
    refetch,
    isLoading,
    currentLocation,
    selectLocation,
    actualLocationOptions: actualLocationsOptions,
  };

  return <Provider value={value}>{children}</Provider>;
};

const useLocations = (): TLocationsContextValues => {
  return useContext(LocationsContext);
};

export {
  LocationsProvider,
  LocationsContext,
  useLocations,
  HALTER_RANCH_LOCATION_ID,
};
