import { ContentLayout, BackButton } from 'components';
import { useCreateInventoryLibrary } from '../../hooks';
import { Form, getNewItem } from '../form';

const title = 'New Library Reserve';

const initialValues = {
  inventory_location_id: '',
  items: [getNewItem()],
  comment: '',
};

export const Create = ({ onExit }: { onExit: () => void }): JSX.Element => {
  const { handleSubmit, isLoading } = useCreateInventoryLibrary({
    onCreate: onExit,
  });
  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      leftAction={<BackButton onClick={onExit} />}
    >
      <Form handleSubmit={handleSubmit} initialValues={initialValues} />
    </ContentLayout>
  );
};
