export const PROCESSING_TIME = [
  {
    value: 'ships_same_day',
    label: 'Ships Same Day',
  },
  {
    value: 'ships_next_business_day',
    label: 'Ships Next Business Day',
  },
  {
    value: 'ships_in_two_three_business_days',
    label: 'Ships in 2 - 3 Business Days',
  },
  {
    value: 'ships_in_three_five_days',
    label: 'Ships in 3 - 5 Days',
  },
  {
    value: 'ships_in_one_week',
    label: 'Ships in 1 Week',
  },
  {
    value: 'ships_in_two_three_weeks',
    label: 'Ships in 2 - 3 Weeks',
  },
  {
    value: 'ships_when_available',
    label: 'Ships When Available',
  },
  {
    value: 'call_for_detail',
    label: 'Call for Details',
  },
  {
    value: 'special_order',
    label: 'Special Order',
  },
  {
    value: 'not_assigned',
    label: 'Not Assigned',
  },
];

export const BOTTLE_SIZE = [
  { value: '375_ml', label: '375 ML' },
  { value: '750_ml', label: '750 ML' },
  { value: '1500_ml', label: '1500 ML' },
  { value: '3000_ml', label: '3000 ML' },
  { value: '6000_ml', label: '6000 ML' },
  { value: 'keg', label: 'Keg' },
];

export const VARIETAL = [
  { value: 'ancestor', label: 'Ancestor' },
  { value: 'cdp', label: 'Cdp' },
  { value: 'cabernet_sauvignon', label: 'Cabernet Sauvignon' },
  { value: 'syrah', label: 'Syrah' },
  { value: 'vin_de_paille', label: 'Vin De Paille' },
  { value: 'carignan', label: 'Carignan' },
  { value: 'grenache_blanc', label: 'Grenache Blanc' },
  { value: 'libelle', label: 'Libelle' },
  { value: 'rose', label: 'Rose' },
  { value: 'picpoul_blanc', label: 'Picpoul Blanc' },
  { value: 'vermentino', label: 'Vermentino' },
  { value: 'viognier', label: 'Viognier' },
  { value: 'malbec', label: 'Malbec' },
  { value: 'cuvee_alice', label: 'Cuvee Alice' },
  { value: 'tannat', label: 'Tannat' },
  { value: 'tempranillo', label: 'Tempranillo' },
  { value: 'block_22', label: 'Block 22' },
  { value: 'synthesis', label: 'Synthesis' },
  { value: 'merlot', label: 'Merlot' },
  { value: 'cabernet_france', label: 'Cabernet France' },
  { value: 'zinfandel', label: 'Zinfandel' },
  { value: 'sauvignon_blanc', label: 'Sauvignon Blanc' },
  { value: 'mourvedre', label: 'Mourvedre' },
  { value: 'alicante_bouschet', label: 'Alicante Bouschet' },
  { value: 'heida', label: 'Heida' },
  { value: 'nero_d_avola', label: `Nero d'Avola` },
  { value: 'petite_sirah', label: `Petite Sirah` },
];
