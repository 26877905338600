import {
  ActionIcon,
  FileInput,
  Switch,
  Text,
  Textarea,
  Grid,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { AdditionalInformationSku, getProductFileName } from 'shared';

type PropType = {
  form: UseFormReturnType<AdditionalInformationSku>;
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
};

export const AdditionalInformation = ({ form, setFile, file }: PropType) => {
  const linkFile = form.values.product_file;

  return (
    <Grid gutter={'xl'}>
      <Grid.Col span={12}>
        <Text weight={600} size="lg" mt="xl">
          Additional Information
        </Text>
      </Grid.Col>

      <Grid.Col span={6}>
        <Grid gutter={'xl'} justify="space-between" align={'center'}>
          <Grid.Col span={'content'}>
            <FileInput
              value={file}
              onChange={setFile}
              placeholder={getProductFileName(linkFile)}
              variant="unstyled"
            />
          </Grid.Col>
          <Grid.Col span={'content'}>
            {form.values.product_file || file ? (
              <ActionIcon
                color="gray"
                onClick={() => {
                  setFile(null);
                  form.setFieldValue('product_file', null);
                }}
              >
                <IconTrash />
              </ActionIcon>
            ) : null}
          </Grid.Col>
        </Grid>
      </Grid.Col>

      <Grid.Col span={6} pt={0}>
        <Switch
          label="E-gift Card"
          labelPosition="right"
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('e_gift_card', { type: 'checkbox' })}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Textarea
          label="Product Description"
          {...form.getInputProps('product_description')}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Textarea
          label="Comment (not shown in POS)"
          {...form.getInputProps('comment')}
        />
      </Grid.Col>
    </Grid>
  );
};
