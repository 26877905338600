import { Grid, Image, Text } from '@mantine/core';
import { PrivateDocumentDto } from 'shared';

type TypeProps = {
  signature: PrivateDocumentDto;
};

export const OrderAdditionalInfo = ({ signature }: TypeProps) => {
  return (
    <Grid mb="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          Additional Information
        </Text>
      </Grid.Col>
      <Grid.Col>
        <Text size="sm" weight={600} mb="xs">
          Collection Signature
        </Text>
        <Image width={100} src={signature.url} alt={signature.document_name} />
      </Grid.Col>
    </Grid>
  );
};
