import {
  Navbar,
  NavLink,
  useMantineTheme,
  ThemeIcon,
  ScrollArea,
} from '@mantine/core';
import { filter, find, startsWith, replace } from 'rambda';
import { Link, useLocation } from 'react-router-dom';

import { RouteName } from 'App/routes/interfaces';
import { useAllowedRoutes } from 'hooks';

const dropAsterisk = replace('/*', '');

export function SideMenu() {
  const routes = useAllowedRoutes();
  const theme = useMantineTheme();
  const { pathname } = useLocation();
  const highLevelRoutes = filter(
    (r) => !!r.showInMenu && r.menuLevel === 0,
    routes,
  );

  const isActive = (path: string) => startsWith(path, pathname);

  return (
    <Navbar width={{ base: 300 }}>
      <ScrollArea scrollbarSize={6} scrollHideDelay={300} p="sm">
        <Navbar.Section grow mt="xs">
          {highLevelRoutes.map(({ label, children, icon: Icon, key, path }) => {
            const IconComponent = Icon ? (
              <ThemeIcon color={theme.colors.halterBlue[0]} variant="light">
                <Icon size={16} stroke={1} color={theme.colors.halterBlue[7]} />
              </ThemeIcon>
            ) : null;
            if (children?.length) {
              return (
                <NavLink
                  key={key}
                  label={label}
                  data-testid={label
                    .replace(/\s+/g, '-')
                    .replace(/'/g, '')
                    .toLowerCase()}
                  icon={IconComponent}
                  childrenOffset={38}
                  defaultOpened={isActive(path)}
                  active={isActive(path)}
                >
                  {children.map((c: RouteName) => {
                    const {
                      label,
                      key,
                      path: p = '',
                    } = find((r) => r.key === c, routes) ?? {};
                    const to = dropAsterisk(p);
                    return (
                      key && (
                        <NavLink
                          key={key}
                          data-testid={label
                            ?.replace(/\s+/g, '-')
                            .toLowerCase()}
                          component={Link}
                          to={to}
                          label={label}
                          active={isActive(to)}
                        />
                      )
                    );
                  })}
                </NavLink>
              );
            }

            return (
              <NavLink
                component={Link}
                data-testid={label.replace(/\s+/g, '-').toLowerCase()}
                to={dropAsterisk(path)}
                key={key}
                label={label}
                icon={IconComponent}
                active={isActive(dropAsterisk(path))}
              />
            );
          })}
        </Navbar.Section>
      </ScrollArea>
    </Navbar>
  );
}
