import { Box, Flex, Text } from '@mantine/core';

export const SummaryOrder = ({
  item,
  isRefunded = false,
}: {
  item: [string, string];
  isRefunded?: boolean;
}) => {
  return (
    <Flex justify="flex-end" mb={2}>
      <Box>
        <Text align="right" size="sm">
          {item[0]}
        </Text>
      </Box>
      <Box sx={{ width: '11%' }}>
        <Text align="right" size="sm">
          {isRefunded ? `-${item[1]}` : item[1]}
        </Text>
      </Box>
    </Flex>
  );
};
