import { FC } from 'react';
import { Anchor, Box, Text } from '@mantine/core';
import { UpdEmployeeViewData } from 'shared';
import { Link } from 'react-router-dom';

export const UpdatingData: FC<{
  updateData?: UpdEmployeeViewData;
}> = ({ updateData }): JSX.Element => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
    <Text size={14} color="dimmed">
      Last edited:
    </Text>
    <Text
      size={14}
      color="dimmed"
    >{`${updateData?.updateDate.value}, ${updateData?.updatedBy.value}`}</Text>
    <Anchor size={14} component={Link} to="">
      See edit history
    </Anchor>
  </Box>
);
