import { useState } from 'react';
import { without } from 'rambda';
import { ActionIcon, Flex, Grid, Loader, Select } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { UseFormReturnType } from '@mantine/form';

import { CreateSku, LOADER } from 'shared';
import { useNonInventory } from 'pages/products/skus/hooks';

type PropType = {
  form: UseFormReturnType<CreateSku, (values: CreateSku) => CreateSku>;
  index: number;
};

export const LocationItem = ({ form, index }: PropType) => {
  const [locationId, setLocationId] = useState<string | null>(null);

  const locations = form.values.specific_information?.locations;
  const inventoryLocationIds = locations?.map(
    ({ inventory_location }) => inventory_location.id,
  );
  const rejectInventorylocation = without(
    [locations?.[index].inventory_location.id],
    inventoryLocationIds as (string | undefined)[],
  );

  const { actualLocationOptions, inventoryLocationOptions, isLoading } =
    useNonInventory({
      locationId: locationId ?? locations?.[index].location.id,
      inventoryLocationsIds: rejectInventorylocation,
    });

  return (
    <Grid gutter="xl" columns={26}>
      <Grid.Col span={6}>
        <Select
          required
          data={actualLocationOptions}
          label="Primary Location"
          {...form.getInputProps(
            `specific_information.locations.${index}.location.id`,
          )}
          onChange={(value) => {
            setLocationId(value);
            form.setFieldValue(
              `specific_information.locations.${index}.location.id`,
              value,
            );
            form.setFieldValue(
              `specific_information.locations.${index}.inventory_location.id`,
              '',
            );
          }}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          required
          data={inventoryLocationOptions}
          label="Inventory Location"
          icon={
            isLoading &&
            locationId === locations?.[index].location.id &&
            !!locations?.[index].key ? (
              <Loader data-testid={LOADER} size={20} />
            ) : null
          }
          {...form.getInputProps(
            `specific_information.locations.${index}.inventory_location.id`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex justify="flex-end" mt={28}>
          <ActionIcon
            color="gray"
            disabled={locations?.length === 1}
            onClick={() =>
              form.removeListItem('specific_information.locations', index)
            }
          >
            <IconTrash stroke={1} size={24} />
          </ActionIcon>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};
