import { randomId } from '@mantine/hooks';
import { BottleQuantity, ClubTierDto, WineSelection } from 'shared';

export const getNewSkuItem = ({
  type,
  bottleSize,
}: {
  type?: string;
  bottleSize?: BottleQuantity;
}): WineSelection => ({
  wine_type: type ?? '',
  bottle_quantity: bottleSize ?? 0,
  quantity: 0,
  is_swappable: true,
  key: randomId(),
});

const getNewSkuItems = (type: string, sizes: BottleQuantity[]) => {
  return sizes.map((size) => getNewSkuItem({ type, bottleSize: size }));
};

export const calculateInitialValue = (tier?: ClubTierDto) => {
  if (!tier?.wine_types_allowed || !tier?.bottle_quantity_allowed) {
    return [];
  }

  const { wine_types_allowed, bottle_quantity_allowed } = tier;

  const initialItems = wine_types_allowed.flatMap((type) => {
    return getNewSkuItems(type, bottle_quantity_allowed);
  });

  return initialItems;
};

export const rejectEmptyItems = ({
  mockValue,
  value,
}: {
  mockValue: WineSelection[];
  value: WineSelection[];
}) => {
  return mockValue.filter(({ wine_type, bottle_quantity }) => {
    return !value.some(
      ({ wine_type: wineTypeValue, bottle_quantity: bottleQuantityValue }) => {
        return (
          wine_type === wineTypeValue && bottle_quantity === bottleQuantityValue
        );
      },
    );
  });
};
