import { PaginatedRequest, ShortBase } from '../common';
import { ShortEmployee } from '../employee';

export enum AdjustmentType {
  SPOIL = 'spoil',
  POURING = 'pouring',
  MANAGER_SAMPLE = 'manager_sample',
  BALANCING = 'balancing',
}

export const adjustmentTypeLabels: Record<AdjustmentType, string> = {
  [AdjustmentType.SPOIL]: 'Spoil',
  [AdjustmentType.POURING]: 'Pouring',
  [AdjustmentType.MANAGER_SAMPLE]: 'Manager Sample',
  [AdjustmentType.BALANCING]: 'Balancing',
};

export interface AdjustmentListItemDto {
  id: string;
  number: string;
  location: ShortBase;
  inventory_location: ShortBase;
  comment?: string;
  adjustment_type: AdjustmentType;
  quantity: number;
  created_at: string;
  created_by: ShortEmployee;
}

export interface AdjustmentListItemOutput {
  id: string;
  number: string;
  location: string;
  inventory_location: string;
  comment?: string;
  adjustment_type: string;
  quantity: number;
  created_at: string;
  creator: string;
}

export interface AdjustmentProduct {
  sku_id: string;
  quantity: number;
  cost: number;
}

export interface AdjustmentPayload {
  inventory_location_id: string;
  adjustment_type: AdjustmentType;
  comment?: string;
  items: AdjustmentProduct[];
}

export interface AdjustmentListRequest extends PaginatedRequest {
  search_string?: string;
  location?: string[];
  adjustment_type?: string;
}

export type AdjustmentItem = {
  sku_id: string;
  sku_number: string;
  sku_product_name: string;
  cost: number;
  quantity: number;
};

export interface AdjustmentDetailDto {
  id: string;
  number: string;
  inventory_location: ShortBase;
  location: ShortBase;
  adjustment_type: AdjustmentType;
  comment?: string;
  items: AdjustmentItem[];
  created_at: string;
  created_by: ShortEmployee;
  issued_at: string;
  issued_by: ShortEmployee;
}
