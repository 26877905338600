import { Button, Collapse, Grid } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { CreateVendor, vendorSchema } from 'shared';
import { ActionButtons } from 'components';
import { AdditionalLocation, VendorProfile } from './formSections';
import { randomId } from '@mantine/hooks';

export type PropsType = {
  handleSubmit: (values: CreateVendor) => void;
  processing: boolean;
  initialValues?: CreateVendor;
  action?: React.ReactNode;
};

const initialVendorLocation = {
  location_id: '',
  street_address: '',
  city: '',
  state: '',
  zip_code: '',
  phone_number: '',
  primary_contact_name: '',
  primary_contact_phone_number: '',
  primary_contact_email: '',
  same_contact_details: false,
};

export const Form = ({
  handleSubmit,
  processing,
  initialValues,
  action,
}: PropsType) => {
  const form = useForm({
    initialValues,
    validate: yupResolver(vendorSchema),
    validateInputOnBlur: true,
  });
  const { vendor_locations, is_location_specific } = form.values;
  const isLocationSpecific = is_location_specific || false;

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <VendorProfile form={form} />
      {vendor_locations.map((_, index) => (
        <Collapse
          in={isLocationSpecific}
          transitionDuration={1000}
          key={randomId()}
        >
          <AdditionalLocation form={form} index={index} />
        </Collapse>
      ))}
      <Grid>
        <Grid.Col span={12}>
          <Button
            my="md"
            variant="subtle"
            disabled={!is_location_specific}
            onClick={() =>
              form.insertListItem('vendor_locations', initialVendorLocation)
            }
          >
            + Add New Location Representative
          </Button>
        </Grid.Col>

        <Grid.Col span={12}>
          <ActionButtons
            loading={processing}
            disabled={!form.isValid()}
            type="submit"
            fullWidth
            label="Save"
            secondary={action}
          />
        </Grid.Col>
      </Grid>
    </form>
  );
};
