import { List } from '@mantine/core';

type Props = {
  options: { title: string; icon: React.ReactNode }[];
};

export const HintSection = ({ options }: Props) => {
  return (
    <List spacing="xs" size="sm" center>
      {options.map(({ title, icon }) => (
        <List.Item key={title} icon={icon}>
          {title}
        </List.Item>
      ))}
    </List>
  );
};
