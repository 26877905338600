import { LocationService } from 'App/api';
import { useApiQuery } from 'hooks';
import { useMemo, useState } from 'react';
import { getSelectOptions, LocationTypes, Location } from 'shared';

const KEY = 'locations-for-adjustment';

export const usePartnerLocations = () => {
  const [locations, setLocations] = useState<Location[]>([]);

  const types = Object.values(LocationTypes).filter(
    (type) => type !== LocationTypes.HALTER_RANCH,
  );

  const { isLoading } = useApiQuery(
    [KEY],
    () =>
      LocationService.getLocations({
        types,
      }),
    {
      onSuccess: (data) => setLocations(data.items),
    },
  );

  const options = useMemo(() => getSelectOptions(locations), [locations]);

  return { locations, options, isLoading };
};
