import { useMemo, useState } from 'react';
import { ShipmentSalesOrdersService } from 'App/api';
import { useApiQuery } from 'hooks';
import { PaginatedRequest } from 'shared';

export const useComplianceHistory = ({ orderId }: { orderId: string }) => {
  const [page, setPage] = useState(1);

  const params: PaginatedRequest = useMemo(() => {
    return {
      page,
      order_id: orderId,
      page_size: 1,
    };
  }, [page, orderId]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getComplianceHistory', key], () =>
    ShipmentSalesOrdersService.getComplianceHistory({ params }),
  );

  return { data, page, totalRecords: data?.total ?? 0, isLoading, setPage };
};
