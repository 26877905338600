import { RoleService } from 'App/api';
import { useApiMutation } from 'hooks';
import { CreateRole, RoleValues } from 'shared';

import { Role } from '../role';

export const Create = ({
  onExit,
  location: location_id,
}: {
  onExit: () => void;
  location: string;
}): JSX.Element => {
  const { mutate: create, isLoading } = useApiMutation(
    ['createRole'],
    (data: CreateRole) => RoleService.create(data),
    {
      onSuccess: onExit,
    },
  );
  return (
    <Role
      title="Create New Role"
      handleSubmit={(data: RoleValues) => create({ ...data, location_id })}
      processing={isLoading}
    />
  );
};
