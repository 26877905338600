import { useNavigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import { ContentLayout, CreateButton } from 'components';
import { getViewPath, getCreatePath, PROPS_DATA_TABLE } from 'shared';
import { Filters } from '../filters';
import { useAdjustment } from '../../hooks';

export const List = ({
  rootPath,
  canCreate,
}: {
  rootPath: string;
  canCreate: boolean;
}): JSX.Element => {
  const navigate = useNavigate();
  const {
    page,
    filters,
    totalRecords,
    output,
    isLoading,
    updateFilters,
    handleSetSearchParams,
  } = useAdjustment();

  return (
    <ContentLayout
      title="Inventory Adjustment"
      processing={isLoading}
      rightAction={
        canCreate && (
          <CreateButton
            data-testid="new-adjustment-btn"
            to={getCreatePath(rootPath)}
          >
            + New Adjustment
          </CreateButton>
        )
      }
    >
      <Box mb="md">
        <Filters handleChange={updateFilters} values={filters} />
      </Box>
      <DataTable
        {...PROPS_DATA_TABLE}
        records={output}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        onRowClick={({ id }) => navigate(getViewPath(rootPath, id))}
        columns={[
          { accessor: 'number', title: 'Adjustment#' },
          { accessor: 'adjustment_type', title: 'Adjustment Type' },
          { accessor: 'created_at', title: 'Date' },
          {
            accessor: 'location',
            title: 'Location',
          },
          {
            accessor: 'inventory_location',
            title: 'Inventory Location',
          },
          { width: 100, accessor: 'quantity', title: 'Total  Qty' },
          { accessor: 'creator', title: 'Name' },
        ]}
      />
    </ContentLayout>
  );
};
