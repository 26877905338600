import { ShipmentSalesOrdersService } from 'App/api';
import { useApiQuery } from 'hooks';
import { CustomerOrderBody, SalesOrderPaymentStatus } from 'shared';

export const useCustomerOrders = ({
  data,
  customerId,
  paymentStatus,
  enabled = true,
}: {
  data?: CustomerOrderBody;
  paymentStatus?: SalesOrderPaymentStatus;
  customerId?: string;
  enabled?: boolean;
}) => {
  const { data: orders, isLoading } = useApiQuery(
    ['customerOrders', customerId],
    () =>
      ShipmentSalesOrdersService.getCustomerOrders({
        data,
        customer_id: customerId,
        ...(paymentStatus
          ? { params: { payment_statuses: [paymentStatus] } }
          : {}),
      }),
    { enabled },
  );

  return { orders, isLoading };
};
