import { CreatePayload, PaginatedRequest } from '../common';
import { ShortEmployee } from '../employee';

export enum LocationTypes {
  HALTER_RANCH = 'halter_ranch',
  INTERNAL = 'internal',
  WHOLESALER = 'wholesaler',
  FULFILMENT = 'fulfilment',
}

export interface Location {
  id: string;
  name: string;
  address: string;
  zip_code: string;
  city: string;
  state: string;
  phone_number: string;
  issued_at: string;
  issued_by: ShortEmployee;
  type: LocationTypes;
}

export interface LocationsRequest extends PaginatedRequest {
  types?: LocationTypes[];
}

export type CreateLocation = CreatePayload<Location>;
