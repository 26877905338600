import { useCallback, useMemo } from 'react';
import { InventoryLibraryService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import { DEFAULT_PAGE_SIZE, normalizeInventoryLibrary } from 'shared';

const getLibraryKey = 'getInventoryLibrary';

export const useInventoryLibrary = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
    location: [],
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';
  const location = searchParams.getAll('location') as string[];
  const status = searchParams.get('status') ?? undefined;

  const params = useMemo(() => {
    return {
      page,
      search_string,
      location,
      status,
      page_size: DEFAULT_PAGE_SIZE,
    };
  }, [location, page, search_string, status]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery([getLibraryKey, key], () =>
    InventoryLibraryService.getCollection(params),
  );

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ ...data, page: '1' });
    },
    [handleSetSearchParams],
  );

  return {
    page,
    filters: { page, search_string, location, status },
    output: data?.items.map((i) => normalizeInventoryLibrary(i)),
    isLoading,
    totalRecords: data?.total ?? 0,
    updateFilters,
    handleSetSearchParams,
  };
};
