import { Text, Container, LoadingOverlay } from '@mantine/core';

import { Form } from 'pages/products/categories/components';
import { useEditCategory } from '../../hooks';
import { ConfirmModal } from 'components';
import { LOADING_OVERLAY, ProductCategoryDto } from 'shared';

export const Category = ({
  canDelete,
  data,
  onDelete,
}: {
  canDelete: boolean;
  data: ProductCategoryDto;
  onDelete: () => void;
}): JSX.Element => {
  const { handleSubmit, handleDelete, processing, formData } = useEditCategory({
    data,
    onDelete,
  });

  return (
    <Container size={'sm'} sx={{ position: 'relative' }}>
      <LoadingOverlay
        data-testid={LOADING_OVERLAY}
        visible={processing}
        overlayBlur={2}
      />
      <Form
        handleSubmit={handleSubmit}
        initialValues={formData}
        action={
          canDelete && (
            <ConfirmModal
              type="button"
              variant="outline"
              fullWidth
              loading={processing}
              onConfirm={handleDelete}
              label="Delete"
            >
              <Text data-testid="delete-category-btn">
                Are you sure you want to delete the category?
              </Text>
            </ConfirmModal>
          )
        }
      />
    </Container>
  );
};
