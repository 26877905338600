import { Grid, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useMemo } from 'react';

import { useApiQuery } from 'hooks';
import { CustomerService } from 'App/api';
import { ActionButtons } from 'components';
import { getDateOutput, getFullName } from 'shared';

export const History = ({
  customerId,
  goBack,
}: {
  customerId: string;
  goBack: () => void;
}): JSX.Element => {
  const { data, isLoading } = useApiQuery(
    ['club-membership-history', customerId],
    () => CustomerService.getClubMembershipHistory(customerId),
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map(
      (
        {
          tier_name,
          start_date,
          end_date,
          reason_to_start,
          reason_to_cancel,
          customer_source_of_start,
          customer_source_of_cancel,
        },
        i,
      ) => ({
        id: `${tier_name}${i}`,
        tier_name,
        start_date: getDateOutput(start_date ?? '') || '-',
        end_date: getDateOutput(end_date ?? '') || '-',
        reason_to_start: reason_to_start ?? '-',
        reason_to_cancel: reason_to_cancel ?? '-',
        source_to_start:
          getFullName({
            first_name: customer_source_of_start?.first_name,
            last_name: customer_source_of_start?.last_name,
          }) || '-',
        source_to_end:
          getFullName({
            first_name: customer_source_of_cancel?.first_name,
            last_name: customer_source_of_cancel?.last_name,
          }) || '-',
      }),
    );
  }, [data]);
  return (
    <Grid gutter={'xl'}>
      <Grid.Col>
        <Text size={18} weight={700}>
          Club Membership History
        </Text>
      </Grid.Col>
      <Grid.Col pb={0}>
        <DataTable
          {...{
            noRecordsText: '',
            fontSize: 'md',
            verticalAlignment: 'top',
            verticalSpacing: 'sm',
            minHeight: 250,
          }}
          fetching={isLoading}
          records={output}
          columns={[
            { accessor: 'tier_name', title: 'Tier / Add-on' },
            { accessor: 'start_date', title: 'Start Date' },
            { accessor: 'source_to_start', title: 'Source' },
            {
              accessor: 'reason_to_start',
              title: 'Reason of Start',
            },
            {
              accessor: 'end_date',
              title: 'End Date',
            },
            { accessor: 'source_to_end', title: 'Source' },
            { accessor: 'reason_to_cancel', title: 'Reason of End' },
          ]}
        />
      </Grid.Col>
      <Grid.Col pt={0}>
        <ActionButtons
          variant="subtle"
          label="Back to Club Member"
          onClick={goBack}
        />
      </Grid.Col>
    </Grid>
  );
};
