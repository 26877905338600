import { Fragment } from 'react';
import {
  PaymentStatus,
  SalesOrderPaymentStatus,
  TransactionType,
  getPaymentMethod,
  getPaymentSummary,
  normalizeLinkOrders,
  normalizePaymentItemsTable,
} from 'shared';
import {
  InventoryDetailsTable,
  LinkOrders,
  PaymentActions,
  PaymentInformation,
} from 'components';
import { Modifiers } from '../modifiers';
import { Props } from './propsType';
import { normalizeRefundItems } from 'pages/orders/posOrders/helpers';

export const OtherAndWholeOrder = ({
  orderId,
  orderNumber,
  orderStatus,
  payment,
  isRefunding,
  generationPDFPaymentIds,
  handleRefund,
  handleSendReceipt,
  handleGeneratePartialReceipt,
}: Props) => {
  const isOrderRefunded = orderStatus === SalesOrderPaymentStatus.REFUND;

  return (
    <>
      {payment.map(({ id, status, transactions, sales_orders }) => {
        const linkOrders = normalizeLinkOrders(sales_orders, orderId);
        const isRefunded = [
          PaymentStatus.refunded,
          PaymentStatus.partially_refunded,
        ].includes(status);

        return (
          <Fragment key={id}>
            {transactions.map(({ detalization, id, type, is_correction }) => {
              const isTransactionRefunded = type === TransactionType.REFUND;
              const orderProducts = detalization.items.map(
                normalizePaymentItemsTable,
              );
              const summary = getPaymentSummary(detalization);
              return (
                <Fragment key={id}>
                  <InventoryDetailsTable
                    orderProducts={orderProducts}
                    summary={summary.filter(
                      (i) => i[0] !== 'Shipping Fee' && i[0] !== 'Shipping Tax',
                    )}
                    // ownerNames={owners.join(', ')}
                    isRefunded={isTransactionRefunded}
                    rowExpansion={{
                      trigger: 'always',
                      content: ({ record: { modifiers } }) => (
                        <Modifiers
                          modifiers={modifiers}
                          isRefunded={isRefunded}
                        />
                      ),
                    }}
                  />
                  {!isTransactionRefunded && !is_correction && (
                    <LinkOrders linkOrders={linkOrders} />
                  )}
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}

      {payment.map(
        ({
          id: paymentId,
          transactions,
          payer,
          payer_name,
          method,
          card_brand,
          card_last4,
          s3_receipt_path,
          signature_url,
          to_refund,
          status,
          change,
        }) => {
          const paymentMethod = getPaymentMethod({
            method,
            card_brand,
            card_last4,
          });

          return (
            <Fragment key={paymentId}>
              {transactions.map(
                ({
                  id,
                  amount,
                  type,
                  detalization,
                  succeeded_at,
                  created_at,
                  status: transactionStatus,
                  is_correction,
                }) => {
                  const isTransactionRefunded = type === TransactionType.REFUND;
                  const productItems = normalizeRefundItems(detalization.items);

                  return (
                    <Fragment key={id}>
                      <PaymentInformation
                        status={transactionStatus}
                        paymentMethod={paymentMethod}
                        isRefunded={isTransactionRefunded}
                        succeededAt={succeeded_at}
                        createAt={created_at}
                        payer={payer}
                        payerName={payer_name}
                        method={method}
                        change={change}
                        amount={amount}
                      >
                        <PaymentActions
                          paymentId={paymentId}
                          paymentMethod={paymentMethod}
                          paymentStatus={status}
                          method={method}
                          payer={payer}
                          payerName={payer_name}
                          s3ReceiptPath={s3_receipt_path}
                          signatureUrl={signature_url}
                          toRefund={to_refund}
                          transactionId={id}
                          isCorrection={is_correction}
                          isRefunded={isTransactionRefunded}
                          isRefunding={isRefunding}
                          isOrderRefunded={isOrderRefunded}
                          generationPDFIds={generationPDFPaymentIds}
                          orderNumber={orderNumber}
                          handleRefund={handleRefund}
                          handleSendReceipt={handleSendReceipt}
                          handleRegenerateReceipt={handleGeneratePartialReceipt}
                          productItems={productItems}
                        />
                      </PaymentInformation>
                    </Fragment>
                  );
                },
              )}
            </Fragment>
          );
        },
      )}
    </>
  );
};
