import { ReactNode } from 'react';
import { Grid, Box, ActionIcon, TextInput, Switch } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';

import { ProductCategoryFormValues } from 'shared';

type PropType = {
  form: UseFormReturnType<ProductCategoryFormValues>;
  children: ReactNode;
};

export const ProductOptions = ({ children, form }: PropType) => {
  const { product_options = [] } = form.values;

  return (
    <Box>
      {product_options?.map(({ required, key }, index) => {
        return (
          <Grid gutter="xl" key={key} mb="xs" align="top">
            <Grid.Col span={8}>
              <TextInput
                {...form.getInputProps(`product_options.${index}.name`)}
                onChange={({ currentTarget: { value: v } }) => {
                  form.setFieldValue(`product_options.${index}.name`, v);
                }}
              />
            </Grid.Col>
            <Grid.Col span={3} pt={2}>
              <Switch
                label="Required"
                checked={required}
                onChange={() => {
                  form.setFieldValue(
                    `product_options.${index}.required`,
                    !required,
                  );
                }}
              />
            </Grid.Col>
            <Grid.Col span={1} pt={20}>
              <Grid align="center" justify="flex-end">
                <ActionIcon
                  data-testid="delete-button"
                  onClick={() => {
                    form.setFieldValue(
                      'product_options',
                      product_options.filter((i) => i.key !== key),
                    );
                  }}
                >
                  <IconTrash size={24} stroke={1} />
                </ActionIcon>
              </Grid>
            </Grid.Col>
          </Grid>
        );
      })}
      {children}
    </Box>
  );
};
