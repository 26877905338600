import { useCallback, useMemo } from 'react';
import { reject, isEmpty } from 'rambda';
import { ProductVendorService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';

export const useVendors = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    company_name: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const company_name = searchParams.get('company_name') ?? '';

  const params = useMemo(() => {
    return {
      page,
      company_name,
    };
  }, [company_name, page]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getProductVendors', key], () =>
    ProductVendorService.getCollection(
      reject(isEmpty, params as Record<string, unknown>),
    ),
  );

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ ...data, page: '1' });
    },
    [handleSetSearchParams],
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map((e) => e);
  }, [data]);

  return {
    output,
    isLoading,
    page,
    totalRecords: data?.total ?? 0,
    filters: { company_name },
    updateFilters,
    handleSetSearchParams,
  };
};
