import * as Yup from 'yup';

import {
  requiredDateValidationRule,
  requiredStringValidationRule,
} from 'shared';

const dateFieldNotRequired = Yup.string().nullable();

export const cycleSettingSchema = Yup.object({
  name: requiredStringValidationRule('Cycle Name'),
  club_tier_id: requiredStringValidationRule('Club Membership Tier'),
  cycle_start_date: dateFieldNotRequired,
  cycle_start_time: dateFieldNotRequired,
  member_list_close_date: dateFieldNotRequired,
  end_of_customization_window: dateFieldNotRequired,
  billing_date: dateFieldNotRequired,
  billing_time: dateFieldNotRequired,
  re_billing_date: dateFieldNotRequired,
  re_billing_time: dateFieldNotRequired,
  estimated_shipping_date: dateFieldNotRequired,
  pick_up_deadline: dateFieldNotRequired,
});

export const getSettingSchema = (isStartAfterSave = false) =>
  Yup.object({
    name: requiredStringValidationRule('Cycle Name'),
    club_tier_id: requiredStringValidationRule('Club Membership Tier'),
    cycle_start_date: isStartAfterSave
      ? dateFieldNotRequired
      : requiredDateValidationRule('Cycle Start Date').nullable(),
    cycle_start_time: isStartAfterSave
      ? dateFieldNotRequired
      : requiredDateValidationRule('Cycle Start Time').nullable(),
    member_list_close_date: requiredDateValidationRule(
      'Member List Close Date',
    ).nullable(),
    end_of_customization_window: requiredDateValidationRule(
      'End of Customization Window',
    ).nullable(),
    billing_date: requiredDateValidationRule('Billing Date').nullable(),
    billing_time: requiredDateValidationRule('Billing Time').nullable(),
    re_billing_date: requiredDateValidationRule('Re-Billing Date').nullable(),
    re_billing_time: requiredDateValidationRule('Re-Billing Time').nullable(),
    estimated_shipping_date: requiredDateValidationRule(
      'Estimated Shipment Date',
    ).nullable(),
    pick_up_deadline: requiredDateValidationRule('Pick-Up Deadline').nullable(),
  });
