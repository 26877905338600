import { ReactNode } from 'react';
import { Grid, Text } from '@mantine/core';
import { TastingLimit as TastingLimitType } from 'shared';

export const TastingLimit = ({
  children,
  tastingLimit,
  currentDate,
}: {
  children: ReactNode;
  currentDate: string;
  tastingLimit?: TastingLimitType;
}) => {
  return (
    <>
      <Grid gutter="xl">
        <Grid.Col span={4}>
          <Text size={18} weight={700}>
            Complimentary Tastings ({currentDate})
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>{children}</Grid.Col>
      </Grid>

      <Grid gutter="xl">
        <Grid.Col span={3}>
          <Text size="sm" weight={600}>
            Redeemed
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text size="sm" weight={600}>
            Available
          </Text>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col span={3}>
          <Text size="sm">{tastingLimit?.redeemed}</Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text size="sm">{tastingLimit?.available}</Text>
        </Grid.Col>
      </Grid>
    </>
  );
};
