import { Grid, Box, Text } from '@mantine/core';

export const Header = ({ shift = false }: { shift?: boolean }): JSX.Element => {
  return (
    <Grid grow gutter={'lg'} align="center">
      {shift && <Grid.Col span={1}> </Grid.Col>}
      <Grid.Col span={2}>
        <Box>
          <Text size="md" weight={600}>
            SKU#
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text size="md" weight={600}>
          Product Name
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text size="md" weight={600}>
          Inventory Type
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text size="md" weight={600}>
          State
        </Text>
      </Grid.Col>
      {!shift && <Grid.Col span={1}> </Grid.Col>}
    </Grid>
  );
};
