import { Container, Stack, Text, Anchor, Box, Loader } from '@mantine/core';
import { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useApiMutation, useToast } from 'hooks';
import { AuthService } from 'App/api';
import { RouteName, generateRoutePath } from 'App/routes';
import { Logo as HRLogo } from 'assets/icons';
import { Logo } from 'components';
import { LOADER, getMessage } from 'shared';
import { ResetPasswordForm } from './components';

export const SUCCESS_MESSAGE = 'You have successfully changed your password!';

export const ResetPassword = (): JSX.Element => {
  const { token } = useParams();
  const navigate = useNavigate();
  const addToast = useToast();
  const {
    mutateAsync: validate,
    error: tokenError,
    isSuccess: isValidToken,
    isError,
  } = useApiMutation(['validateToken'], () =>
    AuthService.validateToken(token as string),
  );

  const {
    mutate,
    error: updateError,
    isLoading,
  } = useApiMutation(
    ['resetPassword'],
    (data) =>
      AuthService.resetPassword(data as { password: string; token: string }),
    {
      onSuccess: () => {
        addToast.success(SUCCESS_MESSAGE);
        navigate('/');
      },
    },
  );

  useEffect(() => {
    validate(null);
  }, [validate]);

  const error = tokenError ?? updateError;

  if (!isValidToken && !isError) {
    return <Loader data-testid={LOADER} size="lg" variant="dots" />;
  }
  return (
    <Container size="xs">
      <Stack justify="center" sx={{ minHeight: '100vh' }}>
        <Logo>
          <HRLogo />
        </Logo>
        {error ? (
          <Box>
            <Text align="center">{getMessage(error)}</Text>
            <Text align="center">
              To request again click{' '}
              <Anchor
                component={Link}
                to={generateRoutePath({ name: RouteName.ForgotPassword })}
              >
                here
              </Anchor>
            </Text>
          </Box>
        ) : (
          <ResetPasswordForm
            handleSubmit={(p: string) => mutate({ password: p, token })}
            loading={isLoading}
          />
        )}
      </Stack>
    </Container>
  );
};
