import {
  Box,
  Text,
  Select,
  NumberInput,
  ActionIcon,
  Loader,
  Grid,
  Flex,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { reject, without } from 'rambda';

import { SkuData } from 'pages/inventory/hooks';
import {
  AdjustmentPayload,
  LOADER,
  PRODUCT_INPUT,
  getSelectOptions,
  notFoundProductError,
} from 'shared';

type PropType = {
  form: UseFormReturnType<
    AdjustmentPayload,
    (values: AdjustmentPayload) => AdjustmentPayload
  >;
  skusData: SkuData[];
  isLoadingSkus: boolean;
  isSubtraction: boolean;
};
export const AddProduct = ({
  form,
  skusData,
  isLoadingSkus,
  isSubtraction,
}: PropType) => {
  const items = form.values.items;
  const canRemove = items.length > 1;
  const fullWidth = { width: '100%' };
  const itemIds = items.map(({ sku_id }) => sku_id);

  const getProductById = (id: string) => skusData.find((s) => s.id === id);

  return (
    <Box mt={'xl'}>
      <Grid gutter={'lg'}>
        <Grid.Col span={6}>
          <Text size={14} weight={600}>
            SKU#
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid gutter={'md'}>
            <Grid.Col span={3}>
              <Text size={14} weight={600}>
                Qty
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Box>
        {items.map(({ sku_id }, index: number) => {
          const rejectItems = without([sku_id], itemIds);
          const available = reject(
            ({ id }) => rejectItems.includes(id),
            skusData,
          );

          const opts = getSelectOptions(available);

          const product = getProductById(sku_id);

          const isEmpty = product && !product.quantity && isSubtraction;

          const min = -(product?.quantity ?? 1);
          const max = isSubtraction ? 0 : Infinity;

          return (
            <Grid gutter={'xl'} key={sku_id} mb="xs">
              <Grid.Col span={6}>
                <Select
                  data-testid={PRODUCT_INPUT}
                  sx={fullWidth}
                  searchable
                  nothingFound={notFoundProductError(!!opts.length)}
                  icon={
                    isLoadingSkus ? (
                      <Loader data-testid={LOADER} size={20} />
                    ) : null
                  }
                  data={opts}
                  {...form.getInputProps(`items.${index}.sku_id`)}
                  onChange={(value: string) => {
                    form.setFieldValue(`items.${index}.sku_id`, value);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Grid gutter={'md'} align="center">
                  <Grid.Col span={3}>
                    <NumberInput
                      data-testid="quantity-input"
                      styles={{ input: { width: '100%' } }}
                      disabled={isEmpty}
                      max={max}
                      min={min}
                      {...form.getInputProps(`items.${index}.quantity`)}
                      error={
                        isEmpty ??
                        form.getInputProps(`items.${index}.quantity`).error
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={2} offset={7}>
                    <Flex justify="flex-end">
                      <ActionIcon
                        onClick={() => form.removeListItem('items', index)}
                        disabled={!canRemove}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
};
