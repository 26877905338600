import { useCallback, useMemo } from 'react';
import { InventoryTransferService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import { normalizeInventoryTransfer } from 'shared/helpers/mapping/inventoryTransfer';
import { DEFAULT_PAGE_SIZE, getParams } from 'shared';

export const useInventoryTransfer = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
    location: [],
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';
  const status = searchParams.get('status') ?? '';
  const location = searchParams.getAll('location') as string[];

  const params = useMemo(() => {
    return {
      page: page.toString(),
      status,
      location,
      search_string,
      page_size: DEFAULT_PAGE_SIZE.toString(),
    };
  }, [location, page, search_string, status]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getInventoryTransfer', key], () =>
    InventoryTransferService.getCollection(getParams(params)),
  );

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ ...data, page: '1' });
    },
    [handleSetSearchParams],
  );

  return {
    page,
    filters: { page, search_string, status, location },
    output: data?.items.map((i) => normalizeInventoryTransfer(i)),
    isLoading,
    totalRecords: data?.total ?? 0,
    updateFilters,
    handleSetSearchParams,
  };
};
