import { randomId } from '@mantine/hooks';
import { reduce } from 'rambda';

export const getNewItem = () => ({
  sku_id: '',
  quantities: {
    library: 0,
    default: 0,
  },
  cost: 0,
  total: 0,
  received_quantity: 0,
  key: randomId(),
});

export const getTotal = (arr: { total: number }[]) =>
  reduce(
    (accumulator, currentValue: { total: number }) => {
      return accumulator + currentValue?.total;
    },
    0,
    arr,
  );
