import { Grid, Select } from '@mantine/core';

import { SearchInput, LocationSelect } from 'components';
import { usePartnerLocations } from 'pages/inventory/hooks';
import { adjustmentTypeOptions, getEmptyOption } from 'shared';

type Params = {
  location?: string[];
  search_string?: string;
  adjustment_type?: string;
};

interface Props {
  handleChange: (v: Params) => void;
  values: Params;
}

export const Filters = ({ handleChange, values }: Props): JSX.Element => {
  const { search_string, location, adjustment_type = '' } = values;
  const { options } = usePartnerLocations();

  return (
    <Grid justify={'space-between'}>
      <Grid.Col span={7}>
        <Grid>
          <Grid.Col span={7}>
            <LocationSelect
              value={location}
              data={options}
              handleChange={(v) => {
                handleChange({ location: v });
              }}
            />
          </Grid.Col>
          <Grid.Col span={5}>
            <Select
              label="Adjustment Type"
              data-testid="adjustment-type-dropdown"
              data={[getEmptyOption('All Types'), ...adjustmentTypeOptions]}
              value={adjustment_type}
              onChange={(value: string) => {
                handleChange({ adjustment_type: value });
              }}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={5} lg={4}>
        <SearchInput
          handleSubmit={(v: string) => handleChange({ search_string: v })}
          label="Search"
          value={search_string}
        />
      </Grid.Col>
    </Grid>
  );
};
