import { Button, Container, Grid, Text, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { ClubMemberForm } from './ClubMemberForm';
import { useCreateCustomer } from '../../hooks';
import { generalInformationSchema, getClubMemberSchema } from './schemes';
import { generalInitialValues, clubMemberInitialValues } from '../../helpers';
import { EMAIL_INPUT, capitalizeText } from 'shared';
import { MaskedInput } from 'components';

type Props = {
  onExit: () => void;
};

export const CreateForm = ({ onExit }: Props) => {
  const { createCustomer, isLoading } = useCreateCustomer({
    onExit,
  });

  const profileForm = useForm({
    initialValues: generalInitialValues,
    validate: yupResolver(generalInformationSchema),
    validateInputOnBlur: true,
  });

  const clubMemberForm = useForm({
    initialValues: clubMemberInitialValues,
    validate: yupResolver(getClubMemberSchema()),
    validateInputOnBlur: true,
  });

  const isInvalid = !profileForm.isValid() || !clubMemberForm.isValid();

  const fullWidth = { maxWidth: '100%' };

  return (
    <>
      <Container size="xl" mb={24}>
        <Grid gutter="xl">
          <Grid.Col>
            <Text size={18} weight={700}>
              Profile Information
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              required
              sx={fullWidth}
              data-testid="firstname-input"
              label="First Name"
              {...profileForm.getInputProps('first_name')}
              onChange={({ currentTarget }) =>
                profileForm.setFieldValue(
                  'first_name',
                  capitalizeText(currentTarget.value),
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              required
              sx={fullWidth}
              data-testid="lastname-input"
              label="Last Name"
              {...profileForm.getInputProps('last_name')}
              onChange={({ currentTarget }) =>
                profileForm.setFieldValue(
                  'last_name',
                  capitalizeText(currentTarget.value),
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              sx={fullWidth}
              required
              placeholder="your@email.com"
              data-testid={EMAIL_INPUT}
              label="Email Address"
              {...profileForm.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MaskedInput
              sx={fullWidth}
              required
              testId="phoneNumber"
              label="Phone Number"
              mask="(999) 999-9999"
              {...profileForm.getInputProps('phone_number')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MaskedInput
              testId="dateOfBirth"
              sx={fullWidth}
              mask="99.99.9999"
              label="Date of Birth"
              placeholder="08.31.1989"
              maskChar={null}
              {...profileForm.getInputProps('date_of_birth')}
            />
          </Grid.Col>
        </Grid>
      </Container>

      <ClubMemberForm form={clubMemberForm} />

      <Container size="xs" px={0} mt="xl">
        <Button
          fullWidth
          loading={isLoading}
          onClick={() =>
            createCustomer({
              profile: profileForm.values,
              clubMember: clubMemberForm.values,
            })
          }
          disabled={isInvalid}
        >
          Save
        </Button>
      </Container>
      <Text align="center" size="xs" mt={15}>
        After saving profile details a reset password link will be sent to the
        new customer.
      </Text>
    </>
  );
};
