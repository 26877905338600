import {
  PaginatedResponse,
  Location,
  CreateLocation,
  TaxDto,
  FeesPayload,
  LocationsRequest,
} from 'shared';

import { LOCATION_API_PATH, GET_LOCAL_TAXES_PATH } from '../../endpoints';
import request from '../../request';

const DEFAULT_PAGINATION_DATA = { page: 1, page_size: 20 };

export default class LocationService {
  static readonly getLocations = (params: LocationsRequest = {}) => {
    return request<PaginatedResponse<Location>>({
      url: LOCATION_API_PATH,
      method: 'get',
      params: { ...DEFAULT_PAGINATION_DATA, ...params },
    });
  };

  static readonly getLocationById = (id: string) => {
    return request<Location>({
      url: `${LOCATION_API_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly create = (data: CreateLocation) => {
    return request({
      url: LOCATION_API_PATH,
      method: 'post',
      data,
    });
  };

  static readonly update = (id: string, data: CreateLocation) => {
    return request({
      url: `${LOCATION_API_PATH}/${id}`,
      method: 'put',
      data,
    });
  };

  static readonly delete = (id: string) => {
    return request({
      url: `${LOCATION_API_PATH}/${id}`,
      method: 'delete',
    });
  };

  static readonly getLocalTaxesList = () => {
    return request<PaginatedResponse<TaxDto>>({
      url: GET_LOCAL_TAXES_PATH,
      method: 'get',
    });
  };

  static readonly getLocalTaxes = (id: string) => {
    return request<TaxDto>({
      url: `${GET_LOCAL_TAXES_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly updateFees = (id: string, data: FeesPayload) => {
    return request<TaxDto>({
      url: `${GET_LOCAL_TAXES_PATH}/${id}`,
      method: 'put',
      data,
    });
  };
}
