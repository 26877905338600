import { useEffect, useMemo } from 'react';
import { SetFieldValue } from '@mantine/form/lib/types';
import { calculateSuggestionInitialValue } from '../helpers';
import { SuggestionsDto } from 'shared';
import { useClubTier } from 'hooks';

export const useSuggestions = ({
  tierId,
  setValues,
}: {
  tierId: string;
  setValues: SetFieldValue<SuggestionsDto>;
}) => {
  const { getTierById, isLoading } = useClubTier({});

  const tier = useMemo(() => getTierById(tierId), [tierId, getTierById]);

  const { tiers, isLoading: isLoadingAdditional } = useClubTier({
    tierId,
    enabled: !!tierId,
  });

  useEffect(() => {
    if (tier && setValues) {
      setValues(
        'suggestions',
        calculateSuggestionInitialValue([...tiers, tier]),
      );
    }
  }, [setValues, tier, tiers]);

  return {
    tiers: tier ? [tier, ...tiers] : [],
    isLoading: isLoading || isLoadingAdditional,
  };
};
