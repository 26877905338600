import { includes } from 'rambda';
import { useCallback, useMemo } from 'react';

import { useSession } from 'App/session';
import { Permissions, hasAdminPermission } from 'shared';

type CheckAccess = Permissions | undefined;

type ReturnValue = {
  checkAccess: (v: CheckAccess) => boolean;
  isAdmin: boolean;
};

export const usePermissions = (): ReturnValue => {
  const { user } = useSession();

  const permissions: Permissions[] = useMemo(
    () => user?.role?.permissions ?? [],
    [user],
  );

  const isAdmin = hasAdminPermission(permissions);

  const checkAccess = useCallback(
    (action: CheckAccess) =>
      !action || isAdmin || includes(action, permissions),
    [isAdmin, permissions],
  );

  return { checkAccess, isAdmin };
};
