import { useNavigate, useParams } from 'react-router-dom';
import { omit } from 'rambda';
import { Box, Grid, useMantineTheme } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import {
  BackButton,
  ContentLayout,
  CreateButton,
  LocationSelect,
} from 'components';
import { getCreatePath, PROPS_DATA_TABLE } from 'shared';
import { useInventoryItem, usePartnerLocations } from 'pages/inventory/hooks';
import { STOCK_QTY_WARNING_LIMIT } from 'pages/inventory/helpers';

const INDENT = 32;

export const Details = ({
  onExit,
  rootPath,
  canCreate,
}: {
  onExit: () => void;
  canCreate: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const {
    isLoading,
    isError,
    output,
    productName,
    location,
    setLocation,
    isAlcohol,
  } = useInventoryItem(id);
  const theme = useMantineTheme();
  const { options } = usePartnerLocations();
  const tableProps = omit(['recordsPerPage'], PROPS_DATA_TABLE);

  if (isError) {
    onExit();
  }

  return (
    <ContentLayout
      title={productName}
      isLoading={isLoading}
      leftAction={<BackButton onClick={() => navigate(-1)} />}
      rightAction={
        canCreate && (
          <CreateButton to={getCreatePath(rootPath)}>
            + Add Inventory
          </CreateButton>
        )
      }
    >
      <Grid mb="md">
        <Grid.Col span={4}>
          <LocationSelect
            handleChange={setLocation}
            value={location}
            data={options}
          />
        </Grid.Col>
      </Grid>

      <DataTable
        data-testid="product-stock-details-table"
        {...tableProps}
        records={output}
        onRowClick={({ id }) => id}
        rowStyle={({ stock }) =>
          isAlcohol && stock <= STOCK_QTY_WARNING_LIMIT
            ? { color: theme.colors.red[6] }
            : undefined
        }
        columns={[
          {
            accessor: 'name',
            title: '',
            width: '25%',
            render: ({ level, name }) => (
              <Box pl={`${INDENT * level}px`}>{name}</Box>
            ),
          },
          { accessor: 'stock', title: 'Qty Total', width: '25%' },
          { accessor: 'available', title: 'Qty Available', width: '25%' },
          { accessor: 'committed', title: 'Qty On Hold', width: '25%' },
        ]}
      />
    </ContentLayout>
  );
};
