import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { NestedRoutes, Permissions } from 'shared';
import { List, Create, Edit } from './components';

export const Categories = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.ProductCategories,
  });
  const canCreate = checkAccess(Permissions.admin_panel_categories_create);
  const canEdit = checkAccess(Permissions.admin_panel_categories_edit);
  const canDelete = checkAccess(Permissions.admin_panel_categories_delete);
  const ListComponent = <List canCreate={canCreate} canEdit={canEdit} />;

  const onExit = useGotoRoute(RouteName.ProductCategories);

  return (
    <>
      <Routes>
        <Route index element={ListComponent} />
        <Route path={NestedRoutes.List} element={ListComponent} />
        {canCreate && (
          <Route
            path={NestedRoutes.Create}
            element={<Create onExit={onExit} />}
          />
        )}
        {canEdit && (
          <Route
            path={NestedRoutes.Edit}
            element={
              <Edit canDelete={canDelete} onExit={onExit} rootPath={rootPath} />
            }
          />
        )}
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: rootPath,
              }}
            />
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};
