import { ContentLayout, BackButton } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { useEditInventoryTransfer } from '../../hooks';

import { FormReceive } from '../form';

export const Receive = ({
  onReceive,
}: {
  onReceive: (id: string) => void;
}): JSX.Element => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { id = '' } = useParams();
  const { data, isLoading, handleReceivedTransfer, isReceiving } =
    useEditInventoryTransfer({
      id,
      onCreate: onReceive,
    });

  const { number_str } = data ?? {};

  const title = `Receive Transfer ${number_str}`;

  return (
    <ContentLayout
      title={title}
      isLoading={isLoading}
      processing={isReceiving}
      leftAction={<BackButton onClick={goBack} />}
    >
      <FormReceive initialValues={data} handleSubmit={handleReceivedTransfer} />
    </ContentLayout>
  );
};
