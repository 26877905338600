import { useMemo, useState, useCallback } from 'react';
import { ShipmentCycleService } from 'App/api';
import { useApiQuery } from 'hooks';
import {
  ShipmentCycleDetailItemOutput,
  PaginatedRequest,
  getFullName,
  capitalizeText,
} from 'shared';
import { getAddOnLabel, getBottlesLabel } from '../helpers';

interface ReturnValue {
  title?: string;
  statusLabel?: string;
  output: ShipmentCycleDetailItemOutput[];
  isLoading: boolean;
  page: number;
  setPage: (p: number) => void;
  totalRecords: number;
  filters: RequestFilter;
  updateFilters: (f: RequestFilter) => void;
  isError: boolean;
}

type RequestFilter = { search_string: string };

export const useShipmentCycle = (cycleId: string): ReturnValue => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<RequestFilter>({
    search_string: '',
  });

  const params: PaginatedRequest = useMemo(() => {
    const { search_string } = filters;
    return {
      page,
      page_size: 10,
      include_on_hold_customers: false,
      ...(search_string && { search_string }),
    };
  }, [filters, page]);

  const key = JSON.stringify(params);

  const { data, isLoading, isError } = useApiQuery(
    ['getShipmentCycle', cycleId, key],
    () => ShipmentCycleService.getCustomers({ cycleId, params }),
  );

  const output: ShipmentCycleDetailItemOutput[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map((cycle) => {
      const {
        id,
        tier: { name: tier_name },
        add_on,
        choice: { wine_type, bottle_quantity },
        first_name,
        last_name,
        delivery_method,
        unpaid,
        pending,
        has_errors,
      } = cycle;
      return {
        id,
        has_errors,
        member_name: getFullName({ first_name, last_name }),
        tier: `${tier_name}${getAddOnLabel(add_on)}`,
        choice: `${capitalizeText(wine_type ?? '')}${getBottlesLabel(
          bottle_quantity,
        )}`,
        delivery_method,
        unpaid: unpaid ?? '',
        pending: pending ?? '',
      };
    });
  }, [data]);

  const updateFilters = useCallback((data: RequestFilter) => {
    setFilters((prev) => ({ ...prev, ...data }));
    setPage(1);
  }, []);

  const { name, status } = data?.shipment_cycle ?? {};

  return {
    title: name,
    statusLabel: status && capitalizeText(status),
    output,
    isLoading,
    isError,
    page,
    setPage,
    totalRecords: data?.total ?? 0,
    filters,
    updateFilters,
  };
};
