import { Link } from 'react-router-dom';
import { ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useCreateDiscount } from '../../hooks';

type Props = {
  onExit: () => void;
  rootPath: string;
};

const title = 'New Discount';

export const Create = ({ rootPath, onExit }: Props): JSX.Element => {
  const { options, isLoading, handleSubmit, tiersOptions } = useCreateDiscount({
    onExit,
  });
  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      leftAction={<BackButton component={Link} to={rootPath} />}
    >
      <Form
        options={options}
        handleSubmit={handleSubmit}
        tiersOptions={tiersOptions}
      />
    </ContentLayout>
  );
};
