import { InventoryAdjustmentService } from 'App/api';
import { useApiQuery, useApiMutation } from 'hooks';
import { useMemo } from 'react';
import { adjustmentTypeLabels } from 'shared';

export const useAdjustmentDetails = ({
  id,
  onDelete,
}: {
  id: string;
  onDelete: () => void;
}) => {
  const { data, isLoading, isError } = useApiQuery(['adjustment', id], () =>
    InventoryAdjustmentService.getOne(id),
  );

  const { mutate: cancel, isLoading: deleting } = useApiMutation(
    ['delete', id],
    InventoryAdjustmentService.cancel,
    {
      onSuccess: onDelete,
    },
  );

  const output = useMemo(() => {
    if (!data) {
      return {};
    }
    const {
      number,
      location: { name: location },
      inventory_location: { name: inventoryLocation },
      adjustment_type,
      items,
      comment,
    } = data;
    return {
      number,
      location,
      inventoryLocation,
      adjustmentType: adjustmentTypeLabels[adjustment_type],
      items,
      comment: comment ?? '',
    };
  }, [data]);

  return { output, isLoading, deleting, isError, cancel };
};
