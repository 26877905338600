import { useState } from 'react';
import { ShipmentCycleService } from 'App/api';
import { useApiMutation, useClubTier } from 'hooks';
import {
  ShipmentCycleDto,
  SuggestionsDto,
  WineSelectionDto,
  ShippingDetails,
} from 'shared';
import { normalizePayload } from '../helpers';
import { Steps } from '../constants';

type SubmitData = {
  cycleSetting: ShipmentCycleDto;
  wineSelection: WineSelectionDto;
  suggestion: SuggestionsDto;
  shippingDetails: ShippingDetails;
  step: Steps;
};

export const useCreateShipmentCycle = ({ onExit }: { onExit: () => void }) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const { options: tierOptions } = useClubTier({});

  const { mutate: create, isLoading } = useApiMutation(
    ['createShipmentCycle'],
    ShipmentCycleService.create,
    { onSuccess: onExit },
  );

  const nextStep = () => setActiveStep((current) => current + 1);

  const handleSubmit = ({
    cycleSetting,
    wineSelection,
    suggestion,
  }: {
    cycleSetting: ShipmentCycleDto;
    wineSelection: WineSelectionDto;
    suggestion: SuggestionsDto;
  }) => {
    create(normalizePayload({ cycleSetting, wineSelection, suggestion }));
  };

  const stepProcessing = ({
    step,
    cycleSetting,
    wineSelection,
    suggestion,
    shippingDetails,
  }: SubmitData) => {
    if (Steps.CycleSetting === step) return nextStep();
    if (Steps.WineSelection === step) return nextStep();
    if (Steps.Suggestions === step) return nextStep();

    if (Steps.ShippingDetails === step) {
      return handleSubmit({
        cycleSetting: { ...cycleSetting, ...shippingDetails },
        wineSelection,
        suggestion,
      });
    }
  };

  return {
    tierOptions,
    activeStep,
    setActiveStep,
    handleSubmit,
    isLoading,
    stepProcessing,
  };
};
