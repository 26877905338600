import { Container } from '@mantine/core';

import { InventoryService } from 'App/api';
import { InventoryFormValues } from 'shared';
import { ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useApiMutation } from 'hooks';
import { getNewItem, getInventoryPayload } from '../form/helpers';

const title = 'New Inventory';

const initialValues: InventoryFormValues = {
  is_vendor: false,
  order_number: '',
  vendor_id: '',
  comment: '',
  inventory_location_id: '',
  items: [getNewItem()],
};

export const Create = ({ onExit }: { onExit: () => void }): JSX.Element => {
  const { mutate, isLoading } = useApiMutation(
    ['create-inventory'],
    (v: InventoryFormValues) => InventoryService.create(getInventoryPayload(v)),
    {
      onSuccess: onExit,
    },
  );
  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      leftAction={<BackButton onClick={onExit} />}
    >
      <Container size={'xs'}>
        <Form handleSubmit={mutate} initialValues={initialValues} />
      </Container>
    </ContentLayout>
  );
};
