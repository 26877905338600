import axios from 'axios';
import { ADDRESS_VALIDATION_PATH } from 'App/api/endpoints';

type Verify = {
  address: {
    locality: string | null;
    administrativeArea: string | null;
    postalCode: string | null;
    addressLines: (string | null)[];
  };
  enableUspsCass: boolean;
};

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;

export const client = axios.create({
  baseURL: `${ADDRESS_VALIDATION_PATH}=${REACT_APP_GOOGLE_MAP_API_KEY}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default class GoogleValidateAddressService {
  static readonly verify = (data: Verify) => {
    return client({
      method: 'post',
      data,
    });
  };
}
