import { useCallback } from 'react';
import { ProductCategoryService } from 'App/api';
import { useApiMutation, useFileUpload } from 'hooks';
import { ProductCategoryFormValues, ProductCategoryFormImages } from 'shared';
import { getCategoryPayload } from '../helpers';

export const useCreateCategory = ({
  onSuccess = () => null,
}: {
  onSuccess?: (id: string) => void;
}): {
  handleSubmit: (
    values: ProductCategoryFormValues,
    files: ProductCategoryFormImages,
  ) => void;
  isLoading: boolean;
} => {
  const { handleMassUpload } = useFileUpload();
  const { mutate: createCategory, isLoading } = useApiMutation(
    ['createCategory'],
    ProductCategoryService.create,
    {
      onSuccess: ({ id }) => onSuccess(id),
    },
  );

  const handleSubmit = useCallback(
    async (
      values: ProductCategoryFormValues,
      files: ProductCategoryFormImages,
    ) => {
      const urls = await handleMassUpload([
        files.webImage,
        files.mobileImage,
      ] as File[]);
      createCategory({
        ...getCategoryPayload(values),
        web_image_url: urls[0].url,
        mobile_image_url: urls[1].url,
      });
    },
    [createCategory, handleMassUpload],
  );

  return {
    handleSubmit,
    isLoading,
  };
};
