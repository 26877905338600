import { Box, Flex, Text } from '@mantine/core';
import { ProductDto } from 'shared';

export const Kit = ({
  specificInformation,
}: {
  specificInformation?: ProductDto['specific_information'];
}) => {
  const fullWidth = { width: '100%' };
  const gap = 50;

  return (
    <Box sx={{ marginBottom: 63 }}>
      <Flex align="start" mb="lg" gap={gap}>
        <Box sx={fullWidth}>
          <Text weight={600} size="sm">
            Kit Products:
          </Text>
        </Box>
        <Flex sx={fullWidth} direction="column">
          {specificInformation?.items?.map((i) => (
            <Box key={i.id} sx={fullWidth}>
              <Text size={14}>
                {i.product_name} x {i.quantity}
              </Text>
            </Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};
