import { FC } from 'react';
import { Box, Text, Anchor } from '@mantine/core';
import { Link } from 'react-router-dom';

export const Links: FC<{
  textLink: string;
  route: string;
}> = ({ textLink, route }) => (
  <Box>
    <Text sx={{ lineHeight: '25px' }}>
      {textLink}{' '}
      <Anchor component={Link} to={route}>
        Click <span>here</span>
      </Anchor>
    </Text>
  </Box>
);
