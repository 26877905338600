import { Button, Flex } from '@mantine/core';

export const CopyPaste = <T,>({
  setCopy,
  copy,
  onPaste,
  currentItems,
}: {
  setCopy: (payload: T[]) => void;
  copy: T[] | null;
  onPaste: () => void;
  currentItems: T[];
}) => {
  return (
    <Flex direction="column" justify="center" align="center">
      <Button
        variant="white"
        sx={{ width: '200px' }}
        onClick={() => setCopy(currentItems)}
      >
        Copy Choice
      </Button>

      <Button
        sx={{ width: '200px' }}
        variant="white"
        disabled={!copy}
        onClick={onPaste}
      >
        Paste Choice
      </Button>
    </Flex>
  );
};
