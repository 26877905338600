import { ShortEmployee } from '../employee';

export enum ProductCategoryType {
  HAS_ITEMS = 'has_items',
  HAS_SUBCATEGORIES = 'has_subcategories',
  EMPTY = 'empty',
  ROOT = 'root',
}

export interface ProductCategoryFormValues
  extends Omit<
    ProductCategoryDto,
    | 'id'
    | 'menu_position'
    | 'issued_at'
    | 'issued_by'
    | 'subcategories'
    | 'type'
    | 'products'
    | 'product_options'
  > {
  id?: string;
  parent_id: string | null;
  is_main_category: boolean;
  subcategories?: ProductCategoryBaseDto[];
  product_options: FormCategoryProductOptions[];
}

export type FormCategoryProductOptions = Omit<CategoryProductOptions, 'id'> & {
  id?: string;
  key?: string;
};

export type ProductCategoryRequest = Omit<
  ProductCategoryFormValues,
  'is_main_category' | 'product_options'
> & {
  product_options: Array<
    Omit<FormCategoryProductOptions, 'id' | 'key'> & {
      id?: string;
    }
  >;
};

export type ProductCategoryPayload = Omit<
  ProductCategoryFormValues,
  'is_main_category'
>;

export type ReorderPayload = {
  categories: { id: string; subcategories: string[] }[];
};

export type CategoryProductOptions = {
  name: string;
  required: boolean;
  id: string;
  order: number;
};

export interface ProductCategoryBaseDto {
  id: string;
  name: string;
  type: ProductCategoryType;
  menu_position: number;
  active: boolean;
  hide_from_pos: boolean;
  members_only: boolean;
  pickup_only: boolean;
  paid_delivery_only: boolean;
  issued_at: string;
  issued_by: ShortEmployee;
  products: CategorySkuDto[];
  description?: string;
  product_options?: CategoryProductOptions[] | null;
  mobile_image_url: string;
  web_image_url: string;
}

export interface ProductCategoryDto extends ProductCategoryBaseDto {
  parent?: ProductCategoryBaseDto;
  subcategories: ProductCategoryBaseDto[];
}

export interface CategorySkuDto {
  id: string;
  name: string;
  sku_number: string;
  is_enabled: boolean;
  inventory_type: string;
  saved_as_draft: boolean;
  menu_position: number;
}

export interface AddCategorySKUs {
  category_id: string;
  skus: string[];
}

export type ProductCategoryFormImages = {
  webImage: File | null;
  mobileImage: File | null;
};
