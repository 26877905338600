import { CreateSku, PaginatedResponse, ProductDto, SkusRequest } from 'shared';
import { PRODUCT_SKUS_PATH } from '../../endpoints';
import request from '../../request';

export default class ProductSkuService {
  static readonly getCollection = (params: SkusRequest) => {
    return request<PaginatedResponse<ProductDto>>({
      url: `${PRODUCT_SKUS_PATH}`,
      params,
      method: 'get',
    });
  };

  static readonly create = (data: CreateSku) => {
    return request<ProductDto>({
      url: `${PRODUCT_SKUS_PATH}`,
      method: 'post',
      data,
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<ProductDto>({
      url: `${PRODUCT_SKUS_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly delete = ({ id }: { id?: string }) => {
    return request({
      url: `${PRODUCT_SKUS_PATH}/${id}`,
      method: 'delete',
    });
  };

  static readonly update = ({ id, data }: { id?: string; data: CreateSku }) => {
    return request<ProductDto>({
      url: `${PRODUCT_SKUS_PATH}/${id}`,
      method: 'put',
      data,
    });
  };
}
