import { useMemo, useCallback } from 'react';
import { CustomerService } from 'App/api';
import { useApiQuery } from 'hooks';
import { getSelectOptions, ClubTierLevel } from 'shared';

export const useClubTier = ({
  level,
  tierId,
  enabled = true,
}: {
  level?: ClubTierLevel;
  tierId?: string;
  enabled?: boolean;
}) => {
  const { data, isLoading } = useApiQuery(
    ['getClubTier', level, tierId],
    () =>
      CustomerService.getClubTiers({
        level: tierId ? 'add_on' : level,
        base_tier_id: tierId,
      }),
    { enabled },
  );

  const options = useMemo(() => {
    if (!data) {
      return [];
    }
    return getSelectOptions(data.items);
  }, [data]);

  const getTierById = useCallback(
    (id: string) => (data?.items ?? []).find(({ id: tierId }) => id === tierId),
    [data],
  );

  return {
    tiers: data?.items ?? [],
    getTierById,
    options,
    isLoading,
  };
};
