import { CustomerService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import { isEmpty, reject } from 'rambda';
import { useCallback, useMemo, useState } from 'react';
import { CustomersRequest } from 'shared';
import { normalizeCustomerList } from '../helpers';

type RequestFilter = { search_string: string };

export const useCustomers = () => {
  const [isCycleOnHold, setIsCycleOnHold] = useState<boolean>(false);

  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params: CustomersRequest = useMemo(() => {
    return {
      page,
      search_string,
      only_with_cycle_on_hold: isCycleOnHold,
    };
  }, [page, search_string, isCycleOnHold]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getCustomers', key], () =>
    CustomerService.getCollection(
      reject(isEmpty, params as Record<string, unknown>),
    ),
  );

  const output = useMemo(() => {
    if (!data) return [];

    return normalizeCustomerList(data.items);
  }, [data]);

  const updateFilters = useCallback(
    (data: RequestFilter) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  return {
    page,
    search_string,
    output,
    totalRecords: data?.total ?? 0,
    isLoading,
    isCycleOnHold,
    updateFilters,
    handleSetSearchParams,
    setIsCycleOnHold,
  };
};
