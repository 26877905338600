import { useMemo } from 'react';
import { useApiQuery, usePermissions } from 'hooks';
import { ProductVendorService } from 'App/api';
import { getSelectOptions, Permissions } from 'shared';

const GET_VENDORS_KEY = 'getVendors';

export const useVendors = () => {
  const { checkAccess } = usePermissions();
  const canAccessVendors = checkAccess(Permissions.admin_panel_vendor_view);
  const { data, isLoading } = useApiQuery(
    [GET_VENDORS_KEY],
    () => ProductVendorService.getCollection({ page_size: 1000 }),
    {
      enabled: canAccessVendors,
    },
  );

  const options = useMemo(
    () =>
      getSelectOptions(
        data?.items.map(({ id, company_name }) => {
          return { id, name: company_name };
        }) ?? [],
      ),
    [data?.items],
  );

  return {
    options,
    isLoading,
    vendors: data?.items ?? [],
  };
};
