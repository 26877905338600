import { useEffect, useMemo } from 'react';
import { SetFieldValue } from '@mantine/form/lib/types';

import { calculateInitialValue } from '../helpers';
import { WineSelectionDto } from 'shared';
import { useClubTier } from 'hooks';

export const useWineSelection = ({
  tierId,
  setValues,
}: {
  tierId: string;
  setValues: SetFieldValue<WineSelectionDto>;
}) => {
  const { getTierById, isLoading } = useClubTier({});

  const tier = useMemo(() => getTierById(tierId), [tierId, getTierById]);

  const initialValue = useMemo(() => calculateInitialValue(tier), [tier]);

  useEffect(() => {
    setValues('wine_selections', initialValue);
  }, [initialValue, setValues]);

  return { tier, isLoading };
};
