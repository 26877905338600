import {
  Card as UICard,
  ActionIcon,
  Stack,
  Button,
  Text,
  Group,
} from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { Location } from 'shared';

interface Props {
  location: Location;
  canEdit: boolean;
  onEditClick: () => void;
  onRolesClick: () => void;
}

export const Card = (props: Props): JSX.Element => {
  const {
    location: { name, address, state, zip_code, phone_number },
    onEditClick,
    onRolesClick,
    canEdit,
  } = props;

  return (
    <UICard sx={{ height: '100%' }} p="lg" shadow="xl" radius="sm">
      <Stack justify="space-between" sx={{ height: '100%' }}>
        <Group position="apart" align={'center'}>
          <Text data-testid="location-card-name" size={'lg'} weight={600}>
            {name}
          </Text>
          {canEdit && (
            <ActionIcon
              data-testid="edit-btn"
              onClick={onEditClick}
              aria-label="EditButton"
              color="dark.9"
            >
              <IconPencil stroke={1} />
            </ActionIcon>
          )}
        </Group>
        <Text>{`${address}, ${state} ${zip_code}`}</Text>
        <Text>{`Phone: ${phone_number}`}</Text>
        <Button
          data-testid="see-roles-btn"
          fullWidth
          size="lg"
          mt={24}
          onClick={onRolesClick}
        >
          See roles
        </Button>
      </Stack>
    </UICard>
  );
};
