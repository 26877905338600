import { Grid, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { useRole } from '../../hooks';
import { ConfirmModal } from 'components';

import { Role } from '../role';
import { RoleValues } from 'shared';

export const Details = ({
  onExit,
  canDelete,
  canEdit,
}: {
  onExit: () => void;
  canEdit: boolean;
  canDelete: boolean;
}): JSX.Element => {
  const { id: roleId = '' } = useParams();
  const {
    role,
    isLoading: pending,
    isError,
    remove,
    edit,
  } = useRole({ roleId, onSuccess: onExit });

  if (isError) {
    onExit();
  }

  if (pending && !role) {
    return <div>...</div>;
  }

  return (
    <Role
      title={role?.name ?? ''}
      roleName={role?.name}
      roleDescription={role?.description}
      initialChecked={role?.permissions}
      handleSubmit={(data: RoleValues) => edit({ ...data, role_id: roleId })}
      processing={pending}
      canEdit={canEdit}
    >
      {canDelete && (
        <Grid justify={'center'} mt="sm">
          <Grid.Col md={6} lg={4}>
            <ConfirmModal
              type="button"
              variant="outline"
              fullWidth
              loading={pending}
              onConfirm={() => remove(roleId)}
              data-testid="delete-role-btn"
              label="Delete Role"
            >
              <Text data-testid="delete-role-btn">{`Are you sure you want to delete the role?`}</Text>
            </ConfirmModal>
          </Grid.Col>
        </Grid>
      )}
    </Role>
  );
};
