import { Accordion, Box, Button, Grid, Space, Group } from '@mantine/core';
import { useState } from 'react';
import { isEmpty } from 'rambda';
import { useNavigate, generatePath } from 'react-router-dom';

import { generateRoutePath, RouteName } from 'App/routes';
import { NestedRoutes, Actions } from 'shared/interactions';
import {
  DndItem,
  DndContainer,
  ContentLayout,
  BackButton,
  CreateButton,
} from 'components';
import { useCategories } from 'pages/products/hooks';
import { ListItem, Header } from './components';
import { useOrderedCategories } from '../../hooks';

export const List = ({
  canCreate,
  canEdit,
}: {
  canCreate: boolean;
  canEdit: boolean;
}): JSX.Element => {
  const navigate = useNavigate();
  const [action, setAction] = useState<Actions>(Actions.View);
  const { categories, isLoading, refetch } = useCategories();
  const { output, restore, onOrderChange, reorder } = useOrderedCategories({
    categories,
    onSuccess: () => {
      refetch();
      setAction(Actions.View);
    },
  });

  const categoriesPath = generateRoutePath({
    name: RouteName.ProductCategories,
  });

  const getEditHandler = (id: string) => () =>
    navigate(generatePath(`${categoriesPath}/${NestedRoutes.Edit}`, { id }));
  const isReorder = action === Actions.Arrange;

  return (
    <ContentLayout
      title="Categories"
      isLoading={isLoading}
      rightAction={
        <Group position="right" spacing={'xs'}>
          {canEdit && !isReorder && (
            <Button variant="subtle" onClick={() => setAction(Actions.Arrange)}>
              Reorder
            </Button>
          )}
          {canCreate && (
            <CreateButton
              data-testid="new-category-btn"
              to={`${categoriesPath}/${NestedRoutes.Create}`}
            >
              + New Category
            </CreateButton>
          )}
        </Group>
      }
      leftAction={
        isReorder && (
          <BackButton
            onClick={() => {
              restore();
              setAction(Actions.View);
            }}
          />
        )
      }
    >
      <Header shift={isReorder} />
      <Accordion
        chevronPosition="right"
        styles={{
          content: {
            padding: '4px 0 8px',
          },
        }}
      >
        <DndContainer onSortEnd={onOrderChange}>
          {output.map(({ subcategories, name, id, ...category }, i) => {
            const title = isEmpty(subcategories) ? (
              <Box m={'md'}>{name}</Box>
            ) : (
              <Accordion.Control>{name}</Accordion.Control>
            );
            return (
              <DndItem key={id} index={i}>
                <Accordion.Item value={`${id}`} key={id}>
                  <ListItem
                    title={title}
                    category={category}
                    onClick={getEditHandler(id)}
                    showAction={canEdit}
                    reorder={isReorder}
                  />
                  <Accordion.Panel>
                    <DndContainer
                      onSortEnd={({ oldIndex, newIndex }) =>
                        onOrderChange({ oldIndex, newIndex, parentId: id })
                      }
                    >
                      {subcategories.map(
                        ({ name: sname, id: sid, ...rest }, n) => (
                          <DndItem key={sid} index={n}>
                            <ListItem
                              key={sid}
                              title={
                                <Box ml="xl" pl={'xl'}>
                                  {sname}
                                </Box>
                              }
                              category={rest}
                              onClick={getEditHandler(sid)}
                              showAction={canEdit}
                              reorder={isReorder}
                            />
                          </DndItem>
                        ),
                      )}
                    </DndContainer>
                  </Accordion.Panel>
                </Accordion.Item>
              </DndItem>
            );
          })}
        </DndContainer>
      </Accordion>
      <Space h={'xl'} />
      {isReorder && (
        <Grid>
          <Grid.Col span={6} offset={3}>
            <Button onClick={reorder} fullWidth>
              Save
            </Button>
          </Grid.Col>
        </Grid>
      )}
    </ContentLayout>
  );
};
