import {
  PaginatedResponse,
  RoleDto,
  CreateRole,
  RoleListDto,
  EditRole,
} from 'shared';

import { ROLE_API_PATH } from '../../endpoints';
import request from '../../request';

export default class RoleService {
  static readonly getAll = (location_id: string) => {
    return request<PaginatedResponse<RoleListDto>>({
      url: ROLE_API_PATH,
      method: 'get',
      params: {
        location_id,
        page_size: 1000,
      },
    });
  };

  static readonly getOne = (role_id: string) => {
    return request<RoleDto>({
      url: `${ROLE_API_PATH}/${role_id}`,
      method: 'get',
    });
  };

  static readonly create = (data: CreateRole) => {
    return request<RoleDto>({
      url: ROLE_API_PATH,
      method: 'post',
      data,
    });
  };

  static readonly edit = ({ role_id, ...data }: EditRole) => {
    return request({
      url: `${ROLE_API_PATH}/${role_id}`,
      method: 'put',
      data,
    });
  };

  static readonly delete = (role_id: string) => {
    return request({
      url: `${ROLE_API_PATH}/${role_id}`,
      method: 'delete',
    });
  };
}
