import { useMemo, useState } from 'react';
import { OrdersService } from 'App/api';
import { useApiQuery } from 'hooks';

export const useChangeHistory = ({ orderId }: { orderId: string }) => {
  const [page, setPage] = useState(1);

  const params = useMemo(() => {
    return {
      page,
      sales_order_id: orderId,
    };
  }, [page, orderId]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getChangeHistory', key], () =>
    OrdersService.getChangeHistory({ params }),
  );

  return { data, page, totalRecords: data?.total ?? 0, isLoading, setPage };
};
