import { Box, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { LocationService } from 'App/api';
import { useLocations } from 'App/contexts';
import { useApiMutation, useApiQuery } from 'hooks';
import { CreateLocation, Location } from 'shared';
import { LOCATIONS } from 'shared/interactions';
import { ConfirmModal } from 'components';

import { Form } from '../form';

export const Edit = ({
  onExit,
  canDelete,
}: {
  onExit: () => void;
  canDelete: boolean;
}): JSX.Element => {
  const { id: locationId = '' } = useParams();
  const { refetch } = useLocations();

  const onSuccess = () => {
    onExit();
    refetch();
  };
  const {
    data,
    isLoading: pending,
    isError,
  } = useApiQuery(
    [LOCATIONS, locationId],
    () => LocationService.getLocationById(locationId),
    {
      cacheTime: 0,
    },
  );

  const { mutate: update, isLoading } = useApiMutation(
    ['updateLocation'],
    (data: CreateLocation) => LocationService.update(locationId, data),
    {
      onSuccess,
    },
  );

  const { mutate: deleteLocation, isLoading: deleting } = useApiMutation(
    ['delete'],
    () => LocationService.delete(id),
    {
      onSuccess,
    },
  );

  if (isError) {
    onExit();
  }

  if (pending && !data) {
    return <div>...</div>;
  }

  const { id, ...location } = data as Location;

  const loading = isLoading || deleting;

  return (
    <Form
      handleSubmit={(data: CreateLocation) => update(data)}
      processing={loading}
      initialValues={location as CreateLocation}
      title={location.name}
    >
      {canDelete && (
        <Box mb="xl">
          <ConfirmModal
            type="button"
            variant="outline"
            fullWidth
            loading={loading}
            onConfirm={() => deleteLocation({})}
            label="Delete Location"
            data-testid="delete-location-btn"
          >
            <Text data-testid="delete-location-text">
              {`Are you sure you want to delete ${location.name} location?`}
            </Text>
          </ConfirmModal>
        </Box>
      )}
    </Form>
  );
};
