import {
  Flex,
  Text,
  Select,
  NumberInput,
  ActionIcon,
  Loader,
  Grid,
  Box,
} from '@mantine/core';
import { reject, without } from 'rambda';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import {
  CreateTransfer,
  getSelectOptions,
  SkuOptions,
  notFoundProductError,
  LOADER,
} from 'shared';

type PropType = {
  form: UseFormReturnType<
    CreateTransfer,
    (values: CreateTransfer) => CreateTransfer
  >;
  skusOptions: SkuOptions[];
  isLoadingSkus: boolean;
};
export const AddProduct = ({ form, skusOptions, isLoadingSkus }: PropType) => {
  const items = form.values?.items;
  const canRemove = items?.length > 1;
  const skuIds = items?.map(({ sku_id }) => sku_id);
  const isLibrary = form.values.is_library;

  const fullWidth = { width: '100%' };

  return (
    <Box mt="xl">
      <Grid gutter="xl">
        <Grid.Col span={6}>
          <Text size={14} weight={600}>
            SKU#
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid gutter={'md'}>
            <Grid.Col span={5}>
              <Text size={14} weight={600} align="left">
                Qty
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Box>
        {items?.map(({ key, sku_id }, index: number) => {
          const { quantities } =
            skusOptions.find((sku: SkuOptions) => sku.id === sku_id) ?? {};
          const quantity = isLibrary
            ? quantities?.library
            : quantities?.default;

          const isEmpty = sku_id && !quantity;

          const rejectSkus = without([sku_id], skuIds);
          const available = reject(
            ({ id }) => rejectSkus.includes(id),
            skusOptions,
          );
          const opts = getSelectOptions(available);

          return (
            <Grid gutter="xl" key={key ?? sku_id} mb="xs">
              <Grid.Col span={6}>
                <Select
                  sx={fullWidth}
                  searchable
                  nothingFound={notFoundProductError(!!opts.length)}
                  data-testid="sku_product_name"
                  icon={
                    isLoadingSkus ? (
                      <Loader data-testid={LOADER} size={20} />
                    ) : null
                  }
                  data={opts}
                  {...form.getInputProps(`items.${index}.sku_id`)}
                />
              </Grid.Col>

              <Grid.Col span={6}>
                <Grid align="flex-start" justify="space-between">
                  <Grid.Col
                    span={5}
                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                  >
                    <NumberInput
                      styles={{ input: { width: 57 } }}
                      hideControls
                      disabled={isEmpty}
                      min={1}
                      max={quantity}
                      data-testid="quantity_sku"
                      {...form.getInputProps(`items.${index}.quantity`)}
                      onChange={(value) =>
                        form.setFieldValue(`items.${index}.quantity`, value)
                      }
                      error={
                        (isEmpty as boolean) ||
                        form.getInputProps(`items.${index}.quantity`).error
                      }
                    />
                    <Text
                      ml="xs"
                      size={14}
                      align="right"
                      pt={6}
                      color={isEmpty ? 'red' : undefined}
                      data-testid="quantity-text"
                    >
                      / {quantity}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span="auto">
                    <Flex justify="flex-end" align={'flex-start'}>
                      <ActionIcon
                        mt={4}
                        disabled={!canRemove}
                        onClick={() => form.removeListItem('items', index)}
                        data-testid="delete-button"
                      >
                        <IconTrash size={24} stroke={1} />
                      </ActionIcon>
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
};
