import { Box, PasswordInput, Button } from '@mantine/core';
import { isEmpty } from 'rambda';
import { useForm, yupResolver } from '@mantine/form';
import { createPasswordSchema } from 'shared';

export const ResetPasswordForm = ({
  handleSubmit,
  loading,
}: {
  handleSubmit: (p: string) => void;
  loading: boolean;
}): JSX.Element => {
  const form = useForm({
    initialValues: {
      password: '',
      confirm: '',
    },
    validate: yupResolver(createPasswordSchema),
    validateInputOnBlur: true,
  });
  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values.password))}>
        <PasswordInput
          placeholder="Password"
          label="Enter New Password"
          description="Minimum 8 characters and contain at least 1 upper case, 1 lower case and 1 number"
          required
          {...form.getInputProps('password')}
        />
        <Box mt="lg">
          <PasswordInput
            placeholder="Repeat Password"
            label="Repeat New Password"
            required
            {...form.getInputProps('confirm')}
          />
        </Box>

        <Box my="xl">
          <Button
            disabled={!isEmpty(form.errors)}
            type="submit"
            fullWidth
            loading={loading}
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};
