import * as Yup from 'yup';
import {
  requiredStringValidationRule,
  requiredNumberValidationRule,
} from 'shared';

const items = Yup.object().shape({
  sku_id: requiredStringValidationRule('Product'),
  quantity: requiredNumberValidationRule('Quantity'),
  is_swappable: Yup.bool().notRequired(),
});

export const wineSelectionSchema = Yup.object({
  wine_selections: Yup.array().of(items),
});
