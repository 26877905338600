import { omit } from 'rambda';
import { InventoryTransferService } from 'App/api';
import { useApiMutation } from 'hooks';
import { InventoryTransferPayload } from 'shared';
import { getTotal } from '../components/form';

export const useCreateTransfer = ({
  onCreate,
}: {
  onCreate: (id: string) => void;
}) => {
  const { mutate, isLoading } = useApiMutation(
    ['createTransfer'],
    InventoryTransferService.create,
    { onSuccess: ({ id }) => onCreate(id) },
  );

  const handleSubmit = (value: InventoryTransferPayload) => {
    const valueTransfer = omit(['origin', 'destination'], value);

    const payload = {
      ...valueTransfer,
      items: valueTransfer.items.map(omit(['key'])),
      total: getTotal(valueTransfer.items),
      comment: '',
    };

    mutate(payload);
  };
  return { handleSubmit, isLoading };
};
