import { PrivateDocumentDto } from 'shared';
import {
  UPLOAD_DOCUMENT_PATH,
  GET_STATES_PATH,
  UPSERT_DOCUMENT_PATH,
  PRIVATE_DOCUMENT_PATH,
} from '../endpoints';
import request from '../request';

export default class GeneralService {
  static readonly uploadDocument = (
    document: File | null,
  ): Promise<{ url: string }> => {
    return request({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: UPLOAD_DOCUMENT_PATH,
      method: 'post',
      data: { document },
    });
  };

  static readonly upsertDocument = (data: {
    document: File;
    url: string;
  }): Promise<{ url: string }> => {
    return request({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: UPSERT_DOCUMENT_PATH,
      method: 'post',
      data,
    });
  };

  static readonly getPrivateDocumentUrl = (data: { document_name: string }) => {
    return request<PrivateDocumentDto>({
      url: PRIVATE_DOCUMENT_PATH,
      method: 'post',
      data,
    });
  };

  static readonly getStates = () => {
    return request<string[]>({
      url: GET_STATES_PATH,
      method: 'get',
    });
  };
}
