import * as Yup from 'yup';

import { requiredStringValidationRule } from 'shared';

const productSchema = Yup.object().shape({
  sku_id: requiredStringValidationRule('Product'),
});

export const culinaryMenuSchema = Yup.object({
  name: requiredStringValidationRule('Menu Name'),
  location_id: requiredStringValidationRule('Primary Location'),
  inventory_location_id: requiredStringValidationRule('Inventory Location'),
  items: Yup.array().of(productSchema),
});
