import { useMemo, useState } from 'react';
import { ShipmentSalesOrdersService } from 'App/api';
import { useApiQuery } from 'hooks';

export const useSkipHistory = ({ customerId }: { customerId: string }) => {
  const [page, setPage] = useState<number>(1);

  const params = useMemo(() => {
    return {
      page,
    };
  }, [page]);

  const key = JSON.stringify(params);

  const { data: skippedOrders, isLoading } = useApiQuery(
    ['skip-history', customerId, key],
    () =>
      ShipmentSalesOrdersService.getSkippedCustomerOrders({
        customer_id: customerId,
        params,
      }),
    {
      enabled: !!customerId,
    },
  );

  return {
    skippedOrders,
    isLoading,
    page,
    setPage,
    totalRecords: skippedOrders?.total ?? 0,
  };
};
