import { useEffect, useState } from 'react';
import { AutocompleteAddress } from 'shared';
import { normalizeAutocompleteData } from '../helpers';

export const useAutocompleteAddress = <T>({
  setValues,
}: {
  setValues: (data: T) => void;
}) => {
  const [currentData, setCurrentData] = useState<AutocompleteAddress | null>(
    null,
  );

  useEffect(() => {
    if (currentData) {
      const fillData = normalizeAutocompleteData(currentData) as T;
      setValues(fillData);
    }
  }, [currentData, setValues]);

  return { setCurrentData };
};
