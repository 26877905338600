import dayjs from 'dayjs';
import { CreateDiscount } from 'shared';

export const getNewItem = () => '';

export const formatDate = (date?: string | null) => {
  if (!date) return null;
  return dayjs(date.replace(/\./g, '/')).format('YYYY-MM-DD');
};

export const normalizeDiscountPayload = (
  data: CreateDiscount,
): CreateDiscount => {
  return {
    ...data,
    start_date: formatDate(data.start_date),
    end_date: formatDate(data.end_date),
    category_id: data.subcategory_id ?? data.category_id,
  };
};
