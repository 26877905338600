import * as Yup from 'yup';

import { requiredStringValidationRule } from 'shared';

export const posOrderSchema = Yup.object({
  default_wine_advisor_id: requiredStringValidationRule('Wine Advisor'),
});

export const receiptSchema = Yup.object({
  email: Yup.string().nullable(),
  phone: Yup.string().nullable(),
});
