import { useRef, useCallback } from 'react';

export const useDebouncedCallback = <T>({
  func,
  wait,
}: {
  func: (agr: T) => void;
  wait: number;
}) => {
  const timeout = useRef<NodeJS.Timeout>();

  const debounce = useCallback(
    (arg: T) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(arg);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait],
  );

  return debounce;
};
