import { UseFormReturnType } from '@mantine/form';
import {
  AdditionalInformationSku,
  CreateCulinarySku,
  CreateSku,
  ProfileSku,
} from 'shared/types';

export const getFormProfile = (
  form: UseFormReturnType<CreateCulinarySku> | UseFormReturnType<CreateSku>,
) => {
  return {
    ...form,
    values: {
      price: form.values.price,
      saved_as_draft: form.values.saved_as_draft,
      product_name: form.values.product_name,
      is_enabled: form.values.is_enabled,
      hide_from_pos: form.values.hide_from_pos,
      show_in_ecommerce: form.values.show_in_ecommerce,
      inventory_type: form.values.inventory_type,
      category_id: form.values.category_id,
      subcategory_id: form.values.subcategory_id,
      cost: form.values.cost,
      tax_rate: form.values.tax_rate,
      vendor_id: form.values.vendor_id,
    },
  } as unknown as UseFormReturnType<ProfileSku>;
};

export const getFormAdditionalInformation = (
  form: UseFormReturnType<CreateCulinarySku> | UseFormReturnType<CreateSku>,
) => {
  return {
    ...form,
    values: {
      product_file: form.values.product_file,
      e_gift_card: form.values.e_gift_card,
      product_description: form.values.product_description,
      comment: form.values.comment,
    },
  } as unknown as UseFormReturnType<AdditionalInformationSku>;
};
