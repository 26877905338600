import { Employee, EmployeeOutput } from '../../types';

export const normalizeEmployee = ({
  id,
  first_name,
  last_name,
  status,
  primary_location: { name: location_name },
  role: { name: role_name },
}: Employee): EmployeeOutput => ({
  id,
  name: `${first_name} ${last_name}`,
  status,
  location_name,
  role_name,
});
