import { UnstyledButton, ButtonProps } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';

export const EditButton = (
  props: ButtonProps & {
    onClick?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: any;
    to?: string;
  },
) => (
  <UnstyledButton data-testid="edit-btn" {...props}>
    <IconPencil stroke={1} />
  </UnstyledButton>
);
