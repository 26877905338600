import { Link, useNavigate, useParams } from 'react-router-dom';
import { Anchor, Button, Container, Flex, Text } from '@mantine/core';
import {
  BackButton,
  ConfirmModal,
  ContentLayout,
  TitleWithBadge,
} from 'components';
import { getEditPath, getFullName, InventoryLibraryStatus } from 'shared';
import { useEditInventoryLibrary } from '../../hooks';
import { dateFormat } from '../../constants';
import { LibraryInfo } from '../form/details';

type Props = {
  canRelease: boolean;
  canCancel: boolean;
  rootPath: string;
  onExit: () => void;
};

export const Details = ({
  canCancel,
  canRelease,
  onExit,
  rootPath,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { data, isLoading, isCancelled, cancelLibrary } =
    useEditInventoryLibrary({
      id,
      onCancel: onExit,
    });
  const { sku, issued_by, created_at, status } = data ?? {};

  return (
    <ContentLayout
      title={
        !isLoading && (
          <TitleWithBadge
            title={`Library Reserve - ${sku?.product_name} (${sku?.sku_number})`}
          />
        )
      }
      processing={isLoading}
      leftAction={<BackButton onClick={() => navigate(-1)} />}
    >
      <Container size="sm" mb={32}>
        <LibraryInfo data={data} />
        {canRelease && (
          <Button
            data-testid="release-for-sale-btn"
            mb={20}
            type="submit"
            onClick={() => navigate(getEditPath(rootPath, id))}
            fullWidth
            aria-label="release"
          >
            Release for Sale
          </Button>
        )}
        {canCancel ? (
          <ConfirmModal
            mt={20}
            data-testid="cancel-operation-btn"
            type="button"
            variant="outline"
            fullWidth
            loading={isCancelled}
            onConfirm={() => cancelLibrary(id)}
            label="Cancel"
            disabled={status === InventoryLibraryStatus.CANCELED}
          >
            <Text>Are you sure you want to cancel the Library?</Text>
          </ConfirmModal>
        ) : null}
      </Container>
      <Flex align="flex-end" direction="column">
        <Text weight={400} size="sm" color="gray" mb={4}>
          Created:
        </Text>
        <Text weight={400} size="sm" color="gray" mb={4}>
          {created_at &&
            new Date(created_at).toLocaleDateString('en-US', dateFormat)}
          , {issued_by && getFullName(issued_by)}
        </Text>
        <Anchor size={14} component={Link} to="">
          See edit history
        </Anchor>
      </Flex>
    </ContentLayout>
  );
};
