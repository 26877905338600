import { Grid, Button, Text, LoadingOverlay } from '@mantine/core';
import { useRoles } from 'hooks';
import { LOADING_OVERLAY } from 'shared';

export const List = ({
  onCreate,
  onView,
  location,
  title,
  canCreate,
}: {
  onCreate: () => void;
  onView: (location: string) => void;
  location: string;
  title: string;
  canCreate: boolean;
}): JSX.Element => {
  const { roles, isLoading } = useRoles(location);

  return (
    <>
      <LoadingOverlay
        data-testid={LOADING_OVERLAY}
        visible={isLoading}
        overlayBlur={2}
      />
      <Text
        data-testid="content-title"
        size={'lg'}
        weight={700}
        align="center"
        mb={24}
      >
        {title}
      </Text>

      {roles.map(({ id, name, description }) => (
        <Grid data-testid="role-element" gutter="xl" key={id} align="center">
          <Grid.Col span={3}>
            <Text lineClamp={1}>{name}</Text>
          </Grid.Col>
          <Grid.Col span={7}>
            <Text lineClamp={1}>{description ?? ''}</Text>
          </Grid.Col>
          <Grid.Col data-testid="see-details-container" span={2}>
            <Button
              data-testid="see-details-btn"
              variant="subtle"
              size="md"
              onClick={() => onView(id)}
            >
              See Details
            </Button>
          </Grid.Col>
        </Grid>
      ))}
      {canCreate && (
        <Grid justify={'center'} mt="lg">
          <Grid.Col md={6} lg={4}>
            <Button
              data-testid="new-role-btn"
              variant="filled"
              fullWidth
              size="lg"
              onClick={onCreate}
            >
              + New Role
            </Button>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};
