import { Link } from 'react-router-dom';
import { ContentLayout, BackButton } from 'components';
import { Form, getNewItem } from '../form';
import { useCreateMenu } from '../../hooks';

const title = 'New Menu';

const initialValues = {
  name: '',
  is_active: true,
  comment: '',
  inventory_location_id: '',
  items: [getNewItem()],
};

export const Create = ({
  onExit,
  rootPath,
}: {
  onExit: () => void;
  rootPath: string;
}): JSX.Element => {
  const { handleSubmit, isLoading } = useCreateMenu({ onExit });
  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      leftAction={<BackButton component={Link} to={rootPath} />}
    >
      <Form handleSubmit={handleSubmit} initialValues={initialValues} />
    </ContentLayout>
  );
};
