import { LibraryListItemDto, StatusInvetoryLibrary } from 'shared/types';

export const normalizeInventoryLibrary = ({
  created_at,
  updated_at,
  status,
  id,
  ...rest
}: LibraryListItemDto) => {
  const reserveDate = new Date(created_at).toLocaleDateString('en-US');
  const updatedDate = updated_at
    ? new Date(updated_at).toLocaleDateString('en-US')
    : reserveDate;

  return {
    id,
    reserveDate,
    updatedDate,
    status: StatusInvetoryLibrary[status],
    ...rest,
  };
};
