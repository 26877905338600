import { PaginatedRequest } from './common';
import { Location, RoleListDto } from './settings';

export enum EmployeeStatus {
  INTRO = 'intro',
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export const StatusColor: { [key in EmployeeStatus]: string } = {
  [EmployeeStatus.INTRO]: 'blue',
  [EmployeeStatus.DISABLED]: 'red',
  [EmployeeStatus.ACTIVE]: 'green',
};

export const StatusLabel: { [key in EmployeeStatus]: string } = {
  [EmployeeStatus.INTRO]: 'Intro',
  [EmployeeStatus.DISABLED]: 'Inactive',
  [EmployeeStatus.ACTIVE]: 'Active',
};
export interface ShortEmployee {
  first_name: string;
  last_name: string;
  email: string;
}

export type EmployeeSearchParams = {
  page?: string;
  search_string?: string;
  page_size?: string;
  location_id?: string;
  field?: 'name' | 'location_name' | 'role_name' | 'status';
  direction?: 'asc' | 'desc';
};
export interface Employee {
  id: string;
  first_name: string;
  last_name: string;
  full_name?: string;
  email: string;
  status: EmployeeStatus;
  start_date: string;
  issued_at: string;
  issued_by: ShortEmployee;
  passcode?: string;
  primary_location: Location;
  role: RoleListDto;
  can_be_approver?: boolean;
}

export interface EmployeesRequest extends PaginatedRequest {
  search_string?: string;
  location_id?: string;
  is_active?: boolean;
  include_system?: boolean;
  field?: 'name' | 'location_name' | 'role_name' | 'status';
  direction?: 'asc' | 'desc';
}

export type NewEmployeeDefault = {
  email: string;
  first_name: string;
  last_name: string;
  primary_location_id: string;
  role_id: string;
  passcode?: string;
};

export type NewEmployee = NewEmployeeDefault & {
  start_date: string;
};

export type EmployeeFormValues = NewEmployeeDefault & {
  start_date: Date;
  hasPosAccess?: boolean;
  active?: boolean;
  can_be_approver?: boolean;
};

export type EmployeeEditData = {
  email: string;
  first_name: string;
  last_name: string;
  primary_location_id: { value: string; label: string };
  role_id: { value: string; label: string };
  start_date: Date;
  passcode: number;
};

export type EmployeeOutput = {
  name: string;
  role_name: string;
  status: EmployeeStatus;
  location_name: string;
  id: string;
  passcode?: string;
};
