import { dissoc, omit } from 'rambda';
import { PROCESSING_TIME } from 'pages/products/skus/constants';
import {
  CreateSku,
  ProductDto,
  inventoryTypeLabels,
  InventoryType,
  taxRateLabels,
  CulinarySkuDto,
  CreateCulinarySku,
} from 'shared/types';

const NOT_ASSIGNED = 'Not Assigned';

export const getProductFileName = (data: string | null) => {
  if (!data) return 'Upload Product File';

  const arr = data.split('/');
  return arr[arr.length - 1];
};

export const getMargin = (price?: string | null, cost?: number | null) => {
  if (price?.indexOf('%') === 0) return 'N/A';

  if (price?.indexOf('$') === 0 && cost) {
    const dropDollar = +price.replace('$', '');

    return dropDollar && dropDollar > 0
      ? `${(((dropDollar - cost) / dropDollar) * 100).toFixed(2)}%`
      : 'N/A';
  }

  return 'N/A';
};

const sharedSkuData = (value: ProductDto | CulinarySkuDto) => {
  return {
    vendor_id: value.vendor?.id ?? null,
    category_id: value.category?.id ?? null,
    subcategory_id: value.subcategory?.id ?? null,
    per_order_limit: value.per_order_limit ?? '',
    cost: value.cost ?? null,
  };
};

export const getSpecificInformation = ({
  type,
  isKit,
  isNonInventory,
  value,
}: {
  type: string | undefined;
  isKit?: boolean;
  isNonInventory?: boolean;
  value?: CreateSku['specific_information'];
}) => {
  if (isKit)
    return {
      items: value?.items?.map(({ id, quantity }) => {
        return { id, quantity };
      }),
    };

  if (type === InventoryType.ALCOHOL) return omit(['items'], value);

  if (isNonInventory)
    return {
      locations: value?.locations?.map((l) => ({
        location_id: l.location.id,
        inventory_location_id: l.inventory_location.id,
      })),
    };

  return null;
};

export const getInventoryType = (
  type: string | undefined,
  isBtg: boolean,
  isKit: boolean,
  values: CreateSku,
) => {
  const btg = isBtg ? values?.btg : null;

  return type === InventoryType.ALCOHOL && !isKit
    ? { ...values, btg }
    : dissoc('btg', values);
};

export const getSkuType = (type: string | undefined, isKit?: boolean) => {
  if (isKit) return 'kit';
  if (type === InventoryType.ALCOHOL) return 'alcohol';

  return 'general';
};

export const getSkuStatus = (isEnabled?: boolean, isDraft?: boolean) => {
  if (isDraft) return 'Draft';

  return isEnabled ? 'Enabled' : 'Disable';
};

export const normalizeSku = ({
  id,
  sku_number,
  inventory_type,
  product_name,
  category,
  is_enabled,
  saved_as_draft,
  margin,
}: ProductDto) => {
  return {
    id,
    sku_number,
    product_name,
    inventory_type: inventoryTypeLabels[inventory_type as InventoryType] || '',
    state: getSkuStatus(is_enabled, saved_as_draft),
    margin: margin ? `${margin}%` : '',
    name: category?.name ?? '',
  };
};
export const normalizeCulinarySku = ({
  id,
  sku_number,
  subcategory,
  category,
  product_name,
  is_enabled,
  saved_as_draft,
  comment,
}: CulinarySkuDto) => {
  return {
    id,
    sku_number,
    product_name,
    state: getSkuStatus(is_enabled, saved_as_draft),
    category: category?.name ?? '',
    subcategory: subcategory?.name ?? '',
    comment,
  };
};

export const normalizeInitialValue = (value: ProductDto): CreateSku => {
  const valueEdit = omit(['category', 'subcategory', 'vendor'], value);

  return {
    ...valueEdit,
    ...sharedSkuData(value),
    price: valueEdit.price ?? '',
    is_kit: value.sku_type === 'kit',
    specific_information: valueEdit.specific_information
      ? { ...valueEdit.specific_information }
      : {
          is_available_by_glass: false,
          is_available_only_by_glass: false,
          abv: '',
          bottle_size: '',
          varietal: null,
          glasses_per_bottle: null,
        },
    btg: valueEdit.btg
      ? { ...valueEdit.btg }
      : { btg_tax_rate: undefined, btg_cost: null, btg_price: '' },
  };
};

export const normalizeInitialCulinarySku = (
  value: CulinarySkuDto,
): CreateCulinarySku => {
  const valueEdit = omit(['category', 'subcategory', 'vendor'], value);

  return {
    ...valueEdit,
    ...sharedSkuData(value),
    price: valueEdit.price ?? '',
    specific_information: {
      items:
        value.specific_information?.items?.map((item) => {
          return { ...item, key: item.id, is_variants: !!item.variants.length };
        }) ?? [],
    },
  };
};

export const getLabel = (
  types: { value: string; label: string }[],
  value?: string | null,
) => types.find((type) => type.value === value)?.label;

const getOrDefault = (
  value?: string | number | null,
  defaultValue?: string,
) => {
  return value ?? defaultValue;
};

export const normalizeSkuDetails = ({
  tax_rate,
  inventory_type,
  ...value
}: ProductDto) => {
  return {
    productName: value?.product_name,
    status: getSkuStatus(value?.is_enabled, value?.saved_as_draft),
    hideFromPOS: value?.hide_from_pos && 'Hide from POS',
    show_in_ecommerce: value?.show_in_ecommerce && 'E-commerce',
    inventoryType: getOrDefault(
      inventoryTypeLabels[inventory_type as InventoryType],
      NOT_ASSIGNED,
    ),
    skuNumber: value?.sku_number,
    category: getOrDefault(value?.category?.name, NOT_ASSIGNED),
    subcategory: getOrDefault(value.subcategory?.name, NOT_ASSIGNED),
    cost: value.cost ? `$${value?.cost}` : NOT_ASSIGNED,
    price: getOrDefault(value.price, NOT_ASSIGNED),
    margin: value.margin ? `${value?.margin}%` : 'N/A',
    taxRate: getOrDefault(taxRateLabels[tax_rate], NOT_ASSIGNED),
    vendorId: value.vendor?.id,
    specificInformation: value.specific_information,
    processingTime: getLabel(PROCESSING_TIME, value?.processing_time),
    pickupOnly: value.pickup_only && 'Pickup Only',
    membersOnly: value.members_only && 'Members Only',
    width: getOrDefault(value.width, NOT_ASSIGNED),
    height: getOrDefault(value.height, NOT_ASSIGNED),
    depth: getOrDefault(value.depth, NOT_ASSIGNED),
    shipsSeparately: value.ships_separately && 'Ships Separately',
    weight: getOrDefault(value.weight, NOT_ASSIGNED),
    perOrderLimit: getOrDefault(value.per_order_limit, NOT_ASSIGNED),
    productFile: value.product_file,
    eGiftCard: value.e_gift_card && 'E-gift Card',
    productDescription: getOrDefault(value.product_description, NOT_ASSIGNED),
    comment: getOrDefault(value.comment, NOT_ASSIGNED),
    skuType: value.sku_type,
    btg: value.btg,
  };
};
