import { useNavigate, useParams } from 'react-router-dom';
import { LoadingOverlay, Text } from '@mantine/core';

import { ConfirmModal, ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useEditCulinarySkus } from '../../hooks';
import { DELETE_BTN, LOADING_OVERLAY } from 'shared';

type PropType = {
  canDelete: boolean;
  canEdit: boolean;
  onExit: () => void;
};

export const Edit = ({ canDelete, canEdit, onExit }: PropType): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const {
    initialValue,
    isLoading,
    isDeleting,
    deleteSku,
    isUpdating,
    handleSubmit,
    processingUpload,
  } = useEditCulinarySkus({
    id,
    onDelete: onExit,
    onExit,
  });

  if (isLoading)
    return (
      <LoadingOverlay
        data-testid={LOADING_OVERLAY}
        visible={isLoading}
        overlayBlur={2}
      />
    );

  const goBack = () => navigate(-1);

  return (
    <ContentLayout
      title="Edit SKU"
      leftAction={<BackButton onClick={goBack} />}
    >
      <Form
        handleSubmit={handleSubmit}
        initialValues={initialValue}
        processing={isUpdating || processingUpload}
        editMode
        canEdit={canEdit}
        action={
          canDelete ? (
            <ConfirmModal
              type="button"
              variant="outline"
              fullWidth
              loading={isDeleting}
              onConfirm={() => deleteSku(id)}
              label="Delete"
              data-testid={DELETE_BTN}
            >
              <Text data-testid="delete-sku-text-message">
                Are you sure you want to delete the SKU?
              </Text>
            </ConfirmModal>
          ) : null
        }
      />
    </ContentLayout>
  );
};
