import dayjs from 'dayjs';
import { DATE_FORMAT_SLASH } from 'shared';
import { DiscountDto } from 'shared/types';

export const normalizeDiscountList = (data?: DiscountDto[]) => {
  if (!data) return [];

  return data.map(
    ({
      id,
      name,
      unit,
      value,
      locations,
      club_tier,
      is_active,
      end_date,
      start_date,
    }) => {
      const startDate = start_date
        ? dayjs(start_date).format(DATE_FORMAT_SLASH)
        : '';
      const endDate = start_date
        ? dayjs(end_date).format(DATE_FORMAT_SLASH)
        : '';
      const allLocation = locations?.map((location) => location?.name).join();

      return {
        id,
        name,
        value: `${value}${unit}`,
        dates: `${startDate}-${endDate}`,
        status: is_active ? 'Enabled' : 'Disabled',
        club_tier: club_tier?.name ?? '-',
        locations:
          allLocation.length >= 26
            ? `${allLocation.slice(0, 26)}...`
            : allLocation,
      };
    },
  );
};
