import * as Yup from 'yup';

import { requiredStringValidationRule } from 'shared';

const productSchema = Yup.object().shape({
  sku_id: requiredStringValidationRule('Product'),
  quantity: Yup.number().required('Required').min(1, 'Required'),
});

export const libraryReserveSchema = Yup.object({
  inventory_location_id: requiredStringValidationRule('Inventory Location'),
  items: Yup.array().of(productSchema),
});

export const releaseForSaleSchema = Yup.object({
  quantity: Yup.number().required('Required').notOneOf([0], 'Required'),
  comment: requiredStringValidationRule('Comment'),
});
