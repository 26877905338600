import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Grid, Text } from '@mantine/core';
import { LinkOrderOptions } from 'shared';

export const LinkOrders = ({
  linkOrders,
}: {
  linkOrders?: LinkOrderOptions;
}) => {
  const navigate = useNavigate();

  if (!linkOrders?.items?.length) return null;

  return (
    <Grid my={30}>
      {linkOrders.items?.map(({ label, link, total }) => (
        <Fragment key={label}>
          <Grid.Col span={6}>
            <Box sx={{ display: 'inline-block' }}>
              <Text
                size="sm"
                sx={{ cursor: 'pointer' }}
                weight={600}
                color="halterBlue.7"
                onClick={() => navigate(link)}
              >
                {label}
              </Text>
            </Box>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex justify="end" pr={8}>
              {total}
            </Flex>
          </Grid.Col>
        </Fragment>
      ))}
      <Grid.Col span={12}>
        <Flex justify="flex-end" mb={2}>
          <Box>
            <Text align="right" pr="xs" size="sm">
              Grand Total
            </Text>
          </Box>
          <Box pr="xs" sx={{ width: '11%' }}>
            <Text align="right" size="sm">
              {linkOrders.grandTotal}
            </Text>
          </Box>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};
