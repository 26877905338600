import { CulinarySkuService } from 'App/api';
import { useApiMutation, useFileUpload } from 'hooks';
import { CreateCulinarySku } from 'shared';

type Props = {
  onExit: () => void;
};

export const useCreateCulinarySkus = ({ onExit }: Props) => {
  const { mutateAsync, isLoading: processingUpload } = useFileUpload();
  const { mutate: createCulinarySku, isLoading } = useApiMutation(
    ['createSku'],
    CulinarySkuService.create,
    {
      onSuccess: onExit,
    },
  );

  const handleSubmit = async (values: CreateCulinarySku, file: File | null) => {
    const payload = {
      ...values,
      price: values.price ?? null,
    };
    if (!file) return createCulinarySku(payload);

    await mutateAsync(file, {
      onSuccess: ({ url }) => {
        createCulinarySku({ ...payload, product_file: url || null });
      },
    });
  };

  return {
    handleSubmit,
    isLoading,
    processingUpload,
  };
};
