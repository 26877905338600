import {
  Box,
  Text,
  Select,
  NumberInput,
  ActionIcon,
  Loader,
  Grid,
  Flex,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { reject, without } from 'rambda';

import { SkuData } from 'pages/inventory/hooks';
import {
  getSelectOptions,
  CreateLibrary,
  notFoundProductError,
  LOADER,
} from 'shared';

type PropType = {
  form: UseFormReturnType<
    CreateLibrary,
    (values: CreateLibrary) => CreateLibrary
  >;
  skusData: SkuData[];
  isLoadingSkus: boolean;
};
export const AddProduct = ({ form, skusData, isLoadingSkus }: PropType) => {
  const items = form.values.items;

  const itemIds = items.map(({ sku_id }) => sku_id);
  const canRemove = items.length > 1;

  return (
    <Box mt="xl">
      <Grid gutter="xl">
        <Grid.Col span={6}>
          <Text size={14} weight={600}>
            SKU#
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid gutter="md">
            <Grid.Col span={3}>
              <Text size={14} align="left" weight={600}>
                Qty
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text size={14} align="center" weight={600}>
                Available
              </Text>
            </Grid.Col>
            <Grid.Col span={5}>
              <Text size={14} align="center" weight={600}>
                Existing Library
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Box>
        {items.map(({ sku_id }, index: number) => {
          const { quantity = '-', quantity_lib } =
            skusData.find((sku: SkuData) => sku.id === sku_id) ?? {};

          const itemTotal = quantity;
          const isEmpty = !itemTotal;
          const rejectItems = without([sku_id], itemIds);
          const available = reject(
            ({ id }) => rejectItems.includes(id),
            skusData,
          );
          const opts = getSelectOptions(available);

          const max = quantity || 0;

          return (
            <Grid gutter="xl" key={sku_id} mb="xs">
              <Grid.Col span={6}>
                <Select
                  sx={{ width: '100%' }}
                  searchable
                  nothingFound={notFoundProductError(!!opts.length)}
                  data-testid="sku_name"
                  data={opts}
                  icon={
                    isLoadingSkus ? (
                      <Loader data-testid={LOADER} size={20} />
                    ) : null
                  }
                  {...form.getInputProps(`items.${index}.sku_id`)}
                  onChange={(value: string) => {
                    form.setFieldValue(`items.${index}.sku_id`, value);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Grid gutter="xl" align="flex-start">
                  <Grid.Col span={3}>
                    <NumberInput
                      data-testid="quantity_sku"
                      styles={{ input: { width: '100%' } }}
                      disabled={isEmpty}
                      max={max}
                      min={0}
                      {...form.getInputProps(`items.${index}.quantity`)}
                      error={
                        isEmpty ||
                        form.getInputProps(`items.${index}.quantity`).error
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={3} pt={'lg'}>
                    <Text
                      size={14}
                      align="center"
                      color={isEmpty ? 'red' : undefined}
                    >
                      {itemTotal}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={4} pt={'lg'}>
                    <Text size={14} align="center">
                      {quantity_lib ?? '-'}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2} pt={'md'}>
                    <Flex justify="flex-end">
                      <ActionIcon
                        onClick={() => form.removeListItem('items', index)}
                        disabled={!canRemove}
                      >
                        <IconTrash size={24} stroke={1} />
                      </ActionIcon>
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
};
