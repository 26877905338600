import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mantine/core';
import { isEmpty } from 'rambda';

import { ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useEditFees } from '../../hooks';

type PropType = {
  onExit: () => void;
};

export const Edit = ({ onExit }: PropType): JSX.Element => {
  const { id = '' } = useParams();
  const { data, isLoading, processing, handleSubmit } = useEditFees({
    id,
    onUpdate: onExit,
  });

  const initialValues = useMemo(
    () =>
      data && {
        value: data.value,
        fees: isEmpty(data.fees) ? [] : data.fees,
      },
    [data],
  );

  return (
    <ContentLayout
      title={data && `${data?.location.name} Local Tax and Fees`}
      leftAction={<BackButton onClick={onExit} />}
      isLoading={isLoading}
      processing={processing}
    >
      <Container size={'xs'} sx={{ position: 'relative' }}>
        {initialValues && (
          <Form
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            processing={processing}
          />
        )}
      </Container>
    </ContentLayout>
  );
};
