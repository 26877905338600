import { Text, Tabs, Button } from '@mantine/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCategory } from './hooks';
import { Category, Skus, Subcategories } from './components';
import { ContentLayout, BackButton } from 'components';
import { ProductCategoryType } from 'shared';
import { Actions } from 'shared/interactions';

export const Edit = ({
  canDelete,
  onExit,
  rootPath,
}: {
  canDelete: boolean;
  onExit: () => void;
  rootPath: string;
}): JSX.Element => {
  const { id = '' } = useParams();
  const { isLoading, isError, category, refetch } = useCategory({ id });
  const [activeTab, setActiveTab] = useState<string | null>('category');
  const [action, setAction] = useState<Actions>(Actions.View);

  if (isError) {
    onExit();
  }

  const isReorder = action === Actions.Arrange;

  const isParent = category?.type === ProductCategoryType.HAS_SUBCATEGORIES;
  const showReorder =
    category?.type === ProductCategoryType.HAS_ITEMS &&
    activeTab === 'skus' &&
    !isReorder;

  return (
    <ContentLayout
      title={category?.name ?? ''}
      processing={isLoading}
      isLoading={isLoading}
      leftAction={
        <BackButton
          onClick={() => (isReorder ? setAction(Actions.View) : onExit())}
        />
      }
      rightAction={
        showReorder && (
          <Button onClick={() => setAction(Actions.Arrange)} variant="subtle">
            Reorder
          </Button>
        )
      }
    >
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List position="center">
          <Tabs.Tab px={12} value="category">
            <Text size="md">General Settings</Text>
          </Tabs.Tab>
          <Tabs.Tab px={12} value="skus" disabled={!category}>
            <Text size="md">Products</Text>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="category" pt={32}>
          {category && (
            <Category data={category} canDelete={canDelete} onDelete={onExit} />
          )}
        </Tabs.Panel>

        <Tabs.Panel value="skus" pt={32}>
          {isParent ? (
            <Subcategories data={category.subcategories} rootPath={rootPath} />
          ) : (
            <Skus
              refetch={refetch}
              onReorder={() => setAction(Actions.View)}
              category={category}
              isReorder={isReorder}
            />
          )}
        </Tabs.Panel>
      </Tabs>
    </ContentLayout>
  );
};
