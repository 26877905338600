import { Link } from 'react-router-dom';
import { Button, ButtonProps } from '@mantine/core';

export const CreateButton = (
  props: ButtonProps & {
    to: string;
  },
) => {
  return (
    <Button variant="outline" component={Link} {...props}>
      {props.children}
    </Button>
  );
};
