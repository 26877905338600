import { GoogleValidateAddressService } from 'App/api/';
import { useApiMutation } from 'hooks';
import { AddressDto } from 'shared';

export const useVerifyAddress = () => {
  const { mutateAsync: verify, isLoading: isVerifying } = useApiMutation(
    ['verifyAddress'],
    ({ data }: { data: AddressDto }) =>
      GoogleValidateAddressService.verify({
        address: {
          locality: data?.city,
          administrativeArea: data?.state,
          postalCode: data?.zip_code,
          addressLines: [data?.street_address_one, data?.street_address_two],
        },
        enableUspsCass: true,
      }),
  );
  return {
    verify,
    isVerifying,
  };
};
