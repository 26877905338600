import { EmployeeFormValues, Employee } from 'shared';

export const getEditEmployeeData = (data: Employee): EmployeeFormValues => ({
  first_name: data.first_name,
  last_name: data.last_name,
  email: data.email,
  start_date: new Date(data.start_date),
  primary_location_id: data.primary_location?.id,
  role_id: data.role?.id,
  passcode: data.passcode ?? '',
  can_be_approver: data.can_be_approver,
});

export const getEmployeePayload = (values: EmployeeFormValues) => {
  const { start_date, hasPosAccess, passcode, active, ...rest } = values;
  return {
    ...rest,
    ...(hasPosAccess ? { passcode } : {}),
    ...(active ? { status: 'active' } : {}),
    start_date: start_date.toISOString(),
  };
};
