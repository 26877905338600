import {
  InventoryTransferDto,
  StatusInvetoryTransfer,
  TransferSkus,
} from 'shared/types';

export const normalizeInventoryTransfer = ({
  id,
  issued_at,
  origin_inventory_location,
  destination_inventory_location,
  status,
  total_quantity,
  number,
  number_str,
  date_received,
  is_library,
}: InventoryTransferDto) => {
  const orderDate = new Date(issued_at).toLocaleDateString('en-US');
  const originName = `${origin_inventory_location?.location_name}: ${origin_inventory_location?.name}`;
  const destinationName = `${destination_inventory_location?.location_name}: ${destination_inventory_location?.name}`;
  const dateReceived = date_received
    ? new Date(date_received).toLocaleDateString('en-US')
    : '-';

  return {
    id,
    number,
    number_str,
    orderDate,
    originName,
    dateReceived,
    destinationName,
    is_library,
    status: StatusInvetoryTransfer[status],
    total: total_quantity?.toLocaleString('en-US'),
  };
};

export const normalizeTransferlValue = ({
  origin_inventory_location,
  destination_inventory_location,
  items,
  status,
  total,
  comment,
  number,
  number_str,
  is_library,
}: InventoryTransferDto) => {
  return {
    origin: origin_inventory_location?.location_id,
    destination: destination_inventory_location?.location_id,
    origin_inventory_location_id: origin_inventory_location?.id,
    destination_inventory_location_id: destination_inventory_location?.id,
    comment,
    status,
    total,
    items: items.filter((i) => i.quantities?.default !== 0),
    number,
    number_str,
    is_library,
  };
};

export const getAdjustmentItem = (sku: TransferSkus) => {
  const quantityAdjust = `-${
    (sku.quantity ?? 0) -
    (sku.to_receive_quantity ?? 0) -
    (sku.received_quantity ?? 0)
  }`;
  return { name: sku.sku_product_name, quantityAdjust };
};
