import { useParams, Link, useNavigate } from 'react-router-dom';
import { Container, Text } from '@mantine/core';
import { generateRoutePath, RouteName } from 'App/routes';
import { BackButton, ContentLayout, EditButton } from 'components';
import { useEditPartners } from '../../hooks';
import { getEditPath, partnerTypeLabels } from 'shared';

type Props = {
  canEdit: boolean;
};

export const Details = ({ canEdit }: Props): JSX.Element => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useEditPartners({
    id,
  });

  const partnersPath = generateRoutePath({
    name: RouteName.Partners,
  });

  return (
    <ContentLayout
      title={data?.company_name ?? ''}
      isLoading={isLoading}
      processing={isLoading}
      leftAction={<BackButton onClick={() => navigate(-1)} />}
      rightAction={
        canEdit && (
          <EditButton component={Link} to={getEditPath(partnersPath, id)} />
        )
      }
    >
      <Container size="xs">
        <Text weight={600} size="sm" mb={10}>
          {data?.company_name}
        </Text>
        <Text weight={400} size="sm" mb={10}>
          {data?.type && partnerTypeLabels[data.type]}
        </Text>
        <Text weight={400} size="sm" mb={10}>
          {data?.website}
        </Text>
        <Text weight={400} size="sm" mb={10}>
          {`${data?.street_address}, ${data?.city}, ${data?.state}, ${data?.zip_code}`}
        </Text>
        <Text weight={400} size="sm" mb={10}>
          {data?.phone_number}
        </Text>
        <Text weight={400} size="sm">
          {data?.primary_contact_name}
        </Text>
        {data?.comment && (
          <>
            <Text weight={600} size="sm" mt={20} mb={10}>
              Comment
            </Text>
            <Text weight={400} size="sm" mb={10}>
              {data?.comment}
            </Text>
          </>
        )}
      </Container>
    </ContentLayout>
  );
};
