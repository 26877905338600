import { randomId } from '@mantine/hooks';

export const getNewModifier = () => ({
  key: randomId(),
  name: '',
  price: null,
  max_quantity: null,
  hide_from_pos: false,
  mandatory: false,
  on_the_side: false,
  lite: false,
  is_variants: false,
  exclude: false,
  variants: [],
});

export const getNewVariant = () => ({
  key: randomId(),
  name: '',
  price: null,
});
