import { useEffect } from 'react';
import { ActionIcon, Box, Button, Grid, Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { isEmpty } from 'rambda';
import { IconTrash } from '@tabler/icons-react';

import { useReorder } from 'hooks';
import {
  CANCEL_BTN,
  CONFIRM_BTN,
  getSkuStatus,
  ProductCategoryDto,
} from 'shared';
import { DndItem, DndContainer, DndTrigger } from 'components';
import { useCategorySKUs } from '../../hooks';
import { Header, AddSkus } from './components';

const BottomButton = ({ children }: { children: React.ReactNode }) => (
  <Grid justify={'center'} mt={'xl'}>
    <Grid.Col lg={6} md={8}>
      {children}
    </Grid.Col>
  </Grid>
);

export const Skus = ({
  isReorder,
  refetch,
  category,
  onReorder,
}: {
  isReorder: boolean;
  refetch: () => void;
  onReorder: () => void;
  category?: ProductCategoryDto;
}): JSX.Element => {
  const categoryId = category?.id ?? '';

  const { addProducts, removeProduct, reorderProducts, isLoading } =
    useCategorySKUs({
      onUpdate: refetch,
      categoryId,
      onReorder,
    });

  const { output, restore, onOrderChange } = useReorder(
    category?.products ?? [],
  );

  const openModal = ({
    productName,
    productId,
  }: {
    productName: string;
    productId: string;
  }) =>
    openConfirmModal({
      title: (
        <Text size={'md'} weight={600}>
          Confirm deletion
        </Text>
      ),
      children: (
        <Text>
          {`Are you sure you want to remove ${productName} from the category`}
        </Text>
      ),
      // labels: { confirm: 'Confirm', cancel: 'Cancel' },
      labels: {
        confirm: <Text data-testid={CONFIRM_BTN}>Confirm</Text>,
        cancel: <Text data-testid={CANCEL_BTN}>Cancel</Text>,
      },
      onConfirm: () => removeProduct(productId),
    });

  useEffect(() => {
    restore();
  }, [isReorder, restore]);

  const addComponent = <AddSkus addProducts={addProducts} />;

  if (isEmpty(output)) {
    return (
      <>
        <Text align="center">This category doesn’t have any products yet.</Text>
        <BottomButton data-testid="add-category-btn">
          {addComponent}
        </BottomButton>
      </>
    );
  }

  return (
    <>
      <Header shift={isReorder} />
      <DndContainer onSortEnd={onOrderChange}>
        {output.map(
          (
            {
              id,
              sku_number: n,
              name,
              inventory_type,
              is_enabled,
              saved_as_draft,
            },
            i,
          ) => {
            return (
              <DndItem key={n} index={i}>
                <Grid grow gutter={'lg'} align="center">
                  {isReorder && (
                    <Grid.Col span={1}>
                      <DndTrigger />
                    </Grid.Col>
                  )}
                  <Grid.Col span={2}>{n}</Grid.Col>
                  <Grid.Col span={3}>{name}</Grid.Col>
                  <Grid.Col span={3}>{inventory_type || 'N/A'}</Grid.Col>
                  <Grid.Col span={3}>
                    {getSkuStatus(is_enabled, saved_as_draft)}
                  </Grid.Col>
                  {!isReorder && (
                    <Grid.Col span={1}>
                      <Box>
                        <ActionIcon
                          ml={'auto'}
                          size="sm"
                          onClick={() =>
                            openModal({
                              productName: name,
                              productId: id,
                            })
                          }
                          aria-label="DeleteButton"
                          color="dark.9"
                        >
                          <IconTrash stroke={1} />
                        </ActionIcon>
                      </Box>
                    </Grid.Col>
                  )}
                </Grid>
              </DndItem>
            );
          },
        )}
      </DndContainer>
      <BottomButton>
        {isReorder ? (
          <Button
            loading={isLoading}
            fullWidth
            onClick={() => reorderProducts(output)}
          >
            Save
          </Button>
        ) : (
          addComponent
        )}
      </BottomButton>
    </>
  );
};
