import React from 'react';
import { IconGripHorizontal } from '@tabler/icons-react';

import { ActionIcon } from '@mantine/core';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortableElementProps,
  SortableContainerProps,
  SortEndHandler,
} from 'react-sortable-hoc';

interface ISortableHandleElement {
  children?: React.ReactNode;
}

interface ISortableItem extends SortableElementProps {
  children: React.ReactNode;
}

interface ISortableContainer extends SortableContainerProps {
  children: React.ReactNode;
}

export const DndTrigger: React.ComponentClass<ISortableHandleElement, unknown> =
  SortableHandle(({ children = null }: { children?: React.ReactNode }) => (
    <ActionIcon>
      <IconGripHorizontal />
      {children}
    </ActionIcon>
  ));

export const DndItem: React.ComponentClass<ISortableItem, unknown> =
  SortableElement(({ children }: { children: React.ReactNode }) => (
    <div>{children}</div>
  ));

export const DndList: React.ComponentClass<ISortableContainer, unknown> =
  SortableContainer(({ children }: { children: React.ReactNode }) => {
    return <div>{children}</div>;
  });

export const DndContainer = ({
  onSortEnd,
  children,
}: {
  children: React.ReactNode;
  onSortEnd?: SortEndHandler;
}): JSX.Element => {
  return (
    <DndList
      lockAxis="y"
      lockToContainerEdges
      useDragHandle
      onSortEnd={onSortEnd}
    >
      {children}
    </DndList>
  );
};
