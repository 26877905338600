import { Grid, Box, ActionIcon } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';

import { DndTrigger } from 'components';
import { ProductCategoryDto } from 'shared';

interface Props {
  category: Omit<
    ProductCategoryDto,
    'subcategories' | 'name' | 'id' | 'parent'
  >;
  title: string | React.ReactNode;
  onClick: () => void;
  showAction: boolean;
  reorder?: boolean;
}

export const ListItem = ({
  category: { active, hide_from_pos },
  title,
  onClick,
  showAction,
  reorder = false,
}: Props) => {
  return (
    <Grid grow gutter={'lg'} align="center">
      {reorder && (
        <Grid.Col span={1}>
          <DndTrigger />
        </Grid.Col>
      )}
      <Grid.Col span={5}>{title}</Grid.Col>
      <Grid.Col span={2}>{active ? 'Yes' : 'No'}</Grid.Col>
      <Grid.Col span={3}>{hide_from_pos ? 'Yes' : 'No'}</Grid.Col>
      {!reorder && (
        <Grid.Col span={2}>
          <Box>
            {showAction && (
              <ActionIcon
                ml={'auto'}
                size="sm"
                onClick={onClick}
                aria-label="EditButton"
                color="dark.9"
              >
                <IconPencil stroke={1} />
              </ActionIcon>
            )}
          </Box>
        </Grid.Col>
      )}
    </Grid>
  );
};
