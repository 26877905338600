import { useApiMutation, useApiQuery, useFileUpload } from 'hooks';
import { CreateCulinarySku, normalizeInitialCulinarySku } from 'shared';
import { CulinarySkuService } from 'App/api';

type Props = {
  id: string;
  onDelete?: () => void;
  onExit?: () => void;
};

const getProductSkusKey = 'getCulinarySkus';

export const useEditCulinarySkus = ({ id, onDelete, onExit }: Props) => {
  const { mutateAsync, isLoading: processingUpload } = useFileUpload();
  const {
    data: sku,
    isLoading,
    isError,
  } = useApiQuery(
    [getProductSkusKey, id],
    () => CulinarySkuService.getOne({ id }),
    {
      cacheTime: 0,
    },
  );

  const { mutate: deleteSku, isLoading: isDeleting } = useApiMutation(
    [getProductSkusKey, id],
    () => CulinarySkuService.delete({ id }),
    {
      onSuccess: onDelete,
    },
  );

  const { mutate: updateSku, isLoading: isUpdating } = useApiMutation(
    [getProductSkusKey, id],
    CulinarySkuService.update,
    {
      onSuccess: onExit,
    },
  );

  const handleSubmit = async (values: CreateCulinarySku, file: File | null) => {
    const payload = {
      id,
      data: { ...values, price: values.price ?? null },
    };

    if (!file) return updateSku(payload);

    await mutateAsync(file, {
      onSuccess: ({ url }) => {
        updateSku({
          ...payload,
          data: { ...payload.data, product_file: url || null },
        });
      },
    });
  };

  const initialValue = sku && normalizeInitialCulinarySku(sku);

  return {
    sku,
    initialValue,
    isLoading,
    isError,
    deleteSku,
    isDeleting,
    handleSubmit,
    isUpdating,
    processingUpload,
  };
};
