import { ReactNode } from 'react';
import { Text, Grid, Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { CopyPaste } from '..';
import { BottleQuantity, WineSelectionDto, WineSelection } from 'shared';
import { Product } from './Product';

type Props = {
  type: string;
  copy: WineSelection[] | null;
  isEdit?: boolean;
  bottleSize: BottleQuantity;
  form: UseFormReturnType<WineSelectionDto>;
  children: ReactNode;
  setCopy: (payload: WineSelection[]) => void;
  paste: ({
    currentItems,
  }: {
    currentItems: WineSelection[];
  }) => { key: string; sku_id?: string }[];
};

export const AddProduct = ({
  form,
  type,
  copy,
  isEdit,
  children,
  bottleSize,
  paste,
  setCopy,
}: Props) => {
  const wineSelections = form.values?.wine_selections;
  const currentItems = wineSelections?.filter(
    (i) => i.wine_type === type && i.bottle_quantity === bottleSize,
  );

  const canRemove = currentItems?.length > 1;
  const skuIds = currentItems?.map(({ sku_id }) => sku_id);
  const isMaxQuantity =
    currentItems.reduce(
      (accumulator, currentValue) => accumulator + currentValue.quantity,
      -1,
    ) >= bottleSize;

  return (
    <Box mt="xl">
      <Grid gutter="xl">
        <Grid.Col span={7}>
          <Text size={14} weight={600}>
            Product Name{' '}
            {isEdit && (
              <Text component="span" color="red">
                *
              </Text>
            )}
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text size={14} weight={600}>
            Qty{' '}
            {isEdit && (
              <Text component="span" color="red">
                *
              </Text>
            )}
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size={14} weight={600}>
            No Swap
          </Text>
        </Grid.Col>
      </Grid>

      <Box>
        {currentItems.map(
          ({
            key,
            quantity,
            is_swappable,
            sku_id,
            sku_number,
            sku_product_name,
          }) => (
            <Product
              key={key}
              id={key}
              form={form}
              isEdit={isEdit}
              quantity={quantity}
              bottleSize={bottleSize}
              isSwappable={is_swappable}
              canRemove={canRemove}
              skuIds={skuIds}
              skuId={sku_id}
              skuNumber={sku_number}
              skuProductName={sku_product_name}
            />
          ),
        )}
      </Box>
      {children}
      {isMaxQuantity && (
        <Text color="red">
          The quantity should not exceed {bottleSize} bottles
        </Text>
      )}
      <CopyPaste<WineSelection>
        copy={copy}
        setCopy={setCopy}
        currentItems={currentItems}
        onPaste={() => {
          const copyState = paste({ currentItems });
          const products = copyState.map((i) => ({
            ...i,
            wine_type: type,
            bottle_quantity: bottleSize,
            quantity: 0,
            is_swappable: true,
          }));

          form.setFieldValue('wine_selections', [
            ...wineSelections,
            ...products,
          ]);
        }}
      />
    </Box>
  );
};
