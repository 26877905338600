import { Grid, Select } from '@mantine/core';

import { SearchInput, LocationSelect } from 'components';
import { usePartnerLocations } from 'pages/inventory/hooks';
import { InventoryListRequest, InventoryPool, getEmptyOption } from 'shared';

interface Props {
  handleChange: (v: { [key: string]: string | string[] }) => void;
  values: InventoryListRequest;
}

const POOL_OPTIONS = [
  getEmptyOption('All'),
  { label: 'Retail', value: InventoryPool.RETAIL },
  { label: 'Storage', value: InventoryPool.STORAGE },
  { label: 'Fulfillment', value: InventoryPool.FULFILLMENT },
  { label: 'Wholesaler', value: InventoryPool.WHOLESALER },
  { label: 'Library Reserve', value: InventoryPool.LIBRARY_RESERVE },
];

export const Filters = ({ handleChange, values }: Props): JSX.Element => {
  const { options } = usePartnerLocations();
  const { search_string, location, pool } = values;

  return (
    <Grid justify={'space-between'}>
      <Grid.Col span={7}>
        <Grid>
          <Grid.Col span={7}>
            <LocationSelect
              value={location}
              data={options}
              handleChange={(v) => {
                handleChange({ location: v });
              }}
            />
          </Grid.Col>
          <Grid.Col span={5}>
            <Select
              label="Pool"
              value={pool}
              searchable
              nothingFound="No options"
              data={POOL_OPTIONS}
              onChange={(v) => handleChange({ pool: v as InventoryPool })}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={5} lg={4}>
        <SearchInput
          handleSubmit={(v: string) => handleChange({ search_string: v })}
          label="Name or SKU#"
          value={search_string}
        />
      </Grid.Col>
    </Grid>
  );
};
