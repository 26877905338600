import { useMemo, useState } from 'react';
import { useApiMutation, useApiQuery } from 'hooks';
import { ShipmentCycleDto, ShipmentCycleStatus } from 'shared';
import { ShipmentCycleService } from 'App/api';
import { normalizePayload, normalizeShipmentCycle } from '../helpers';

const SHIPMENT_CYCLE_KEY = 'shipment_cycle';

type Props = {
  id: string;
  onExit: () => void;
};

export const useEditShipmentCycle = ({ id, onExit }: Props) => {
  const [isInventoryCheck, setIsInventoryCheck] = useState(false);
  const [isStartAfterSave, setIsStartAfterSave] = useState(false);
  const { data, isLoading, isError, refetch } = useApiQuery(
    ['SHIPMENT_CYCLE_KEY', id],
    () => ShipmentCycleService.getOne(id),
  );
  const { data: inventoryDetails, isLoading: isLoadingInventoryCheck } =
    useApiQuery(['inventoryDetails', id], () =>
      ShipmentCycleService.getInventoryDetails(id),
    );

  const onSuccess = ({ status }: ShipmentCycleDto) => {
    if (status === ShipmentCycleStatus.READY) {
      refetch();
      onExit();
    } else {
      refetch();
    }
  };

  const { mutate, isLoading: isUpdatingCycle } = useApiMutation(
    [SHIPMENT_CYCLE_KEY, id],
    ShipmentCycleService.update,
    {
      onSuccess: onSuccess,
    },
  );

  const canBeDeleted = [
    ShipmentCycleStatus.DRAFT,
    ShipmentCycleStatus.READY,
  ].includes(data?.status as ShipmentCycleStatus);

  const { mutate: kill, isLoading: deleting } = useApiMutation(
    [SHIPMENT_CYCLE_KEY, 'delete', id],
    ShipmentCycleService.delete,
    {
      onSuccess: onExit,
    },
  );

  const deleteCycle = () => {
    kill(id);
  };

  const handleSubmit = (values: ShipmentCycleDto) => {
    const body = normalizePayload({
      isStartAfterSave,
      cycleSetting: values,
      suggestion: { suggestions: values.suggestions ?? [] },
      wineSelection: { wine_selections: values?.wine_selections ?? [] },
    });
    mutate({ id, data: body });
  };

  const initialValue = useMemo(
    () => data && normalizeShipmentCycle(data),
    [data],
  );

  const isPublished = data?.status === ShipmentCycleStatus.PUBLISHED;

  return {
    initialValue,
    data,
    isLoading,
    isError,
    handleSubmit,
    isUpdatingCycle,
    isInventoryCheck,
    setIsInventoryCheck,
    isStartAfterSave,
    setIsStartAfterSave,
    inventoryDetails,
    isLoadingInventoryCheck,
    deleteCycle,
    deleting,
    canBeDeleted,
    isPublished,
  };
};
