import * as Yup from 'yup';

import { requiredStringValidationRule } from 'shared/validations';

const validatePickup = Yup.object()
  .nullable()
  .when(['isPickup', 'hasShipment'], {
    is: (isPickup: boolean, hasShipment: boolean) => isPickup && hasShipment,
    then: () =>
      Yup.object().shape({
        location_id: requiredStringValidationRule('Location'),
      }),
  });

const validateShipment = Yup.object()
  .nullable()
  .when(['isPickup', 'hasShipment'], {
    is: (isPickup: boolean, hasShipment: boolean) => !isPickup && hasShipment,
    then: () =>
      Yup.object().shape({
        address_id: requiredStringValidationRule('Address'),
      }),
  });

export const getClubMemberSchema = (isEdit = false) =>
  Yup.object({
    tier: Yup.object().shape({
      id: requiredStringValidationRule('Club Membership Tier'),
    }),
    pickup: validatePickup,
    ...(isEdit ? { shipment: validateShipment } : {}),
  });
