import { useMemo, useCallback, useState } from 'react';
import { ShipmentCycleService } from 'App/api';
import { useApiMutation, useApiQuery, useQueryParams, useToast } from 'hooks';
import { ShipmentCycleCollectionItemOutput } from 'shared';
import { normalizeShipmentCycleSummary } from '../helpers';
import { Mode } from '../constants';

type RequestFilter = { search_string: string };

export const useShipmentCycles = () => {
  const toast = useToast();
  const [mode, setMode] = useState<Mode>(Mode.LIST);
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params = useMemo(() => {
    return {
      page,
      page_size: 3,
      search_string,
    };
  }, [page, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading, refetch } = useApiQuery(
    ['getShipmentCycles', key],
    () => ShipmentCycleService.getCollection(params),
  );

  const { data: allInventoryDetails, isLoading: isLoadingAllInventoryDetails } =
    useApiQuery(['allInventoryDetails'], () =>
      ShipmentCycleService.getAllInventoryDetails(),
    );

  const { mutate: copy, isLoading: isCopied } = useApiMutation(
    ['copyShipmentCycles'],
    ShipmentCycleService.copy,
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
  const {
    mutate: refreshInventoryDetails,
    isLoading: isRefreshInventoryDetails,
  } = useApiMutation(
    ['refreshInventoryDetails'],
    ShipmentCycleService.refreshInventoryDetails,
    {
      onSuccess: (data) => {
        toast.success(data.message);
      },
    },
  );

  const output: ShipmentCycleCollectionItemOutput[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map((cycle) => {
      const { id, summary, details } = cycle;
      return {
        id,
        copyId: `${id}${summary.name}`,
        ...normalizeShipmentCycleSummary(summary),
        details: details.map(normalizeShipmentCycleSummary),
      };
    });
  }, [data]);

  const updateFilters = useCallback(
    (data: RequestFilter) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  const handleRefreshInventoryDetails = () => refreshInventoryDetails({});

  return {
    output,
    page,
    mode,
    search_string,
    isRefreshInventoryDetails,
    isLoading: isLoading || isCopied || isLoadingAllInventoryDetails,
    totalRecords: data?.total ?? 0,
    allInventoryDetails,
    copy,
    setMode,
    updateFilters,
    handleSetSearchParams,
    handleRefreshInventoryDetails,
  };
};
