import {
  CreatePartner,
  PaginatedRequest,
  PaginatedResponse,
  PartnerDto,
} from 'shared';
import { PARTNER_PATH } from '../../endpoints';
import request from '../../request';

export default class PartnerService {
  static readonly create = (data: CreatePartner) => {
    return request<PartnerDto>({
      url: PARTNER_PATH,
      method: 'post',
      data,
    });
  };

  static readonly getCollection = (params: PaginatedRequest) => {
    return request<PaginatedResponse<PartnerDto>>({
      url: PARTNER_PATH,
      params,
      method: 'get',
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<PartnerDto>({
      url: `${PARTNER_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly delete = ({ id }: { id?: string }) => {
    return request({
      url: `${PARTNER_PATH}/${id}`,
      method: 'delete',
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id?: string;
    data: CreatePartner;
  }) => {
    return request<PartnerDto>({
      url: `${PARTNER_PATH}/${id}`,
      method: 'put',
      data,
    });
  };
}
