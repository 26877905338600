import { useMemo, useState } from 'react';
import { InventoryService, SkuService } from 'App/api';
import { useApiQuery } from 'hooks';
import { isEmpty } from 'rambda';
import {
  InventoryUnitRequest,
  InventoryUnitOutput,
  InventoryType,
} from 'shared';
import { getInventoryUnitOutput } from '../helpers';

interface ReturnValue {
  output: InventoryUnitOutput[];
  isLoading: boolean;
  isError: boolean;
  location: string[];
  setLocation: (v: string[]) => void;
  productName: string;
  isAlcohol: boolean;
}

export const useInventoryItem = (id: string): ReturnValue => {
  const [location, setLocation] = useState<string[]>([]);

  const params: InventoryUnitRequest = useMemo(() => {
    return {
      sku_id: id,
      ...(isEmpty(location) ? {} : { location }),
    };
  }, [id, location]);

  const key = JSON.stringify(params);

  const { data, isLoading, isError } = useApiQuery(
    ['getProductSkus', key],
    () => InventoryService.getOne(params),
  );

  const { data: product } = useApiQuery(['getSkus', id], () =>
    SkuService.getOne({ id }),
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return getInventoryUnitOutput(data);
  }, [data]);

  return {
    output,
    isLoading,
    location,
    setLocation,
    isError,
    productName: product
      ? `${product.product_name} (${product.sku_number})`
      : '',
    isAlcohol: product?.inventory_type === InventoryType.ALCOHOL,
  };
};
