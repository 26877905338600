import { useSession } from 'App/session';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { ResponseStatus, getMessage } from 'shared';
import { useToast } from './useToast';

export const useErrors = (): {
  handleErrors: (error: AxiosError) => void;
} => {
  const { signOut } = useSession();
  const toast = useToast();

  const handleErrors = useCallback(
    (error: AxiosError) => {
      toast.error(getMessage(error));
      if (error.response?.status === ResponseStatus.UNAUTHORIZED) {
        signOut();
      }
    },
    [signOut, toast],
  );

  return {
    handleErrors,
  };
};
