import { randomId } from '@mantine/hooks';
import { dissoc } from 'rambda';

import { InventoryFormValues, InventoryPayload } from 'shared';

export const getNewItem = () => ({
  sku_id: '',
  quantity: 1,
  key: randomId(),
});

export const getVendorData = ({
  comment,
  ...rest
}: Partial<InventoryFormValues>): Partial<InventoryFormValues> => ({
  ...rest,
  ...(comment ? { comment } : {}),
});

export const getInventoryPayload = ({
  inventory_location_id,
  items,
  is_vendor,
  ...rest
}: InventoryFormValues): InventoryPayload => ({
  ...(is_vendor ? getVendorData(rest) : {}),
  inventory_location_id,
  items: items.map(dissoc('key')),
});
