import dayjs from 'dayjs';
import { CustomerService } from 'App/api';
import { useApiQuery } from 'hooks';

enum Month {
  'Jan' = 1,
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
}

export const useTastingLimit = ({ customerId }: { customerId: string }) => {
  const month = dayjs().month() + 1;
  const year = dayjs().year();

  const { data: tastingLimit, isLoading: isLoadingTastingLimit } = useApiQuery(
    ['tasting-limit', customerId],
    () =>
      CustomerService.getTastingLimits({
        id: customerId,
        year,
        month,
      }),
    {
      enabled: !!customerId,
    },
  );

  return {
    tastingLimit,
    isLoadingTastingLimit,
    currentDate: `${Month[month]} ${year}`,
  };
};
