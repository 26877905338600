import { Link, useNavigate, useParams } from 'react-router-dom';
import { ContentLayout, BackButton } from 'components';
import { useEditPosOrder } from '../../hooks';
import { Form } from '../form';

type PropType = {
  onExit: () => void;
};

export const Edit = ({ onExit }: PropType): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const {
    order,
    payment,
    isLoading,
    processing,
    isRefunding,
    isGeneratingFullReceipt,
    generationPDFPaymentIds,
    handleRefund,
    handleSubmit,
    handleSendReceipt,
    handleGenerateFullReceipt,
    handleGeneratePartialReceipt,
  } = useEditPosOrder({
    id,
    onExit,
  });

  return (
    <ContentLayout
      title={`Order ${order?.number ?? ''}`}
      processing={processing}
      isLoading={isLoading}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
    >
      {order && payment && (
        <Form
          loading={processing}
          order={order}
          payment={payment}
          generationPDFPaymentIds={generationPDFPaymentIds}
          isRefunding={isRefunding}
          isGeneratingFullReceipt={isGeneratingFullReceipt}
          handleRefund={handleRefund}
          handleSubmit={handleSubmit}
          handleSendReceipt={handleSendReceipt}
          handleGenerateFullReceipt={handleGenerateFullReceipt}
          handleGeneratePartialReceipt={handleGeneratePartialReceipt}
        />
      )}
    </ContentLayout>
  );
};
