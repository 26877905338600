import { useMemo, useState } from 'react';
import { CustomerService, StripeService } from 'App/api';
import { useApiMutation, useApiQuery } from 'hooks';
import {
  normalizeCardData,
  normalizeWallets,
} from 'pages/crm/customers/helpers';
import { EditBillingAddress, TWalletForm } from 'shared';

export const useEditWallet = ({
  id,
  refetchCustomer,
}: {
  id: string;
  refetchCustomer: () => void;
}) => {
  const [isForm, setIsForm] = useState(false);
  const [editCardData, setEditCardData] = useState<EditBillingAddress | null>(
    null,
  );

  const {
    data,
    isFetching,
    isLoading: isLoadingCards,
    refetch,
  } = useApiQuery(['cards', id], () => CustomerService.getCards({ id }));

  const { mutateAsync: createToken } = useApiMutation(
    ['createToken'],
    StripeService.createToken,
  );

  const { mutate: deleteCard, isLoading: isDeleting } = useApiMutation(
    ['deleteCard', id],
    CustomerService.deleteCard,
    {
      onSuccess: () => {
        refetch();
        refetchCustomer();
      },
    },
  );

  const { mutate: setDefaultCard, isLoading: isSetting } = useApiMutation(
    ['setDefaultCard', id],
    CustomerService.setDefaultCard,
    {
      onSuccess: () => refetch(),
    },
  );

  const { mutate: updateCardName, isLoading: isUpdatingCardName } =
    useApiMutation(['updateCardName', id], CustomerService.updateCardName);

  const { mutate: updateBillingAddress, isLoading: isUpdating } =
    useApiMutation(['updateCard', id], CustomerService.updateBillingAddress, {
      onSuccess: () => {
        refetch();
        refetchCustomer();
        setEditCardData(null);
      },
    });

  const { mutate: createCard, isLoading: isCreating } = useApiMutation(
    ['card'],
    CustomerService.createCard,
    {
      onSuccess: (data, variables) => {
        if (variables.data.set_as_default) {
          setDefaultCard({ id, idCard: data.id });
        }

        setIsForm(false);
        refetch();
        refetchCustomer();
      },
    },
  );

  const handleCreateCard = async ({
    wallet,
    update_orders,
  }: {
    wallet: TWalletForm;
    update_orders?: boolean;
  }) => {
    const cardData = normalizeCardData(wallet);
    const { data } = await createToken(cardData?.card_element);

    const cardPayload = {
      token_id: data.id,
      name: cardData?.name,
      set_as_default: cardData?.set_as_default,
      billing_address: cardData?.billing_address,
    };

    createCard({ id, data: cardPayload, update_orders });
  };

  const handleUpdateBillingAddress = (data: EditBillingAddress) => {
    const {
      street_address_one,
      street_address_two,
      idCard,
      customerId,
      cardName,
    } = data ?? {};

    if (editCardData?.cardName !== cardName) {
      updateCardName({ id, idCard, card_name: cardName });
    }

    updateBillingAddress({
      idCard,
      id: customerId,
      data: {
        ...data,
        address_line_1: street_address_one,
        address_line_2: street_address_two,
      },
    });
  };

  const cards = useMemo(() => data && normalizeWallets(data.items), [data]);

  return {
    cards,
    isForm,
    isCreating,
    isUpdating,
    isLoadingCards:
      isLoadingCards ||
      isFetching ||
      isSetting ||
      isDeleting ||
      isUpdatingCardName,
    editCardData,
    setIsForm,
    handleUpdateBillingAddress,
    handleCreateCard,
    deleteCard,
    setDefaultCard,
    setEditCardData,
  };
};
