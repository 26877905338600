import { useEffect, useState } from 'react';
import { usePDF } from '@react-pdf/renderer';

export const useGeneratePdf = ({
  pdfDocument,
}: {
  pdfDocument: JSX.Element;
}) => {
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const [instance] = usePDF({ document: pdfDocument });

  useEffect(() => {
    if (instance?.url) {
      fetch(instance.url)
        .then((res) => res.blob())
        .then((blob) => setPdfBlob(blob));
    }
  }, [instance]);

  const handleOpenPDF = () => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      window.open(url, '_blank');
    }
  };

  return { handleOpenPDF };
};
