import {
  Button,
  Container,
  Flex,
  Grid,
  Group,
  Loader,
  Select,
  Switch,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconSend } from '@tabler/icons-react';
import {
  capitalizeText,
  EMAIL_INPUT,
  HALTER_RANCH_LOCATION_ID,
  LOADER,
  LOCATION_DROPDOWN,
  ProfilePayload,
  SECONDARY_LOCATION_DROPDOWN,
} from 'shared';
import { useInventoryLocations } from 'pages/inventory/hooks';
import { MaskedInput } from 'components';
import { useEmployees } from 'hooks';

export type Props = {
  form: UseFormReturnType<ProfilePayload>;
  isEdit?: boolean;
  canEdit?: boolean;
  processing?: boolean;
  isSendingPassword?: boolean;
  handleResetPassword?: () => void;
  handleSubmit?: (values: ProfilePayload) => void;
};

export const ProfileForm = ({
  form,
  isEdit,
  canEdit,
  processing,
  isSendingPassword = false,
  handleSubmit,
  handleResetPassword = () => null,
}: Props): JSX.Element => {
  const prefferedLocationId = form.values.preferred_primary_location_id ?? '';
  const fullWidth = { maxWidth: '100%' };
  const isActive = true;

  const {
    setPrimaryLocation,
    primaryLocation,
    primaryLocationOptions,
    isLoading,
    inventoryLocationOptions,
  } = useInventoryLocations(prefferedLocationId, true);

  const { employeesOptions } = useEmployees({
    isActive,
    locationId: HALTER_RANCH_LOCATION_ID,
  });

  return (
    <Container size="xl">
      <form>
        <Grid gutter="xl">
          <Grid.Col span={6}>
            <TextInput
              required
              sx={fullWidth}
              data-testid="firstname-input"
              label="First Name"
              {...form.getInputProps('first_name')}
              onChange={({ currentTarget }) =>
                form.setFieldValue(
                  'first_name',
                  capitalizeText(currentTarget.value),
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              required
              sx={fullWidth}
              data-testid="lastname-input"
              label="Last Name"
              {...form.getInputProps('last_name')}
              onChange={({ currentTarget }) =>
                form.setFieldValue(
                  'last_name',
                  capitalizeText(currentTarget.value),
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              data-testid="customer-source-dropdown"
              label="Customer Source"
              rightSectionWidth={30}
              searchable
              data={employeesOptions}
              {...form.getInputProps('customer_source_id')}
              onChange={(value) => {
                form.setFieldValue('customer_source_id', value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MaskedInput
              testId="dateOfBirth"
              sx={fullWidth}
              mask="99.99.9999"
              label="Date of Birth"
              placeholder="08.31.1989"
              maskChar={null}
              {...form.getInputProps('date_of_birth')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              sx={fullWidth}
              required
              placeholder="your@email.com"
              data-testid={EMAIL_INPUT}
              label="Email Address"
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MaskedInput
              sx={fullWidth}
              required
              testId="phoneNumber"
              label="Phone Number"
              mask="(999) 999-9999"
              {...form.getInputProps('phone_number')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              data-testid={LOCATION_DROPDOWN}
              label="Preferred Primary Location"
              value={primaryLocation}
              rightSectionWidth={30}
              data={primaryLocationOptions}
              onChange={(value) => {
                setPrimaryLocation(value ?? '');
                form.setFieldValue('preferred_primary_location_id', value);
                form.setFieldValue('secondary_location_id', null);
              }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              data-testid={SECONDARY_LOCATION_DROPDOWN}
              label="Secondary Location"
              rightSectionWidth={30}
              icon={
                isLoading && primaryLocation ? (
                  <Loader data-testid={LOADER} size={20} />
                ) : null
              }
              onChange={(value) => {
                form.setFieldValue('secondary_location_id', value ?? null);
              }}
              data={inventoryLocationOptions}
              disabled={!primaryLocation}
              {...form.getInputProps('secondary_location_id')}
            />
          </Grid.Col>
          {isEdit && (
            <Grid.Col span={12}>
              <Text size="sm" weight={600} mb="xs">
                LTV
              </Text>
              <Text size="sm">{form.values.lifetime_value}</Text>
            </Grid.Col>
          )}

          <Grid.Col span={6}>
            <Flex justify="space-between">
              <Switch
                data-testid="emailOptIn-switch"
                label="Email Opt In"
                {...form.getInputProps('email_opt_in', {
                  type: 'checkbox',
                })}
              />
              <Switch
                data-testid="smsOptIn-switch"
                label="SMS Opt In"
                {...form.getInputProps('sms_opt_in', {
                  type: 'checkbox',
                })}
              />
            </Flex>
          </Grid.Col>

          <Grid.Col span={6} mt="xs">
            {isEdit && (
              <Flex gap="lg" align="center">
                <Text size="sm">To send a reset password link click here</Text>
                <Button
                  variant="subtle"
                  data-testid="send-reset-password-link-btn"
                  loading={isSendingPassword}
                  leftIcon={<IconSend />}
                  onClick={handleResetPassword}
                >
                  Reset Password
                </Button>
              </Flex>
            )}
          </Grid.Col>

          <Grid.Col span={6}>
            <Textarea label="POS Notes" {...form.getInputProps('pos_notes')} />
          </Grid.Col>
          <Grid.Col span={6}>
            <Textarea
              label="System Notes"
              {...form.getInputProps('system_notes')}
            />
          </Grid.Col>
        </Grid>
      </form>
      {isEdit && handleSubmit && (
        <Group position="center" my="xl">
          <Button
            sx={{ width: 540 }}
            disabled={!form.isValid() || !canEdit}
            type="submit"
            onClick={() => handleSubmit(form.values)}
            fullWidth
            loading={processing}
            aria-label="save"
          >
            Save
          </Button>
        </Group>
      )}
    </Container>
  );
};
