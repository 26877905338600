import { useState } from 'react';
import { reject } from 'rambda';
import { randomId } from '@mantine/hooks';

export const useCopyPaste = <
  T extends { sku_id?: string; sku_number?: string; sku_product_name?: string },
>() => {
  const [copy, setCopy] = useState<T[] | null>(null);

  const paste = ({ currentItems }: { currentItems: T[] }) => {
    if (!copy) return [];

    const copyProducts = copy.map(
      ({ sku_id, sku_number, sku_product_name }) => ({
        key: randomId(),
        sku_id,
        sku_number,
        sku_product_name,
      }),
    );

    const currentItemIds = currentItems?.map(({ sku_id }) => sku_id);

    const rejectTheSame = reject(
      ({ sku_id }) => currentItemIds.includes(sku_id),
      copyProducts,
    );
    setCopy(null);

    return rejectTheSame;
  };

  return { copy, setCopy, paste };
};
