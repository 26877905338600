import {
  IconSquare,
  IconSquareMinus,
  IconSquareCheck,
  IconFolderPlus,
  IconFolderMinus,
  IconChevronUp,
  IconChevronDown,
} from '@tabler/icons-react';

export const icons = {
  check: <IconSquareCheck size={24} stroke={1} />,
  uncheck: <IconSquare size={24} stroke={1} />,
  halfCheck: <IconSquareMinus size={24} stroke={1} />,
  expandClose: <IconChevronDown size={24} stroke={1} />,
  expandOpen: <IconChevronUp size={24} stroke={1} />,
  expandAll: <IconFolderPlus stroke={1} size={24} />,
  collapseAll: <IconFolderMinus stroke={1} size={24} />,
  parentClose: null,
  parentOpen: null,
  leaf: null,
};
