import { TWalletForm, CardDto, AddressDto, BillingAddress } from 'shared';
import { displayAddressDescription } from './common';

export const walletInitialValues: TWalletForm = {
  card_name: '',
  card_number: '',
  expiration_date: '',
  cvv: '',
  name_on_card: '',
  first_name: '',
  last_name: '',
  country: 'USA',
  state: '',
  city: '',
  business: '',
  street_address_one: '',
  street_address_two: '',
  zip_code: '',
  set_as_default: false,
};

const normalizeEditCardData = ({
  billing_address,
}: {
  billing_address: BillingAddress;
}) => {
  const { address_line_1, address_line_2 } = billing_address ?? {};

  return {
    ...billing_address,
    street_address_two: address_line_2,
    street_address_one: address_line_1,
  };
};

export const normalizeWallets = (data: CardDto[]) => {
  return data?.map(
    ({
      id,
      card_name,
      is_default,
      billing_address,
      card_brand,
      card_last_4,
      stripe_card_id,
    }) => {
      const {
        city,
        state,
        zip_code,
        country,
        address_line_1,
        address_line_2,
        first_name,
        last_name,
      } = billing_address ?? {};
      const numberCard = `${card_brand} **** **** **** ${card_last_4}`;
      const streetAddress = displayAddressDescription({
        first_name,
        last_name,
        street_address_one: address_line_1,
        street_address_two: address_line_2,
        city,
        state,
        zip_code,
        country,
      } as AddressDto);

      return {
        id,
        stripe_card_id,
        brand: card_brand,
        last4: card_last_4,
        cardName: card_name || 'Unknown',
        isDefault: is_default,
        numberCard,
        streetAddress,
        billing_address: normalizeEditCardData({ billing_address }),
      };
    },
  );
};

export const normalizeCardData = (wallet: TWalletForm) => {
  const {
    card_number,
    card_name,
    cvv,
    expiration_date,
    name_on_card,
    set_as_default,
    first_name,
    last_name,
    business,
    street_address_one,
    street_address_two,
    city,
    state,
    zip_code,
    country,
  } = wallet;
  const isValidCardData = card_number && cvv && expiration_date;

  if (!isValidCardData) return null;

  const monthAndYear = expiration_date.split('/');

  return {
    set_as_default,
    name: card_name,
    card_element: {
      'card[number]': card_number,
      'card[exp_month]': monthAndYear[0].trim(),
      'card[exp_year]': monthAndYear[1].trim(),
      'card[cvc]': cvv,
      'card[name]': name_on_card,
      'card[address_city]': city,
      'card[address_country]': country,
      'card[address_line1]': street_address_one,
      'card[address_line2]': street_address_two,
      'card[address_state]': state,
      'card[address_zip]': zip_code,
    },

    billing_address: {
      first_name,
      last_name,
      business,
      address_line_1: street_address_one,
      address_line_2: street_address_two,
      city,
      state,
      zip_code,
      country,
    },
  };
};
