import { Grid, Select, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  CycleOrderPayload,
  WalletSelectOption,
  getCurrencyString,
} from 'shared';

export const CorrectionPayment = ({
  paymentCard,
  form,
  isCash,
  totalDueCorrection = 0,
}: {
  paymentCard: WalletSelectOption[];
  form: UseFormReturnType<
    CycleOrderPayload,
    (values: CycleOrderPayload) => CycleOrderPayload
  >;
  isCash: boolean;
  totalDueCorrection?: number;
}) => {
  const isRefund = totalDueCorrection < 0;
  return (
    <Grid mb={48} gutter="xl" align="center">
      <Grid.Col span={3}>
        {isCash && isRefund ? (
          <Text mt={24}>Refund in cash only</Text>
        ) : (
          <Select
            required
            label="Payment Method"
            disabled={isRefund}
            data={paymentCard}
            {...form.getInputProps('customer_card_id')}
          />
        )}
      </Grid.Col>
      {!!totalDueCorrection && (
        <Grid.Col span={2} mt={24}>
          <Text>{getCurrencyString(totalDueCorrection)}</Text>
        </Grid.Col>
      )}
    </Grid>
  );
};
