import { Grid, Select } from '@mantine/core';

import { SearchInput } from 'components';
import {
  SkusRequest,
  SkuStatus,
  getEmptyOption,
  inventoryTypeOptions,
  STATE_OPTIONS,
} from 'shared';
import { useCategories } from 'pages/products';

interface Props {
  handleChange: (v: { [key: string]: string | string[] }) => void;
  values: SkusRequest;
}

export const Filters = ({ handleChange, values }: Props): JSX.Element => {
  const { search_string, inventory_type, category, status } = values;

  const { categoryNestedOptions } = useCategories();

  return (
    <Grid justify={'space-between'}>
      <Grid.Col span={7}>
        <Grid>
          <Grid.Col span={4}>
            <Select
              label="Inventory Type"
              data-testid="inventory-type-dropdown"
              value={inventory_type}
              searchable
              nothingFound="No options"
              data={[
                getEmptyOption('All Inventory Types'),
                ...inventoryTypeOptions,
              ]}
              onChange={(v) => handleChange({ inventory_type: v ?? '' })}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Category"
              data-testid="category-dropdown"
              value={category}
              searchable
              nothingFound="No options"
              data={[
                getEmptyOption('All Categories'),
                ...categoryNestedOptions,
              ]}
              onChange={(v) => handleChange({ category: v ?? '' })}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="State"
              data-testid="state-dropdown"
              value={status}
              searchable
              nothingFound="No options"
              data={STATE_OPTIONS}
              onChange={(v: SkuStatus) => handleChange({ status: v || '' })}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col md={5} lg={4}>
        <SearchInput
          handleSubmit={(v: string) => handleChange({ search_string: v })}
          data-testid="name-or-sku-search-input"
          label="Name or Product#"
          value={search_string}
        />
      </Grid.Col>
    </Grid>
  );
};
