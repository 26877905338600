import * as Yup from 'yup';

import {
  requiredStringValidationRule,
  zipCodeValidator,
  phoneNumberValidator,
} from './common';

export const locationSchema = Yup.object({
  name: requiredStringValidationRule('Name'),
  city: requiredStringValidationRule('City'),
  address: requiredStringValidationRule('Address'),
  state: requiredStringValidationRule('State'),
  zip_code: zipCodeValidator,
  phone_number: phoneNumberValidator,
});
