import {
  Box,
  Collapse,
  Flex,
  Select,
  Switch,
  TextInput,
  ActionIcon,
  Grid,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { getInputKey } from 'pages/products/vendors/helpers';
import { useLocations } from 'App/contexts';
import { MaskedInput } from 'components';
import { CreateVendor, stateOptions } from 'shared';

type PropType = {
  form: UseFormReturnType<CreateVendor, (values: CreateVendor) => CreateVendor>;
  index?: number;
};

export const AdditionalLocation = ({ form, index }: PropType) => {
  const { options } = useLocations();
  const isSameContactDetails =
    typeof index === 'number' &&
    form.values.vendor_locations[index].same_contact_details;
  const fullWidth = { width: '100%' };
  const isRequired =
    typeof index === 'number'
      ? !form.values.vendor_locations[index].same_contact_details
      : true;

  return (
    <>
      {typeof index === 'number' && (
        <>
          <Flex justify="space-between" align="top" gap={'md'} mt="xl">
            <Select
              mb="lg"
              label="Primary Location"
              sx={fullWidth}
              rightSectionWidth={30}
              data={options}
              {...form.getInputProps(`vendor_locations.${index}.location_id`)}
            />
            <ActionIcon
              onClick={() => form.removeListItem('vendor_locations', index)}
              mt="28px"
            >
              <IconTrash size={24} stroke={1} />
            </ActionIcon>
          </Flex>

          <Box mb="xl">
            <Switch
              label="Same Contact Details"
              labelPosition="right"
              {...form.getInputProps(
                `vendor_locations.${index}.same_contact_details`,
                {
                  type: 'checkbox',
                },
              )}
            />
          </Box>
        </>
      )}
      <Collapse in={!isSameContactDetails} transitionDuration={1000}>
        <Grid gutter={'xl'}>
          <Grid.Col span={12}>
            <TextInput
              required={isRequired}
              label="Street Address"
              {...form.getInputProps(getInputKey('street_address', index))}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              required={isRequired}
              label="City"
              {...form.getInputProps(getInputKey('city', index))}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              required={isRequired}
              sx={fullWidth}
              label="State"
              data={['', ...stateOptions]}
              {...form.getInputProps(getInputKey('state', index))}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MaskedInput
              sx={fullWidth}
              testId={typeof index !== 'number' ? 'zipCode' : `zipCode${index}`}
              label="Zip Code"
              mask="99999-9999"
              required={isRequired}
              {...form.getInputProps(getInputKey('zip_code', index))}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <MaskedInput
              testId={
                typeof index !== 'number'
                  ? 'phoneNumber'
                  : `phoneNumber${index}`
              }
              label="Phone Number"
              mask="(999) 999-9999"
              required={isRequired}
              {...form.getInputProps(getInputKey('phone_number', index))}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Primary Contact Name"
              {...form.getInputProps(
                getInputKey('primary_contact_name', index),
              )}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <MaskedInput
              label="Primary Contact Phone Number"
              mask="(999) 999-9999"
              {...form.getInputProps(
                getInputKey('primary_contact_phone_number', index),
              )}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Primary Contact Email"
              {...form.getInputProps(
                getInputKey('primary_contact_email', index),
              )}
            />
          </Grid.Col>
        </Grid>
      </Collapse>
    </>
  );
};
