import {
  AdjustmentListRequest,
  AdjustmentListItemDto,
  PaginatedResponse,
  AdjustmentDetailDto,
  AdjustmentPayload,
} from 'shared';
import { INVENTORY_ADJUSTMENT_PATH } from '../../endpoints';
import request from '../../request';

export default class InventoryAdjustmentService {
  static readonly create = (data: AdjustmentPayload) => {
    return request<{ id: string }>({
      url: `${INVENTORY_ADJUSTMENT_PATH}`,
      method: 'post',
      data,
    });
  };

  static readonly getCollection = (params: AdjustmentListRequest) => {
    return request<PaginatedResponse<AdjustmentListItemDto>>({
      url: `${INVENTORY_ADJUSTMENT_PATH}`,
      params,
      method: 'get',
    });
  };

  static readonly getOne = (id: string) => {
    return request<AdjustmentDetailDto>({
      url: `${INVENTORY_ADJUSTMENT_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly cancel = (id: string) => {
    return request<{ id: string }>({
      url: `${INVENTORY_ADJUSTMENT_PATH}/${id}/cancel`,
      method: 'post',
    });
  };
}
