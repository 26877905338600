import { useState } from 'react';
import { Box, Button, Container, Loader, Select, Grid } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useLocations } from 'App/contexts';
import {
  CreateLibrary,
  INVENTORY_LOCATION_DROPDOWN,
  LOADER,
  LOCATION_DROPDOWN,
} from 'shared';
import { ActionButtons } from 'components';
import { useInventorySkus } from 'pages/inventory/hooks';
import { getNewSkuItem } from 'pages/inventory/helpers';
import { AddProduct } from './AddProduct';
import { libraryReserveSchema } from './schema';

export type Props = {
  handleSubmit: (values: CreateLibrary) => void;
  initialValues: CreateLibrary;
  action?: JSX.Element | null;
};

export const Form = ({
  handleSubmit,
  initialValues,
  action,
}: Props): JSX.Element => {
  const [locationId, setLocationId] = useState('');
  const { actualLocationOptions } = useLocations();
  const form = useForm({
    initialValues,
    validate: yupResolver(libraryReserveSchema),
    validateInputOnBlur: true,
  });

  const inventoryLocationId = form.values.inventory_location_id;

  const { skusData, isLoading, fetchingSkus, inventoryLocationOptions } =
    useInventorySkus({
      locationId,
      inventoryLocationId,
      groupByPool: true,
      showInPos: false,
    });

  return (
    <Container size="sm">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid gutter={'xl'}>
          <Grid.Col span={6}>
            <Select
              required
              label="Primary Location"
              data-testid={LOCATION_DROPDOWN}
              rightSectionWidth={30}
              onChange={(value) => {
                setLocationId(value ?? '');
                form.setFieldValue('items', [getNewSkuItem()]);
                form.setFieldValue('inventory_location_id', '');
              }}
              data={actualLocationOptions}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              required
              label="Inventory Location"
              data-testid={INVENTORY_LOCATION_DROPDOWN}
              rightSectionWidth={30}
              icon={
                isLoading && locationId ? (
                  <Loader data-testid={LOADER} size={20} />
                ) : null
              }
              {...form.getInputProps('inventory_location_id')}
              onChange={(value) => {
                form.setFieldValue('items', [getNewSkuItem()]);
                form.setFieldValue('inventory_location_id', value ?? '');
              }}
              data={inventoryLocationOptions}
              disabled={!locationId}
            />
          </Grid.Col>
        </Grid>

        <AddProduct
          form={form}
          skusData={skusData}
          isLoadingSkus={fetchingSkus && !!inventoryLocationId}
        />

        <Button
          variant="white"
          p="0"
          onClick={() => form.insertListItem('items', getNewSkuItem())}
        >
          + Add New Product
        </Button>
        <Box my="xl">
          <ActionButtons
            disabled={!form.isValid()}
            label="Save"
            aria-label="save"
            secondary={action}
          />
        </Box>
      </form>
    </Container>
  );
};
