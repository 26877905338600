import { useNavigate, useParams } from 'react-router-dom';
import { ContentLayout, BackButton } from 'components';
import { useEditInventoryLibrary } from '../../hooks';
import { ReleaseForSale } from '../form';
import { ReleaseLibrary } from 'shared';

type PropType = {
  onExit: () => void;
};

const initialValues: ReleaseLibrary = {
  quantity: 0,
  comment: '',
};

export const Release = ({ onExit }: PropType): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { data, isLoading, releaseForSale, isReleasing } =
    useEditInventoryLibrary({
      id,
      onCancel: onExit,
    });

  const goBack = () => navigate(-1);
  const title = `Library Reserve - ${data?.sku.product_name} (${data?.sku.sku_number})`;

  return (
    <ContentLayout
      title={title}
      leftAction={<BackButton onClick={goBack} />}
      isLoading={isLoading}
      processing={isReleasing}
    >
      <ReleaseForSale
        handleSubmit={releaseForSale}
        initialValues={initialValues}
        data={data}
      />
    </ContentLayout>
  );
};
