import {
  PaginatedResponse,
  InventoryLocationDto,
  InventoryLocationPayload,
  InventoryPayload,
  InventoryListRequest,
  InventoryListItemDto,
  InventorySkuItemsDto,
  InventoryUnitRequest,
  InventoryUnitDto,
} from 'shared';
import {
  PRODUCT_INVENTORY_LOCATION_PATH,
  PRODUCT_INVENTORY_LIST_PATH,
} from '../../endpoints';
import request from '../../request';

export default class InventoryService {
  static readonly getLocations = ({
    location_id,
    show_in_pos,
  }: {
    location_id?: string;
    show_in_pos?: boolean;
  }) => {
    return request<PaginatedResponse<InventoryLocationDto>>({
      url: `${PRODUCT_INVENTORY_LOCATION_PATH}`,
      params: { location_id: location_id ?? undefined, show_in_pos },
      method: 'get',
    });
  };

  static readonly createLocation = (data: InventoryLocationPayload) => {
    return request<InventoryLocationDto>({
      url: `${PRODUCT_INVENTORY_LOCATION_PATH}`,
      method: 'post',
      data,
    });
  };

  static readonly getLocation = (id: string) => {
    return request<InventoryLocationDto>({
      url: `${PRODUCT_INVENTORY_LOCATION_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly deleteLocation = ({ id }: { id?: string }) => {
    return request({
      url: `${PRODUCT_INVENTORY_LOCATION_PATH}/${id}`,
      method: 'delete',
    });
  };

  static readonly updateLocation = ({
    id,
    data,
  }: {
    id?: string;
    data: InventoryLocationPayload;
  }) => {
    return request<InventoryLocationDto>({
      url: `${PRODUCT_INVENTORY_LOCATION_PATH}/${id}`,
      method: 'put',
      data,
    });
  };

  static readonly create = (data: InventoryPayload) => {
    return request({
      url: `${PRODUCT_INVENTORY_LIST_PATH}/additions`,
      method: 'post',
      data,
    });
  };

  static readonly getCollection = (params: InventoryListRequest) => {
    return request<PaginatedResponse<InventoryListItemDto>>({
      url: `${PRODUCT_INVENTORY_LIST_PATH}`,
      method: 'get',
      params,
    });
  };

  static readonly getSkus = ({
    id,
    groupByPool,
  }: {
    id: string;
    groupByPool?: boolean;
  }) => {
    const query = groupByPool ? '?group_by_pool=True' : '';
    return request<InventorySkuItemsDto>({
      url: `${PRODUCT_INVENTORY_LOCATION_PATH}/${id}/skus${query}`,
      method: 'get',
    });
  };

  static readonly getOne = ({ sku_id, ...params }: InventoryUnitRequest) => {
    return request<InventoryUnitDto>({
      url: `${PRODUCT_INVENTORY_LIST_PATH}/${sku_id}`,
      method: 'get',
      params,
    });
  };
}
