import { Container } from '@mantine/core';

import { ProductCategoryFormValues } from 'shared';
import { ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useCreateCategory } from '../../hooks';

const title = 'New Category';

const initialValues: ProductCategoryFormValues = {
  name: '',
  parent_id: '',
  is_main_category: true,
  active: true,
  hide_from_pos: false,
  members_only: false,
  pickup_only: false,
  paid_delivery_only: false,
  description: '',
  product_options: [],
  web_image_url: '',
  mobile_image_url: '',
};

export const Create = ({ onExit }: { onExit: () => void }): JSX.Element => {
  const { handleSubmit, isLoading } = useCreateCategory({
    onSuccess: onExit,
  });
  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      leftAction={<BackButton onClick={onExit} />}
    >
      <Container size={'sm'}>
        <Form handleSubmit={handleSubmit} initialValues={initialValues} />
      </Container>
    </ContentLayout>
  );
};
