import { sortBy, prop, reject, isEmpty } from 'rambda';

export const sortByMenuPosition = <T>(arr: T[]): T[] =>
  sortBy(prop('menu_position'), arr);

export const removeAccentsDiacritics = (string: string) =>
  string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const getCurrencyString = (v: string | number) =>
  v.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const getPercentString = (v: number) =>
  (v / 100).toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  });

export const getParams = <T extends { [key: string]: string | string[] }>(
  data: T,
) => reject(isEmpty, data);

export const setProductValue = <T extends { key?: string; sku_id?: string }>({
  arr,
  key,
  value,
}: {
  arr: T[];
  key?: string;
  value: { [key: string]: string | number | boolean | undefined };
}) => {
  return arr.map((i) => {
    if (i?.key === key) {
      return {
        ...i,
        ...value,
      };
    }
    return i;
  });
};

export const hasPages = ({
  total = 0,
  defaultSize,
  currentPage,
}: {
  total?: number;
  defaultSize: number;
  currentPage: number;
}): boolean => {
  return total / defaultSize > currentPage;
};
