import { useNavigate } from 'react-router-dom';
import { Box, Flex, Grid, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import {
  OrderType,
  PROPS_DATA_TABLE,
  SalesOrderPaymentStatus,
  ServiceClass,
  UpcomingShipmentList,
  getOrderRouteByType,
} from 'shared';
import { PaymentStatusBadge } from './PaymentStatusBadge';

type Props = {
  id: string;
  number: number;
  items: UpcomingShipmentList[];
  summary: [string, string][] | null;
  orderInfo: [string, string][];
  service_class: ServiceClass;
  payment_status: SalesOrderPaymentStatus;
};

export const OrderUpcomingShipment = ({
  id,
  number,
  items,
  summary,
  orderInfo,
  service_class,
  payment_status,
}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <Text
        my={24}
        size="lg"
        weight="700"
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 5,
        }}
        onClick={() =>
          navigate(
            getOrderRouteByType({
              id,
              type: OrderType.shipment,
              service_class,
            }),
          )
        }
      >
        #{number}
        <PaymentStatusBadge payment_status={payment_status} />
      </Text>

      <Grid mb="xl" gutter="xl">
        <Grid.Col>
          <DataTable
            minHeight={80}
            emptyState={PROPS_DATA_TABLE.emptyState}
            records={items}
            borderColor="transparent"
            rowBorderColor="transparent"
            columns={[
              {
                accessor: 'product_name',
                title: 'Product Name',
                width: '50%',
              },
              {
                accessor: 'quantity',
                title: 'Qty',
                textAlignment: 'right',
                width: '12%',
              },

              {
                accessor: 'retail_price',
                title: 'Retail Price',
                textAlignment: 'right',
                width: '12%',
              },

              {
                accessor: 'discounted_price',
                title: 'Discounted Price',
                textAlignment: 'right',
                width: '12%',
              },
              {
                accessor: 'total_price',
                title: 'Total',
                width: '14%',
                textAlignment: 'right',
              },
            ]}
          />
        </Grid.Col>

        <Grid.Col>
          {summary?.map((item) => (
            <Flex justify="flex-end" mb={2} key={item[0]}>
              <Box>
                <Text align="right" pr="xs" size="sm">
                  {item[0]}
                </Text>
              </Box>
              <Box pr="xs" sx={{ width: '11%' }}>
                <Text align="right" size="sm">
                  {item[1]}
                </Text>
              </Box>
            </Flex>
          ))}
        </Grid.Col>

        {orderInfo?.map((item) => (
          <Grid.Col span={6} key={item[0]}>
            <Box sx={{ width: '50%' }}>
              <Text size="sm" weight={600} mb={5}>
                {item[0]}
              </Text>
              <Text size="sm">{item[1] || 'N/A'}</Text>
            </Box>
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
};
