import { Box } from '@mantine/core';
import { ContentLayout, CreateButton } from 'components';
import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';
import { getCreatePath, getEditPath, PROPS_DATA_TABLE } from 'shared';
import { useCulinarySkus } from '../../hooks';
import { Filters } from '../filters';

export const List = ({
  canCreate,
  rootPath,
}: {
  canCreate: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const {
    output,
    page,
    totalRecords,
    filters,
    isLoading,
    updateFilters,
    handleSetSearchParams,
  } = useCulinarySkus();
  return (
    <ContentLayout
      title="SKUs"
      processing={isLoading}
      rightAction={
        canCreate && (
          <CreateButton data-testid="new-sku-btn" to={getCreatePath(rootPath)}>
            + New SKU
          </CreateButton>
        )
      }
    >
      <Box mb="md">
        <Filters handleChange={updateFilters} values={filters} />
      </Box>
      <DataTable
        {...PROPS_DATA_TABLE}
        records={output}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        onRowClick={({ id }) => navigate(getEditPath(rootPath, id))}
        columns={[
          { width: '10%', accessor: 'sku_number', title: 'SKU#' },
          { width: '30%', accessor: 'product_name', title: 'Product Name' },
          { width: '10%', accessor: 'category', title: 'Category' },
          { width: '10%', accessor: 'subcategory', title: 'Sub-Category' },
          { width: '10%', accessor: 'state', title: 'State' },
          { width: 200, accessor: 'comment', title: 'Comment', ellipsis: true },
        ]}
      />
    </ContentLayout>
  );
};
