import { ReactNode, FC } from 'react';
import { Box, Center } from '@mantine/core';

export const Logo: FC<{
  children: ReactNode;
}> = ({ children }): JSX.Element => (
  <Box
    sx={{
      marginBottom: '6.25rem',
    }}
  >
    <Center>{children}</Center>
  </Box>
);
