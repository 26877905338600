import { Button, Container } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { AddressForm } from './AddressForm';
import { addressEditSchema } from './schemes';
import { AddressDto, CustomerDto } from 'shared';
import { getInitialAddressValue } from '../../helpers';

type Props = {
  profile?: CustomerDto['profile'];
  isLoading: boolean;
  opened: boolean;
  isUnpaid?: boolean;
  isEdit?: boolean;
  handleSubmit: (values: AddressDto, update_orders?: boolean) => void;
  setIsNotVerify: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
  goBack: () => void;
};

export const AddAddressForm = ({
  profile,
  isLoading,
  opened,
  isUnpaid,
  isEdit,
  handleSubmit,
  setIsNotVerify,
  goBack,
  close,
}: Props) => {
  const form = useForm({
    initialValues: getInitialAddressValue(profile),
    validate: yupResolver(addressEditSchema),
    validateInputOnBlur: true,
  });

  return (
    <Container size="sm">
      <AddressForm
        isEdit={isEdit}
        form={form}
        isUnpaid={isUnpaid}
        handleSubmit={handleSubmit}
        processing={isLoading}
        opened={opened}
        close={close}
        data-testid="close-btn"
        setIsNotVerify={setIsNotVerify}
      />
      <Container size="xs" my="xl">
        <Button fullWidth variant="white" onClick={goBack}>
          Back to Addresses
        </Button>
      </Container>
    </Container>
  );
};
