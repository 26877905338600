import { getEmptyOption, InventoryLibraryStatus } from 'shared';

export const STATUS_OPTIONS = [
  getEmptyOption('All'),
  { label: 'Reserved', value: InventoryLibraryStatus.RESERVED },
  { label: 'Released', value: InventoryLibraryStatus.RELEASED },
  { label: 'Canceled', value: InventoryLibraryStatus.CANCELED },
];

export const TABLE_COLUMS = [
  { width: 130, accessor: 'sku.sku_number', title: 'SKU#' },
  { width: 170, accessor: 'sku.product_name', title: 'Product Name' },
  {
    width: 250,
    accessor: 'inventory_location.location.name',
    title: 'Primary Location',
  },
  {
    width: 250,
    accessor: 'inventory_location.name',
    title: 'Inventory Location',
  },
  { accessor: 'quantity', title: 'Qty' },
  { accessor: 'reserveDate', title: 'Reserve Date' },
  { accessor: 'updatedDate', title: 'Latest Update' },
  { accessor: 'status', title: 'Status' },
];
