import { useCallback, useMemo, useState } from 'react';
import { isEmpty, reject } from 'rambda';

import { SkusRequest, getSelectOptions, hasPages } from 'shared';
import { SkuService } from 'App/api';
import { useApiQuery } from './useApiQuery';

type Props = {
  inputParams?: SkusRequest;
};

const PAGE_SIZE = 50;

export const useSkus = ({ inputParams }: Props) => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<SkusRequest>({
    search_string: '',
  });

  const params = useMemo(() => {
    const { search_string } = filters;
    return {
      page,
      search_string,
      page_size: PAGE_SIZE,
      ...inputParams,
    };
  }, [filters, page, inputParams]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(
    ['getProductSkus', key],
    () =>
      SkuService.getCollection(
        reject(isEmpty, params as Record<string, unknown>),
      ),
    {
      cacheTime: 0,
    },
  );

  const skuOptions = useMemo(() => {
    if (!data) {
      return [];
    }
    return getSelectOptions(
      data.items.map(({ id, product_name, sku_number, comment }) => ({
        id,
        name: `[${sku_number}] ${product_name}`,
        description: comment,
      })),
    );
  }, [data]);

  const updateFilters = useCallback((data: SkusRequest) => {
    setFilters((prev) => ({ ...prev, ...data }));
    setPage(1);
  }, []);

  return {
    skus: data?.items,
    skuOptions,
    isLoading,
    setPage,
    updateFilters,
    hasMore: hasPages({
      total: data?.total,
      defaultSize: params?.page_size,
      currentPage: page,
    }),
  };
};
