import { useRef } from 'react';
import { FileButton, Grid, Text, Button, Box, Image } from '@mantine/core';

type Props = {
  onFileChange: (f: File) => void;
  file: File | null;
  fileUrl?: string;
  title: string;
};

export const ImageUpload = ({ fileUrl, onFileChange, file, title }: Props) => {
  const hash = useRef(new Date().getTime()).current;
  const urlString = fileUrl && `${fileUrl}?${hash}`;
  const imageUrl = file ? URL.createObjectURL(file) : urlString;

  return (
    <Grid gutter={'xl'}>
      <Grid.Col>
        <Text size="lg" weight={600}>
          {title}
        </Text>
      </Grid.Col>
      <Grid.Col>
        <Grid gutter={'xl'}>
          <Grid.Col>
            <FileButton onChange={onFileChange} accept="image/png,image/jpeg">
              {(props) =>
                imageUrl ? (
                  <Box maw={'100%'} mx="auto" {...props}>
                    <Image
                      src={imageUrl}
                      radius="md"
                      height={320}
                      imageProps={{
                        onLoad: () =>
                          file ? URL.revokeObjectURL(imageUrl) : null,
                      }}
                      caption="Click to change"
                    />
                  </Box>
                ) : (
                  <Button {...props}>Upload image</Button>
                )
              }
            </FileButton>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
