import { useCallback, useMemo } from 'react';

import { useApiQuery, useQueryParams } from 'hooks';
import { PaginatedRequest } from 'shared';
import { DiscountService } from 'App/api';
import { normalizeDiscountList } from '../helpers';

type RequestFilter = { search_string: string };

export const useDiscounts = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params: PaginatedRequest = useMemo(() => {
    return {
      page,
      page_size: 10,
      search_string,
    };
  }, [page, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getDiscounts', key], () =>
    DiscountService.getCollection(params),
  );

  const updateFilters = useCallback(
    (data: RequestFilter) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  const output = useMemo(() => normalizeDiscountList(data?.items), [data]);

  return {
    page,
    search_string,
    output,
    totalRecords: data?.total ?? 0,
    isLoading,
    handleSetSearchParams,
    updateFilters,
  };
};
