import { Location } from '../settings';
import { PaginatedRequest } from '../common';

export interface InventoryLocationDto {
  id: string;
  name: string;
  show_in_pos: boolean;
  location: Location;
}

export interface InventoryLocationPayload
  extends Omit<InventoryLocationDto, 'id' | 'location'> {
  location_id: string;
}

export enum InventoryType {
  ALCOHOL = 'alcohol',
  RETAIL_FOOD = 'retail_food',
  GRATUITY = 'gratuity',
  MERCHANDISE = 'merchandise',
  NON_INVENTORY_ITEMS = 'non_inventory_items',
  SHIPPING = 'shipping',
  TASTING = 'tasting',
  CULINARY = 'culinary',
  EXPERIENCES = 'experiences',
  DISCOUNTS = 'discounts',
}

export const inventoryTypeLabels: Record<InventoryType, string> = {
  [InventoryType.ALCOHOL]: 'Alcohol',
  [InventoryType.RETAIL_FOOD]: 'Retail food',
  [InventoryType.GRATUITY]: 'Gratuity',
  [InventoryType.MERCHANDISE]: 'Merchandise',
  [InventoryType.NON_INVENTORY_ITEMS]: 'Non-inventory items',
  [InventoryType.SHIPPING]: 'Shipping',
  [InventoryType.TASTING]: 'Tasting',
  [InventoryType.CULINARY]: 'Culinary',
  [InventoryType.EXPERIENCES]: 'Experiences',
  [InventoryType.DISCOUNTS]: 'Discounts',
};

export enum TaxRate {
  DEFAULT_RATE = 'default_rate',
  NOT_TAXED = 'not_taxed',
}

export const taxRateLabels: Record<TaxRate, string> = {
  [TaxRate.DEFAULT_RATE]: 'Default (local) Tax rate',
  [TaxRate.NOT_TAXED]: 'Product Not Taxed',
};

export enum InventoryPool {
  RETAIL = 'retail',
  STORAGE = 'storage',
  FULFILLMENT = 'fulfilment',
  LIBRARY_RESERVE = 'library',
  WHOLESALER = 'wholesaler',
}

type InventoryItem = {
  sku_id: string;
  quantity: number;
};

type FormInventoryItem = InventoryItem & {
  key: string;
};

export interface InventoryPayload {
  order_number?: string;
  vendor_id?: string;
  comment?: string;
  inventory_location_id: string;
  items: InventoryItem[];
}

export interface InventoryFormValues extends InventoryPayload {
  is_vendor: boolean;
  items: FormInventoryItem[];
}

export interface InventoryListItemDto {
  stock_keeping_unit_id: string;
  sku_number: string;
  product_name: string;
  inventory_type: InventoryType;
  sku_type: string;
  total_cost: number;
  quantity: number;
  has_lte_items: boolean;
}

export interface InventoryListRequest extends PaginatedRequest {
  search_string?: string;
  location?: string[];
  pool?: InventoryPool;
}

export type InventorySkuItem = {
  id: string;
  sku_number: string;
  product_name: string;
  quantities: {
    library: number;
    default: number;
  };
};

export interface InventorySkuItemsDto {
  items: InventorySkuItem[];
}
export interface InventoryUnitRequest {
  sku_id: string;
  location?: string[];
}

export interface InventoryUnit {
  total_cost: number;
  stock: number;
  available: number;
  committed: number;
  id?: string;
  name?: string;
  locations?: InventoryUnit[] | null;
  inventory_locations?: InventoryUnit[] | null;
}

export type InventoryUnitDto = Record<string, InventoryUnit | null>;

export interface InventoryUnitOutput {
  stock: number;
  available: number;
  committed: number;
  id: string;
  name: string;
  level: 0 | 1 | 2;
}
