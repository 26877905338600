import { ReactNode } from 'react';
import { Text } from '@mantine/core';

export const SuggestionType = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <>
      <Text size={18} weight={600} my={24}>
        {title}
      </Text>
      {children}
    </>
  );
};
