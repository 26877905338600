import { Text, Grid, Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { CreateCulinaryMenu } from 'shared';
import { Product } from './Product';

type PropType = {
  form: UseFormReturnType<
    CreateCulinaryMenu,
    (values: CreateCulinaryMenu) => CreateCulinaryMenu
  >;
};

export const AddProduct = ({ form }: PropType) => {
  const items = form.values?.items;

  return (
    <Box mt="xl">
      <Grid gutter="lg">
        <Grid.Col span={6}>
          <Text size={14} weight={600}>
            Product Name or SKU#
          </Text>
        </Grid.Col>
      </Grid>

      <Box>
        {items?.map(({ key, sku_id }, index: number) => {
          return (
            <Product
              key={key ?? sku_id}
              form={form}
              skuId={sku_id}
              index={index}
            />
          );
        })}
      </Box>
    </Box>
  );
};
