import * as Yup from 'yup';
import dayjs from 'dayjs';

import {
  emailValidationRule,
  phoneNumberValidator,
  requiredStringValidationRule,
} from 'shared';

export const generalInformationSchema = Yup.object({
  first_name: requiredStringValidationRule('First Name'),
  last_name: requiredStringValidationRule('Last Name'),
  email: emailValidationRule,
  phone_number: phoneNumberValidator,
  customer_source_id: Yup.string().nullable(),
  date_of_birth: Yup.string()
    .nullable()
    .test('Is date greater', 'Age must be 21 years old or more', (value) => {
      const currentDate = dayjs(new Date());
      if (!value) return true;
      return dayjs(currentDate.diff(value)).year() - 1970 >= 21;
    })
    .matches(/^(0[1-9]|1[0-2]).(0[1-9]|[12]\d|3[01]).(19|20)\d{2}$/, {
      excludeEmptyString: true,
      message: 'Incorrect date of birth',
    }),
  preferred_primary_location_id: Yup.string().nullable(),
  secondary_location_id: Yup.string().nullable(),
  email_opt_in: Yup.boolean(),
  sms_opt_in: Yup.boolean(),
  pos_notes: Yup.string().nullable(),
  system_notes: Yup.string().nullable(),
});
