import { DiscountService } from 'App/api';
import { useLocations } from 'App/contexts';
import { useApiMutation, useClubTier } from 'hooks';
import { CreateDiscount } from 'shared';
import { normalizeDiscountPayload } from '../components/form';

type Props = {
  onExit: () => void;
};

const key = 'createDiscount';

export const useCreateDiscount = ({ onExit }: Props) => {
  const { options } = useLocations();

  const { options: tiersOptions, isLoading: isLoadingTiers } = useClubTier({});
  const { mutate: createDiscount, isLoading } = useApiMutation(
    [key],
    DiscountService.create,
    {
      onSuccess: onExit,
    },
  );

  const handleSubmit = (values: CreateDiscount) => {
    createDiscount(normalizeDiscountPayload(values));
  };

  return {
    options,
    tiersOptions,
    handleSubmit,
    isLoading: isLoading || isLoadingTiers,
  };
};
