import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '@mantine/core';

import { Form } from '../form';
import { ConfirmModal, ContentLayout, BackButton } from 'components';
import { useEditInventoryTransfer } from '../../hooks';

type PropType = {
  canCancel?: boolean;
  onExit: () => void;
  onCreate?: (id: string) => void;
};

export const Edit = ({
  canCancel,
  onExit,
  onCreate,
}: PropType): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const {
    initialValues,
    isLoading,
    cancelTransfer,
    isCancelled,
    handleSubmit,
    isUpdating,
  } = useEditInventoryTransfer({
    id,
    onCancel: onExit,
    onCreate,
  });

  const goBack = () => navigate(-1);
  const title = `Transfer ${initialValues?.number_str}`;

  return (
    <ContentLayout
      title={title}
      leftAction={<BackButton onClick={goBack} />}
      isLoading={isLoading}
      processing={isUpdating}
    >
      <Form
        handleSubmit={(v) => handleSubmit(id, v)}
        initialValues={initialValues}
        editMode
        action={
          canCancel ? (
            <ConfirmModal
              type="button"
              variant="outline"
              fullWidth
              loading={isCancelled}
              mt={26}
              onConfirm={() => cancelTransfer(id)}
              data-testid="cancel-transfer-btn"
              label="Сancel Transfer"
            >
              <Text>Are you sure you want to cancel the Transfer?</Text>
            </ConfirmModal>
          ) : null
        }
      />
    </ContentLayout>
  );
};
