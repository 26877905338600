import { ReactNode } from 'react';
import { Box, Button, Container, Space } from '@mantine/core';
import { CreateCulinarySku, CreateSku, SAVE_BTN } from 'shared';

type Props = {
  children: ReactNode;
  initialValues?: CreateSku | CreateCulinarySku;
  isCreate?: boolean;
  action?: ReactNode;
  processing: boolean;
  isValid: boolean;
  canEdit?: boolean;
  editMode?: boolean;
  isDraftDisabled: boolean;
  setFieldValue: (field: string, value: boolean) => void;
};

export const SkuWrapper = ({
  children,
  initialValues,
  isCreate,
  action,
  processing,
  isValid,
  canEdit,
  editMode,
  isDraftDisabled,
  setFieldValue,
}: Props) => {
  const isEdit = editMode && canEdit;

  return (
    <>
      {children}
      <Space h={'xl'} />
      <Container size={'xs'} p={0}>
        {(isEdit || isCreate) && (
          <Box>
            <Box mb="xl">
              <Button
                loading={processing}
                disabled={!isValid}
                type="submit"
                onClick={() => setFieldValue('saved_as_draft', false)}
                fullWidth
                data-testid={SAVE_BTN}
              >
                Save
              </Button>
            </Box>
            {initialValues?.saved_as_draft || isCreate ? (
              <Box mb={'xl'}>
                <Button
                  loading={processing}
                  disabled={isDraftDisabled}
                  variant="outline"
                  type="submit"
                  onClick={() => setFieldValue('saved_as_draft', true)}
                  fullWidth
                  data-testid="save-draft-btn"
                >
                  Save as Draft
                </Button>
              </Box>
            ) : (
              action
            )}
          </Box>
        )}
        {initialValues?.saved_as_draft && action}
      </Container>
    </>
  );
};
