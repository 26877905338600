import * as Yup from 'yup';

import { requiredStringValidationRule, zipCodeValidator } from 'shared';

export const billingAddressSchema = Yup.object({
  first_name: requiredStringValidationRule('First Name').nullable(),
  last_name: requiredStringValidationRule('Last Name').nullable(),
  country: requiredStringValidationRule('Country').nullable(),
  state: requiredStringValidationRule('State').nullable(),
  city: requiredStringValidationRule('City').nullable(),
  business: Yup.string().nullable(),
  street_address_one:
    requiredStringValidationRule('Street Address 1').nullable(),
  street_address_two: Yup.string().nullable(),
  zip_code: zipCodeValidator,
});

export const walletSchema = Yup.object({
  card_name: requiredStringValidationRule('Card Name'),
  card_number: requiredStringValidationRule('Card Number'),
  expiration_date: requiredStringValidationRule('Expiration Date'),
  cvv: requiredStringValidationRule('CVV'),
  name_on_card: requiredStringValidationRule('Name on Card'),
  first_name: requiredStringValidationRule('First Name').nullable(),
  last_name: requiredStringValidationRule('Last Name').nullable(),
  country: requiredStringValidationRule('Country').nullable(),
  state: requiredStringValidationRule('State').nullable(),
  city: requiredStringValidationRule('City').nullable(),
  business: Yup.string().nullable(),
  street_address_one:
    requiredStringValidationRule('Street Address 1').nullable(),
  street_address_two: Yup.string().nullable(),
  zip_code: zipCodeValidator,
  is_default: Yup.boolean(),
});
