import { AdjustmentType } from './adjustment';

export enum InventoryTransferStatus {
  transit = 'transit',
  canceled = 'canceled',
  partially_received = 'partially_received',
  fully_received = 'fully_received',
}

export const StatusInvetoryTransfer: {
  [key in InventoryTransferStatus]: string;
} = {
  [InventoryTransferStatus.transit]: 'Transit',
  [InventoryTransferStatus.canceled]: 'Canceled',
  [InventoryTransferStatus.partially_received]: 'Partially Received',
  [InventoryTransferStatus.fully_received]: 'Fully Received',
};

export type SkuOptions = {
  id: string;
  name: string;
  quantities: {
    default: number;
    library: number;
  };
};

export type TransferSkus = {
  sku_id?: string;
  sku_product_name?: string;
  quantities: {
    default: number;
    library: number;
  };
  quantity?: number;
  total: number;
  cost?: number;
  received_quantity: number;
  received_total?: number;
  to_receive_quantity?: number;
  is_received?: boolean;
  key?: string;
};

export type CreateTransfer = {
  origin: string | null;
  destination: string | null;
  origin_inventory_location_id: string | null;
  destination_inventory_location_id: string | null;
  comment?: string;
  status?: InventoryTransferStatus;
  is_library: boolean;
  items: TransferSkus[];
};

export interface InventoryTransferPayload
  extends Omit<CreateTransfer, 'origin' | 'destination' | 'items'> {
  items: Omit<TransferSkus[], 'key' | 'sku_product_name'>;
}

export type InventoryTransferDto = {
  number: number;
  number_str: string;
  id: string;
  issued_at: string;
  origin_inventory_location: {
    id: string;
    name: string;
    location_id: string;
    location_name: string;
  };
  destination_inventory_location: {
    id: string;
    name: string;
    location_id: string;
    location_name: string;
  };
  comment: string;
  adjustment_type?: AdjustmentType;
  receiveItems?: { name?: string; quantityAdjust: string }[];
  status: InventoryTransferStatus;
  total: number;
  total_quantity: number;
  is_library: boolean;
  items: TransferSkus[];
  date_received: string | null;
};

export type FilterValue = {
  location?: string[];
  search_string?: string;
  status?: string;
};
