import { ReactNode } from 'react';
import { Text } from '@mantine/core';
import { WineType, WineTypeTitle } from 'shared';

export const WineSelectionType = ({
  type,
  name,
  children,
}: {
  type: WineType;
  name?: string;
  children: ReactNode;
}) => {
  const title =
    WineTypeTitle[type] === WineTypeTitle.silver_label
      ? name
      : `${name} ${WineTypeTitle[type]}`;
  return (
    <>
      <Text size={18} weight={600} my={24}>
        {title}
      </Text>
      {children}
    </>
  );
};
