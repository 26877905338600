import { PaginatedResponse, SkuDto, SkusRequest } from 'shared';
import { SKUS_PATH } from '../../endpoints';
import request from '../../request';

export default class SkuService {
  static readonly getCollection = (params: SkusRequest) => {
    return request<PaginatedResponse<SkuDto>>({
      url: `${SKUS_PATH}`,
      params,
      method: 'get',
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<SkuDto>({
      url: `${SKUS_PATH}/${id}`,
      method: 'get',
    });
  };
}
