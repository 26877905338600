import { Grid, Select, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DatePicker } from '@mantine/dates';
import { isNil } from 'rambda';
import {
  CycleOrderPayload,
  FREIGHT_COMPANY,
  FREIGHT_METHOD,
  DATE_FORMAT_SLASH,
  getCurrencyString,
} from 'shared';

type PropType = {
  form: UseFormReturnType<CycleOrderPayload>;
  shippingCost?: number;
  isBlocked?: boolean;
};

export const FreightData = ({
  form,
  shippingCost,
  isBlocked = false,
}: PropType) => {
  return (
    <Grid gutter="xl">
      <Grid.Col span={4}>
        <Select
          required
          label="Freight Company"
          disabled={isBlocked}
          data={FREIGHT_COMPANY}
          {...form.getInputProps('freight_company')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required
          label="Freight Method"
          disabled={isBlocked}
          data={FREIGHT_METHOD}
          {...form.getInputProps('freight_method')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DatePicker
          required
          inputFormat={DATE_FORMAT_SLASH}
          disabled={isBlocked}
          placeholder="mm/dd/yyyy"
          label="Shipping Date"
          {...form.getInputProps('shipping_date')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          label="Tracking #"
          {...form.getInputProps('tracking_number')}
        />
      </Grid.Col>
      <Grid.Col>
        <Text size="sm" weight={600}>
          Shipping Fee
        </Text>
        <Text mt="xs">
          {isNil(shippingCost) ? 'N/A' : getCurrencyString(shippingCost)}
        </Text>
      </Grid.Col>
    </Grid>
  );
};
