import * as Yup from 'yup';
import {
  phoneNumberValidator,
  requiredStringValidationRule,
  zipCodeValidator,
} from 'shared';

export const validationSchema = Yup.object({
  company_name: requiredStringValidationRule('Company Name'),
  type: requiredStringValidationRule('Partner Type'),
  website: Yup.string()
    .url('Invalid Website.')
    .required('Please Enter Website'),
  street_address: requiredStringValidationRule('Street Address'),
  city: requiredStringValidationRule('City'),
  state: requiredStringValidationRule('State'),
  zip_code: zipCodeValidator,
  phone_number: phoneNumberValidator,
  primary_contact_name: Yup.string().nullable(),
  comment: Yup.string()
    .max(150, 'Comment should not be greater than 150 characters')
    .nullable(),
});
