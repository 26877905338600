import { omit } from 'rambda';
import { InventoryLibraryService } from 'App/api';
import { useApiMutation } from 'hooks';
import { CreateLibrary } from 'shared';

export const useCreateInventoryLibrary = ({
  onCreate,
}: {
  onCreate: () => void;
}) => {
  const { mutate, isLoading } = useApiMutation(
    ['createLibrary'],
    InventoryLibraryService.create,
    {
      onSuccess: () => onCreate(),
    },
  );

  const handleSubmit = (values: CreateLibrary) => {
    const payload = {
      ...values,
      items: values.items.map(omit(['key', 'total'])),
    };
    mutate(payload);
  };
  return { handleSubmit, isLoading };
};
