import { FC } from 'react';
import { Box, Button, NumberInput, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { FeeValues } from 'shared';
import { FeeItems } from './FeeItems';
import { taxesFeesSchema } from './schema';

export type Props = {
  handleSubmit: (values: FeeValues) => void;
  initialValues: FeeValues;
  processing: boolean;
};

export const Form: FC<Props> = ({
  handleSubmit,
  initialValues,
  processing,
}): JSX.Element => {
  const form = useForm({
    initialValues,
    validate: yupResolver(taxesFeesSchema),
    validateInputOnBlur: true,
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Box mb="xl">
        <NumberInput
          label="Local Tax, (%)"
          min={0}
          precision={2}
          step={0.01}
          hideControls
          {...form.getInputProps('value')}
        />
      </Box>
      <Box mb={'lg'}>
        <Text size={'sm'} weight={600}>
          Fees
        </Text>
      </Box>

      <FeeItems form={form} />
      <Box my="xl">
        <Button
          disabled={!form.isValid()}
          type="submit"
          fullWidth
          loading={processing}
          aria-label="save"
        >
          Save
        </Button>
      </Box>
    </form>
  );
};
