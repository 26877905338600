import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { Permissions } from 'shared';
import { List, Create, Details, Edit } from './components';
import { NestedRouteComponent } from 'components';

export const ShipmentCycle = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.ShipmentCycle,
  });

  const canCreate = checkAccess(Permissions.admin_panel_shipment_cycle_create);
  const canSeeDetails = checkAccess(
    Permissions.admin_panel_shipment_cycle_detail_view,
  );
  const canEdit = checkAccess(Permissions.admin_panel_shipment_cycle_edit);
  const canDelete = checkAccess(Permissions.admin_panel_shipment_cycle_delete);
  const onExit = useGotoRoute(RouteName.ShipmentCycle);

  const CreateComponent = <Create onExit={onExit} rootPath={rootPath} />;
  const ListComponent = (
    <List
      canCreate={canCreate}
      canSeeDetails={canSeeDetails}
      rootPath={rootPath}
    />
  );
  const EditComponent = <Edit onExit={onExit} canDelete={canDelete} />;
  const DetailsComponent = <Details onExit={onExit} />;

  return (
    <NestedRouteComponent
      showEdit={canEdit}
      showCreate={canCreate}
      ListComponent={ListComponent}
      Create={CreateComponent}
      Edit={EditComponent}
      Details={DetailsComponent}
      rootPath={rootPath}
    />
  );
};
