import request from '../request';
import {
  Employee,
  EmployeeRequest,
  EmployeesRequest,
  EmployeeUpdateBody,
  NewEmployee,
  PaginatedResponse,
} from 'shared';
import { GET_USER_PROFILE_PATH, EMPLOYEE_API_PATH } from '../endpoints';
export default class EmployeeService {
  static readonly getUser = () => {
    return request<Employee>({
      url: GET_USER_PROFILE_PATH,
      method: 'GET',
    });
  };

  static readonly createEmployee = (params: NewEmployee) => {
    return request<NewEmployee & { id: string }>({
      url: EMPLOYEE_API_PATH,
      method: 'post',
      data: params,
    });
  };

  static readonly getEmployees = (params: EmployeesRequest) => {
    return request<PaginatedResponse<Employee>>({
      url: EMPLOYEE_API_PATH,
      method: 'get',
      params,
    });
  };

  static readonly getEmployee = ({ employeeId }: EmployeeRequest) => {
    return request<Employee>({
      url: `${EMPLOYEE_API_PATH}/${employeeId}`,
      method: 'get',
    });
  };

  static readonly updateEmployee = ({
    employee_id,
    data,
  }: {
    employee_id: string | undefined;
    data: EmployeeUpdateBody;
  }) => {
    return request<Employee>({
      url: `${EMPLOYEE_API_PATH}/${employee_id}`,
      method: 'put',
      data,
    });
  };

  static readonly disableEmployee = (employeeId: string) => {
    return request({
      url: `${EMPLOYEE_API_PATH}/${employeeId}/disable`,
      method: 'post',
      data: {},
    });
  };

  static readonly activateEmployee = (employeeId: string) => {
    return request({
      url: `${EMPLOYEE_API_PATH}/${employeeId}/activate`,
      method: 'post',
      data: {},
    });
  };
}
