import { SalesOrderComplianceStatus } from 'shared';

export const status = {
  [SalesOrderComplianceStatus.ERROR]: {
    color: 'red',
    text: 'Oops, something went wrong, try sending the request again.',
  },
  [SalesOrderComplianceStatus.COMPLIANT]: {
    color: 'blue',
    text: 'This order has successfully passed compliace check.',
  },
  [SalesOrderComplianceStatus.NOT_COMPLIANT]: {
    color: 'red',
    text: 'Oops, this order did NOT pass the compliance check. Please, check ShipCompliant.',
  },
  default: {
    color: 'blue',
    text: 'This order is already sent to Shipcompliant, no changes are possible.',
  },
};
