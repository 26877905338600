import { useNavigate } from 'react-router-dom';
import { omit } from 'rambda';
import { Grid, Select } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import {
  NestedRoutes,
  getViewPath,
  getEmptyOption,
  PROPS_DATA_TABLE,
} from 'shared';
import { ContentLayout, CreateButton } from 'components';
import { useInventoryLocations } from 'pages/inventory/hooks';

export const List = ({
  canCreate,
  rootPath,
}: {
  canCreate: boolean;
  canEdit: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const {
    output,
    primaryLocationOptions: options,
    primaryLocation,
    setPrimaryLocation,
    isLoading,
  } = useInventoryLocations();
  const tableProps = omit(['recordsPerPage'], PROPS_DATA_TABLE);

  return (
    <ContentLayout
      title="Inventory Locations"
      processing={isLoading}
      rightAction={
        canCreate && (
          <CreateButton
            data-testid="new-inventory-location-btn"
            to={`${rootPath}/${NestedRoutes.Create}`}
          >
            + New Location
          </CreateButton>
        )
      }
    >
      <Grid mb={'md'}>
        <Grid.Col span={7}>
          <Grid.Col span={7}>
            <Select
              data={[getEmptyOption('All Locations'), ...options]}
              label="Select Primary Location"
              data-testid="select-primary-location-dropdown"
              value={primaryLocation}
              onChange={(value: string) => {
                setPrimaryLocation(value);
              }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid>
      <DataTable
        data-testid="inventory-locations-table"
        {...tableProps}
        records={output}
        onRowClick={({ id }) => navigate(getViewPath(rootPath, id))}
        columns={[
          { accessor: 'name', title: 'Inventory Location Name' },
          { accessor: 'location', title: 'Location' },
          {
            accessor: 'show_in_pos',
            title: 'Show in POS',
            render: ({ show_in_pos }) => (show_in_pos ? 'Yes' : 'No'),
          },
        ]}
      />
    </ContentLayout>
  );
};
