import { Button, Flex, Text } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';

export const ConfirmData = ({
  message,
  approve,
  reject = () => null,
}: {
  message: string;
  approve: () => void;
  reject?: () => void;
}) => {
  return (
    <>
      <Text data-testid="change-upcoming-text-message" mb="xl">
        {message}
      </Text>
      <Flex gap="xl">
        <Button
          data-testid="reject-btn"
          fullWidth
          variant="outline"
          onClick={() => {
            reject();
            closeAllModals();
          }}
        >
          No
        </Button>
        <Button
          data-testid="accept-btn"
          fullWidth
          onClick={() => {
            approve();
            closeAllModals();
          }}
        >
          Yes
        </Button>
      </Flex>
    </>
  );
};
