import { AuthService } from 'App/api';
import { useSession } from 'App/session';
import { useApiMutation } from 'hooks';
import { SinginResponse, SinginRequest } from 'shared';

const queryKey = 'signin';

export const useSignInForm = (): {
  handleSubmit: ({ email, password }: SinginRequest) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  data?: SinginResponse;
} => {
  const { setTokens } = useSession();
  const {
    isSuccess,
    mutate: authenticate,
    data,
    error,
    isError,
    isLoading,
  } = useApiMutation([queryKey], AuthService.signIn, {
    onSuccess: (data) => {
      setTokens(data);
    },
  });

  const handleSubmit = (values: SinginRequest) => authenticate(values);

  return {
    handleSubmit,
    error,
    isError,
    isLoading,
    isSuccess,
    data,
  };
};
