import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Loader,
  Select,
  Switch,
  Text,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconInfoCircle } from '@tabler/icons-react';

import { CreateTransfer, LOADER } from 'shared';
import { ActionButtons } from 'components';
import { useInitialForm } from '../../hooks';
import { AddProduct } from './AddProduct';
import { getNewItem } from './helpers';
import { transferSchema } from './schema';

export type Props = {
  handleSubmit: (values: CreateTransfer) => void;
  initialValues?: CreateTransfer;
  action?: JSX.Element | null;
  editMode?: boolean;
};

export const Form = ({
  handleSubmit,
  initialValues,
  action,
  editMode,
}: Props): JSX.Element => {
  const form = useForm({
    initialValues,
    validate: yupResolver(transferSchema),
    validateInputOnBlur: true,
  });
  const payloadOrigin = {
    inventorySlug: form.values.origin ?? initialValues?.origin,
    currentInventoryId: form.values.origin_inventory_location_id,
    isLibrary: form.values.is_library,
  };

  const payloadDistanation = {
    inventorySlug: form.values.destination ?? initialValues?.destination,
    currentInventoryId: form.values.destination_inventory_location_id,
  };
  const {
    skusOptions,
    isLoadingSkus,
    actualLocationOptions,
    inventoryOptions: originInventoryOptions,
    isLoadingInventory: isLoadingOriginInventory,
  } = useInitialForm(payloadOrigin);

  const {
    inventoryOptions: distanationInventoryOptions,
    isLoadingInventory: isLoadingDistonationInventory,
  } = useInitialForm(payloadDistanation);

  const fullWidth = { width: '100%' };
  const isLibrary = form.values.is_library;

  return (
    <Container size="sm">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid gutter="xl">
          {!editMode && (
            <Grid.Col span={12}>
              <Grid gutter={'xl'} align="center">
                <Grid.Col span={6} pt={0}>
                  <Switch
                    label="Show Library Reserve"
                    labelPosition="right"
                    sx={{ ...fullWidth, fontWeight: 600 }}
                    {...form.getInputProps('is_library', { type: 'checkbox' })}
                    onChange={(value) => {
                      form.setFieldValue('is_library', value.target.checked);
                      form.setFieldValue('origin', '');
                      form.setFieldValue('origin_inventory_location_id', '');
                      form.setFieldValue('items', [getNewItem()]);
                    }}
                  />
                </Grid.Col>
                {isLibrary && (
                  <Grid.Col span={6}>
                    <Flex sx={fullWidth} direction="row" align="center" gap={5}>
                      <IconInfoCircle size={18} stroke={1} color="gray.6" />
                      <Text size={14} color="gray.6">
                        Only Library Reserve can be selected
                      </Text>
                    </Flex>
                  </Grid.Col>
                )}
              </Grid>
            </Grid.Col>
          )}

          <Grid.Col span={6}>
            <Select
              required
              label="Origin Primary Location"
              data-testid="origin-primary-location-dropdown"
              sx={fullWidth}
              rightSectionWidth={30}
              {...form.getInputProps('origin')}
              onChange={(value) => {
                form.setFieldValue('origin', value);
                form.setFieldValue('origin_inventory_location_id', '');
                form.setFieldValue('items', [getNewItem()]);
              }}
              data={actualLocationOptions}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              required
              label="Destination Primary Location"
              data-testid="destination-primary-location-dropdown"
              sx={fullWidth}
              rightSectionWidth={30}
              {...form.getInputProps('destination')}
              onChange={(value) => {
                form.setFieldValue('destination', value);
                form.setFieldValue('destination_inventory_location_id', '');
              }}
              data={actualLocationOptions}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Select
              required
              label="Origin Inventory Location"
              data-testid="origin-inventory-location-dropdown"
              sx={fullWidth}
              rightSectionWidth={30}
              icon={
                isLoadingOriginInventory ? (
                  <Loader data-testid={LOADER} size={20} />
                ) : null
              }
              {...form.getInputProps('origin_inventory_location_id')}
              onChange={(value) => {
                form.setFieldValue('origin_inventory_location_id', value);
                form.setFieldValue('items', [getNewItem()]);
              }}
              data={originInventoryOptions}
              disabled={isLoadingOriginInventory}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              required
              label="Destination Inventory Location"
              data-testid="destination-inventory-location-dropdown"
              sx={fullWidth}
              rightSectionWidth={30}
              icon={
                isLoadingDistonationInventory ? (
                  <Loader data-testid={LOADER} size={20} />
                ) : null
              }
              {...form.getInputProps('destination_inventory_location_id')}
              data={distanationInventoryOptions}
              disabled={isLoadingDistonationInventory}
            />
          </Grid.Col>
        </Grid>

        <AddProduct
          form={form}
          skusOptions={skusOptions}
          isLoadingSkus={isLoadingSkus}
        />

        <Button
          data-testid="add-new-product-to-transfer-btn"
          variant="white"
          p="0"
          onClick={() => form.insertListItem('items', getNewItem())}
        >
          + Add New Product
        </Button>
        <Box my="xl">
          <ActionButtons
            disabled={!form.isValid()}
            type="submit"
            label="Save"
            aria-label="save"
            secondary={action}
          />
        </Box>
      </form>
    </Container>
  );
};
