import { InventoryLibraryService } from 'App/api';
import { useApiMutation, useApiQuery } from 'hooks';
import { normalizeInventoryLibrary, ReleaseLibrary } from 'shared';

type Props = {
  id: string;
  onCancel?: () => void;
};

const getInventoryLibrary = 'getInventoryLibrary';

export const useEditInventoryLibrary = ({ id, onCancel }: Props) => {
  const { data, isLoading } = useApiQuery(
    [getInventoryLibrary, id],
    () => InventoryLibraryService.getOne({ id }),
    {
      cacheTime: 0,
    },
  );

  const { mutate: cancelLibrary, isLoading: isCancelled } = useApiMutation(
    [getInventoryLibrary, id],
    () => InventoryLibraryService.cancel(id),
    {
      onSuccess: onCancel,
    },
  );

  const { mutate: releaseLibrary, isLoading: isReleasing } = useApiMutation(
    [getInventoryLibrary, id],
    InventoryLibraryService.release,
    {
      onSuccess: onCancel,
    },
  );

  const releaseForSale = (values: ReleaseLibrary) => {
    releaseLibrary({ id, data: values });
  };

  const initialValues = data && normalizeInventoryLibrary(data);

  return {
    data,
    initialValues,
    isLoading,
    cancelLibrary,
    isCancelled,
    releaseForSale,
    isReleasing,
  };
};
