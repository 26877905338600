import { Ref, useEffect, useState } from 'react';
import { BaseSelectProps, Flex, Text } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import Select, {
  ActionMeta,
  MultiValue,
  SingleValue,
  GroupBase,
  components,
  OptionProps,
} from 'react-select';

import { SelectOption } from 'shared';
import SelectType from 'react-select/dist/declarations/src/Select';

type OptionType = {
  value: string;
  label: string;
};

interface ISelect extends BaseSelectProps {
  options: SelectOption[];
  isLoading: boolean;
  hasMore: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  defaultValue?: SelectOption;
  inputRef?: Ref<SelectType<OptionType, true, GroupBase<OptionType>>>;
  searchByString?: (data: { search_string: string }) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onChange?: (
    newValue: SingleValue<SelectOption> | MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>,
  ) => void;
}

const CustomOption = (
  optionProps: OptionProps<
    OptionType & { description?: string },
    boolean,
    GroupBase<OptionType>
  >,
) => {
  const { description } = optionProps.data ?? {};
  return (
    <components.Option {...optionProps}>
      <Text size="sm">{optionProps.children}</Text>
      {description && (
        <Text size="xs" opacity={0.65}>
          {description}
        </Text>
      )}
    </components.Option>
  );
};

export const SelectAsync = ({
  options,
  error,
  isLoading,
  hasMore,
  label = '',
  inputRef,
  required = false,
  isSearchable = false,
  isMulti = false,
  disabled = false,
  defaultValue,
  setPage,
  onChange,
  searchByString = () => null,
  onBlur,
  onFocus,
}: ISelect) => {
  const [values, setValues] = useState<SelectOption[]>([]);
  const [value, setValue] = useDebouncedState('', 500);

  const prevPage = () => setPage((prev: number) => (prev === 1 ? 1 : prev - 1));
  const nextPage = () => setPage((prev: number) => (hasMore ? prev + 1 : prev));

  useEffect(() => {
    if (!isLoading) setValues(options);
    return () => setValues([]);
  }, [isLoading, options]);

  useEffect(() => {
    searchByString({ search_string: value });
  }, [searchByString, value]);

  return (
    <>
      <Flex>
        <Text size="sm" weight={600}>
          {label}
        </Text>
        {required && label && (
          <Text color="red" ml={2}>
            *
          </Text>
        )}
      </Flex>
      <Select
        ref={inputRef}
        isMulti={isMulti}
        options={values}
        isDisabled={disabled}
        isLoading={isLoading}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        onChange={onChange}
        onInputChange={setValue}
        onMenuScrollToTop={prevPage}
        onMenuScrollToBottom={nextPage}
        onBlur={onBlur}
        onFocus={onFocus}
        components={{
          Option: (optionProps) => <CustomOption {...optionProps} />,
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#DEE2E6',
            primary: '#0A8ABF',
          },
        })}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
      {required && error && (
        <Text size="sm" color="red">
          {error}
        </Text>
      )}
    </>
  );
};
