import { useMemo, useState } from 'react';
import { CustomerService } from 'App/api';
import { useApiQuery } from 'hooks';

export const useTastingHistory = ({ customerId }: { customerId: string }) => {
  const [page, setPage] = useState<number>(1);

  const params = useMemo(() => {
    return {
      page,
    };
  }, [page]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(
    ['tasting-history', customerId, key],
    () =>
      CustomerService.getTastingHistory({
        id: customerId,
        params,
      }),
    {
      enabled: !!customerId,
    },
  );

  return {
    data,
    isLoading,
    page,
    setPage,
    totalRecords: data?.total ?? 0,
  };
};
