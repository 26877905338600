import { FC } from 'react';
import { Box, TextInput, Group, Button, PasswordInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconEyeCheck, IconEyeOff } from '@tabler/icons-react';
import { EMAIL_INPUT, SinginRequest } from 'shared';
import { signInFormSchema } from 'pages/signIn/schemas';

type Props = {
  handleSubmit: ({ email, password }: SinginRequest) => void;
  isLoading: boolean;
};

const toggleIcon = ({ reveal, size }: { reveal: boolean; size: number }) => {
  if (reveal) return <IconEyeOff size={size} />;

  return <IconEyeCheck size={size} />;
};

export const initialValues: SinginRequest = {
  email: '',
  password: '',
};

export const SignInForm: FC<Props> = ({ handleSubmit, isLoading }) => {
  const form = useForm({
    initialValues: initialValues,
    validate: yupResolver(signInFormSchema),
    validateInputOnBlur: true,
  });

  return (
    <Box mb={'1.25rem'}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box mb={'2.5rem'}>
          <TextInput
            required
            label="Email"
            aria-label="email"
            data-testid={EMAIL_INPUT}
            mb={'2rem'}
            {...form.getInputProps('email')}
          />

          <PasswordInput
            required
            label="Password"
            aria-label="password"
            data-testid="password-input"
            visibilityToggleIcon={toggleIcon}
            {...form.getInputProps('password')}
          />
        </Box>

        <Group>
          <Button
            disabled={!form.isValid()}
            loading={isLoading}
            type="submit"
            sx={{ width: '100%' }}
            aria-label="log in"
            data-testid="login-btn"
          >
            Log In
          </Button>
        </Group>
      </form>
    </Box>
  );
};
