import * as Yup from 'yup';

import {
  phoneNumberValidator,
  requiredStringValidationRule,
  zipCodeValidator,
} from './common';

const vendorLocationSchema = Yup.object().shape({
  same_contact_details: Yup.bool(),
  location_id: requiredStringValidationRule('Primary Location'),
  street_address: Yup.string()
    .notRequired()
    .when('same_contact_details', {
      is: false,
      then: () => requiredStringValidationRule('Street Address'),
    }),
  city: Yup.string()
    .notRequired()
    .when('same_contact_details', {
      is: false,
      then: () => requiredStringValidationRule('City'),
    }),

  state: Yup.string()
    .notRequired()
    .when('same_contact_details', {
      is: false,
      then: () => requiredStringValidationRule('State'),
    }),

  zip_code: Yup.string()
    .notRequired()
    .when('same_contact_details', {
      is: false,
      then: () => zipCodeValidator,
    }),
  phone_number: Yup.string()
    .notRequired()
    .when('same_contact_details', {
      is: false,
      then: () => phoneNumberValidator,
    }),
  primary_contact_name: Yup.string().nullable(),
  primary_contact_phone_number: Yup.string().nullable(),
  primary_contact_email: Yup.string().nullable(),
});

export const vendorSchema = Yup.object({
  company_name: requiredStringValidationRule('Company Name'),
  website: Yup.string()
    .url('Invalid Website.')
    .required('Please Enter Website'),
  street_address: requiredStringValidationRule('Street Address'),
  city: requiredStringValidationRule('City'),
  state: requiredStringValidationRule('State'),
  zip_code: zipCodeValidator,
  phone_number: phoneNumberValidator,
  primary_contact_name: Yup.string().nullable(),
  primary_contact_phone_number: Yup.string().nullable(),
  primary_contact_email: Yup.string()
    .email('Please enter valid email address')
    .notRequired(),
  comment: Yup.string().nullable(),
  is_location_specific: Yup.bool(),
  vendor_locations: Yup.array().when('is_location_specific', {
    is: true,
    then: () => Yup.array().of(vendorLocationSchema),
  }),
});
