import {
  Flex,
  Grid,
  LoadingOverlay,
  Pagination,
  Paper,
  Text,
} from '@mantine/core';
import ReactJson from 'react-json-view';
import { ActionButtons } from 'components';
import { useComplianceHistory } from 'hooks';

type Props = {
  orderId: string;
  goBack: () => void;
};

export const ComplianceHistory = ({ goBack, orderId }: Props) => {
  const { data, page, totalRecords, isLoading, setPage } = useComplianceHistory(
    {
      orderId,
    },
  );

  return (
    <Paper p="lg" mt="md" sx={{ position: 'relative' }}>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />

      <Grid gutter="xl">
        <Grid.Col>
          <Text size={18} weight={700} mb={25}>
            Compliance History
          </Text>
          <ReactJson
            displayDataTypes={false}
            displayObjectSize={false}
            src={data?.items[0] ?? {}}
            theme="rjv-default"
          />
          <Flex justify="flex-end" mt={25}>
            <Pagination page={page} onChange={setPage} total={totalRecords} />
          </Flex>
        </Grid.Col>

        <Grid.Col mt={10}>
          <ActionButtons
            variant="subtle"
            label="Back to Order"
            onClick={goBack}
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
