import {
  CreateCulinarySku,
  CulinarySkuDto,
  PaginatedResponse,
  CulinarySkusRequest,
} from 'shared';
import { PRODUCT_CULINARY } from '../../endpoints';
import request from '../../request';

export default class CulinarySkuService {
  static readonly getCollection = (params: CulinarySkusRequest) => {
    return request<PaginatedResponse<CulinarySkuDto>>({
      url: `${PRODUCT_CULINARY}`,
      params,
      method: 'get',
    });
  };

  static readonly create = (data: CreateCulinarySku) => {
    return request<CulinarySkuDto>({
      url: `${PRODUCT_CULINARY}`,
      method: 'post',
      data,
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<CulinarySkuDto>({
      url: `${PRODUCT_CULINARY}/${id}`,
      method: 'get',
    });
  };

  static readonly delete = ({ id }: { id?: string }) => {
    return request({
      url: `${PRODUCT_CULINARY}/${id}`,
      method: 'delete',
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id?: string;
    data: CreateCulinarySku;
  }) => {
    return request<CulinarySkuDto>({
      url: `${PRODUCT_CULINARY}/${id}`,
      method: 'put',
      data,
    });
  };
}
