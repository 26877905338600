import { Button, Container, Text } from '@mantine/core';
import { DeliveryMethod, RecalculateAndPayRequest } from 'shared';

export const BulkActionButtons = ({
  orderIds,
  loading,
  toggle,
  handleSubmit,
}: {
  orderIds: string[];
  loading: boolean;
  toggle: () => void;
  handleSubmit: (data: RecalculateAndPayRequest) => void;
}) => {
  return (
    <Container my="xl" size="sm">
      <Text align="center" mb={15}>
        By clicking the button below, you will change the delivery method from
        Pickup to Shipping, and process corresponding upcharge or refund.
      </Text>
      <Button
        mb={15}
        fullWidth
        loading={loading}
        disabled={!orderIds.length}
        onClick={() =>
          handleSubmit({
            delivery_method: DeliveryMethod.SHIPPING,
            order_ids: orderIds,
          })
        }
      >
        Update And Process Order
      </Button>
      <Button fullWidth variant="white" onClick={toggle}>
        Cancel
      </Button>
    </Container>
  );
};
