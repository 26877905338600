import { omit } from 'rambda';
import { useApiMutation } from 'hooks';
import { CulinaryMenuService } from 'App/api';
import { CreateCulinaryMenu } from 'shared';

export const useCreateMenu = ({ onExit }: { onExit: () => void }) => {
  const { mutate, isLoading } = useApiMutation(
    ['createMenuCulinary'],
    CulinaryMenuService.create,
    { onSuccess: onExit },
  );

  const handleSubmit = (value: CreateCulinaryMenu) => {
    const valueCulinaryMenu = omit(['location_id'], value);

    const payload = {
      ...valueCulinaryMenu,
      items: valueCulinaryMenu.items.map(omit(['key'])),
    };

    mutate(payload);
  };
  return { handleSubmit, isLoading };
};
