import { Flex, Grid, NumberInput, Select, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { MaskedInput } from 'components';
import { CreateDiscount, SelectOption, inventoryTypeOptions } from 'shared';

type Props = {
  categoryOptions: SelectOption[];
  subcategoryOptions: SelectOption[];
  form: UseFormReturnType<CreateDiscount>;
};

export const AdditionalSettings = ({
  form,
  categoryOptions,
  subcategoryOptions,
}: Props) => {
  const fullWidth = { width: '100%' };
  return (
    <Grid gutter="xl">
      <Grid.Col>
        <Text color="dark" weight={600} size="lg">
          Additional Settings
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <MaskedInput
          testId="startDate"
          sx={fullWidth}
          mask="99.99.9999"
          label="Start Date"
          placeholder="08.31.1989"
          maskChar={null}
          {...form.getInputProps('start_date')}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <MaskedInput
          testId="endDate"
          sx={fullWidth}
          mask="99.99.9999"
          label="End Date"
          placeholder="08.31.1989"
          maskChar={null}
          {...form.getInputProps('end_date')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Select
          label="Inventory Type"
          clearable
          sx={{ width: '50%', paddingRight: 12 }}
          rightSectionWidth={30}
          {...form.getInputProps('inventory_type')}
          data={inventoryTypeOptions}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          label="Applied to Category"
          clearable
          sx={fullWidth}
          rightSectionWidth={30}
          {...form.getInputProps('category_id')}
          onChange={(value) => {
            form.setFieldValue('subcategory_id', null);
            form.setFieldValue('category_id', value);
          }}
          data={categoryOptions}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          label="Applied for Sub-Category"
          clearable
          sx={fullWidth}
          disabled={!subcategoryOptions.length}
          rightSectionWidth={30}
          value={form.values.subcategory_id}
          {...form.getInputProps('subcategory_id')}
          data={subcategoryOptions}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Flex gap="xl">
          <NumberInput
            sx={fullWidth}
            hideControls
            min={0}
            label="Min Qty (Pc)"
            {...form.getInputProps('minimum_quantity')}
          />
          <NumberInput
            hideControls
            label="Min Total ($)"
            sx={fullWidth}
            precision={2}
            parser={(value) => value?.replace(/\$\s?|%\s?/g, '')}
            formatter={(value) => (value ? `$${value}` : '')}
            {...form.getInputProps('minimum_total')}
          />
        </Flex>
      </Grid.Col>
    </Grid>
  );
};
