import { randomId } from '@mantine/hooks';

export const getNewItem = () => ({
  key: randomId(),
  location: {
    id: '',
  },
  inventory_location: {
    id: '',
  },
});
