import {
  ActionIcon,
  Button,
  NumberInput,
  Switch,
  Text,
  TextInput,
  Grid,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { CreateCulinarySku, SWITCH_GRID_TOP_OFFSET } from 'shared';
import { getNewVariant } from '../helpers';
import { VariantItem } from './VariantItem';

type Props = {
  form: UseFormReturnType<CreateCulinarySku>;
  index: number;
};

export const ModifierItem = ({ form, index }: Props) => {
  const variants = form.values.specific_information?.items?.[index].variants;
  const item = `specific_information.items.${index}`;
  const fullWidth = { width: '100%' };

  const hasVariants = !!variants?.length;

  return (
    <Grid gutter={'xl'}>
      <Grid.Col span={6}>
        <TextInput
          required
          sx={fullWidth}
          label="Modifier Name"
          {...form.getInputProps(`${item}.name`)}
        />
      </Grid.Col>

      <Grid.Col span={6} pt={SWITCH_GRID_TOP_OFFSET}>
        <Grid gutter={'xl'}>
          <Grid.Col span={4}>
            <Switch
              label="Hide from POS"
              labelPosition="right"
              sx={{ fontWeight: 600 }}
              {...form.getInputProps(`${item}.hide_from_pos`, {
                type: 'checkbox',
              })}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Switch
              label="Mandatory"
              labelPosition="right"
              sx={{ fontWeight: 600 }}
              {...form.getInputProps(`${item}.mandatory`, { type: 'checkbox' })}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Switch
              label="Variants"
              labelPosition="right"
              sx={{ fontWeight: 600 }}
              {...form.getInputProps(`${item}.is_variants`, {
                type: 'checkbox',
              })}
              onChange={() => {
                if (!variants?.length) {
                  form.setFieldValue(`${item}.is_variants`, true);
                  form.setFieldValue(`${item}.variants`, [
                    getNewVariant(),
                    getNewVariant(),
                  ]);
                } else {
                  form.setFieldValue(`${item}.is_variants`, false);
                  form.setFieldValue(`${item}.variants`, []);
                }
              }}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>

      {hasVariants ? (
        <>
          {variants.map((variant, i) => (
            <Grid.Col span={12} key={variant.key || variant.id}>
              <VariantItem form={form} index={i} modifierIndex={index} />
            </Grid.Col>
          ))}

          <Grid.Col span={12}>
            <Button
              variant="subtle"
              onClick={() =>
                form.insertListItem(`${item}.variants`, getNewVariant())
              }
            >
              <IconPlus />
              <Text weight={600} size="sm" ml={10}>
                Add Variant
              </Text>
            </Button>
          </Grid.Col>
        </>
      ) : (
        <Grid.Col span={6}>
          <Grid gutter={'xl'} align="center">
            <Grid.Col span={5}>
              <NumberInput
                hideControls
                precision={2}
                sx={fullWidth}
                label="Price ($) (Empty for free)"
                {...form.getInputProps(`${item}.price`)}
              />
            </Grid.Col>
            <Grid.Col span={5}>
              <NumberInput
                sx={fullWidth}
                hideControls
                label="Max Qty"
                {...form.getInputProps(`${item}.max_quantity`)}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <ActionIcon
                mt="xl"
                ml={'auto'}
                onClick={() =>
                  form.removeListItem('specific_information.items', index)
                }
              >
                <IconTrash size={24} stroke={1} />
              </ActionIcon>
            </Grid.Col>
            <Grid.Col span={4}>
              <Switch
                label="On the Side"
                labelPosition="right"
                sx={{ fontWeight: 600 }}
                {...form.getInputProps(`${item}.on_the_side`, {
                  type: 'checkbox',
                })}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Switch
                label="Lite"
                labelPosition="right"
                sx={{ fontWeight: 600 }}
                {...form.getInputProps(`${item}.lite`, { type: 'checkbox' })}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Switch
                label="Exclude"
                labelPosition="right"
                sx={{ fontWeight: 600 }}
                {...form.getInputProps(`${item}.exclude`, { type: 'checkbox' })}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      )}
    </Grid>
  );
};
