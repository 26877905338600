import { useNavigate, useParams } from 'react-router-dom';
import { Container, LoadingOverlay, Text } from '@mantine/core';

import { ConfirmModal, ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useEditVendors } from '../../hooks';
import { LOADING_OVERLAY } from 'shared';

type PropType = {
  canDelete: boolean;
  onExit: () => void;
  onCreate?: (id: string) => void;
};

export const Edit = ({
  canDelete,
  onExit,
  onCreate,
}: PropType): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const {
    initialValue,
    isLoading,
    isDeleting,
    deleteVendor,
    isUpdating,
    handleSubmit,
  } = useEditVendors({
    id,
    onDelete: onExit,
    onCreate,
  });

  if (isLoading)
    return (
      <LoadingOverlay
        data-testid={LOADING_OVERLAY}
        visible={isLoading}
        overlayBlur={2}
      />
    );

  const goBack = () => navigate(-1);

  return (
    <ContentLayout
      title="Edit Vendor"
      leftAction={<BackButton onClick={goBack} />}
    >
      <Container size={'sm'} sx={{ position: 'relative' }}>
        <Form
          handleSubmit={handleSubmit}
          initialValues={initialValue}
          processing={isUpdating}
          action={
            canDelete ? (
              <ConfirmModal
                type="button"
                variant="outline"
                fullWidth
                loading={isDeleting}
                onConfirm={() => deleteVendor(id)}
                label="Delete"
              >
                <Text data-testid="delete-vendor-btn">
                  Are you sure you want to delete the vendor?
                </Text>
              </ConfirmModal>
            ) : null
          }
        />
      </Container>
    </ContentLayout>
  );
};
