import { ReactNode } from 'react';
import { Container, Box, Button, ButtonProps } from '@mantine/core';

interface Props extends ButtonProps {
  secondary?: ReactNode;
  label: string;
  marginTop?: number;
  onClick?: () => void;
}

export const ActionButtons = ({
  loading = false,
  disabled = false,
  type = 'submit',
  label,
  secondary,
  marginTop = 0,
  ...rest
}: Props) => {
  return (
    <Container size="xs" px={0} mt={marginTop}>
      <Button
        data-testid={`${label.replace(/\s+/g, '-').toLowerCase()}-btn`}
        {...rest}
        loading={loading}
        disabled={disabled}
        type={type}
        fullWidth
      >
        {label}
      </Button>
      {secondary && <Box mt="lg">{secondary}</Box>}
    </Container>
  );
};
