import { useNavigate } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { getViewPath, Permissions } from 'shared';
import { Create, Details, Edit, List } from './components';
import { NestedRouteComponent } from 'components';

export const Vendors = (): JSX.Element => {
  const navigate = useNavigate();
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.Vendors,
  });
  const canCreate = checkAccess(Permissions.admin_panel_vendor_create);
  const canEdit = checkAccess(Permissions.admin_panel_vendor_edit);
  const canDelete = checkAccess(Permissions.admin_panel_vendor_delete);

  const onExit = useGotoRoute(RouteName.Vendors);
  const onCreate = (id: string) => navigate(getViewPath(rootPath, id));

  const ListComponent = <List canCreate={canCreate} rootPath={rootPath} />;
  const CreateComponent = <Create onCreate={onCreate} rootPath={rootPath} />;
  const EditComponent = (
    <Edit canDelete={canDelete} onExit={onExit} onCreate={onCreate} />
  );
  const DetailsComponent = <Details canEdit={canEdit} rootPath={rootPath} />;

  return (
    <NestedRouteComponent
      showEdit={canEdit}
      showCreate={canCreate}
      rootPath={rootPath}
      ListComponent={ListComponent}
      Create={CreateComponent}
      Edit={EditComponent}
      Details={DetailsComponent}
    />
  );
};
