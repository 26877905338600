import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useCallback } from 'react';
import { RoleService } from 'App/api';
import { useApiQuery } from './useApiQuery';
import { getSelectOptions } from 'shared';

export const useRoles = (location: string | undefined) => {
  const { data, isLoading } = useApiQuery(
    ['roles', location],
    () => RoleService.getAll(location as string),
    { enabled: !!location },
  );

  const client = useQueryClient();

  const refetch = () => {
    client.invalidateQueries(['roles', location]);
  };

  const options = useMemo(() => getSelectOptions(data?.items ?? []), [data]);

  const findRoleById = useCallback(
    (id: string) => (data?.items ?? []).find((r) => r.id === id),
    [data],
  );

  return {
    roles: data?.items ?? [],
    options,
    refetch,
    isLoading,
    findRoleById,
  };
};
