import { ReactNode } from 'react';
import { Text, Grid, Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { CopyPaste } from '..';
import { SuggestionsDto, Suggestion } from 'shared';
import { Product } from './Product';

type PropType = {
  children: ReactNode;
  tierId: string;
  form: UseFormReturnType<SuggestionsDto>;
  copy: Suggestion[] | null;
  setCopy: (payload: Suggestion[]) => void;
  paste: ({
    currentItems,
  }: {
    currentItems: Suggestion[];
  }) => { key: string; sku_id?: string }[];
};

export const AddProduct = ({
  children,
  form,
  tierId,
  copy,
  setCopy,
  paste,
}: PropType) => {
  const suggestions = form.values.suggestions;
  const currentItems = suggestions?.filter((i) => i.club_tier_id === tierId);

  const canRemove = currentItems?.length > 1;
  const skuIds = currentItems?.map(({ sku_id }) => sku_id);

  return (
    <Box mt="xl">
      <Grid gutter="xl">
        <Grid.Col span={7}>
          <Text size={14} weight={600}>
            Product Name
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text size={14} weight={600}>
            Max Qty
          </Text>
        </Grid.Col>
      </Grid>

      <Box>
        {currentItems.map(
          ({ key, max_quantity, sku_product_name, sku_number, sku_id }) => (
            <Product
              key={key}
              id={key}
              form={form}
              skuIds={skuIds}
              skuId={sku_id}
              skuNumber={sku_number}
              skuProductName={sku_product_name}
              maxQuantity={max_quantity}
              canRemove={canRemove}
            />
          ),
        )}
      </Box>
      {children}
      <CopyPaste<Suggestion>
        copy={copy}
        setCopy={setCopy}
        currentItems={currentItems}
        onPaste={() => {
          const copyState = paste({ currentItems });
          const products = copyState.map((i) => ({
            ...i,
            max_quantity: 0,
            club_tier_id: tierId,
          }));

          form.setFieldValue('suggestions', [...suggestions, ...products]);
        }}
      />
    </Box>
  );
};
