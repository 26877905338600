import { useCallback } from 'react';

import { useApiMutation } from 'hooks';
import { CategorySkuDto } from 'shared';
import { ProductCategoryService } from 'App/api';

export const useCategorySKUs = ({
  onUpdate,
  categoryId,
  onReorder,
}: {
  onUpdate: () => void;
  onReorder: () => void;
  categoryId: string;
}) => {
  const { mutate: add, isLoading: adding } = useApiMutation(
    ['add-skus'],
    ProductCategoryService.addSKUs,
    {
      onSuccess: onUpdate,
    },
  );

  const { mutate: removeProduct, isLoading: removing } = useApiMutation(
    ['remove-sku'],
    (sku_id: string) =>
      ProductCategoryService.removeSKU({ category_id: categoryId, sku_id }),
    {
      onSuccess: onUpdate,
    },
  );

  const { mutate: reorder, isLoading: reordering } = useApiMutation(
    ['reorder-skus'],
    ProductCategoryService.reorderSKUs,
    {
      onSuccess: () => {
        onUpdate();
        onReorder();
      },
    },
  );

  const addProducts = useCallback(
    (skus: string[]) => {
      add({ category_id: categoryId, skus });
    },
    [add, categoryId],
  );

  const reorderProducts = useCallback(
    (skus: CategorySkuDto[]) => {
      reorder({ category_id: categoryId, skus: skus.map(({ id }) => id) });
    },
    [categoryId, reorder],
  );

  return {
    isLoading: adding || removing || reordering,
    addProducts,
    removeProduct,
    reorderProducts,
  };
};
