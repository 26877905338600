import { Box, Flex, Text } from '@mantine/core';
import { BOTTLE_SIZE, VARIETAL } from 'pages/products/skus/constants';
import { getLabel, ProductDto, taxRateLabels } from 'shared';

export const SpecificInformation = ({
  specificInformation,
  btg,
}: {
  specificInformation?: ProductDto['specific_information'];
  btg?: ProductDto['btg'];
}) => {
  const fullWidth = { width: '100%' };
  const gap = 50;

  return (
    <Box sx={{ marginBottom: 63 }}>
      <Text weight={600} size="lg" mb={20}>
        Specific Information
      </Text>
      <Flex align="center" mb="lg" gap={gap}>
        <Box sx={fullWidth}>
          <Text weight={600} size="sm">
            ABV
          </Text>
          <Text size="sm" color="gray.5">
            {specificInformation?.abv ?? 'Not Assigned'}
          </Text>
        </Box>
        <Box sx={fullWidth}>
          <Text weight={600} size="sm">
            Bottle Size
          </Text>
          <Text size="sm" color="gray.5">
            {getLabel(BOTTLE_SIZE, specificInformation?.bottle_size) ??
              'Not Assigned'}
          </Text>
        </Box>
      </Flex>
      <Flex align="center" mb="lg" gap={gap}>
        <Box sx={fullWidth}>
          <Text weight={600} size="sm">
            Varietal
          </Text>
          <Text size="sm" color="gray.5">
            {getLabel(VARIETAL, specificInformation?.varietal) ??
              'Not Assigned'}
          </Text>
        </Box>
        <Box sx={fullWidth}>
          <Text weight={600} size="sm">
            Glasses per Bottle
          </Text>
          <Text size="sm" color="gray.5">
            {specificInformation?.glasses_per_bottle ?? 'Not Assigned'}
          </Text>
        </Box>
      </Flex>
      <Flex align="center" mb="lg">
        <Text weight={600} size="sm">
          {specificInformation?.has_sample && 'Sample'}
        </Text>
      </Flex>

      {btg ? (
        <>
          <Flex align="center" mb="lg" gap={gap}>
            <Box sx={fullWidth}>
              <Text weight={600} size="sm">
                {specificInformation?.is_available_by_glass &&
                  'BTG (available by the glass)'}
              </Text>
            </Box>
            <Box sx={fullWidth}>
              <Text weight={600} size="sm">
                {specificInformation?.is_available_only_by_glass &&
                  'BTG only (available only by the glass only)'}
              </Text>
            </Box>
          </Flex>

          <Flex align="center" mb="lg" gap={gap}>
            <Box sx={fullWidth}>
              <Text weight={600} size="sm">
                SKU
              </Text>
              <Text size="sm" color="gray.5">
                {btg.btg_sku_number}
              </Text>
            </Box>
            <Box sx={fullWidth}>
              <Text weight={600} size="sm">
                Tax Rate
              </Text>
              <Text size="sm" color="gray.5">
                {btg.btg_tax_rate
                  ? taxRateLabels[btg.btg_tax_rate]
                  : 'Not Assigned'}
              </Text>
            </Box>
          </Flex>

          <Flex align="start" mb="lg" gap={gap}>
            <Flex sx={fullWidth}>
              <Box sx={fullWidth}>
                <Text weight={600} size="sm">
                  Cost
                </Text>
                <Text size="sm" color="gray.5">
                  {btg.btg_cost ? `$${btg.btg_cost}` : 'Not Assigned'}
                </Text>
              </Box>
              <Box sx={fullWidth}>
                <Text weight={600} size="sm">
                  Price
                </Text>
                <Text size="sm" color="gray.5">
                  {btg.btg_price ?? 'Not Assigned'}
                </Text>
              </Box>
            </Flex>

            <Flex sx={fullWidth} justify="space-between">
              <Text size="sm">Calculated Margin</Text>
              <Text size="sm" color="gray.5">
                {btg.btg_margin ? `${btg.btg_margin}%` : 'N/A'}
              </Text>
            </Flex>
          </Flex>
        </>
      ) : null}
    </Box>
  );
};
