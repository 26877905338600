import { getEmptyOption, InventoryTransferStatus } from 'shared';

export const STATUS_OPTIONS = [
  getEmptyOption('All'),
  { label: 'Transit', value: InventoryTransferStatus.transit },
  { label: 'Canceled', value: InventoryTransferStatus.canceled },
  { label: 'Fully Received', value: InventoryTransferStatus.fully_received },
  {
    label: 'Partially Received',
    value: InventoryTransferStatus.partially_received,
  },
];

export const DATA_TABLE_COLUMS = [
  { width: 100, accessor: 'number_str', title: 'Transfer #' },
  { width: 120, accessor: 'orderDate', title: 'Order Date' },
  { width: 250, accessor: 'originName', title: 'Origin' },
  { width: 250, accessor: 'destinationName', title: 'Destination' },
  { accessor: 'dateReceived', title: 'Date Received' },
  { accessor: 'status', title: 'Status' },
];
