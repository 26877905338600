import { Flex, Skeleton } from '@mantine/core';

export const SummarySkeleton = () => {
  return (
    <Flex direction="column" align="end" mb={2} gap={9} my={8}>
      <Skeleton height={15} width="18%" />
      <Skeleton height={15} width="24%" />
      <Skeleton height={15} width="14%" />
      <Skeleton height={15} width="15%" />
      <Skeleton height={15} width="21%" />
      <Skeleton height={15} width="21%" />
      <Skeleton height={15} width="16%" />
      <Skeleton height={15} width="15%" />
    </Flex>
  );
};
