import { Container, LoadingOverlay, Text } from '@mantine/core';
import { OrdersHistory, OrderUpcomingShipment } from './components';
import { useCustomerOrders } from './hooks';

export const EditOrders = ({ customerId }: { customerId: string }) => {
  const {
    page,
    total,
    orderItems,
    ordersHistory,
    isLoading,
    isLoadingUpcomingShipment,
    setPage,
  } = useCustomerOrders({
    id: customerId,
  });

  const hasOrderItems = !!orderItems.length;

  return (
    <Container size="xl">
      <LoadingOverlay
        visible={isLoading || isLoadingUpcomingShipment}
        overlayBlur={2}
      />

      {hasOrderItems && (
        <>
          <Text size="lg" weight={700}>
            Upcoming Shipment
          </Text>
          {orderItems.map((item) => (
            <OrderUpcomingShipment key={item.id} {...item} />
          ))}
        </>
      )}

      <OrdersHistory
        ordersHistory={ordersHistory}
        page={page}
        total={total}
        setPage={setPage}
      />
    </Container>
  );
};
