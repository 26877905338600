import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { EmployeeFormValues, getViewPath } from 'shared';
import { ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useCreateEmployee } from '../../hooks';

export const initialValues: EmployeeFormValues = {
  first_name: '',
  last_name: '',
  email: '',
  start_date: new Date(),
  primary_location_id: '',
  role_id: '',
  passcode: '',
  hasPosAccess: false,
  can_be_approver: false,
};

export const Create = ({
  rootPath,
  onExit,
}: {
  rootPath: string;
  onExit: () => void;
}): JSX.Element => {
  const navigate = useNavigate();
  const onSuccess = useCallback(
    (id = '') => {
      navigate(getViewPath(rootPath, id));
    },
    [navigate, rootPath],
  );

  const { handleSubmit, isLoading } = useCreateEmployee(onSuccess);

  return (
    <ContentLayout
      title="New Employee"
      processing={isLoading}
      leftAction={<BackButton onClick={onExit} />}
    >
      <Form handleSubmit={handleSubmit} initialValues={initialValues} />
    </ContentLayout>
  );
};
