import { Container, Text, Box, Flex } from '@mantine/core';
import { IconCheck, IconMinus } from '@tabler/icons-react';
import { useParams, Link } from 'react-router-dom';

import { BackButton, ContentLayout, EditButton } from 'components';
import { getEditPath } from 'shared';
import { useInventoryLocation } from '../../hooks';

export const Details = ({
  onExit,
  rootPath,
  canEdit,
}: {
  onExit: () => void;
  canEdit: boolean;
  rootPath: string;
}): JSX.Element => {
  const { id = '' } = useParams();
  const { data, isLoading, isError } = useInventoryLocation(id);

  if (isError) {
    onExit();
  }

  return (
    <ContentLayout
      title={data?.name ?? ''}
      isLoading={isLoading}
      leftAction={<BackButton onClick={onExit} />}
      rightAction={
        canEdit && (
          <EditButton component={Link} to={getEditPath(rootPath, id)} />
        )
      }
    >
      <Container size={'xs'}>
        <Flex justify={'center'}>
          <Box>
            <Flex align={'center'}>
              <Text weight={600}>Show in POS</Text>
              <Box ml="xl">
                {data?.show_in_pos ? (
                  <IconCheck stroke={1} />
                ) : (
                  <IconMinus stroke={1} />
                )}
              </Box>
            </Flex>

            <Box mt="lg">
              <Text weight={600}>Parent Location</Text>
              <Text weight={600}>{data?.location.name}</Text>
            </Box>
          </Box>
        </Flex>
      </Container>
    </ContentLayout>
  );
};
