import { useNavigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import { ContentLayout, CreateButton } from 'components';
import { getCreatePath, getViewPath, PROPS_DATA_TABLE } from 'shared';
import { Filters } from '../filters';
import { useVendors } from '../../hooks';

export const List = ({
  canCreate,
  rootPath,
}: {
  canCreate: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const {
    output,
    isLoading,
    page,
    filters,
    totalRecords,
    updateFilters,
    handleSetSearchParams,
  } = useVendors();

  return (
    <ContentLayout
      title="Vendors"
      rightAction={
        canCreate && (
          <CreateButton
            data-testid="new-vendor-btn"
            to={getCreatePath(rootPath)}
          >
            + New Vendor
          </CreateButton>
        )
      }
      processing={isLoading}
    >
      <Box mb="md">
        <Filters handleChange={updateFilters} values={filters} />
      </Box>
      <DataTable
        {...PROPS_DATA_TABLE}
        page={page}
        records={output}
        totalRecords={totalRecords}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        onRowClick={({ id }) => navigate(getViewPath(rootPath, id))}
        columns={[
          { accessor: 'company_name', title: 'Company Name' },
          { accessor: 'phone_number', title: 'Phone Number' },
          { accessor: 'primary_contact_name', title: 'Primary Contact' },
          {
            accessor: 'location',
            title: 'Location',
            render: ({ vendor_locations }) =>
              vendor_locations
                .map(({ location }) => location?.name ?? '')
                .join(', '),
          },
          { accessor: 'comment', title: 'Comment' },
        ]}
      />
    </ContentLayout>
  );
};
