import { UseFormReturnType } from '@mantine/form';

import { SuggestionSection } from './components';
import { ClubTierDto, SuggestionsDto } from 'shared';

export type Props = {
  form: UseFormReturnType<SuggestionsDto>;
  tiers?: ClubTierDto[];
};

export const SuggestionsForm = ({ form, tiers }: Props) => {
  return <SuggestionSection tiers={tiers} form={form} />;
};
