import { useMemo } from 'react';
import { InventoryService } from 'App/api';

import { useApiQuery } from 'hooks';
import { getSelectOptions, SelectOption } from 'shared';

const KEY = 'inventory-location';

type Params = {
  locationId?: string;
  inventoryLocationId?: string;
  groupByPool?: boolean;
  showInPos?: boolean;
};

export type SkuData = {
  id: string;
  quantity: number;
  quantity_lib: number;
  name: string;
};

type ReturnValue = {
  skusData: SkuData[];
  inventoryLocationOptions: SelectOption[];
  isLoading: boolean;
  fetchingSkus: boolean;
};

export const useInventorySkus = ({
  locationId: location_id,
  inventoryLocationId,
  groupByPool = false,
  showInPos: show_in_pos,
}: Params): ReturnValue => {
  const { data, isLoading } = useApiQuery(
    [KEY, location_id],
    () => InventoryService.getLocations({ location_id, show_in_pos }),
    {
      enabled: !!location_id,
    },
  );

  const { data: skus, isLoading: fetchingSkus } = useApiQuery(
    ['inventory-skus', inventoryLocationId],
    () =>
      InventoryService.getSkus({
        id: inventoryLocationId as string,
        groupByPool,
      }),
    {
      enabled: !!inventoryLocationId,
    },
  );

  const inventoryLocationOptions = useMemo(() => {
    if (!data) return [];

    return getSelectOptions(data.items);
  }, [data]);

  const skusData = useMemo(() => {
    if (!skus) return [];

    return skus.items.map(({ id, product_name, sku_number, quantities }) => ({
      id,
      quantity: quantities.default,
      quantity_lib: quantities.library,
      name: `[${sku_number}] ${product_name}`,
    }));
  }, [skus]);

  return {
    inventoryLocationOptions,
    skusData,
    isLoading,
    fetchingSkus,
  };
};
