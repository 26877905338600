import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { Permissions } from 'shared';
import { List, Create /* Details, Edit */ } from './components';
import { NestedRouteComponent } from 'components';

export const Discounts = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.Discounts,
  });

  const canCreate = checkAccess(Permissions.admin_panel_discounts_create);
  const canEdit = checkAccess(Permissions.admin_panel_discounts_edit);

  const onExit = useGotoRoute(RouteName.Discounts);

  const CreateComponent = <Create onExit={onExit} rootPath={rootPath} />;
  const ListComponent = <List canCreate={canCreate} rootPath={rootPath} />;

  return (
    <NestedRouteComponent
      showEdit={canEdit}
      showCreate={canCreate}
      ListComponent={ListComponent}
      Create={CreateComponent}
      rootPath={rootPath}
    />
  );
};
