import { keys } from 'rambda';

import {
  accountGroupLabels,
  adjustmentTypeLabels,
  inventoryTypeLabels,
  partnerTypeLabels,
  salesOrderStatusLabels,
  taxRateLabels,
  supplyTypeLabels,
  SkuDto,
  salesOrderChannelLabels,
  orderPaymentStatusLabels,
} from 'shared/types';

export function getSelectOptions<
  T extends { name: string; id: string; description?: string },
>(data: T[]) {
  return data.map(({ id, name, description }) => ({
    value: id,
    label: name,
    description,
  }));
}

export const getEmptyOption = (label: string, value = '') => ({ label, value });

export const partnerTypeOptions = keys(partnerTypeLabels).map((k) => ({
  value: k,
  label: partnerTypeLabels[k],
}));

export const adjustmentTypeOptions = keys(adjustmentTypeLabels).map((k) => ({
  value: k,
  label: adjustmentTypeLabels[k],
}));

export const inventoryTypeOptions = keys(inventoryTypeLabels).map((k) => ({
  value: k,
  label: inventoryTypeLabels[k],
}));

export const taxRateOptions = keys(taxRateLabels).map((k) => ({
  value: k,
  label: taxRateLabels[k],
}));

export const accountGroupOptions = keys(accountGroupLabels).map((k) => ({
  value: k,
  label: accountGroupLabels[k],
}));

export const salesOrderStatusOptions = keys(salesOrderStatusLabels).map(
  (k) => ({
    value: k,
    label: salesOrderStatusLabels[k],
  }),
);

export const orderPaymentStatusOptions = keys(orderPaymentStatusLabels).map(
  (k) => ({
    value: k,
    label: orderPaymentStatusLabels[k],
  }),
);

export const channelOrderOptions = keys(salesOrderChannelLabels).map((k) => ({
  value: k,
  label: salesOrderChannelLabels[k],
}));

export const supplyTypeOptions = (isPickUp?: boolean) =>
  keys(supplyTypeLabels)
    .map((k) => ({
      value: k,
      label: supplyTypeLabels[k],
    }))
    .filter((i) =>
      isPickUp
        ? i.label === supplyTypeLabels.pickup
        : i.label !== supplyTypeLabels.pickup,
    );

export const getSkusOptions = (skusItem: SkuDto[] | undefined) => {
  if (!skusItem) return [];

  return skusItem.map(({ id, product_name, sku_number, comment }) => ({
    id,
    name: `[${sku_number}] ${product_name}`,
    description: comment ?? '',
  }));
};
