import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { has, isEmpty, reduce, reject } from 'rambda';

type Params = {
  [key: string]: string | string[];
};

export const useQueryParams = (params: Params) => {
  const [searchParams, setSearchParams] = useSearchParams(params);

  const entries = Array.from(searchParams.entries());

  const currentParams = reduce(
    (acc: Params, [key, value]) => {
      if (has(key, acc)) {
        if (Array.isArray(acc[key])) {
          (acc[key] as string[]).push(value);
        } else {
          acc[key] = [acc[key] as string, value];
        }
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {},
    entries,
  );

  const handleSetSearchParams = useCallback(
    (value: { [key: string]: string[] | string }) => {
      setSearchParams({ ...reject(isEmpty, { ...currentParams, ...value }) });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchParams],
  );

  return {
    searchParams,
    handleSetSearchParams,
  };
};
