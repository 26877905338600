import { useMemo, useCallback } from 'react';
import { useApiMutation, useToast, useFileUpload } from 'hooks';
import {
  ProductCategoryFormValues,
  ProductCategoryDto,
  ProductCategoryFormImages,
} from 'shared';
import { ProductCategoryService } from 'App/api';
import {
  getFormData,
  getCategoryPayload,
} from 'pages/products/categories/helpers';

const updateCategoryKey = 'updateCategory';
const deleteCategoryKey = 'deleteCategory';

export const useEditCategory = ({
  onDelete,
  data,
}: {
  data: ProductCategoryDto;
  onDelete: () => void;
}): {
  handleSubmit: (
    values: ProductCategoryFormValues,
    files: ProductCategoryFormImages,
  ) => void;
  handleDelete: () => void;
  processing: boolean;
  formData: ProductCategoryFormValues;
} => {
  const { handleFileUpload, isLoading, isUpserting } = useFileUpload();
  const formData = useMemo(() => getFormData(data), [data]);
  const addToast = useToast();

  const { mutate: updateCategory, isLoading: updating } = useApiMutation(
    [updateCategoryKey],
    ProductCategoryService.update,
    {
      onSuccess: () =>
        addToast.success('You have successfully updated the category'),
    },
  );

  const { mutate: deleteCategory, isLoading: deleting } = useApiMutation(
    [deleteCategoryKey],
    ProductCategoryService.delete,
    {
      onSuccess: onDelete,
    },
  );

  const handleSubmit = useCallback(
    async (
      values: ProductCategoryFormValues,
      files: ProductCategoryFormImages,
    ) => {
      const webImageUrl = await handleFileUpload(
        values.web_image_url,
        files.webImage,
      );
      const mobileImageUrl = await handleFileUpload(
        values.mobile_image_url,
        files.mobileImage,
      );
      updateCategory({
        id: data.id,
        data: {
          ...getCategoryPayload(values),
          web_image_url: webImageUrl,
          mobile_image_url: mobileImageUrl,
        },
      });
    },
    [data.id, handleFileUpload, updateCategory],
  );

  const handleDelete = () => deleteCategory({ id: data.id });

  return {
    handleSubmit,
    handleDelete,
    processing: updating || deleting || isLoading || isUpserting,
    formData,
  };
};
