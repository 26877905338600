import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { List, Edit } from './components';
import { NestedRouteComponent } from 'components';

export const PosOrders = () => {
  const rootPath = generateRoutePath({
    name: RouteName.PosOrders,
  });

  const onExit = useGotoRoute(RouteName.PosOrders);

  const ListComponent = <List rootPath={rootPath} />;
  const EditComponent = <Edit onExit={onExit} />;

  return (
    <NestedRouteComponent
      ListComponent={ListComponent}
      rootPath={rootPath}
      showEdit={true}
      Edit={EditComponent}
    />
  );
};
