import { Container, Grid, Select, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FREIGHT_COMPANY, FREIGHT_METHOD } from '../../constants';
import { ShippingDetails } from 'shared';

export type Props = {
  form: UseFormReturnType<ShippingDetails>;
};

export const ShippingDetailsForm = ({ form }: Props) => {
  return (
    <Container size="xl">
      <form>
        <Grid gutter="xl">
          <Grid.Col span={12}>
            <Text weight={700} size="lg" mt={24}>
              Shipment Details
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              data={FREIGHT_COMPANY}
              label="Freight Company"
              {...form.getInputProps('freight_company')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              data={FREIGHT_METHOD}
              label="Freight Method"
              {...form.getInputProps('freight_method')}
            />
          </Grid.Col>
        </Grid>
      </form>
    </Container>
  );
};
