import { useMemo, useState } from 'react';
import { isNil } from 'rambda';

import { InventoryService } from 'App/api';
import { useLocations } from 'App/contexts';
import { useApiQuery } from 'hooks';
import { getSelectOptions } from 'shared';

export const useInventoryLocations = (
  locationId = '',
  show_in_pos?: boolean,
) => {
  const [primaryLocation, setPrimaryLocation] = useState<string | undefined>(
    locationId || undefined,
  );

  const { actualLocationOptions: primaryLocationOptions } = useLocations();

  const { data, isLoading } = useApiQuery(
    ['inventory-locations', primaryLocation],
    () =>
      InventoryService.getLocations({
        ...(primaryLocation ? { location_id: primaryLocation } : {}),
        ...(isNil(show_in_pos) ? {} : { show_in_pos }),
      }),
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map(({ location, ...rest }) => ({
      ...rest,
      location: location.name,
    }));
  }, [data]);

  const inventoryLocationOptions = useMemo(
    () => getSelectOptions(output),
    [output],
  );

  return {
    output,
    isLoading,
    primaryLocation,
    setPrimaryLocation,
    primaryLocationOptions,
    inventoryLocationOptions,
  };
};
