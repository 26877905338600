import request from '../request';
import {
  PaginatedResponse,
  RefundPayload,
  SalesOrderSource,
  SendReceiptPayload,
} from 'shared';
import { SALES_ORDERS } from '../endpoints';

export default class OrdersService {
  static readonly sendReceipt = ({
    id,
    data,
  }: {
    id?: string;
    data: SendReceiptPayload;
  }) => {
    return request({
      url: `${SALES_ORDERS}/${id}/send-receipt`,
      method: 'post',
      data,
    });
  };

  static readonly regenerateReceipt = ({ id }: { id?: string }) => {
    return request({
      url: `${SALES_ORDERS}/${id}/regenerate-receipt`,
      method: 'post',
    });
  };

  static readonly getChangeHistory = ({
    params,
  }: {
    params: { page: number; sales_order_id: string };
  }) => {
    return request<PaginatedResponse<unknown>>({
      url: `history-of-changes/${SALES_ORDERS}/${params.sales_order_id}`,
      method: 'get',
      params,
    });
  };

  static readonly refund = ({
    id,
    data,
  }: {
    id?: string;
    data: RefundPayload;
  }) => {
    return request({
      url: `${SALES_ORDERS}/${id}/refund-for-all-order-types`,
      method: 'post',
      data: { ...data, source: SalesOrderSource.ALICE },
    });
  };
}
