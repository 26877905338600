import { useNavigate } from 'react-router-dom';
import { Box, Tabs, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import { ContentLayout, CreateButton } from 'components';
import { getCreatePath, getViewPath, PROPS_DATA_TABLE } from 'shared';
import { useInventoryLibrary } from '../../hooks';
import { Filters } from 'components/filters';
import { STATUS_OPTIONS, TABLE_COLUMS } from '../../constants';
import { useState } from 'react';

export const List = ({
  rootPath,
  canCreate,
}: {
  rootPath: string;
  canCreate?: boolean;
}): JSX.Element => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string | null>('library');
  const {
    page,
    filters,
    totalRecords,
    output,
    isLoading,
    updateFilters,
    handleSetSearchParams,
  } = useInventoryLibrary();

  return (
    <ContentLayout title="Inventory Allocation" processing={isLoading}>
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List
          position="center"
          sx={{ position: 'relative', borderBottom: 0 }}
        >
          <Tabs.Tab px={12} value="library">
            <Text size="md">Library Reserve</Text>
          </Tabs.Tab>
          {canCreate && (
            <CreateButton
              sx={{ position: 'absolute', right: 0, bottom: 0 }}
              to={getCreatePath(rootPath)}
            >
              + Library Reserve
            </CreateButton>
          )}
        </Tabs.List>

        <Tabs.Panel value="library" pt={32}>
          <Box mb="md">
            <Filters
              statusLabel="Status"
              searchLabel="Search"
              statusOptions={STATUS_OPTIONS}
              handleChange={updateFilters}
              values={filters}
            />
          </Box>
          <DataTable
            {...PROPS_DATA_TABLE}
            records={output}
            page={page}
            onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
            totalRecords={totalRecords}
            onRowClick={({ id }) => navigate(getViewPath(rootPath, id))}
            columns={TABLE_COLUMS}
          />
        </Tabs.Panel>
      </Tabs>
    </ContentLayout>
  );
};
