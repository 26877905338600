import { MantineNumberSize, Text } from '@mantine/core';
import { DataTableVerticalAlignment } from 'mantine-datatable';
import { DEFAULT_PAGE_SIZE } from './common';

type TDataTable = {
  highlightOnHover: boolean;
  noRecordsText: string;
  emptyState: JSX.Element;
  fontSize?: MantineNumberSize;
  verticalAlignment?: DataTableVerticalAlignment;
  verticalSpacing?: MantineNumberSize;
  minHeight?: number;
  recordsPerPage: number;
};

export const PROPS_DATA_TABLE: TDataTable = {
  highlightOnHover: true,
  noRecordsText: '',
  fontSize: 'md',
  verticalAlignment: 'top',
  verticalSpacing: 'sm',
  minHeight: 250,
  emptyState: (
    <Text color="dimmed" size="sm">
      No data found...
    </Text>
  ),
  recordsPerPage: DEFAULT_PAGE_SIZE,
};
