import { AddressDto, CustomerError, ErrorTopic } from 'shared';

export const normalizeCustomerErrors = ({
  errors,
}: {
  errors?: CustomerError[];
}) => {
  if (!errors) return null;

  const profileErrors = errors.filter(
    ({ topic }) => topic === ErrorTopic.profile,
  );
  const walletErrors = errors.filter(
    ({ topic }) => topic === ErrorTopic.wallet,
  );

  const clubMemberErrors = errors.filter(
    ({ topic }) => topic === ErrorTopic.club_membership,
  );

  const addressErrors = errors.filter(
    ({ topic }) => topic === ErrorTopic.addresses,
  );

  return { profileErrors, addressErrors, walletErrors, clubMemberErrors };
};

export const displayAddressDescription = ({
  first_name,
  last_name,
  street_address_one,
  street_address_two,
  city,
  state,
  zip_code,
  country,
}: AddressDto): string => {
  let description = '';
  if (first_name) description += first_name + ' ';
  if (last_name) description += last_name + ', ';
  if (street_address_one) description += street_address_one + ', ';
  if (street_address_two) description += street_address_two + ', ';
  if (city) description += city + ', ';
  if (state) description += state + ', ';
  if (zip_code) description += zip_code + ', ';
  if (country) description += country + ', ';

  if (description.endsWith(', ')) description = description.slice(0, -2);

  return description;
};

export const getChangeInformationMessage = (message: string) =>
  `You have opened an order.\nDo you want to change ${message} for the upcoming order?`;
