import { LocationService } from 'App/api';
import { useLocations } from 'App/contexts';
import { useApiMutation } from 'hooks';
import { CreateLocation, LocationTypes } from 'shared';

import { Form } from '../form';

export const initialValues: CreateLocation = {
  name: '',
  address: '',
  zip_code: '',
  city: '',
  state: '',
  phone_number: '',
  type: LocationTypes.INTERNAL,
};

export const Create = ({ onExit }: { onExit: () => void }): JSX.Element => {
  const { refetch } = useLocations();

  const onSuccess = () => {
    onExit();
    refetch();
  };
  const { mutate, isLoading } = useApiMutation(
    ['createLocation'],
    (data: CreateLocation) => LocationService.create(data),
    {
      onSuccess,
    },
  );
  return (
    <Form
      handleSubmit={mutate}
      processing={isLoading}
      initialValues={initialValues}
      title="New Location"
    />
  );
};
