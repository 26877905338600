import { ContentLayout, BackButton } from 'components';
import { useCreateTransfer } from '../../hooks';
import { Form, getNewItem } from '../form';

const title = 'New Transfer';

const initialValues = {
  origin: null,
  destination: null,
  origin_inventory_location_id: '',
  destination_inventory_location_id: '',
  is_library: false,
  items: [getNewItem()],
};

export const Create = ({
  onExit,
  onCreate,
}: {
  onExit: () => void;
  onCreate: (id: string) => void;
}): JSX.Element => {
  const { handleSubmit, isLoading } = useCreateTransfer({ onCreate });
  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      leftAction={<BackButton onClick={onExit} />}
    >
      <Form handleSubmit={handleSubmit} initialValues={initialValues} />
    </ContentLayout>
  );
};
