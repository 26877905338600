import { useParams, useNavigate, Link } from 'react-router-dom';
import { Text } from '@mantine/core';

import { useEditPartners } from '../../hooks';
import { ContentLayout, BackButton, ConfirmModal } from 'components';

import { Form } from '../form';

type PropType = {
  canDelete: boolean;
  onExit: () => void;
  onCreate: (id: string) => void;
};

export const Edit = ({
  canDelete,
  onExit,
  onCreate,
}: PropType): JSX.Element => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    isUpdating,
    handleSubmit,
    isDeleting,
    deletePartner,
  } = useEditPartners({ id, onDelete: onExit, onCreate });

  const goBack = () => navigate(-1);

  const initialValues = data;

  return (
    <ContentLayout
      title={data?.company_name}
      isLoading={isLoading}
      processing={isUpdating}
      leftAction={<BackButton onClick={goBack} component={Link} />}
    >
      {initialValues && (
        <Form handleSubmit={handleSubmit} initialValues={initialValues}>
          {canDelete && (
            <ConfirmModal
              type="button"
              variant="outline"
              fullWidth
              loading={isDeleting}
              onConfirm={() => deletePartner(id)}
              label="Delete"
            >
              <Text>Are you sure you want to delete the partner?</Text>
            </ConfirmModal>
          )}
        </Form>
      )}
    </ContentLayout>
  );
};
