import { Button, Text, Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconPlus } from '@tabler/icons-react';
import { CreateCulinarySku } from 'shared';
import { getNewModifier } from '../helpers';
import { ModifierItem } from './ModifierItem';

type Props = {
  form: UseFormReturnType<CreateCulinarySku>;
};

export const Modifiers = ({ form }: Props) => {
  const modifiers = form.values.specific_information?.items;
  return (
    <Grid gutter={'xl'}>
      <Grid.Col span={12}>
        <Text weight={600} size="lg" mt="xl">
          Modifiers
        </Text>
      </Grid.Col>

      {modifiers?.map((data, index) => (
        <Grid.Col span={12} key={data.key}>
          <ModifierItem form={form} index={index} />
        </Grid.Col>
      ))}
      <Grid.Col span={12}>
        <Button
          variant="subtle"
          onClick={() =>
            form.insertListItem('specific_information.items', getNewModifier())
          }
        >
          <IconPlus />
          <Text weight={600} size="sm" ml={10}>
            Add Modifier
          </Text>
        </Button>
      </Grid.Col>
    </Grid>
  );
};
