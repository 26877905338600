import { Badge } from '@mantine/core';
import { SalesOrderPaymentStatus, orderPaymentStatusLabels } from 'shared';

export const PaymentStatusBadge = ({
  payment_status,
}: {
  payment_status: SalesOrderPaymentStatus;
}) => {
  const color = [
    SalesOrderPaymentStatus.UNPAID,
    SalesOrderPaymentStatus.ERROR,
  ].includes(payment_status)
    ? 'red'
    : 'blue';

  return (
    <Badge variant="filled" color={color}>
      {orderPaymentStatusLabels[payment_status]}
    </Badge>
  );
};
