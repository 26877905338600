import { Link } from 'react-router-dom';
import { ContentLayout, BackButton } from 'components';
import { CreateForm } from '../forms';

type Props = {
  onExit: () => void;
  rootPath: string;
};

const title = 'New Shipment Cycle';

export const Create = ({ rootPath, onExit }: Props): JSX.Element => {
  return (
    <ContentLayout
      title={title}
      processing={false}
      leftAction={<BackButton component={Link} to={rootPath} />}
    >
      <CreateForm onExit={onExit} />
    </ContentLayout>
  );
};
