import { useQueryClient } from '@tanstack/react-query';
import { useApiQuery } from 'hooks';
import { ProductCategoryDto } from 'shared';
import { ProductCategoryService } from 'App/api';

export const useCategory = ({
  id,
}: {
  id: string;
}): {
  isError: boolean;
  category?: ProductCategoryDto;
  isLoading: boolean;
  refetch: () => void;
} => {
  const key = ['getCategory', id];
  const { data, isLoading, isError } = useApiQuery(
    key,
    () => ProductCategoryService.getOne({ id }),
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const client = useQueryClient();

  const refetch = () => {
    client.invalidateQueries(key);
  };

  return {
    category: data,
    isError,
    isLoading,
    refetch,
  };
};
