import { CulinaryMenuDto, CulinaryMenu } from 'shared/types';

export const normalizeCulinaryMenu = ({
  id,
  name,
  location,
  inventory_location,
  is_active,
  created_at,
}: CulinaryMenuDto) => {
  return {
    id,
    name,
    created_at: new Date(created_at).toLocaleDateString('en-US'),
    locationName: location.name,
    inventoryLocationName: inventory_location.name,
    status: is_active ? 'Active' : 'Disabled',
  };
};

export const normalizeCulinaryMenuValue = ({
  name,
  is_active,
  location,
  inventory_location,
  items,
  comment,
}: CulinaryMenu) => {
  return {
    name,
    is_active,
    location_id: location.id,
    inventory_location_id: inventory_location.id,
    items: items.map((i) => ({
      sku_id: i.sku_id,
      product_name: i.product_name,
    })),
    comment,
  };
};
