import { Grid, Text } from '@mantine/core';
import { PosSalesOrderDto, supplyTypeLabels } from 'shared';

type Props = {
  order: PosSalesOrderDto;
};

export const SupplyDetails = ({ order }: Props) => {
  const {
    deplete_inventory_location: { name },
    deplete_location: { name: pname },
    supply_type,
  } = order;

  return (
    <Grid mb="xl" gutter="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          Inventory Information
        </Text>
      </Grid.Col>
      <Grid.Col>
        <Grid gutter="xl">
          <Grid.Col span={4}>
            <Text weight={600} size={14} mb={'sm'}>
              Supply Type
            </Text>
            <Text size={14}>{supplyTypeLabels[supply_type] ?? 'Local'}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text weight={600} size={14} mb={'sm'}>
              Deplete from Primary Location
            </Text>
            <Text size={14}>{pname}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text weight={600} size={14} mb={'sm'}>
              Deplete from Inventory Location
            </Text>
            <Text size={14}>{name}</Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
