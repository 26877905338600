import { useMemo } from 'react';
import { LocationService } from 'App/api';
import { useApiQuery } from 'hooks';
import { TaxOutput } from 'shared';

interface ReturnValue {
  output: TaxOutput[];
  isLoading: boolean;
}

export const useLocalTaxes = (): ReturnValue => {
  const { data, isLoading } = useApiQuery(
    ['getLocalTaxes'],
    LocationService.getLocalTaxesList,
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map(
      ({ location: { name, address, state, zip_code }, ...rest }) => ({
        ...rest,
        location: name,
        address: `${address}, ${state} ${zip_code}`,
      }),
    );
  }, [data]);

  return {
    output,
    isLoading,
  };
};
