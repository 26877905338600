import { useState } from 'react';
import { Button, Grid, Select, Text } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { SingleValue } from 'react-select';
import {
  ClubMembershipChangeReason,
  ClubMembershipValues,
  HALTER_RANCH_LOCATION_ID,
  SelectOption,
} from 'shared';
import { SelectAsync } from 'components';
import { useEmployees } from 'hooks';

type Props = {
  isNoneTier: boolean;
  formValue: ClubMembershipValues;
  onSubmit: ({
    value,
    reason,
    customer_source_id,
  }: {
    value: ClubMembershipValues;
    reason?: ClubMembershipChangeReason | null;
    customer_source_id?: string | null;
  }) => void;
};

export const Reason = ({ isNoneTier, onSubmit, formValue }: Props) => {
  const [value, setValue] = useState<{
    reason: ClubMembershipChangeReason | null;
    customer_source_id: string | null;
  }>({
    reason: null,
    customer_source_id: null,
  });
  const { reason, customer_source_id } = value;
  const isInvalid = !value.customer_source_id || !value.reason;

  const { employeesOptions, hasMore, isLoading, setPageSelectAsync } =
    useEmployees({
      locationId: HALTER_RANCH_LOCATION_ID,
      page_size: 100,
      isActive: true,
      isSelectAsync: true,
      noCache: true,
    });

  return (
    <Grid gutter="xl">
      {isNoneTier && (
        <Grid.Col>
          <Text align="center" size={14} weight={400}>
            You are about to cancel Club Membership Tier and Club Add-On. Are
            you sure?
          </Text>
        </Grid.Col>
      )}

      <Grid.Col>
        <Select
          required
          label="Reason"
          mb={24}
          data={Object.values(ClubMembershipChangeReason)}
          onChange={(e) =>
            setValue((prev) => ({
              ...prev,
              reason: e as ClubMembershipChangeReason,
            }))
          }
        />
        <SelectAsync
          required
          label="Customer Source"
          setPage={setPageSelectAsync}
          hasMore={hasMore}
          isLoading={isLoading}
          options={employeesOptions}
          onChange={(item) => {
            const { value } = (item as SingleValue<SelectOption>) ?? {};
            if (value)
              setValue((prev) => ({
                ...prev,
                customer_source_id: value,
              }));
          }}
        />
      </Grid.Col>
      <Grid.Col>
        <Button
          fullWidth
          disabled={isInvalid}
          data-testid="submit-btn"
          onClick={() => {
            onSubmit({
              reason,
              customer_source_id,
              value: formValue,
            });
            closeAllModals();
          }}
        >
          Save
        </Button>
      </Grid.Col>
    </Grid>
  );
};
