import { Grid } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { ContentLayout, CreateButton, SearchInput } from 'components';
import { useDiscounts } from 'pages/discounts/hooks';
import { PROPS_DATA_TABLE, getCreatePath } from 'shared';

export const List = ({
  canCreate,
  rootPath,
}: {
  canCreate: boolean;
  rootPath: string;
}) => {
  const {
    page,
    search_string,
    totalRecords,
    isLoading,
    output,
    updateFilters,
    handleSetSearchParams,
  } = useDiscounts();

  return (
    <ContentLayout
      title="Discounts"
      processing={isLoading}
      rightAction={
        canCreate && (
          <CreateButton
            data-testid="new-discount-btn"
            to={getCreatePath(rootPath)}
          >
            + New Discount
          </CreateButton>
        )
      }
    >
      <Grid mb="md" justify="space-between">
        <Grid.Col span={5} offset={7}>
          <SearchInput
            handleSubmit={(value: string) =>
              updateFilters({ search_string: value })
            }
            label="Search"
            value={search_string}
          />
        </Grid.Col>
      </Grid>
      <DataTable
        {...PROPS_DATA_TABLE}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        records={output}
        columns={[
          { accessor: 'name', title: 'Promocode Name', width: '20%' },
          { accessor: 'value', title: 'Value', width: '10%' },
          {
            accessor: 'locations',
            title: 'Location',
            width: '20%',
          },
          {
            accessor: 'club_tier',
            title: 'Club Tier',
            width: '20%',
          },
          {
            accessor: 'status',
            title: 'Status',
            width: '10%',
          },
          {
            accessor: 'dates',
            title: 'Dates',
            width: '20%',
          },
        ]}
      />
    </ContentLayout>
  );
};
