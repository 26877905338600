import { Grid, Badge, Text, Button } from '@mantine/core';
import { getDateOutput } from 'shared';

type Props = {
  date: string | null;
  onClick: () => void;
};

export const ClubMemberStatus = ({ date, onClick }: Props): JSX.Element => {
  if (!date) {
    return (
      <Grid gutter="xl" justify="space-between">
        <Grid.Col span={'auto'} />
        <Grid.Col span={'auto'}>
          <Button variant="subtle" onClick={onClick}>
            See Membership History
          </Button>
        </Grid.Col>
      </Grid>
    );
  }
  return (
    <Grid gutter={'xl'}>
      <Grid.Col>
        <Text size={18} weight={700}>
          Status
        </Text>
      </Grid.Col>
      <Grid.Col pb={0}>
        <Badge variant="filled" size="sm">
          Active
        </Badge>
      </Grid.Col>
      <Grid.Col pt={0}>
        <Grid gutter="xl" justify="space-between" align="center">
          <Grid.Col span={'auto'}>
            <Text>Member since: {getDateOutput(date)}</Text>
          </Grid.Col>
          <Grid.Col span={'auto'}>
            <Button variant="subtle" onClick={onClick}>
              See Membership History
            </Button>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
