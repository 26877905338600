import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

import { ContentLayout, HintSection } from 'components';
import {
  PROPS_DATA_TABLE,
  getEditPath,
  salesOrderSourceTitle,
  salesOrderStatusLabels,
  ServiceClass,
  getComplianceStatus,
  getCurrencyString,
  orderHintOptions,
} from 'shared';

import { useBulkAction, useOrders } from 'hooks';
import { Filters } from '../Filters';

export const List = ({ rootPath }: { rootPath: string }) => {
  const {
    selected,
    bulkButton,
    bulkActionButtons,
    isLoading: isBulkActionLoading,
  } = useBulkAction();
  const {
    output,
    isLoading,
    page,
    totalRecords,
    search_string,
    statuses,
    payment_statuses,
    updateFilters,
    handleSetSearchParams,
  } = useOrders({
    serviceClass: ServiceClass.wine_club,
    queryKey: 'cycle',
  });

  const navigate = useNavigate();

  return (
    <ContentLayout
      title="Club Cycle Orders"
      processing={isLoading || isBulkActionLoading}
    >
      <Filters
        handleChange={updateFilters}
        isSelectedBulk={!!selected?.selectedRecords?.length}
        values={{ search_string, statuses, payment_statuses }}
      >
        {bulkButton}
      </Filters>

      <DataTable
        {...PROPS_DATA_TABLE}
        data-testid="cycle-order-table"
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        records={output}
        onRowClick={({ id }) => navigate(getEditPath(rootPath, id))}
        {...selected}
        columns={[
          {
            accessor: 'compliance_status',
            title: '',
            render: ({ compliance_status }) => {
              return (
                <Box
                  data-testid={compliance_status}
                  sx={{
                    backgroundColor: getComplianceStatus(compliance_status),
                    width: '2px',
                    height: '40px',
                    margin: '-8px -8px',
                  }}
                />
              );
            },
            width: '3px',
          },
          {
            accessor: 'number',
            title: 'Order #',
          },
          {
            accessor: 'shipment_cycle.name',
            title: 'Cycle Name',
          },
          {
            accessor: 'created_at',
            title: 'Creation Date',
          },
          {
            accessor: 'fully_paid_at',
            title: 'Payment Date',
          },
          {
            accessor: 'source',
            title: 'Source',
            render: ({ source }) => salesOrderSourceTitle[source],
          },
          {
            accessor: 'total',
            title: 'Total',
            render: ({ total, is_zero_shipping_tax_fallback_triggered }) => {
              const markAsZero = is_zero_shipping_tax_fallback_triggered ? (
                <IconAlertCircle size="20px" color="#909296" />
              ) : (
                ''
              );

              return (
                <Flex align="center" gap={5}>
                  <Box>{getCurrencyString(total)}</Box>
                  {markAsZero}
                </Flex>
              );
            },
          },
          {
            accessor: 'customer',
            title: 'Customer',
            render: ({ customer: { first_name, last_name } }) =>
              `${first_name} ${last_name}`,
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => salesOrderStatusLabels[status],
          },
        ]}
      />
      {bulkActionButtons}
      <HintSection options={orderHintOptions} />
    </ContentLayout>
  );
};
