import { Link, useNavigate, useParams } from 'react-router-dom';
import { ContentLayout, BackButton, ConfirmModal } from 'components';
import { Form } from '../form';
import { useEditMenu } from '../../hooks';
import { Text } from '@mantine/core';

export const Edit = ({
  onExit,
  canDelete,
  canEdit,
}: {
  onExit: () => void;
  canDelete: boolean;
  canEdit: boolean;
}): JSX.Element => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const {
    initialValues,
    isLoading,
    handleSubmit,
    deleteMenu,
    isDeleting,
    isUpdating,
  } = useEditMenu({ id, onExit });

  const title = `Edit Menu ${initialValues?.name}`;

  return (
    <ContentLayout
      title={title}
      isLoading={isLoading}
      processing={isUpdating}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
    >
      <Form
        handleSubmit={(v) => handleSubmit(id, v)}
        initialValues={initialValues}
        canEdit={canEdit}
        editMode
        action={
          canDelete && (
            <ConfirmModal
              type="button"
              variant="outline"
              fullWidth
              loading={isDeleting}
              mt={26}
              onConfirm={() => deleteMenu(id)}
              label="Delete Menu"
            >
              <Text>Are you sure you want to delete the Menu?</Text>
            </ConfirmModal>
          )
        }
      />
    </ContentLayout>
  );
};
