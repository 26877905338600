import * as Yup from 'yup';

import {
  requiredDateValidationRule,
  requiredStringValidationRule,
  requiredNumberValidationRule,
} from '.';
import { SalesOrderItemChannel } from 'shared/types';

const items = Yup.object().shape({
  sku_id: requiredStringValidationRule('Product Name').nullable(),
  quantity: requiredNumberValidationRule('Qty'),
  key: Yup.string(),
  sku_name: Yup.string(),
  price: Yup.number(),
  discount: Yup.number(),
  total: Yup.number(),
});

const getShippingStringValidation = (label: string) =>
  Yup.string()
    .notRequired()
    .nullable()
    .when('delivery_method', {
      is: 'Shipping',
      then: () => requiredStringValidationRule(label).nullable(),
    });

export const getOrderSchema = (isCycleOrder = false) =>
  Yup.object({
    channel: Yup.string(),
    status: requiredStringValidationRule('Order Status'),
    items: Yup.array().of(items),
    supply_type: requiredStringValidationRule('Supply Type'),
    updated_by_id: requiredStringValidationRule(),
    customer_shipping_address_id:
      getShippingStringValidation('Shipping Address'),
    deplete_location_id: requiredStringValidationRule().nullable(),
    deplete_inventory_location_id: getShippingStringValidation(
      'Deplete Inventory Location',
    ),
    pick_up_deadline: Yup.date()
      .notRequired()
      .nullable()
      .when('delivery_method', {
        is: 'Pickup',
        then: () => requiredDateValidationRule('Pickup Deadline').nullable(),
      }),
    freight_company: getShippingStringValidation('Freight Company'),
    freight_method: getShippingStringValidation('Freight Method'),
    shipping_date: Yup.date()
      .notRequired()
      .nullable()
      .when('delivery_method', {
        is: 'Shipping',
        then: () => requiredDateValidationRule('Pickup Deadline').nullable(),
      }),
    billing_datetime: requiredDateValidationRule('Billing Date').nullable(),
    shipment_cycle_id: !isCycleOrder
      ? Yup.string()
          .notRequired()
          .nullable()
          .when('channel', {
            is: SalesOrderItemChannel.WINE_CLUB,
            then: () => requiredStringValidationRule('Wine Club').nullable(),
          })
      : Yup.string().nullable(),
    re_billing_datetime: isCycleOrder
      ? requiredDateValidationRule('Re-Billing Date').nullable()
      : Yup.date().nullable(),
    customer_card_id: isCycleOrder
      ? requiredStringValidationRule('Card').nullable()
      : Yup.string().nullable(),
    is_skipped: Yup.boolean().notRequired(),
  });
