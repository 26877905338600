import { ProductVendorService } from 'App/api';
import { useApiMutation } from 'hooks';
import { CreateVendor } from 'shared';

type Props = { onSuccess: (id: string) => void };

type ReturnValue = {
  handleSubmit: (values: CreateVendor) => void;
  isLoading: boolean;
};

export const useCreateVendors = ({ onSuccess }: Props): ReturnValue => {
  const { mutate: createVendor, isLoading } = useApiMutation(
    ['createVendor'],
    ProductVendorService.create,
    {
      onSuccess: (data) => onSuccess(data.id),
    },
  );

  const handleSubmit = (values: CreateVendor) => {
    const payload = {
      company_name: values.company_name,
      website: values.website,
      street_address: values.street_address,
      city: values.city,
      state: values.state,
      zip_code: values.zip_code,
      phone_number: values.phone_number,
      primary_contact_name: values.primary_contact_name,
      primary_contact_phone_number: values.primary_contact_phone_number,
      primary_contact_email: values.primary_contact_email,
      comment: values.comment,
      is_location_specific: values.is_location_specific,
      vendor_locations: values.vendor_locations.map((loc) =>
        loc.same_contact_details
          ? {
              same_contact_details: true,
              location_id: loc.location_id,
            }
          : loc,
      ),
    };

    createVendor(payload);
  };

  return {
    handleSubmit,
    isLoading,
  };
};
