import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { Permissions } from 'shared';
import { List, Create, Details, Release } from './components';
import { NestedRouteComponent } from 'components';

export const Allocation = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.InventoryAllocation,
  });
  const canCreate = checkAccess(Permissions.admin_panel_library_create);
  const canRelease = checkAccess(Permissions.admin_panel_library_release);
  const canCancel = checkAccess(Permissions.admin_panel_library_cancel);

  const onExit = useGotoRoute(RouteName.InventoryAllocation);

  const ListComponent = <List canCreate={canCreate} rootPath={rootPath} />;
  const CreateComponent = <Create onExit={onExit} />;
  const ReleaseComponent = <Release onExit={onExit} />;

  const DetailsComponent = (
    <Details
      rootPath={rootPath}
      canRelease={canRelease}
      canCancel={canCancel}
      onExit={onExit}
    />
  );

  return (
    <NestedRouteComponent
      showEdit={canRelease}
      showCreate={canCreate}
      rootPath={rootPath}
      ListComponent={ListComponent}
      Create={CreateComponent}
      Edit={ReleaseComponent}
      Details={DetailsComponent}
    />
  );
};
