export enum Steps {
  CycleSetting = 'Cycle Setting',
  WineSelection = 'Wine Selection',
  Suggestions = 'Suggestions',
  ShippingDetails = 'Shipping Details',
}

export enum CycleStatus {
  draft = 'Draft',
  ready = 'Ready',
  publishing_in_progress = 'Publishing in Progress',
  published = 'Published',
  completed = 'Completed',
}

export enum Mode {
  LIST = 'list',
  INVENTORY_CHECK = 'inventory-check',
}

export const FREIGHT_COMPANY = [
  { label: 'FedEx', value: 'fedex' },
  { label: 'UPS', value: 'ups' },
];
export const SHIPMENT_COST = [
  { label: 'Free', value: 'free' },
  { label: 'Fixed Value', value: 'fixedvalue' },
];
export const FREIGHT_METHOD = [{ label: 'Ground', value: 'ground' }];
