import { useState, useCallback, useMemo } from 'react';
import { Container, Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useToast } from 'hooks';
import { isEmpty } from 'rambda';
import {
  CreateSku,
  getFormAdditionalInformation,
  getFormProfile,
  skuSchema,
  SkuFormImages,
} from 'shared';
import { useInitialForm } from '../../hooks';
import {
  Kit,
  NonInventory,
  ShippingSettings,
  SpecificInformation,
  Images,
  CategoryOptions,
} from './formSections';
import { AdditionalInformation, ProductProfile, SkuWrapper } from 'components';

export type PropsType = {
  handleSubmit: (
    values: CreateSku,
    file: File | null,
    images?: SkuFormImages,
  ) => void;
  processing: boolean;
  editMode?: boolean;
  initialValues: CreateSku;
  vendorName?: string;
  action?: React.ReactNode;
  isCreate?: boolean;
  canEdit?: boolean;
};

export const Form = ({
  handleSubmit,
  processing,
  initialValues,
  vendorName,
  action,
  isCreate,
  canEdit,
  editMode,
}: PropsType) => {
  const [file, setFile] = useState<File | null>(null);
  const [images, setImages] = useState<SkuFormImages>({
    webImage: null,
    webHoverImage: null,
    mobileImage: null,
  });
  const addToast = useToast();

  const form = useForm({
    initialValues,
    validate: yupResolver(skuSchema),
    validateInputOnBlur: true,
  });

  const payload = {
    categoriId: form.values.category_id,
    price: form.values.price,
    cost: form.values.cost,
    btgCost: form.values.btg?.btg_cost,
    btgPrice: form.values.btg?.btg_price,
  };
  const {
    categoryOptions,
    subcategyOptions,
    vendorsOptions,
    margin,
    btgMargin,
    skuOptions,
    categories,
    isLoadingSkus,
    hasMore,
    setPage,
    updateFilters,
  } = useInitialForm(payload);

  const isDisabledKit = !isCreate && !initialValues?.saved_as_draft;
  const isDraft = form.values.saved_as_draft;
  const isInEcom = form.values.show_in_ecommerce;

  const { category_id: catId, subcategory_id: subCatId } = form.values;

  const selectedCategory = useMemo(() => {
    if (isEmpty(categories) || !catId) {
      return null;
    }
    const currentCategory = categories.find((c) => c.id === catId);
    if (subCatId) {
      return (
        currentCategory?.subcategories.find((s) => s.id === subCatId) ?? null
      );
    }
    return currentCategory ?? null;
  }, [categories, catId, subCatId]);

  const submit = useCallback(
    (values: CreateSku) => {
      if (!values.show_in_ecommerce) {
        return handleSubmit(values, file);
      }
      if (!images.webImage && !values.web_image_url) {
        addToast.error('Web Image Required!');
        return;
      }
      if (!images.mobileImage && !values.mobile_image_url) {
        addToast.error('Mobile Image Required!');
        return;
      }
      if (!images.webHoverImage && !values.web_hover_image_url) {
        addToast.error('Web Hover Image Required!');
        return;
      }
      handleSubmit(values, file, images);
    },
    [addToast, file, handleSubmit, images],
  );

  return (
    <Container size={'xl'} p={0}>
      <form
        onSubmit={form.onSubmit((values) => submit(values))}
        noValidate={isDraft}
      >
        <SkuWrapper
          initialValues={initialValues}
          isCreate={isCreate}
          action={action}
          processing={processing}
          setFieldValue={form.setFieldValue}
          isValid={form.isValid()}
          canEdit={canEdit}
          editMode={canEdit}
          isDraftDisabled={!form.values.product_name}
        >
          <ProductProfile
            form={getFormProfile(form)}
            margin={margin}
            skuNumber={initialValues?.sku_number}
            categoryOptions={categoryOptions}
            subcategyOptions={subcategyOptions}
            vendorsOptions={vendorsOptions}
            vendorName={vendorName}
          />

          <Kit
            form={form}
            skuOptions={skuOptions}
            isLoadingSkus={isLoadingSkus}
            hasMoreSku={hasMore}
            setPageSku={setPage}
            updateFilters={updateFilters}
            isDisabledKit={isDisabledKit}
          />
          <NonInventory form={form} editMode={editMode} />
          <SpecificInformation
            form={form}
            margin={btgMargin}
            skuNumber={initialValues?.btg?.btg_sku_number}
          />
          <ShippingSettings form={form} />
          <Box mb="xl">
            <AdditionalInformation
              file={file}
              setFile={setFile}
              form={getFormAdditionalInformation(form)}
            />
          </Box>

          <CategoryOptions
            form={form}
            category={selectedCategory}
            initialOptions={initialValues.category_options ?? []}
          />

          {isInEcom && (
            <Images form={form} images={images} setImages={setImages} />
          )}
        </SkuWrapper>
      </form>
    </Container>
  );
};
