export interface IFileContent {
  fileName: string;
  contentType: string;
  uploadContent: string;
  preview: string;
}

export type SelectOption = {
  value: string;
  label: string;
};

export interface BaseDto {
  id: string;
  name: string;
}

export interface ShortBase {
  id: string;
  name: string;
}

export type PaginatedRequest = {
  page?: number;
  page_size?: number;
};

export interface PrivateDocumentDto {
  document_name: string;
  url: string;
}

export type PaginatedResponse<T> = {
  items: T[];
  total: number;
  page: number;
  page_size: number;
};

export type CreatePayload<T> = Omit<T, 'id' | 'issued_at' | 'issued_by'>;

export type FeeUnit = '$' | '%';

export type AutocompleteAddress = {
  administrative_area_level_1: { long: string; short: string };
  country: { long: string; short: string };
  formattedAddress: { long: string; short: string };
  postal_code: { long: string; short: string };
  postal_code_suffix: { long: string; short: string };
  locality: { long: string; short: string };
  street_number: { long: string; short: string };
  route: { long: string; short: string };
};

export type WineType = 'reds' | 'mixed' | 'silver_label' | 'ancestor';

export enum WineTypeTitle {
  'reds' = 'Reds',
  'mixed' = 'Mixed',
  'silver_label' = 'Silver Label',
  'ancestor' = 'Ancestor',
}

export type BottleQuantity = 6 | 12;

export enum DeliveryMethod {
  SHIPPING = 'Shipping',
  PICKUP = 'Pickup',
}
