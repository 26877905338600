import { Box } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import { ContentLayout, CreateButton } from 'components';
import { Filters } from 'components/filters';
import { getCreatePath, getEditPath, PROPS_DATA_TABLE } from 'shared';
import { STATUS_OPTIONS } from '../../constants';
import { useMenu } from '../../hooks';
import { useNavigate } from 'react-router-dom';

export const List = ({
  canCreate,
  rootPath,
}: {
  canCreate: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const {
    page,
    output,
    totalRecords,
    filters,
    isLoading,
    updateFilters,
    handleSetSearchParams,
  } = useMenu();

  return (
    <ContentLayout
      title="Menu Management"
      rightAction={
        canCreate && (
          <CreateButton data-testid="new-menu-btn" to={getCreatePath(rootPath)}>
            + New Menu
          </CreateButton>
        )
      }
      processing={isLoading}
    >
      <Box mb="md">
        <Filters
          statusLabel="Status"
          searchLabel="Search"
          locationLabel="Primary Location"
          statusOptions={STATUS_OPTIONS}
          handleChange={updateFilters}
          values={filters}
        />
      </Box>
      <DataTable
        {...PROPS_DATA_TABLE}
        records={output}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        onRowClick={({ id }) => navigate(getEditPath(rootPath, id))}
        columns={[
          { accessor: 'name', title: 'Menu Name' },
          { accessor: 'locationName', title: 'Primary Location' },
          { accessor: 'inventoryLocationName', title: 'Inventory Location' },
          { accessor: 'created_at', title: 'Creation Date' },
          { accessor: 'status', title: 'Status' },
        ]}
      />
    </ContentLayout>
  );
};
