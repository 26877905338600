import dayjs from 'dayjs';
import { Grid, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import { ActionButtons } from 'components';
import { DATE_FORMAT_SLASH, PROPS_DATA_TABLE } from 'shared';
import { useTastingHistory } from '../../hooks';

export const TastingHistory = ({
  customerId,
  goBack,
}: {
  customerId: string;
  goBack: () => void;
}): JSX.Element => {
  const { data, isLoading, page, setPage, totalRecords } = useTastingHistory({
    customerId,
  });

  return (
    <Grid gutter="xl">
      <Grid.Col>
        <Text size={18} weight={700}>
          Tasting History
        </Text>
      </Grid.Col>
      <Grid.Col pb={0}>
        <DataTable
          {...PROPS_DATA_TABLE}
          page={page}
          onPageChange={setPage}
          totalRecords={totalRecords}
          fetching={isLoading}
          records={data?.items}
          columns={[
            {
              width: '25%',
              accessor: 'location',
              title: 'Location',
              render: ({ location: { name } }) => name,
            },
            {
              width: '25%',
              accessor: 'quantity',
              title: 'Qty',
            },
            {
              width: '25%',
              accessor: 'closed_at',
              title: 'Date',
              render: ({ closed_at }) =>
                dayjs(closed_at).format(DATE_FORMAT_SLASH),
            },
            {
              width: '25%',
              accessor: 'number',
              title: 'Order #',
            },
          ]}
        />
      </Grid.Col>
      <Grid.Col pt={0}>
        <ActionButtons
          variant="subtle"
          label="Back to Club Member"
          onClick={goBack}
        />
      </Grid.Col>
    </Grid>
  );
};
