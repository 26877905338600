import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { usePermissions } from 'hooks';
import { NestedRoutes, Permissions } from 'shared';
import { List, Create, Edit, Details } from './components';

export const InventoryLocations = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.InventoryLocations,
  });
  const canCreate = checkAccess(
    Permissions.admin_panel_inventory_locations_create,
  );
  const canEdit = checkAccess(Permissions.admin_panel_inventory_locations_edit);
  const canDelete = checkAccess(
    Permissions.admin_panel_inventory_locations_delete,
  );
  const onExit = useGotoRoute(RouteName.InventoryLocations);

  const ListComponent = (
    <List canCreate={canCreate} canEdit={canEdit} rootPath={rootPath} />
  );

  return (
    <>
      <Routes>
        <Route index element={ListComponent} />
        <Route path={NestedRoutes.List} element={ListComponent} />
        <Route
          path={NestedRoutes.View}
          element={
            <Details onExit={onExit} rootPath={rootPath} canEdit={canEdit} />
          }
        />
        {canCreate && (
          <Route
            path={NestedRoutes.Create}
            element={<Create onExit={onExit} />}
          />
        )}
        {canEdit && (
          <Route
            path={NestedRoutes.Edit}
            element={
              <Edit canDelete={canDelete} onExit={onExit} rootPath={rootPath} />
            }
          />
        )}
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: rootPath,
              }}
            />
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};
