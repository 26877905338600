import { FC, useEffect } from 'react';
import { Select, Switch, Textarea, Grid } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import {
  InventoryFormValues,
  getEmptyOption,
  SWITCH_GRID_TOP_OFFSET,
} from 'shared';
import { ActionButtons } from 'components';
import { useInventoryLocations } from 'pages/inventory/hooks';
import { ProductItems } from './ProductItems';
import { VendorData } from './VendorData';
import { inventorySchema } from './schema';

export type Props = {
  handleSubmit: (values: InventoryFormValues) => void;
  initialValues: InventoryFormValues;
};

export const Form: FC<Props> = ({
  handleSubmit,
  initialValues,
}): JSX.Element => {
  const { setFieldValue, ...form } = useForm({
    initialValues,
    validate: yupResolver(inventorySchema),
    validateInputOnBlur: true,
  });
  const {
    primaryLocationOptions,
    setPrimaryLocation,
    primaryLocation,
    inventoryLocationOptions,
  } = useInventoryLocations();

  useEffect(() => {
    setFieldValue('inventory_location_id', '');
  }, [primaryLocation, setFieldValue]);

  const options = primaryLocation
    ? [getEmptyOption('Not Selected'), ...inventoryLocationOptions]
    : [getEmptyOption('Select Primary Location')];

  const isVendor = form.values.is_vendor;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid gutter={'xl'}>
        <Grid.Col span={12} pt={SWITCH_GRID_TOP_OFFSET}>
          <Switch
            label="Vendor Inventory"
            labelPosition="right"
            {...form.getInputProps('is_vendor', { type: 'checkbox' })}
          />
        </Grid.Col>
        {isVendor && (
          <Grid.Col span={12}>
            <VendorData form={{ setFieldValue, ...form }} />
          </Grid.Col>
        )}
        <Grid.Col span={12}>
          <Select
            required
            data-testid="destination-primary-location-dropdown"
            label="Destination Primary Location"
            data={[getEmptyOption('Not Selected'), ...primaryLocationOptions]}
            value={primaryLocation}
            onChange={(value: string) => setPrimaryLocation(value)}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            required
            data-testid="destination-inventory-location-dropdown"
            label="Destination Inventory Location"
            data={options}
            {...form.getInputProps('inventory_location_id')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <ProductItems form={{ setFieldValue, ...form }} />
        </Grid.Col>
        {isVendor && (
          <Grid.Col span={12}>
            <Textarea label="Comment" {...form.getInputProps('comment')} />
          </Grid.Col>
        )}
        <Grid.Col span={12}>
          <ActionButtons
            disabled={!form.isValid()}
            type="submit"
            label="Save"
            aria-label="save"
          />
        </Grid.Col>
      </Grid>
    </form>
  );
};
