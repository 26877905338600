import { useCallback, useMemo, useState } from 'react';
import { EmployeeService } from 'App/api';
import { useLocations } from 'App/contexts';
import { useApiQuery, useQueryParams } from 'hooks';
import {
  normalizeEmployee,
  getSelectOptions,
  getParams,
  hasPages,
  EmployeeSearchParams,
} from 'shared';
import { EMPLOYEES } from 'shared/interactions';
import { DataTableSortStatus } from 'mantine-datatable';

const DEFAULT_PAGE_SIZE = 1000;

type Props = {
  locationId?: string;
  isSelectAsync?: boolean;
  isActive?: boolean;
  noCache?: boolean;
  include_system?: boolean;
  page_size?: number;
};

export const useEmployees = ({
  locationId,
  noCache = false,
  isActive = false,
  isSelectAsync = false,
  include_system = false,
  page_size = DEFAULT_PAGE_SIZE,
}: Props) => {
  const [pageSelectAsync, setPageSelectAsync] = useState(1);
  const [searchStringSelectAsync, setSearchStringSelectAsync] = useState('');

  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    field: '',
    direction: '',
    search_string: '',
  });
  const { currentLocation: location_id } = useLocations();

  const page = searchParams.get('page');
  const field = searchParams.get('field');
  const direction = searchParams.get(
    'direction',
  ) as EmployeeSearchParams['direction'];

  const search_string = searchParams.get('search_string');

  const params = useMemo(() => {
    return {
      field,
      direction,
      page_size,
      include_system,
      page: isSelectAsync ? pageSelectAsync : Number(page) || 1,
      search_string: isSelectAsync ? searchStringSelectAsync : search_string,
      location_id: locationId ?? location_id,
      is_active: isActive,
    };
  }, [
    direction,
    field,
    include_system,
    isActive,
    isSelectAsync,
    locationId,
    location_id,
    page,
    pageSelectAsync,
    page_size,
    searchStringSelectAsync,
    search_string,
  ]);

  const key = JSON.stringify(params);

  const { data, isLoading, isError } = useApiQuery(
    [EMPLOYEES, key],
    () =>
      EmployeeService.getEmployees(
        getParams(params as unknown as EmployeeSearchParams),
      ),
    {
      enabled: !!params.location_id,
      ...(noCache ? { cacheTime: 0 } : {}),
    },
  );

  const updateFilters = useCallback(
    (data: EmployeeSearchParams) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  const setSortStatus = useCallback(
    (sort: DataTableSortStatus) => {
      handleSetSearchParams({
        page: '1',
        field: sort.columnAccessor,
        direction: sort.direction,
      });
    },
    [handleSetSearchParams],
  );

  const employeesOutput = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map((e) => normalizeEmployee(e));
  }, [data]);

  const employeesOptions = useMemo(
    () => getSelectOptions(employeesOutput),
    [employeesOutput],
  );

  return {
    field,
    isError,
    direction,
    isLoading,
    employeesOutput,
    employeesOptions,
    page: Number(page) || 1,
    search_string: search_string ?? '',
    employees: data?.items ?? [],
    totalRecords: data?.total ?? 0,
    updateFilters,
    setSortStatus,
    handleSetSearchParams,
    setPageSelectAsync,
    setSearchStringSelectAsync,
    hasMore: hasPages({
      total: data?.total,
      defaultSize: page_size,
      currentPage: Number(pageSelectAsync) || 1,
    }),
  };
};
