import { PartnerService } from 'App/api';
import { CreatePartner, PartnerType } from 'shared';
import { ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useApiMutation } from 'hooks';

const title = 'New Partner';

const initialValues: CreatePartner = {
  company_name: '',
  type: PartnerType.WHOLESALER,
  website: '',
  street_address: '',
  city: '',
  state: '',
  zip_code: '',
  phone_number: '',
  primary_contact_name: '',
  comment: '',
};

export const Create = ({ onExit }: { onExit: () => void }): JSX.Element => {
  const { mutate, isLoading } = useApiMutation(
    ['create-partner'],
    PartnerService.create,
    {
      onSuccess: onExit,
    },
  );
  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      leftAction={<BackButton onClick={onExit} />}
    >
      <Form handleSubmit={(v) => mutate(v)} initialValues={initialValues} />
    </ContentLayout>
  );
};
