import { PaginatedRequest } from '../common';

export enum PartnerType {
  WHOLESALER = 'wholesaler',
  FULFILMENT = 'fulfilment',
}

export const partnerTypeLabels: Record<PartnerType, string> = {
  [PartnerType.WHOLESALER]: 'Wholesaler',
  [PartnerType.FULFILMENT]: 'Fulfilment',
};

export interface PartnerDto {
  id: string;
  company_name: string;
  type: PartnerType;
  website: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  phone_number: string;
  primary_contact_name: string | null;
  comment: string | null;
}

export type CreatePartner = Omit<PartnerDto, 'id'>;

export interface PartnersRequest extends PaginatedRequest {
  search_string?: string;
}
