import { FC } from 'react';
import { Box, Text } from '@mantine/core';

export const Item: FC<{
  item: { label: string; value: string | number };
}> = ({ item }): JSX.Element => (
  <>
    <Box>
      <Text weight={600}>{item.label}</Text>
    </Box>
    <Box>{item.value}</Box>
  </>
);
