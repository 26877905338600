import {
  Grid,
  Container,
  Button,
  Text,
  Group,
  LoadingOverlay,
} from '@mantine/core';
import { useNavigate, generatePath } from 'react-router-dom';
import { generateRoutePath, RouteName } from 'App/routes';
import { useLocations } from 'App/contexts';
import { LOADING_OVERLAY, NestedRoutes } from 'shared';
import { Card } from '../card';

export const List = ({
  canCreate,
  canEdit,
}: {
  canCreate: boolean;
  canEdit: boolean;
}): JSX.Element => {
  const { locations, isLoading } = useLocations();
  const navigate = useNavigate();
  const pathToRoles = generateRoutePath({
    name: RouteName.Roles,
  });
  const locationsPath = generateRoutePath({
    name: RouteName.Locations,
  });

  return (
    <Container size={'xl'} sx={{ position: 'relative' }}>
      <LoadingOverlay
        data-testid={LOADING_OVERLAY}
        visible={isLoading}
        overlayBlur={2}
      />
      <Text
        data-testid="locations-screen-title"
        size={'lg'}
        weight={700}
        align="center"
        mt={16}
        mb={24}
      >
        Locations
      </Text>
      <Grid gutter="xl">
        {locations.map((l) => (
          <Grid.Col data-testid="location-card" md={6} lg={4} key={l.id}>
            <Card
              location={l}
              canEdit={canEdit}
              onEditClick={() =>
                navigate(
                  generatePath(`${locationsPath}/${NestedRoutes.Edit}`, {
                    id: l.id,
                  }),
                )
              }
              onRolesClick={() => navigate(`${pathToRoles}?location=${l.id}`)}
            />
          </Grid.Col>
        ))}
      </Grid>
      {canCreate && (
        <Group position="right" mt={24}>
          <Button
            variant="subtle"
            data-testid="new-location-btn"
            size="lg"
            onClick={() => navigate(`${locationsPath}/${NestedRoutes.Create}`)}
          >
            + New Location
          </Button>
        </Group>
      )}
    </Container>
  );
};
