import { useNavigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import { ContentLayout, CreateButton } from 'components';
import { getCreatePath, getViewPath, PROPS_DATA_TABLE } from 'shared';
import { useInventoryTransfer } from '../../hooks';
import { Filters } from 'components/filters';
import { DATA_TABLE_COLUMS, STATUS_OPTIONS } from '../../constants';
import { usePartnerLocations } from 'pages/inventory/hooks';

export const List = ({
  rootPath,
  canCreate,
}: {
  rootPath: string;
  canCreate?: boolean;
}): JSX.Element => {
  const navigate = useNavigate();
  const { options } = usePartnerLocations();
  const {
    page,
    filters,
    totalRecords,
    output,
    isLoading,
    updateFilters,
    handleSetSearchParams,
  } = useInventoryTransfer();

  return (
    <ContentLayout
      title="Inventory Transfer"
      processing={isLoading}
      rightAction={
        canCreate && (
          <CreateButton
            data-testid="new-inventory-transfer-btn"
            to={getCreatePath(rootPath)}
          >
            + New Transfer
          </CreateButton>
        )
      }
    >
      <Box mb="md">
        <Filters
          statusLabel="Status"
          searchLabel="Search"
          statusOptions={STATUS_OPTIONS}
          handleChange={updateFilters}
          partnerLocation={options}
          values={filters}
        />
      </Box>
      <DataTable
        data-testid="transfer-list-table"
        {...PROPS_DATA_TABLE}
        records={output}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        onRowClick={({ id }) => navigate(getViewPath(rootPath, id))}
        columns={DATA_TABLE_COLUMS}
      />
    </ContentLayout>
  );
};
