import { useNavigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import { ContentLayout, CreateButton } from 'components';
import { getCreatePath, getViewPath, PROPS_DATA_TABLE } from 'shared';
import { Filters } from '../filters';
import { useSkus } from '../../hooks';

export const List = ({
  canCreate,
  rootPath,
}: {
  canCreate: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const {
    page,
    filters,
    output,
    isLoading,
    totalRecords,
    updateFilters,
    handleSetSearchParams,
  } = useSkus();

  return (
    <ContentLayout
      title="Products"
      processing={isLoading}
      rightAction={
        canCreate && (
          <CreateButton data-testid="new-sku-btn" to={getCreatePath(rootPath)}>
            + New Product
          </CreateButton>
        )
      }
    >
      <Box mb="md">
        <Filters handleChange={updateFilters} values={filters} />
      </Box>

      <DataTable
        {...PROPS_DATA_TABLE}
        records={output}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        onRowClick={({ id }) => navigate(getViewPath(rootPath, id))}
        columns={[
          { width: 120, accessor: 'sku_number', title: 'Product#' },
          { width: 350, accessor: 'product_name', title: 'Product Name' },
          { accessor: 'inventory_type', title: 'Inventory Type' },
          { accessor: 'name', title: 'Category' },
          { accessor: 'margin', title: 'Margin' },
          { accessor: 'state', title: 'State' },
        ]}
      />
    </ContentLayout>
  );
};
