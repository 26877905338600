import { FC, useMemo } from 'react';
import { TextInput, Select, Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { useApiQuery } from 'hooks';
import { ProductVendorService } from 'App/api';
import { InventoryFormValues, getSelectOptions } from 'shared';

export type Props = {
  form: UseFormReturnType<
    InventoryFormValues,
    (values: InventoryFormValues) => InventoryFormValues
  >;
};

export const VendorData: FC<Props> = ({ form }): JSX.Element => {
  const { data } = useApiQuery(['getVendors'], () =>
    ProductVendorService.getCollection({ page_size: 1000 }),
  );
  const options = useMemo(
    () =>
      data
        ? getSelectOptions(
            data.items.map(({ id, company_name }) => ({
              id,
              name: company_name,
            })),
          )
        : [],
    [data],
  );
  return (
    <Grid gutter={'xl'}>
      <Grid.Col span={12}>
        <TextInput
          required
          label="Order #"
          {...form.getInputProps('order_number')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Select
          required
          searchable
          label="Vendor Name"
          data={options}
          {...form.getInputProps('vendor_id')}
        />
      </Grid.Col>
    </Grid>
  );
};
