import { useMemo } from 'react';
import { getSelectOptions } from 'shared';
import { useCategories } from 'pages/products';

export const useInitialForm = (categoryId: string | null) => {
  const { categories, categoryOptions } = useCategories();

  const subcategoryOptions = useMemo(() => {
    const category = categories.find((c) => categoryId === c.id);
    return getSelectOptions(category?.subcategories ?? []);
  }, [categories, categoryId]);

  return {
    categoryOptions,
    subcategoryOptions,
  };
};
