import dayjs from 'dayjs';
import {
  EmployeeViewListData,
  UpdEmployeeViewData,
  Employee,
  hasPOSAccess,
  convertToCaliforniaTZ,
} from 'shared';

const startDateFormat = 'MMM D, YYYY';
const updDateFormat = 'MMM D, YYYY, h.mm A';

export const getListData = (data: Employee): EmployeeViewListData => ({
  firstName: { label: 'First Name', value: data.first_name },
  lastName: { label: 'Last Name', value: data.last_name },
  email: { label: 'Email', value: data.email },
  startDate: {
    label: 'Start Date',
    value: data.start_date
      ? dayjs(data.start_date).format(startDateFormat)
      : '',
  },
  primaryLocation: {
    label: 'Primary Location',
    value: data.primary_location.name,
  },
  role: {
    label: 'Role',
    value: data.role.name,
  },
  ...(hasPOSAccess(data.role.permissions)
    ? {
        passcode: {
          label: 'Passcode',
          value: data.passcode ?? '',
        },
      }
    : {}),
});

export const getUpdateData = (data: Employee): UpdEmployeeViewData => ({
  updateDate: {
    label: 'Update Date',
    value: data?.issued_at
      ? convertToCaliforniaTZ(data?.issued_at).format(updDateFormat)
      : '',
  },
  updatedBy: {
    label: 'Updated By',
    value:
      data?.issued_by?.first_name && data?.issued_by?.last_name
        ? `${data?.issued_by?.first_name} ${data?.issued_by?.last_name}`
        : ' - ',
  },
});
