import { ReactNode } from 'react';
import { Badge, Box, Flex, Paper, Text } from '@mantine/core';

type Props = {
  title: string | null;
  isDefault?: boolean;
  description: string | null;
  subDescriptions?: string;
  children: ReactNode;
};

export const Card = ({
  title,
  isDefault,
  description,
  children,
  subDescriptions,
}: Props) => {
  return (
    <Paper p="lg" withBorder mb="xl">
      <Flex justify="space-between">
        <Flex align="center" gap={16} pb="xl">
          <Text color="dark" weight={600} size="lg">
            {title}
          </Text>
          {isDefault && (
            <Badge variant="filled" color="halterBlue.5">
              Default
            </Badge>
          )}
        </Flex>
        <Box>{children}</Box>
      </Flex>

      <Text color="dark" size="md">
        {subDescriptions}
      </Text>
      <Text color="dark" size="md">
        {description}
      </Text>
    </Paper>
  );
};
