import { useMemo, useState } from 'react';
import { MultiSelect, Button, Grid, Text } from '@mantine/core';
import { openModal, closeAllModals } from '@mantine/modals';

import { useApiQuery } from 'hooks';
import { ProductCategoryService } from 'App/api';
import { getSelectOptions } from 'shared';

const Content = ({ onSubmit }: { onSubmit: (d: string[]) => void }) => {
  const { data, isLoading } = useApiQuery(
    ['free-product'],
    ProductCategoryService.getFreeSKUs,
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const [value, setValue] = useState<string[]>([]);

  const options = useMemo(() => {
    if (!data) {
      return [];
    }
    return getSelectOptions(
      data.items.map(({ id, name, sku_number }) => ({
        id,
        name: `[${sku_number}] ${name}`,
      })),
    );
  }, [data]);
  return (
    <Grid align="flex-end" mb="xl">
      <Grid.Col span={9}>
        <MultiSelect
          data={options}
          placeholder="Select SKUs"
          value={value}
          onChange={setValue}
          searchable
          nothingFound="Nothing found"
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <Button
          disabled={isLoading}
          fullWidth
          onClick={() => {
            onSubmit(value);
            closeAllModals();
          }}
          mt="md"
        >
          Add
        </Button>
      </Grid.Col>
    </Grid>
  );
};

export const AddSkus = ({
  addProducts,
}: {
  addProducts: (d: string[]) => void;
}) => {
  return (
    <Button
      fullWidth
      onClick={() => {
        openModal({
          size: 'lg',
          title: (
            <Text size={'md'} weight={600}>
              Add Products to a Category
            </Text>
          ),
          children: <Content onSubmit={addProducts} />,
        });
      }}
    >
      Add Products
    </Button>
  );
};
