import { Flex, Box, Text } from '@mantine/core';

export const TransferView = ({
  originLocationName,
  originInventoryLocationName,
  destinationLocationName,
  destinationInventoryLocationName,
}: {
  originLocationName?: string;
  originInventoryLocationName?: string;
  destinationLocationName?: string;
  destinationInventoryLocationName?: string;
}) => {
  const fullWidth = { width: '100%' };
  const gap = 50;

  return (
    <>
      <Flex sx={fullWidth} justify="space-between" mb="lg">
        <Box>
          <Text mb="xs" size={14} weight={600}>
            Origin Location:
          </Text>
          <Text mb="xs" size={14}>
            {originLocationName}
          </Text>
          <Text mb="xs" size={14}>
            {originInventoryLocationName}
          </Text>
        </Box>
        <Box>
          <Text mb="xs" size={14} weight={600}>
            Destination Location:
          </Text>
          <Text mb="xs" size={14}>
            {destinationLocationName}
          </Text>
          <Text mb="xs" size={14}>
            {destinationInventoryLocationName}
          </Text>
        </Box>
      </Flex>

      <Flex mb="xs" sx={fullWidth} justify="space-between" gap={gap}>
        <Box sx={fullWidth}>
          <Text size={14} weight={600}>
            Product Name
          </Text>
        </Box>
        <Flex justify="space-between" sx={fullWidth}>
          <Flex sx={fullWidth}>
            <Text size={14} weight={600}>
              Qty
            </Text>
          </Flex>
          <Flex sx={{ ...fullWidth, justifyContent: 'center' }}>
            <Text size={14} weight={600}>
              Already Received
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
