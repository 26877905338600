import { Link } from 'react-router-dom';

import { ContentLayout, BackButton } from 'components';
import { TaxRate } from 'shared';
import { useCreateSkus } from '../../hooks';
import { Form } from '../form';
import { getNewItem } from '../form/helper';

export const initialValues = {
  product_name: '',
  is_enabled: true,
  is_kit: false,
  hide_from_pos: false,
  inventory_type: undefined,
  category_id: null,
  subcategory_id: null,
  cost: null,
  price: '',
  tax_rate: TaxRate.DEFAULT_RATE,
  vendor_id: null,
  processing_time: 'not_assigned',
  pickup_only: false,
  members_only: false,
  ships_separately: false,
  width: null,
  height: null,
  depth: null,
  weight: null,
  per_order_limit: '',
  product_file: '',
  e_gift_card: true,
  product_description: '',
  comment: '',
  saved_as_draft: false,
  show_in_ecommerce: false,
  sku_number: null,
  specific_information: {
    is_available_by_glass: false,
    is_available_only_by_glass: false,
    has_sample: false,
    abv: '',
    bottle_size: null,
    varietal: null,
    glasses_per_bottle: null,
    items: [],
    locations: [getNewItem()],
  },
  btg: {
    btg_tax_rate: undefined,
    btg_cost: null,
    btg_price: '',
  },
  is_non_inventory: false,
  mobile_image_url: '',
  web_image_url: '',
  web_hover_image_url: '',
  category_options: [],
};

export const Create = ({
  onCreate,
  rootPath,
}: {
  onCreate: (id: string) => void;
  rootPath: string;
}): JSX.Element => {
  const { handleSubmit, isLoading, processingUpload } = useCreateSkus({
    onSuccess: onCreate,
  });

  return (
    <ContentLayout
      title="New Product"
      leftAction={<BackButton component={Link} to={rootPath} />}
    >
      <Form
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        processing={isLoading || processingUpload}
        isCreate
      />
    </ContentLayout>
  );
};
