import { useQueryClient } from '@tanstack/react-query';

import { RoleService } from 'App/api';
import { useApiQuery, useApiMutation } from 'hooks';
import { RoleDto, EditRole } from 'shared';

export const useRole = ({
  roleId,
  onSuccess: handleSuccess = () => null,
}: {
  roleId: string;
  onSuccess?: () => void;
}): {
  role: RoleDto | undefined;
  isLoading: boolean;
  isError: boolean;
  remove: (r: string) => void;
  edit: (r: EditRole) => void;
} => {
  const key = ['role', roleId];
  const {
    data: role,
    isLoading,
    isError,
  } = useApiQuery(key, () => RoleService.getOne(roleId));

  const client = useQueryClient();

  const refetch = () => {
    client.invalidateQueries(key);
  };

  const onSuccess = () => {
    handleSuccess();
    refetch();
  };

  const { mutate: remove, isLoading: processing } = useApiMutation(
    ['deleteRole'],
    (role: string) => RoleService.delete(role),
    {
      onSuccess,
    },
  );

  const { mutate: edit, isLoading: editing } = useApiMutation(
    ['editRole'],
    (data: EditRole) => RoleService.edit(data),
    {
      onSuccess,
    },
  );

  return {
    role,
    isLoading: isLoading || processing || editing,
    isError,
    remove,
    edit,
  };
};
