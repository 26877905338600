import {
  Alert,
  Box,
  TextInput,
  Container,
  Stack,
  Button,
  Text,
} from '@mantine/core';
import { isEmpty } from 'rambda';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { useApiMutation } from 'hooks';
import { AuthService } from 'App/api';
import { Logo as HRLogo } from 'assets/icons';
import { Logo } from 'components';
import { getMessage, emailValidationRule, EMAIL_INPUT } from 'shared';

export const ForgotPassword = (): JSX.Element => {
  const { mutate, error, isSuccess, isLoading } = useApiMutation(
    ['forgotPassword'],
    (email: string) => AuthService.forgotPassword(email),
  );
  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: yupResolver(Yup.object({ email: emailValidationRule })),
    validateInputOnBlur: true,
  });
  return (
    <Container size="xs">
      <Stack justify="center" sx={{ minHeight: '100vh' }}>
        <Logo>
          <HRLogo />
        </Logo>
        {!!error && (
          <Alert color="red">
            <Text size={'lg'}>{getMessage(error)}</Text>
          </Alert>
        )}
        {isSuccess ? (
          <Text align="center">
            The link to reset your password has been sent to provided email.
          </Text>
        ) : (
          <Box>
            <form onSubmit={form.onSubmit((values) => mutate(values.email))}>
              <TextInput
                label="Email Address"
                required
                placeholder="your@email.com"
                data-testid={EMAIL_INPUT}
                {...form.getInputProps('email')}
              />
              <Box mt={40}>
                <Button
                  disabled={!isEmpty(form.errors)}
                  type="submit"
                  fullWidth
                  loading={isLoading}
                  data-testid="send-reset-link"
                >
                  Send Reset Link
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Stack>
    </Container>
  );
};
