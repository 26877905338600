import { ReactElement } from 'react';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { ToastProvider } from 'react-toast-notifications';

import { AppRoutes } from './routes';
import { SessionProvider } from './session';
import QueryClientProvider from './api/QueryClientProvider';
import { theme } from './theme';

function App(): ReactElement {
  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <QueryClientProvider>
        <ToastProvider>
          <ModalsProvider>
            <SessionProvider>
              <AppRoutes />
            </SessionProvider>
          </ModalsProvider>
        </ToastProvider>
      </QueryClientProvider>
    </MantineProvider>
  );
}

export default App;
