import * as Yup from 'yup';
import dayjs from 'dayjs';

import { zipCodeRegex } from 'shared/validations/regex';
import { phoneNumberValidator, requiredStringValidationRule } from 'shared';

const fieldNotRequired = Yup.string().nullable();

const dateOfBirth = Yup.string()
  .test('Is date greater', 'Age must be 21 years old or more', (value) => {
    const currentDate = dayjs(new Date());
    if (!value) return true;
    return dayjs(currentDate.diff(value)).year() - 1970 >= 21;
  })
  .matches(/^(0[1-9]|1[0-2]).(0[1-9]|[12]\d|3[01]).(19|20)\d{2}$/, {
    excludeEmptyString: true,
    message: 'Incorrect date of birth',
  })
  .nullable()
  .notRequired();

const getEmailPhoneDateOfBirth = (isEdit = false) => {
  return {
    email: isEdit
      ? requiredStringValidationRule('Email Address')
          .email('Please enter valid email address')
          .nullable()
      : Yup.string()
          .email('Please enter valid email address')
          .nullable()
          .notRequired(),

    phone_number: isEdit
      ? phoneNumberValidator
      : Yup.string().nullable().notRequired(),

    date_of_birth: isEdit
      ? dateOfBirth.required('Date of Birth is required')
      : dateOfBirth,
  };
};

export const addressStepSchema = Yup.object({
  address_name: fieldNotRequired,
  first_name: fieldNotRequired,
  last_name: fieldNotRequired,
  country: fieldNotRequired,
  state: fieldNotRequired,
  city: fieldNotRequired,
  business: fieldNotRequired,
  street_address_one: fieldNotRequired,
  street_address_two: fieldNotRequired,
  ...getEmailPhoneDateOfBirth(),
  zip_code: Yup.string().matches(zipCodeRegex, 'Invalid zip code.').nullable(),
  is_same_as_shipping_address: Yup.boolean(),
  is_default: Yup.boolean(),
});

export const addressEditSchema = Yup.object({
  address_name: fieldNotRequired,
  first_name: requiredStringValidationRule('First Name'),
  last_name: requiredStringValidationRule('Last Name'),
  country: requiredStringValidationRule('Country'),
  state: requiredStringValidationRule('State'),
  city: requiredStringValidationRule('City'),
  ...getEmailPhoneDateOfBirth(true),
  business: fieldNotRequired,
  street_address_one: requiredStringValidationRule('Street Address'),
  street_address_two: fieldNotRequired,
  zip_code: requiredStringValidationRule('Zip')
    .matches(zipCodeRegex, 'Invalid zip code.')
    .nullable(),
  is_same_as_shipping_address: Yup.boolean(),
  is_default: Yup.boolean(),
});
