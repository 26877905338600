import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { Edit, List } from './components';
import { NestedRouteComponent } from 'components';
import { BulkActionProvider, usePermissions } from 'hooks';
import { Permissions } from 'shared';

export const CycleOrders = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.CycleOrders,
  });
  const onExit = useGotoRoute(RouteName.CycleOrders);

  const canEdit = checkAccess(
    Permissions.admin_panel_shipment_cycle_sales_order_edit,
  );

  const ListComponent = <List rootPath={rootPath} />;
  const EditComponent = <Edit onExit={onExit} />;

  return (
    <BulkActionProvider rootPath={rootPath}>
      <NestedRouteComponent
        ListComponent={ListComponent}
        rootPath={rootPath}
        showEdit={canEdit}
        Edit={EditComponent}
      />
    </BulkActionProvider>
  );
};
