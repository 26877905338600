import { omit } from 'rambda';

import { ContentLayout, BackButton } from 'components';
import { AdjustmentType, AdjustmentPayload } from 'shared';
import { InventoryAdjustmentService } from 'App/api';
import { useApiMutation } from 'hooks';
import { Form, getNewItem } from '../form';

const title = 'New Adjustment';

const initialValues = {
  inventory_location_id: '',
  adjustment_type: AdjustmentType.SPOIL,
  comment: '',
  items: [getNewItem()],
};

export const Create = ({
  onExit,
  onCreate,
}: {
  onExit: () => void;
  onCreate: (id: string) => void;
}): JSX.Element => {
  const { mutate, isLoading } = useApiMutation(
    ['create-adjustment'],
    InventoryAdjustmentService.create,
    {
      onSuccess: ({ id }) => onCreate(id),
    },
  );

  const handleSubmit = ({ comment, items, ...data }: AdjustmentPayload) => {
    mutate({
      ...data,
      items: items.map(omit(['key'])),
      ...(comment && { comment }),
    });
  };
  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      leftAction={<BackButton onClick={onExit} />}
    >
      <Form handleSubmit={handleSubmit} initialValues={initialValues} />
    </ContentLayout>
  );
};
