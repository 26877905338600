import { Button, Collapse, Switch } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { CreateSku, InventoryType } from 'shared';
import { getNewItem } from '../helper';
import { LocationItem } from './LocationItem';

type PropType = {
  form: UseFormReturnType<CreateSku, (values: CreateSku) => CreateSku>;
  editMode?: boolean;
};

export const NonInventory = ({ form, editMode }: PropType) => {
  const locations = form.values.specific_information?.locations;
  const isNonInventory = form.values.is_non_inventory ?? false;
  const inventoryType = form.values.inventory_type;

  const showFields = [
    InventoryType.EXPERIENCES,
    InventoryType.TASTING,
    InventoryType.DISCOUNTS,
  ].includes(inventoryType as InventoryType);

  return showFields ? (
    <>
      <Switch
        labelPosition="right"
        label="Non-Inventory Controlled"
        sx={{ fontWeight: 600, marginBottom: 30 }}
        {...form.getInputProps('is_non_inventory', { type: 'checkbox' })}
        onChange={(e) => {
          if (editMode) {
            form.setFieldValue('is_non_inventory', e.currentTarget.checked);
            form.setFieldValue('specific_information', {
              ...form.values.specific_information,
              locations: [getNewItem()],
            });
          } else {
            form.setFieldValue('is_non_inventory', e.currentTarget.checked);
          }
        }}
      />

      <Collapse in={isNonInventory} transitionDuration={1000}>
        {locations?.map((l, index) => (
          <LocationItem
            key={l.key ?? `${l.location?.id}${l.inventory_location.id}`}
            form={form}
            index={index}
          />
        ))}

        <Button
          mt={10}
          variant="white"
          onClick={() =>
            form.insertListItem('specific_information.locations', getNewItem())
          }
        >
          + Add New Location
        </Button>
      </Collapse>
    </>
  ) : null;
};
