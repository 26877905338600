import { Grid, Select } from '@mantine/core';

import { LocationSelect, SearchInput } from 'components';
import { FilterValue, SEARCH_INPUT, SelectOption } from 'shared';

interface Props {
  searchLabel: string;
  statusLabel?: string;
  locationLabel?: string;
  values: FilterValue;
  handleChange: (v: FilterValue) => void;
  statusOptions?: SelectOption[];
  partnerLocation?: SelectOption[];
}

export const Filters = ({
  handleChange,
  values,
  searchLabel,
  statusLabel,
  statusOptions,
  partnerLocation,
  locationLabel,
}: Props): JSX.Element => {
  const { search_string, location, status } = values;

  return (
    <Grid justify="space-between">
      <Grid.Col span={7}>
        <Grid>
          <Grid.Col span={7}>
            <LocationSelect
              label={locationLabel}
              data={partnerLocation}
              handleChange={(v) => handleChange({ location: v })}
              value={location}
            />
          </Grid.Col>
          <Grid.Col span={5}>
            {statusOptions ? (
              <Select
                data-testid="status-dropdown"
                label={statusLabel}
                value={status}
                searchable
                nothingFound="No options"
                data={statusOptions}
                onChange={(v: string) => handleChange({ status: v })}
              />
            ) : null}
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={5} lg={4}>
        <SearchInput
          data-testid={SEARCH_INPUT}
          handleSubmit={(v: string) => handleChange({ search_string: v })}
          label={searchLabel}
          value={search_string}
        />
      </Grid.Col>
    </Grid>
  );
};
