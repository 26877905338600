import { reduce, append } from 'rambda';
import { SelectItem } from '@mantine/core';

import { ProductCategoryDto } from 'shared';

export function getNestedOptions(data: ProductCategoryDto[]): SelectItem[] {
  return reduce(
    (acc, { id, name, subcategories }) => {
      let res: SelectItem[] = [
        ...acc,
        ...(!subcategories.length ? [{ label: name, value: id }] : []),
      ];
      if (subcategories?.length) {
        subcategories.forEach(({ name: sname, id: sid }) => {
          res = append({ label: `[${name}]: ${sname}`, value: sid }, res);
        });
      }
      return res;
    },
    [] as SelectItem[],
    data,
  );
}
