import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing, Replay } from '@sentry/browser';

import App from 'App/App';
import { Environment } from 'shared';

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_ENVIRONMENT = '',
  REACT_APP_SENTRY_TRACE_SAMPLE_RATE = 0.1,
} = process.env;

const isDevEnvironment = [Environment.Development, Environment.Qa].includes(
  REACT_APP_ENVIRONMENT as Environment,
);

const integrations = isDevEnvironment
  ? [new BrowserTracing(), new Replay()]
  : [new BrowserTracing()];

if (REACT_APP_ENVIRONMENT !== Environment.Local) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations,
    environment: REACT_APP_ENVIRONMENT,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: REACT_APP_SENTRY_TRACE_SAMPLE_RATE as number,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
);
