import { MultiSelect } from '@mantine/core';

import { useLocations } from 'App/contexts';
import { SelectOption } from 'shared';

interface Props {
  label?: string;
  data?: SelectOption[];
  handleChange: (v: string[]) => void;
  value?: string[];
}

export const LocationSelect = ({
  handleChange,
  data,
  value,
  label = 'Location',
}: Props): JSX.Element => {
  const { actualLocationOptions } = useLocations();

  return (
    <MultiSelect
      data-testid={`${label.replace(/\s+/g, '-').toLowerCase()}-dropdown`}
      label={label}
      value={value}
      searchable
      nothingFound="No options"
      data={data ?? actualLocationOptions}
      onChange={(v) => {
        handleChange(v);
      }}
    />
  );
};
