import { omit } from 'rambda';
import { CulinaryMenuService } from 'App/api';
import { useApiMutation, useApiQuery } from 'hooks';
import { CreateCulinaryMenu, normalizeCulinaryMenuValue } from 'shared';

const getCulinaryMenu = 'getCulinaryMenu';

export const useEditMenu = ({
  id,
  onExit,
}: {
  id: string;
  onExit?: () => void;
}) => {
  const { data, isLoading } = useApiQuery(
    [getCulinaryMenu, id],
    () => CulinaryMenuService.getOne({ id }),
    {
      cacheTime: 0,
    },
  );

  const { mutate: updateMenu, isLoading: isUpdating } = useApiMutation(
    [getCulinaryMenu, id],
    CulinaryMenuService.update,
    { onSuccess: onExit },
  );

  const { mutate: deleteMenu, isLoading: isDeleting } = useApiMutation(
    [getCulinaryMenu, id],
    () => CulinaryMenuService.delete({ id }),
    {
      onSuccess: onExit,
    },
  );

  const handleSubmit = (id: string, value: CreateCulinaryMenu) => {
    const valueCulinaryMenu = omit(['location_id'], value);

    const payload = {
      id,
      data: {
        ...valueCulinaryMenu,
      },
    };

    updateMenu(payload);
  };

  const initialValues = data && normalizeCulinaryMenuValue(data);

  return {
    data,
    initialValues,
    isLoading,
    handleSubmit,
    deleteMenu,
    isDeleting,
    isUpdating,
  };
};
