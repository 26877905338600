import { FC } from 'react';
import { SimpleGrid, Box, Container } from '@mantine/core';
import { Employee } from 'shared';
import { Item } from './components';
import { UpdatingData } from '../updatingData';
import { getListData, getUpdateData } from 'pages/employees/helpers';

export const PersonalData: FC<{
  employee: Employee;
}> = ({ employee }): JSX.Element => {
  const listData = getListData(employee);
  const updateData = getUpdateData(employee);

  return (
    <Box>
      <Container size="sm">
        <SimpleGrid cols={2} spacing={24} verticalSpacing="lg" mb={20}>
          {listData &&
            Object.values(listData).map((item) => (
              <Item key={item.label} item={item} />
            ))}
        </SimpleGrid>
      </Container>
      <UpdatingData updateData={updateData} />
    </Box>
  );
};
