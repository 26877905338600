import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, Flex, Text } from '@mantine/core';

import {
  BackButton,
  ContentLayout,
  EditButton,
  TitleWithBadge,
} from 'components';
import {
  getEditPath,
  getReceivePath,
  StatusInvetoryTransfer,
  InventoryTransferStatus,
  RECEIVE_BTN,
} from 'shared';
import { useEditInventoryTransfer } from '../../hooks';
import { TransferView } from '../TransferView';

export const Details = ({
  canEdit,
  canReceive,
  rootPath,
}: {
  canEdit: boolean;
  canReceive: boolean;
  rootPath: string;
}): JSX.Element => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useEditInventoryTransfer({ id });

  const {
    origin_inventory_location: origin,
    destination_inventory_location: destination,
    items,
    status,
    number_str,
  } = data ?? {};
  const title = `Transfer ${number_str}`;
  const transferStatus = status && StatusInvetoryTransfer[status];

  const flex = {
    width: '80px',
    display: 'flex',
  };
  const fullWidth = { width: '100%' };
  const gap = 50;

  return (
    <ContentLayout
      title={
        !isLoading && (
          <TitleWithBadge
            data-testid="transfer-status"
            title={title}
            status={transferStatus}
          />
        )
      }
      isLoading={isLoading}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
      rightAction={
        canEdit &&
        status === InventoryTransferStatus.transit && (
          <EditButton
            data-testid="transfer-edit-btn"
            component={Link}
            to={getEditPath(rootPath, id)}
          />
        )
      }
    >
      <Container size="sm">
        <TransferView
          originLocationName={origin?.location_name}
          originInventoryLocationName={origin?.name}
          destinationLocationName={destination?.location_name}
          destinationInventoryLocationName={destination?.name}
        />

        {items?.map((sku) => {
          const remained = (sku.quantity ?? 0) - (sku.received_quantity ?? 0);
          return (
            <Flex
              key={sku.sku_id}
              sx={fullWidth}
              justify="space-between"
              gap={gap}
              mb="xs"
            >
              <Box sx={fullWidth}>
                <Text size={14}>{sku.sku_product_name}</Text>
              </Box>
              <Flex justify="space-between" sx={fullWidth}>
                <Flex align="center" sx={fullWidth}>
                  <Text sx={flex} size={14}>
                    {remained}
                  </Text>
                </Flex>
                <Flex sx={{ ...fullWidth, justifyContent: 'center' }}>
                  <Text size={14}>{sku.received_quantity}</Text>
                </Flex>
              </Flex>
            </Flex>
          );
        })}
        {status !== InventoryTransferStatus.fully_received && canReceive && (
          <Box my="xl">
            <Button
              data-testid={RECEIVE_BTN}
              fullWidth
              component={Link}
              to={getReceivePath(rootPath, id)}
            >
              Receive
            </Button>
          </Box>
        )}
      </Container>
    </ContentLayout>
  );
};
