import { Box, useMantineTheme } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';

import { useInventoryList } from 'pages/inventory/hooks';
import { ContentLayout, CreateButton } from 'components';
import { NestedRoutes, getViewPath, PROPS_DATA_TABLE } from 'shared';
import { Filters } from '../filters';

export const List = ({
  rootPath,
  canCreate,
}: {
  rootPath: string;
  canCreate: boolean;
}): JSX.Element => {
  const {
    output,
    isLoading,
    page,
    filters,
    totalRecords,
    updateFilters,
    handleSetSearchParams,
  } = useInventoryList();

  const theme = useMantineTheme();
  const navigate = useNavigate();
  const getViewHandler = (id: string) => navigate(getViewPath(rootPath, id));
  return (
    <ContentLayout
      title="Inventories"
      processing={isLoading}
      rightAction={
        canCreate && (
          <CreateButton
            data-testid="add-inventory-list-item"
            to={`${rootPath}/${NestedRoutes.Create}`}
          >
            + Add Inventory
          </CreateButton>
        )
      }
    >
      <Box mb="md">
        <Filters handleChange={updateFilters} values={filters} />
      </Box>

      <DataTable
        data-testid="inventory-list-table"
        {...PROPS_DATA_TABLE}
        records={output}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        onRowClick={({ id }) => getViewHandler(id)}
        rowStyle={({ isLowStock }) =>
          isLowStock ? { color: theme.colors.red[6] } : undefined
        }
        columns={[
          { width: 130, accessor: 'sku_number', title: 'SKU#' },
          {
            accessor: 'product_name',
            title: 'Product Name',
          },
          { accessor: 'inventory_type', title: 'Inventory Type' },
          { accessor: 'quantity', title: 'Stock' },
        ]}
      />
    </ContentLayout>
  );
};
