import axios from 'axios';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

const client = axios.create({
  baseURL: 'https://api.stripe.com/v1/tokens',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

type Card = {
  'card[number]': string;
  'card[exp_month]': string;
  'card[exp_year]': string;
  'card[cvc]': string;
  'card[name]': string;
  'card[address_city]': string | null;
  'card[address_country]': string | null;
  'card[address_line1]': string | null;
  'card[address_line2]': string | null;
  'card[address_state]': string | null;
  'card[address_zip]': string | null;
};

export default class StripeService {
  static readonly createToken = (
    data?: Card,
  ): Promise<{ data: { id: string | null } }> => {
    return client({
      method: 'post',
      data: { ...data, key: REACT_APP_STRIPE_PUBLIC_KEY },
    });
  };
}
