import { ShortBase } from '../common';
import { Modifier } from '../culinary';
import { DiscountUnit } from '../discount';
import {
  AdvisorSchema,
  SalesOrderStatus,
  SalesOrderPaymentStatus,
  SupplyType,
  SalesOrderItemChannel,
} from '../membersClub';
import { Fee, Location, LocationTypes } from '../settings';

export enum PaymentMethod {
  cash = 'cash',
  card = 'card',
}

interface ShortCustomer {
  id: string;
  first_name: string;
  last_name: string;
  main_tier: {
    club_tier: {
      name: string;
    };
  } | null;
}

enum PosOrderType {
  AT_TABLE = 'at_table',
  DIRECT_SALES = 'direct_sales',
}

interface FeeData {
  id: string;
  name: string;
  amount: number;
}

export type Summary = {
  discounts: number;
  items_price: number;
  taxes: number;
  fees: number;
  tips: number;
  total: number;
  shipping_cost?: number;
  shipping_taxes?: number;
  promocode_discounts?: number;
  promocode_name?: string | null;
};

export interface PosSalesItem {
  id: string;
  quantity: number;
  refunded_quantity: number;
  product_name: string;
  sku_type?: string;
  pure_price_per_item: number;
  total_taxes: number;
  fee_value: number;
  fee_data: FeeData[];
  discount: number;
  full_price: number;
  promocode_discount: number;
  sku_id: string;
  modifiers: Modifier[];
  modifiers_price: number;
  course?: number;
  notes: string;
  status: string;
  payment_status?: SalesOrderPaymentStatus;
  is_item_splitted: boolean;
  owner: Payer | null;
  sub_total: number;
  promocode_data: {
    unit: DiscountUnit;
  };
  split_data: {
    original_item_id: string;
    split_parts_quantity: number;
    single_pure_item_price: number;
    single_item_discount: number;
    is_split_part_paid: boolean;
  };
  is_paid: boolean;
}

export interface PosSalesItemOutput extends Partial<PosSalesItem> {
  id: string;
  quantity: number;
  product_name: string;
  discount_string: string;
  total_price: string;
  retail_price: string;
  discounted_price: string;
}

export interface Checkout {
  id: string;
  items: PosSalesItem[];
  customer_id?: string;
  total: number;
  items_price: number;
  taxes: number;
  fees: number;
  fee_data: FeeData[];
  discounts: number;
  tips: number;
  is_paid: boolean;
  shipping?: number;
  shipping_taxes: number;
}

export interface PosGuest {
  id: string;
  name?: string | null;
  customer?: ShortCustomer | null;
  checkout: Checkout;
  seat_number?: number;
  table_id?: string | null;
}

export interface PosSalesOrderDto {
  id: string;
  number: string;
  status: SalesOrderStatus;
  payment_status: SalesOrderPaymentStatus;
  type: 'general' | 'shipment_cycle' | 'pos';
  supply_type: SupplyType;
  created_at: string;
  fully_paid_at: string;
  updated_at: string;
  customer?: ShortCustomer | null;
  items?: PosSalesItem[];
  source?: string;
  deplete_inventory_location: {
    id: string;
    name: string;
  };
  deplete_location: {
    id: string;
    name: string;
    type: LocationTypes;
  };
  local_tax: {
    id: string;
    value: number;
    location: Location;
    fees: Fee[];
  };
  s3_receipt_path: string | null;
  channel: SalesOrderItemChannel;
  pos_order_type: PosOrderType;
  pos_payment_type: PosSalesPaymentType;
  total: number;
  tips: number;
  guests: PosGuest[];
  default_wine_advisor: AdvisorSchema | null;
  tables?: ShortBase[];
  greatest_club_tier_name?: string;
  is_receipt_generated: boolean;
}

export interface PosSalesOrderPayload {
  status?: SalesOrderStatus;
  source?: string;
  deplete_inventory_location_id?: string;
  customer_id?: string;
  pos_order_type?: PosOrderType;
  tables_ids?: string[];
  guests?: PosGuest[];
  default_wine_advisor_id: string;
}

export interface SendReceiptPayload {
  delivery_type?: 'email' | 'sms';
  delivery_target?: string;
}

export enum PosSalesPaymentType {
  PER_ITEM = 'per_item',
  PER_GUEST = 'per_guest',
  OTHER = 'other',
  WHOLE_TICKET = 'whole_ticket',
}

export interface Payer {
  id: string;
  number: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
}
