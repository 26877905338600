import { useState } from 'react';
import {
  NumberInput,
  Select,
  Switch,
  Text,
  TextInput,
  Grid,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  inventoryTypeOptions,
  ProfileSku,
  taxRateOptions,
  SWITCH_GRID_TOP_OFFSET,
  InventoryType,
  PRODUCT_INPUT,
} from 'shared';

type OptionType = {
  value: string;
  label: string;
};

type PropType = {
  form: UseFormReturnType<ProfileSku>;
  margin: string;
  skuNumber?: string | null;
  isCulinary?: boolean;
  categoryOptions: OptionType[];
  subcategyOptions: OptionType[];
  vendorsOptions?: OptionType[];
  vendorName?: string;
};

export const ProductProfile = ({
  form,
  margin,
  skuNumber,
  isCulinary = false,
  categoryOptions,
  subcategyOptions,
  vendorsOptions,
  vendorName,
}: PropType) => {
  const [price, setPrice] = useState(form.values.price);

  const isDraft = form.values.saved_as_draft;
  const fullWidth = { width: '100%' };

  return (
    <Grid gutter="xl">
      <Grid.Col span={12}>
        <Text weight={600} size="lg">
          Product Profile
        </Text>
      </Grid.Col>

      <Grid.Col span={6}>
        <TextInput
          required={!isDraft}
          sx={fullWidth}
          data-testid="sku-product-name-input"
          label="Product Name"
          {...form.getInputProps('product_name')}
        />
      </Grid.Col>

      <Grid.Col span={6} pt={SWITCH_GRID_TOP_OFFSET}>
        <Grid gutter="xl" justify="space-between">
          <Grid.Col span="auto">
            <Switch
              label="Enable"
              labelPosition="right"
              sx={{ fontWeight: 600 }}
              {...form.getInputProps('is_enabled', { type: 'checkbox' })}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <Switch
              label="Hide from POS"
              labelPosition="right"
              sx={{ fontWeight: 600 }}
              {...form.getInputProps('hide_from_pos', { type: 'checkbox' })}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            {!isCulinary && (
              <Switch
                label="E-commerce"
                labelPosition="right"
                sx={{ fontWeight: 600 }}
                {...form.getInputProps('show_in_ecommerce', {
                  type: 'checkbox',
                })}
              />
            )}
          </Grid.Col>
        </Grid>
      </Grid.Col>

      <Grid.Col span={6}>
        <Select
          required={!isDraft}
          label="Inventory Type"
          data-testid="inventory-type-input-dropdown"
          sx={fullWidth}
          rightSectionWidth={30}
          {...form.getInputProps('inventory_type')}
          data={inventoryTypeOptions}
          onChange={(value) => {
            if (value) {
              form.setFieldValue('inventory_type', value as InventoryType);
              form.setFieldValue('is_non_inventory', false);
            }
          }}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <TextInput
          label={isCulinary ? 'SKU#' : 'Product#'}
          data-testid={PRODUCT_INPUT}
          disabled
          placeholder={skuNumber ?? 'Will be displayed after saving'}
          variant="unstyled"
          sx={fullWidth}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Select
          label="Category"
          clearable
          sx={fullWidth}
          rightSectionWidth={30}
          {...form.getInputProps('category_id')}
          onChange={(value) => {
            form.setFieldValue('subcategory_id', null);
            form.setFieldValue('category_id', value);
          }}
          data={categoryOptions}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          label="Sub-Category"
          clearable
          sx={fullWidth}
          disabled={!subcategyOptions.length}
          rightSectionWidth={30}
          value={form.values.subcategory_id}
          {...form.getInputProps('subcategory_id')}
          data={subcategyOptions}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Grid gutter={'xl'}>
          <Grid.Col span={6}>
            <NumberInput
              hideControls
              required={!isDraft}
              label="Cost ($)"
              data-testid="cost-input"
              sx={fullWidth}
              precision={2}
              parser={(value) => value?.replace(/\$\s?|%\s?/g, '')}
              formatter={(value) => (value ? `$${value}` : '')}
              {...form.getInputProps('cost')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              required={!isDraft}
              label={isCulinary ? 'Price ($)' : 'Price ($ or %)'}
              data-testid="price-input"
              sx={fullWidth}
              {...form.getInputProps('price')}
              value={price}
              onChange={(event) => {
                const value = event?.currentTarget?.value.replace(/,/g, '.');
                const res = `${value === '0' ? '$' : ''}${value}`;
                setPrice(res);
                form.setFieldValue('price', res);
              }}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>

      <Grid.Col span={6} pt={40}>
        <Grid gutter={'xl'}>
          <Grid.Col span={6}>
            <Text>Calculated Margin</Text>
          </Grid.Col>
          <Grid.Col span={'content'} ml="auto">
            <Text>{margin}</Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>

      <Grid.Col span={6}>
        <Select
          required
          sx={!isCulinary ? fullWidth : { width: '50%', paddingRight: 25 }}
          data-testid="tax-rate-dropdown"
          label="Tax Rate"
          rightSectionWidth={30}
          {...form.getInputProps('tax_rate')}
          data={taxRateOptions}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        {!isCulinary && vendorsOptions?.length ? (
          <Select
            sx={fullWidth}
            label="Vendor"
            rightSectionWidth={30}
            data={[{ value: null, label: 'Not Selected' }, ...vendorsOptions]}
            {...form.getInputProps('vendor_id')}
          />
        ) : (
          <Text>{vendorName ?? ''}</Text>
        )}
      </Grid.Col>
    </Grid>
  );
};
