import { useMemo, useCallback } from 'react';
import { reject, isEmpty } from 'rambda';
import { ProductSkuService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import { SkuStatus, normalizeSku } from 'shared';

export const useSkus = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    category: '',
    status: '',
    search_string: '',
    inventory_type: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';
  const category = searchParams.get('category') ?? '';
  const status = (searchParams.get('status') as SkuStatus) ?? '';
  const inventory_type = searchParams.get('inventory_type') ?? '';

  const params = useMemo(() => {
    return {
      page,
      inventory_type,
      category,
      status,
      search_string,
    };
  }, [category, inventory_type, page, search_string, status]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getProductSkus', key], () =>
    ProductSkuService.getCollection(
      reject(isEmpty, params as Record<string, unknown>),
    ),
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map((e) => normalizeSku(e));
  }, [data]);

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ ...data, page: '1' });
    },
    [handleSetSearchParams],
  );

  return {
    output,
    isLoading,
    page,
    totalRecords: data?.total ?? 0,
    filters: { inventory_type, category, status, search_string },
    updateFilters,
    handleSetSearchParams,
  };
};
