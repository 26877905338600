import request from '../request';
import { RefundItemRequest, RefundedItemCost } from 'shared';
import { SALES_ORDER_ITEMS } from '../endpoints';

export default class SalesOrderItemsService {
  static readonly getRefundedItemCost = ({
    paymentId,
    items,
  }: {
    paymentId?: string | null;
    items: RefundItemRequest[];
  }) => {
    return request<RefundedItemCost>({
      url: `${SALES_ORDER_ITEMS}/payment/${paymentId}/refunded-item-cost`,
      method: 'post',
      data: { items },
    });
  };
}
