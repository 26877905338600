import { useCallback, useState } from 'react';
import { move } from 'rambda';

import { sortByMenuPosition } from 'shared';

export function useReorder<T extends { menu_position?: number }>(items: T[]) {
  const [output, setOutput] = useState<T[]>([]);

  const restore = useCallback(() => {
    setOutput(sortByMenuPosition(items));
  }, [items]);

  const onOrderChange = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      setOutput(move(oldIndex, newIndex, output));
    },
    [output],
  );

  return {
    output,
    restore,
    onOrderChange,
  };
}
