import { Button, Flex, Select, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';

import { Payments, SendReceiptPayload } from 'shared';
import { receiptSchema } from '../form';

type TypeProps = {
  orderNumber?: string;
  handleSubmit: (data: SendReceiptPayload) => void;
  payment: Payments[];
  receiptPath: string | null;
};

export const ReceiptModal = ({
  payment,
  orderNumber,
  receiptPath,
  handleSubmit,
}: TypeProps) => {
  const form = useForm({
    initialValues: {
      email: null,
      phone: null,
    },
    validate: yupResolver(receiptSchema),
    validateInputOnBlur: true,
  });
  const { email, phone } = form.values;

  const handleSend = (
    deliveryType: SendReceiptPayload['delivery_type'],
    deliveryTarget: SendReceiptPayload['delivery_target'],
  ) => {
    handleSubmit({
      delivery_type: deliveryType,
      delivery_target: deliveryTarget,
    });
    closeAllModals();
  };

  return (
    <Flex direction="column" gap="xl">
      <Text size="sm" weight={400} align="center">
        Select the way you want to get the receipt for <br />
        order #{orderNumber}
      </Text>
      <Button component="a" href={receiptPath ?? ''} target="_blanck">
        Print as PDF
      </Button>
      <Select
        label="Email"
        clearable
        searchable
        data={payment
          .filter((item) => item.payer?.email)
          .map((item) => item.payer?.email ?? '')}
        {...form.getInputProps('email')}
      />
      <Button
        data-testid="send-email-btn"
        disabled={!email}
        onClick={() => email && handleSend('email', email)}
      >
        Send email
      </Button>
      <Select
        label="Phone Number"
        clearable
        searchable
        data={payment
          .filter((i) => i.payer?.phone_number)
          .map((i) => i.payer?.phone_number ?? '')}
        {...form.getInputProps('phone')}
      />
      <Button data-testid="send-sms-btn" disabled={!phone}>
        Send SMS
      </Button>
    </Flex>
  );
};
