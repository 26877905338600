import { Grid } from '@mantine/core';
import { SearchInput } from 'components';

import { PartnersRequest } from 'shared';

interface Props {
  handleChange: (v: { [key: string]: string | string[] }) => void;
  values: PartnersRequest;
}

export const Filters = ({ handleChange, values }: Props): JSX.Element => {
  const { search_string } = values;

  return (
    <Grid justify={'flex-end'}>
      <Grid.Col md={5} lg={4}>
        <SearchInput
          handleSubmit={(v: string) => handleChange({ search_string: v })}
          label="Search"
          value={search_string}
        />
      </Grid.Col>
    </Grid>
  );
};
