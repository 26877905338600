import { SingleValue } from 'react-select';
import { reject, without } from 'rambda';
import { ActionIcon, Flex, Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';

import { SelectAsync } from 'components';
import { useSkus } from 'hooks';
import {
  CreateCulinaryMenu,
  DELETE_BTN,
  SelectOption,
  SkuStatus,
} from 'shared';

type Props = {
  form: UseFormReturnType<
    CreateCulinaryMenu,
    (values: CreateCulinaryMenu) => CreateCulinaryMenu
  >;
  index: number;
  skuId: string;
};

export const Product = ({ form, index, skuId }: Props) => {
  const { skuOptions, isLoading, setPage, updateFilters, hasMore } = useSkus({
    inputParams: {
      is_culinary: true,
      status: SkuStatus.Enabled,
    },
  });

  const items = form.values?.items;
  const item = items[index];

  const canRemove = items?.length > 1;
  const skuIds = items?.map(({ sku_id }) => sku_id);
  const rejectSkus = without([skuId], skuIds);

  const available = reject(
    ({ value }) => rejectSkus.includes(value),
    skuOptions,
  );

  const defaultValue = { value: item.sku_id, label: item.product_name };

  return (
    <Grid gutter="lg" mb="xs">
      <Grid.Col span={6}>
        <SelectAsync
          required
          isSearchable
          searchByString={updateFilters}
          setPage={setPage}
          hasMore={hasMore}
          isLoading={isLoading}
          options={available}
          {...form.getInputProps(`items.${index}`)}
          defaultValue={defaultValue}
          onChange={(e: SingleValue<SelectOption>) => {
            if (!e?.value) return form.setFieldValue(`items.${index}`, null);

            form.setFieldValue(`items.${index}`, {
              sku_id: e.value,
              product_name: e.label,
            });
          }}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Grid align="center" justify="space-between">
          <Grid.Col span="auto">
            <Flex justify="flex-end">
              <ActionIcon
                data-testid={DELETE_BTN}
                disabled={!canRemove}
                onClick={() => form.removeListItem('items', index)}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </Flex>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
