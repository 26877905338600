import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { NestedRoutes, Permissions } from 'shared';
import { List, Create, Edit } from './components';
import { NestedRouteComponent } from 'components';

export const Customers = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.Customers,
  });

  const canCreate = checkAccess(Permissions.admin_panel_customer_create);
  const canEdit = checkAccess(Permissions.admin_panel_customer_edit);
  const onExit = useGotoRoute(RouteName.Crm);

  const CreateComponent = <Create onExit={onExit} rootPath={rootPath} />;
  const EditComponent = <Edit canEdit={canEdit} rootPath={rootPath} />;
  const ListComponent = <List canCreate={canCreate} rootPath={rootPath} />;

  return (
    <NestedRouteComponent
      showExtraRoute
      showCreate={canCreate}
      ListComponent={ListComponent}
      extraRoute={{ path: NestedRoutes.TabEdit, element: EditComponent }}
      Create={CreateComponent}
      rootPath={rootPath}
    />
  );
};
