import {
  PaginatedResponse,
  VendorDto,
  CreateVendor,
  VendorsRequest,
} from 'shared';
import { PRODUCT_VENDORS_PATH } from '../../endpoints';
import request from '../../request';

export default class VendorService {
  static readonly getCollection = (params: VendorsRequest) => {
    return request<PaginatedResponse<VendorDto>>({
      url: PRODUCT_VENDORS_PATH,
      params,
      method: 'get',
    });
  };

  static readonly create = (data: CreateVendor) => {
    return request<VendorDto>({
      url: PRODUCT_VENDORS_PATH,
      method: 'post',
      data,
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<VendorDto>({
      url: `${PRODUCT_VENDORS_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly delete = ({ id }: { id?: string }) => {
    return request({
      url: `${PRODUCT_VENDORS_PATH}/${id}`,
      method: 'delete',
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id?: string;
    data: CreateVendor;
  }) => {
    return request<VendorDto>({
      url: `${PRODUCT_VENDORS_PATH}/${id}`,
      method: 'put',
      data,
    });
  };
}
