import { Grid, TextInput, Button } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import { SEARCH_INPUT } from 'shared';

interface Props {
  handleSubmit: (v: string) => void;
  value?: string;
  label?: string;
}

export const SearchInput = ({
  handleSubmit,
  value = '',
  label,
}: Props): JSX.Element => {
  const [text, setText] = useState(value);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(text);
      }}
    >
      <Grid align={'flex-end'} gutter="sm" justify={'flex-end'}>
        <Grid.Col span={8}>
          <TextInput
            data-testid={SEARCH_INPUT}
            label={label}
            value={text}
            placeholder="Search..."
            icon={<IconSearch stroke={1} size={14} />}
            onChange={({ target: { value } }) => setText(value)}
          />
        </Grid.Col>
        <Grid.Col span={'content'}>
          <Button type="submit" data-testid="search-submit-btn">
            <IconSearch stroke={1} size={24} />
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};
