import { Routes, Route, Outlet, Navigate, useNavigate } from 'react-router-dom';

import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { usePermissions } from 'hooks';
import { getViewPath, NestedRoutes, Permissions } from 'shared';
import { List, Create, Edit, Details } from './components';

export const Partners = (): JSX.Element => {
  const navigate = useNavigate();
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.Partners,
  });
  const canCreate = checkAccess(Permissions.admin_panel_partner_create);
  const canEdit = checkAccess(Permissions.admin_panel_partner_edit);
  const canDelete = checkAccess(Permissions.admin_panel_partner_delete);
  const onExit = useGotoRoute(RouteName.Partners);
  const onCreate = (id: string) => navigate(getViewPath(rootPath, id));

  const ListComponent = (
    <List canCreate={canCreate} canEdit={canEdit} rootPath={rootPath} />
  );

  return (
    <>
      <Routes>
        <Route index element={ListComponent} />
        <Route path={NestedRoutes.List} element={ListComponent} />
        <Route
          path={NestedRoutes.View}
          element={<Details canEdit={canEdit} />}
        />
        {canCreate && (
          <Route
            path={NestedRoutes.Create}
            element={<Create onExit={onExit} />}
          />
        )}
        {canEdit && (
          <Route
            path={NestedRoutes.Edit}
            element={
              <Edit canDelete={canDelete} onExit={onExit} onCreate={onCreate} />
            }
          />
        )}
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: rootPath,
              }}
            />
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};
