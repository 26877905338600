import { useForm, yupResolver } from '@mantine/form';
import { Button, Container } from '@mantine/core';
import { AddressDto, TWalletForm } from 'shared';
import { WalletForm } from '../../forms/WalletForm';
import { walletSchema } from '../../forms/schemes';
import { walletInitialValues } from 'pages/crm/customers/helpers';

type Props = {
  defaultAddress?: AddressDto;
  isCreating: boolean;
  isUnpaid?: boolean;
  createCard: (v: TWalletForm, update_orders?: boolean) => void;
  setIsForm: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddCard = ({
  defaultAddress,
  isCreating,
  createCard,
  setIsForm,
  isUnpaid,
}: Props) => {
  const walletForm = useForm({
    initialValues: { ...walletInitialValues, ...defaultAddress },
    validate: yupResolver(walletSchema),
    validateInputOnBlur: true,
  });
  return (
    <Container size="sm">
      <WalletForm
        isEdit
        isUnpaid={isUnpaid}
        form={walletForm}
        processing={isCreating}
        handleSubmit={createCard}
      />
      <Container size="xs" my="xl">
        <Button fullWidth variant="white" onClick={() => setIsForm(false)}>
          Back to Wallet
        </Button>
      </Container>
    </Container>
  );
};
