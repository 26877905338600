import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { NestedRoutes } from 'shared';

type Props = {
  rootPath: string;
  showEdit?: boolean;
  showCreate?: boolean;
  showDetails?: boolean;
  showExtraRoute?: boolean;
  ListComponent: JSX.Element;
  Create?: JSX.Element;
  Edit?: JSX.Element;
  Details?: JSX.Element;
  extraRoute?: { path: string; element: JSX.Element };
};

const EmptyComponent = <div />;

export const NestedRouteComponent = ({
  rootPath,
  showEdit,
  showCreate = false,
  showDetails = true,
  showExtraRoute = false,
  ListComponent,
  Create = EmptyComponent,
  Edit = EmptyComponent,
  Details = EmptyComponent,
  extraRoute = { path: '', element: EmptyComponent },
}: Props) => {
  return (
    <>
      <Routes>
        <Route index element={ListComponent} />
        <Route path={NestedRoutes.List} element={ListComponent} />
        {showCreate && <Route path={NestedRoutes.Create} element={Create} />}
        {showEdit && <Route path={NestedRoutes.Edit} element={Edit} />}
        {showDetails && <Route path={NestedRoutes.View} element={Details} />}
        {showExtraRoute && (
          <Route path={extraRoute?.path} element={extraRoute?.element} />
        )}
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: rootPath,
              }}
            />
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};
