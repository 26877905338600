import { emailValidationRule, requiredStringValidationRule } from 'shared';
import { uniq, includes } from 'rambda';
import * as yup from 'yup';

export const employeeFormSchema = yup.object({
  email: emailValidationRule,
  first_name: requiredStringValidationRule('First Name'),
  last_name: requiredStringValidationRule('Last Name'),
  primary_location_id: requiredStringValidationRule('Primary Location'),
  role_id: requiredStringValidationRule('Role'),
  passcode: yup
    .string()
    .notRequired()
    .when(`hasPosAccess`, {
      is: true,
      then: () =>
        requiredStringValidationRule('Passcode')
          .matches(/\d{4}/g, 'Passcode must contain only digits')
          .test(
            '4digits',
            'Passcode must contain 4 digits',
            (val) => val?.length === 4,
          )
          .test(
            'nonSequential',
            'Passcode cannot contain 4 sequential digits',
            (val) => !includes(`${val}`, '0123456789'),
          )
          .test(
            'notOfOne',
            'Passcode can not contain only one unique digit',
            (val) => uniq(`${val}`.split('')).length > 1,
          ),
    }),
});
