import { generatePath } from 'react-router-dom';

export enum NestedRoutes {
  List = 'list',
  Edit = 'edit/:id',
  Create = 'create',
  View = 'view/:id',
  Arrange = 'arrange',
  Receive = 'receive/:id',
  TabEdit = 'edit/:id/:tab',
}

const generateInnerPath = (path: string, id: string, tab?: string) =>
  generatePath(path, { id, tab });

export const getEditPath = (rootPath: string, id: string): string =>
  generateInnerPath(`${rootPath}/${NestedRoutes.Edit}`, id);

export const getViewPath = (rootPath: string, id: string): string =>
  generateInnerPath(`${rootPath}/${NestedRoutes.View}`, id);

export const getCreatePath = (rootPath: string): string =>
  `${rootPath}/${NestedRoutes.Create}`;

export const getReceivePath = (rootPath: string, id: string): string =>
  generateInnerPath(`${rootPath}/${NestedRoutes.Receive}`, id);

export const getTabEditPath = (
  rootPath: string,
  id: string,
  tab?: string,
): string => generateInnerPath(`${rootPath}/${NestedRoutes.TabEdit}`, id, tab);
