import { SetValues } from '@mantine/form/lib/types';

import { CustomerService } from 'App/api';
import { useApiMutation, useToast } from 'hooks';
import { RequestAction } from '../../../../constants';
import {
  getClubMemberValues,
  normalizeChangeTierData,
} from '../../../../helpers';
import {
  ClubMembershipDto,
  ClubMembershipPayload,
  ClubMembershipValues,
} from 'shared';

export const useClubMembershipRequest = ({
  customerId,
  clubMembership,
  refetch,
  setInitialValues,
}: {
  customerId: string;
  clubMembership: ClubMembershipDto;
  refetch: () => void;
  setInitialValues: SetValues<ClubMembershipValues>;
}) => {
  const addToast = useToast();

  const { mutateAsync, isLoading } = useApiMutation(
    ['partialUpdateClubMembership', customerId],
    CustomerService.updateClubMembership,
    {
      onSuccess: (result) => {
        const currentData = getClubMemberValues(result);

        refetch();
        setInitialValues((prev) => {
          return {
            ...prev,
            ...currentData,
          };
        });
      },
    },
  );

  const requestAction = async ({ type }: { type: RequestAction }) => {
    const { customer_source, reason } =
      clubMembership.club_membership_request ?? {};

    const data = {
      ...(type === RequestAction.Approve
        ? clubMembership.club_membership_request
        : {
            ...clubMembership,
            tier: clubMembership.tier && {
              ...clubMembership.tier,
              id: clubMembership.tier?.club_tier?.id,
            },
            add_on: clubMembership.add_on && {
              ...clubMembership.add_on,
              id: clubMembership.add_on?.club_tier.id,
            },
          }),
      reason,
      customer_source_id: customer_source?.id,
      pickup: clubMembership.pickup
        ? { location_id: clubMembership.pickup?.location.id }
        : null,
      shipment: clubMembership.shipment?.address.id
        ? {
            address_id: clubMembership.shipment?.address.id,
          }
        : null,
      club_membership_request: null,
    } as ClubMembershipPayload;

    try {
      await mutateAsync({
        customer_id: customerId,
        club_membership_id: clubMembership.id,
        data,
      });
      addToast.success(`Request ${type}`);
    } catch {
      //
    }
  };

  const isRequest = !!clubMembership.club_membership_request;
  const changeTierData = normalizeChangeTierData({ clubMembership });

  return { isRequest, changeTierData, requestAction, isLoading };
};
