import { useMemo } from 'react';
import { getSelectOptions, getMargin } from 'shared';
import { useCategories } from 'pages/products';

type TPayload = {
  categoriId: string | null;
  price?: string | null;
  cost: number | null;
  btgPrice?: string | null;
  btgCost?: number | null;
};

export const useInitialForm = (payload: TPayload) => {
  const { categories, categoryOptions } = useCategories();
  const { categoriId, cost, price } = payload;

  const subcategyOptions = useMemo(() => {
    const categorie = categories.find((c) => categoriId === c.id);
    return getSelectOptions(categorie?.subcategories ?? []);
  }, [categories, categoriId]);

  const margin = useMemo(() => getMargin(price, cost), [cost, price]);

  return {
    categoryOptions,
    subcategyOptions,
    margin,
  };
};
