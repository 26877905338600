import * as Yup from 'yup';

import { requiredStringValidationRule } from 'shared';

const getVendorRule = (label: string) =>
  Yup.string()
    .notRequired()
    .nullable()
    .when('is_vendor', {
      is: true,
      then: () => requiredStringValidationRule(label),
    });

const itemSchema = Yup.object().shape({
  sku_id: requiredStringValidationRule('Product'),
  quantity: Yup.number().required('Required'),
});

export const inventorySchema = Yup.object({
  inventory_location_id: requiredStringValidationRule('Inventory Location'),
  items: Yup.array().of(itemSchema),
  order_number: getVendorRule('Order #'),
  vendor_id: getVendorRule('Vendor Name'),
  comment: Yup.string().notRequired().nullable(),
});
