import { Box, Text } from '@mantine/core';
import React from 'react';
import { LibraryListItemDto } from 'shared';

export const LibraryInfo = ({ data }: { data?: LibraryListItemDto }) => {
  const { inventory_location, sku, quantity } = data ?? {};
  return (
    <Box mb={20}>
      <Text weight={600} size="sm" mb={20}>
        Inventory Location:
      </Text>
      <Text weight={400} size="sm" mb={10}>
        {inventory_location?.location.name}
      </Text>
      <Text weight={400} size="sm" mb={20}>
        {inventory_location?.name}
      </Text>
      <Text weight={400} size="sm" mb={10}>
        {`${sku?.product_name} (${sku?.sku_number})`}
      </Text>
      <Text weight={400} size="sm" mb={20}>
        {quantity} bottles
      </Text>
    </Box>
  );
};
