import { useNavigate, useParams } from 'react-router-dom';

import { Textarea, Box, Text, Container, Grid, TextInput } from '@mantine/core';
import { BackButton, ContentLayout, ConfirmModal } from 'components';

import { useAdjustmentDetails } from '../../hooks';

export const Details = ({
  onExit,
  canCancel,
}: {
  onExit: () => void;
  canCancel: boolean;
}): JSX.Element => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { output, isLoading, isError, cancel, deleting } = useAdjustmentDetails(
    { id, onDelete: onExit },
  );

  if (isError) {
    onExit();
  }

  const {
    location,
    inventoryLocation,
    adjustmentType,
    items,
    number,
    comment,
  } = output;

  return (
    <ContentLayout
      processing={isLoading || deleting}
      title={`Adjustment #${number}`}
      leftAction={<BackButton onClick={() => navigate(-1)} />}
    >
      <Container size="sm">
        <Grid gutter={'lg'}>
          <Grid.Col span={6}>
            <TextInput
              data-testid="location-detail"
              label="Location"
              width={1}
              value={location}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Inventory Location"
              data-testid="inventory-location-detail"
              width={1}
              value={inventoryLocation}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Adjustment Type"
              data-testid="adjustment-type-detail"
              width={1}
              value={adjustmentType}
            />
          </Grid.Col>
        </Grid>
        <Grid gutter={'lg'} mt="lg">
          <Grid.Col span={6}>
            <Text size={14} weight={600}>
              Product Name
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Grid gutter={'md'}>
              <Grid.Col span={4}>
                <Text size={14} weight={600}>
                  Qty
                </Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
        {items?.map(({ sku_id, sku_product_name, quantity }) => (
          <Grid gutter={'lg'} key={sku_id} mb="xs">
            <Grid.Col span={6}>
              <TextInput
                data-testid="sku-detail"
                width={1}
                value={sku_product_name}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Grid gutter={'md'} align="center">
                <Grid.Col span={4}>
                  <TextInput
                    data-testid="quantity-detail"
                    width={1}
                    value={quantity}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        ))}
        <Box>
          <Textarea
            data-testid="comment-detail"
            label="Comment"
            value={comment}
          />
        </Box>
        {canCancel && (
          <Box my="xl">
            <ConfirmModal
              type="button"
              variant="outline"
              data-testid="cancel-adjustment-btn"
              fullWidth
              loading={deleting}
              onConfirm={() => cancel(id)}
              label="Cancel Adjustment"
            >
              <Text data-testid="confirmation-modal-text">
                Are you sure you want to cancel the adjustment?
              </Text>
            </ConfirmModal>
          </Box>
        )}
      </Container>
    </ContentLayout>
  );
};
