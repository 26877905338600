import VendorService from 'App/api/services/products/vendor.service';
import { useApiMutation, useApiQuery } from 'hooks';
import { CreateVendor } from 'shared';

interface Props {
  id: string;
  onDelete?: () => void;
  onCreate?: (id: string) => void;
}

const getProductVendorsKey = 'getProductVendors';

export const useEditVendors = ({
  id,
  onDelete,
  onCreate = () => null,
}: Props) => {
  const {
    data: vendor,
    isLoading,
    isError,
  } = useApiQuery(
    [getProductVendorsKey, id],
    () => VendorService.getOne({ id }),
    {
      cacheTime: 0,
    },
  );

  const { mutate: deleteVendor, isLoading: isDeleting } = useApiMutation(
    [getProductVendorsKey, id],
    () => VendorService.delete({ id }),
    {
      onSuccess: onDelete,
    },
  );

  const { mutate: updateVendor, isLoading: isUpdating } = useApiMutation(
    [getProductVendorsKey, id],
    VendorService.update,
    {
      onSuccess: (data) => onCreate(data.id),
    },
  );

  const handleSubmit = (values: CreateVendor) => {
    const payload = {
      id,
      data: {
        company_name: values.company_name,
        website: values.website,
        street_address: values.street_address,
        city: values.city,
        state: values.state,
        zip_code: values.zip_code,
        phone_number: values.phone_number,
        primary_contact_name: values.primary_contact_name,
        primary_contact_phone_number: values.primary_contact_phone_number,
        primary_contact_email: values.primary_contact_email,
        comment: values.comment,
        is_location_specific: values.is_location_specific,
        vendor_locations: values.vendor_locations.map((loc) =>
          loc.same_contact_details
            ? {
                same_contact_details: true,
                location_id: loc.location_id,
              }
            : loc,
        ),
      },
    };

    updateVendor(payload);
  };

  const initialValue = vendor && {
    ...vendor,
    is_location_specific: !!vendor.vendor_locations.length,
    vendor_locations: vendor.vendor_locations.map((loc) => {
      return { ...loc, location_id: loc.location?.id ?? '' };
    }),
  };

  return {
    vendor,
    initialValue,
    isLoading,
    isError,
    deleteVendor,
    isDeleting,
    handleSubmit,
    isUpdating,
  };
};
