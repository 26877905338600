import request from '../../request';
import {
  PaginatedRequest,
  PaginatedResponse,
  PosSalesOrderDto,
  PosSalesOrderPayload,
} from 'shared';
import { POS_SALES_ORDERS } from '../../endpoints';

export default class PosSalesOrdersService {
  static readonly getCollection = (
    params: PaginatedRequest & { statuses?: string | string[] },
  ) => {
    return request<PaginatedResponse<PosSalesOrderDto>>({
      url: POS_SALES_ORDERS,
      params,
      method: 'get',
    });
  };

  static readonly getOne = (id: string, include_canceled_items?: boolean) => {
    return request<PosSalesOrderDto>({
      url: `${POS_SALES_ORDERS}/${id}`,
      method: 'get',
      params: { include_canceled_items },
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id?: string;
    data: PosSalesOrderPayload;
  }) => {
    return request<PosSalesOrderDto>({
      url: `${POS_SALES_ORDERS}/${id}`,
      method: 'patch',
      data,
    });
  };

  static readonly getTotalCalculation = (order_id: string) => {
    return request({
      url: `${POS_SALES_ORDERS}/${order_id}/total_calculation`,
      method: 'get',
    });
  };

  static readonly refund = ({ id }: { id: string }) => {
    return request({
      url: `${POS_SALES_ORDERS}/${id}/refund`,
      method: 'post',
      data: {
        refund_type: 'full',
        applied_for: [],
      },
    });
  };
}
