import { UseFormReturnType } from '@mantine/form';

import { WineSelectSection } from './components';
import { ClubTierDto, WineSelectionDto } from 'shared';

type Props = {
  form: UseFormReturnType<WineSelectionDto>;
  tier?: ClubTierDto;
  isEdit?: boolean;
};

export const WineSelectionForm = ({ form, tier, isEdit }: Props) => {
  return <WineSelectSection isEdit={isEdit} tier={tier} form={form} />;
};
