import { useMemo, useCallback } from 'react';
import { InventoryService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import { inventoryTypeLabels, InventoryType, InventoryPool } from 'shared';

import { getInventoryListParams } from '../helpers';

export const useInventoryList = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
    location: [],
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';
  const location = searchParams.getAll('location') as unknown as string[];
  const pool = searchParams.get('pool') as InventoryPool;

  const params = useMemo(() => {
    return {
      page,
      ...getInventoryListParams({ page, search_string, pool, location }),
    };
  }, [location, page, pool, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getProductSkus', key], () =>
    InventoryService.getCollection(params),
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map(
      ({ inventory_type, stock_keeping_unit_id, has_lte_items, ...rest }) => ({
        ...rest,
        isLowStock: inventory_type === InventoryType.ALCOHOL && has_lte_items,
        id: stock_keeping_unit_id,
        stock_keeping_unit_id,
        inventory_type: inventory_type
          ? inventoryTypeLabels[inventory_type]
          : '-',
      }),
    );
  }, [data]);

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ ...data, page: '1' });
    },
    [handleSetSearchParams],
  );

  return {
    output,
    isLoading,
    page,
    filters: { search_string, location, pool },
    totalRecords: data?.total ?? 0,
    handleSetSearchParams,
    updateFilters,
  };
};
