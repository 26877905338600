import { randomId } from '@mantine/hooks';
import { ClubTierDto, Suggestion } from 'shared';

export const getNewSuggestionItem = ({
  tierId,
}: {
  tierId?: string;
}): Suggestion => ({
  sku_id: '',
  club_tier_id: tierId ?? '',
  max_quantity: 0,
  key: randomId(),
});

export const calculateSuggestionInitialValue = (tiers?: ClubTierDto[]) => {
  if (!tiers) return [];

  return tiers?.map(({ id }) => {
    return getNewSuggestionItem({ tierId: id });
  });
};
