import { Grid, Text } from '@mantine/core';
import { DataTable, DataTableRowExpansionProps } from 'mantine-datatable';

import { SummaryOrder } from '../SummaryOrder';
import {
  CorrectionOrder,
  getRecalculationSummary,
  OrderProductsTable,
  PROPS_DATA_TABLE,
} from 'shared';

type PropType = {
  isRefunded?: boolean;
  orderProducts: OrderProductsTable[];
  summary: [string, string][] | null;
  corrections?: CorrectionOrder[];
  // ownerNames?: string;
  rowExpansion?: DataTableRowExpansionProps<OrderProductsTable>;
};

export const InventoryDetailsTable = ({
  isRefunded,
  orderProducts,
  summary,
  corrections,
  // ownerNames,
  rowExpansion,
}: PropType) => {
  const title = isRefunded ? 'Refund' : 'Inventory';
  const correctionsSummary =
    corrections && getRecalculationSummary(corrections);

  return (
    <Grid mb="xl" gutter="xl">
      <Grid.Col>
        <Text size="lg" weight={600}>
          {title} Details
        </Text>
      </Grid.Col>

      {/* {ownerNames && (
        <Flex p={12}>
          <Text size="sm" weight={600}>
            {ownerNames}
          </Text>
        </Flex>
      )} */}

      <Grid.Col>
        <DataTable
          minHeight={80}
          emptyState={PROPS_DATA_TABLE.emptyState}
          records={orderProducts}
          borderColor="transparent"
          rowBorderColor="transparent"
          columns={[
            {
              accessor: 'product_name',
              title: 'Product Name',
              width: '25%',
            },
            {
              accessor: 'quantity',
              title: 'Qty',
              width: '9%',
            },
            {
              accessor: 'retail_price',
              title: 'Retail Price',
              textAlignment: 'right',
              width: '11%',
            },
            {
              accessor: 'discount_string',
              title: 'Discount',
              textAlignment: 'right',
              width: '11%',
            },
            {
              accessor: 'discounted_price',
              title: 'Discounted Price',
              textAlignment: 'right',
              width: '11%',
            },
            {
              accessor: 'total_price',
              title: 'Total',
              width: '11%',
              textAlignment: 'right',
            },
          ]}
          rowExpansion={rowExpansion}
        />
      </Grid.Col>
      <Grid.Col>
        {summary?.map((item) => <SummaryOrder key={item[0]} item={item} />)}
      </Grid.Col>
      {!isRefunded &&
        correctionsSummary?.map(({ id, summary }) => (
          <Grid.Col key={id}>
            {summary?.map((item) => <SummaryOrder key={item[0]} item={item} />)}
          </Grid.Col>
        ))}
    </Grid>
  );
};
