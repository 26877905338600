import { DataTable } from 'mantine-datatable';
import { Button, ActionIcon, Grid } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { ContentLayout, CreateButton, SearchInput } from 'components';
import {
  getCreatePath,
  PROPS_DATA_TABLE,
  getViewPath,
  getEditPath,
} from 'shared';
import { Expand } from './components';
import { useShipmentCycles } from '../../hooks';
import { Mode } from '../../constants';
import { InventoryCheckDetails } from '../inventoryCheck';

export const List = ({
  canSeeDetails,
  canCreate,
  rootPath,
}: {
  canSeeDetails: boolean;
  canCreate: boolean;
  rootPath: string;
}): JSX.Element => {
  const {
    page,
    output,
    mode,
    isLoading,
    isRefreshInventoryDetails,
    search_string,
    totalRecords,
    allInventoryDetails,
    copy,
    setMode,
    updateFilters,
    handleSetSearchParams,
    handleRefreshInventoryDetails,
  } = useShipmentCycles();
  const navigate = useNavigate();

  if (mode === Mode.INVENTORY_CHECK)
    return (
      <InventoryCheckDetails
        isAllCycles
        title="Inventory Check - All Cycles"
        action={
          <Button
            loading={isRefreshInventoryDetails}
            onClick={handleRefreshInventoryDetails}
          >
            Refresh Inventory Details
          </Button>
        }
        isLoading={isLoading}
        goBack={() => setMode(Mode.LIST)}
        inventoryDetails={allInventoryDetails}
      />
    );

  return (
    <ContentLayout
      title="Shipment Cycles"
      processing={isLoading}
      rightAction={
        canCreate && (
          <CreateButton
            data-testid="new-shipment-cycle-btn"
            to={getCreatePath(rootPath)}
          >
            + New Shipment Cycle
          </CreateButton>
        )
      }
    >
      <Grid mb="md" justify="space-between" align="center">
        <Grid.Col span={6}>
          <Button
            variant="subtle"
            onClick={() => setMode(Mode.INVENTORY_CHECK)}
          >
            See Inventory Check Details
          </Button>
        </Grid.Col>
        <Grid.Col span={6}>
          <SearchInput
            handleSubmit={(value: string) =>
              updateFilters({ search_string: value })
            }
            label="Search"
            value={search_string}
          />
        </Grid.Col>
      </Grid>
      <DataTable
        {...PROPS_DATA_TABLE}
        style={{ minWidth: '1240px' }}
        recordsPerPage={3}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        records={output}
        onRowClick={({ id }) => navigate(getEditPath(rootPath, id))}
        columns={[
          { accessor: 'name', title: 'Cycle Name', width: '20%' },
          { accessor: 'status', title: 'Status', width: '11%' },
          {
            accessor: 'billing_cycle_start',
            title: 'Billing Cycle Start',
            width: '15%',
          },
          {
            accessor: 'active_members',
            title: 'Members',
            width: '7%',
          },
          {
            accessor: 'skipped',
            title: 'Skipped',
            width: '7%',
          },
          {
            accessor: 'processed',
            title: 'Open',
            width: '7%',
          },
          {
            accessor: 'unpaid',
            title: 'Unpaid',
            width: '7%',
          },
          {
            accessor: 'pending',
            title: 'Pending',
            width: '7%',
          },
          {
            accessor: 'closed',
            title: 'Closed',
            render: () => 0,
            width: '7%',
          },
          {
            hidden: !canSeeDetails,
            accessor: 'copyId',
            title: '',
            render: ({ id }) => (
              <Button
                my={-12}
                variant="subtle"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(getViewPath(rootPath, id));
                }}
              >
                Details
              </Button>
            ),
          },
          {
            hidden: !canCreate,
            accessor: 'id',
            title: '',
            render: ({ id }) => (
              <ActionIcon
                ml={'auto'}
                size="md"
                onClick={(e) => {
                  e.stopPropagation();
                  copy({ cycleId: id });
                }}
                aria-label="EditButton"
                color="dark.9"
              >
                <IconCopy stroke={1} />
              </ActionIcon>
            ),
          },
        ]}
        rowExpansion={{
          trigger: 'always',
          content: ({ record: { details } }) => <Expand data={details} />,
        }}
      />
    </ContentLayout>
  );
};
