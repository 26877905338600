import { useParams, Link } from 'react-router-dom';

import { useEmployee } from 'hooks';
import {
  BackButton,
  ContentLayout,
  EditButton,
  TitleWithBadge,
} from 'components';
import { getEditPath, EmployeeStatus, StatusColor, StatusLabel } from 'shared';
import { PersonalData } from './components';

export const View = ({
  onExit,
  rootPath,
  canEdit,
}: {
  onExit: () => void;
  canEdit: boolean;
  rootPath: string;
}): JSX.Element => {
  const { id = '' } = useParams();
  const { data, isLoading, isError, output } = useEmployee(id);

  if (isError) {
    onExit();
  }

  const employeeStatus = output?.status as EmployeeStatus;
  const status =
    employeeStatus !== EmployeeStatus.ACTIVE ? StatusLabel[employeeStatus] : '';

  return (
    <ContentLayout
      title={
        <TitleWithBadge
          title={output?.name}
          status={status}
          color={StatusColor[employeeStatus]}
        />
      }
      isLoading={isLoading}
      leftAction={<BackButton onClick={onExit} />}
      rightAction={
        canEdit && (
          <EditButton component={Link} to={getEditPath(rootPath, id)} />
        )
      }
    >
      {data && <PersonalData employee={data} />}
    </ContentLayout>
  );
};
