import { ShortEmployee } from '../employee';
import { ShortSkuDto } from '../products';
import { AdjustmentPayload } from './adjustment';
import { InventoryLocationDto } from './inventory';

export type CreateLibrary = Omit<
  AdjustmentPayload,
  'adjustment_type' | 'comment'
>;

export enum InventoryLibraryStatus {
  RESERVED = 'reserved',
  RELEASED = 'released',
  CANCELED = 'canceled',
}

export const StatusInvetoryLibrary: {
  [key in InventoryLibraryStatus]: string;
} = {
  [InventoryLibraryStatus.RELEASED]: 'Released',
  [InventoryLibraryStatus.RESERVED]: 'Reserved',
  [InventoryLibraryStatus.CANCELED]: 'Canceled',
};

export interface LibraryListItemDto {
  id: string;
  sku: ShortSkuDto;
  product_name: string;
  inventory_location: InventoryLocationDto;
  quantity: number;
  status: InventoryLibraryStatus;
  created_at: string;
  updated_at: string;
  issued_by: ShortEmployee;
  created_by: ShortEmployee;
}

export interface ReleaseLibrary {
  quantity: number;
  comment: string;
}
