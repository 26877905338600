import { Button } from '@mantine/core';
import { SalesOrderStatus } from 'shared';

export const BulkButton = ({
  statuses,
  toggle,
}: {
  statuses: SalesOrderStatus;
  toggle: () => void;
}) => {
  if (statuses === SalesOrderStatus.WAITING_PICKUP)
    return (
      <Button variant="white" onClick={toggle}>
        Ship Orders
      </Button>
    );

  return null;
};
