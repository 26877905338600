import { ReactNode, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Text,
  LoadingOverlay,
  Grid,
  TextInput,
  Textarea,
  ActionIcon,
  Switch,
} from '@mantine/core';
import { isEmpty } from 'rambda';
import CheckboxTree from 'react-checkbox-tree';
import { IconPencil, IconX } from '@tabler/icons-react';
import './css.css';

import { LOADING_OVERLAY, RoleValues, SAVE_BTN } from 'shared';
import { Actions } from 'shared/interactions';
import { icons } from './helpers';
import { useRoleEdit } from 'pages/settings/roles/hooks';
import { nodes, Node } from '../../data';

export interface Props {
  handleSubmit: (data: RoleValues) => void;
  processing: boolean;
  title: string;
  roleName?: string;
  roleDescription?: string;
  initialChecked?: string[];
  initialExpanded?: string[];
  children?: ReactNode;
  expandAll?: boolean;
  canEdit?: boolean;
}

export const Role = ({
  handleSubmit,
  processing,
  title,
  roleName = '',
  roleDescription = '',
  initialChecked = [],
  initialExpanded = [],
  expandAll = true,
  children = null,
  canEdit = false,
}: Props): JSX.Element => {
  const [expanded, setExpanded] = useState<string[]>(initialExpanded);
  const [action, setAction] = useState<Actions>(
    isEmpty(initialChecked) ? Actions.Create : Actions.View,
  );
  const {
    checked,
    setChecked,
    name,
    setName,
    description,
    setDescription,
    onSubmit,
    isSuperAdmin,
    handleSuperAdmin,
  } = useRoleEdit({
    handleSubmit,
    roleDescription,
    roleName,
    initialChecked,
  });

  useEffect(() => {
    if (expandAll) {
      const res: string[] = [];
      const cb = ({ value, children: ch }: Node) => {
        if (ch) {
          res.push(value);
          ch.forEach(cb);
        }
      };
      nodes.forEach(cb);
      setExpanded(res);
    }
  }, [expandAll]);

  const readonly = action === Actions.View;
  const modifiedNodes = nodes.map((node) => ({
    ...node,
    label: <div data-testid={`${node.value}-node`}>{node.label}</div>,
  }));

  return (
    <Box>
      <LoadingOverlay data-testid={LOADING_OVERLAY} visible={processing} />
      <Grid mb="xl">
        <Grid.Col span={8} offset={2}>
          <Text
            data-testid="content-title"
            align="center"
            size={'lg'}
            weight={600}
          >
            {title}
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          {action !== Actions.Create && canEdit && (
            <ActionIcon
              ml={'auto'}
              onClick={() => setAction(readonly ? Actions.Edit : Actions.View)}
              data-testid="edit-role-btn"
              aria-label="EditButton"
              color="dark.9"
            >
              {readonly ? <IconPencil stroke={1} /> : <IconX stroke={1} />}
            </ActionIcon>
          )}
        </Grid.Col>
      </Grid>
      <Grid gutter="lg">
        <Grid.Col span={6}>
          <TextInput
            data-testid="role-name-input"
            label="Role Name"
            value={name}
            disabled={readonly}
            onChange={(event) => setName(event.currentTarget.value)}
            required
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Textarea
            data-testid="role-description-input"
            label="Role Description"
            value={description}
            disabled={readonly}
            onChange={(event) => setDescription(event.currentTarget.value)}
          />
        </Grid.Col>
      </Grid>

      <Grid gutter="lg" mb="lg">
        <Grid.Col data-testid="super-admin-role-switch" span={6}>
          <Switch
            label="Super Admin"
            size="lg"
            checked={isSuperAdmin}
            data-testid={
              isSuperAdmin
                ? 'super-admin-toggled-on'
                : 'super-admin-toggled-off'
            }
            onChange={() => !readonly && handleSuperAdmin()}
          />
        </Grid.Col>
      </Grid>

      {!isSuperAdmin && (
        <CheckboxTree
          icons={icons}
          nodes={modifiedNodes}
          checked={checked}
          expanded={expanded}
          onCheck={(checked) => setChecked(checked)}
          onExpand={(expanded) => setExpanded(expanded)}
          disabled={readonly}
        />
      )}
      {!readonly && (
        <Grid justify={'center'} mt="lg">
          <Grid.Col md={6} lg={4}>
            <Button data-testid={SAVE_BTN} fullWidth onClick={onSubmit}>
              Save
            </Button>
          </Grid.Col>
        </Grid>
      )}
      {!readonly && children}
    </Box>
  );
};
