import { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'rambda';
import { useDisclosure } from '@mantine/hooks';

import { useApiMutation, useApiQuery } from 'hooks';
import { CustomerService } from 'App/api';
import { AddressDto, dateInputOutputFormat } from 'shared';
import { normalizeAddresses } from '../../../../helpers';
import { useVerifyAddress } from '../../../../hooks';

type Props = {
  customerId?: string;
  refetch: () => void;
};

export const useEditAddress = ({ customerId, refetch }: Props) => {
  const [editAddressValues, setEditAddressValues] = useState<AddressDto | null>(
    null,
  );
  const [isAddAddress, setIsAddAddress] = useState<boolean>(false);
  const [isNotVerify, setIsNotVerify] = useState<boolean>(false);

  const { verify } = useVerifyAddress();
  const [openedVerifyAddressModal, { open, close }] = useDisclosure(false);

  const {
    data,
    isFetching,
    isLoading: isLoadingAddresses,
    refetch: refetchAddress,
  } = useApiQuery(['addresses', customerId], () =>
    CustomerService.getAddresses({ id: customerId }),
  );

  const { mutate, isLoading: isCreating } = useApiMutation(
    ['addAddress', customerId],
    CustomerService.addAddress,
    {
      onSuccess: () => {
        refetchAddress();
        refetch();
        setIsAddAddress(false);
      },
    },
  );

  const {
    mutate: editAddress,
    mutateAsync: editAddressAsync,
    isLoading: isSetting,
  } = useApiMutation(['editAddress', customerId], CustomerService.editAddress, {
    onSuccess: () => {
      setEditAddressValues(null);
      refetch();
      refetchAddress();
    },
  });

  const addAddress = useCallback(
    async (values: AddressDto, update_orders?: boolean) => {
      try {
        const payload: {
          id?: string;
          data: AddressDto;
          update_orders?: boolean;
        } = {
          id: customerId,
          data: {
            ...values,
            date_of_birth: dateInputOutputFormat(
              values.date_of_birth as string,
              'output',
            ),
          },
          update_orders,
        };
        const { data } = await verify({ data: values });
        const isUnconfirmed = data.result.verdict?.hasUnconfirmedComponents;
        return isUnconfirmed && !isNotVerify ? open() : mutate(payload);
      } catch (e) {
        //
      }
    },
    [customerId, isNotVerify, mutate, open, verify],
  );

  const { mutate: deleteAddress, isLoading: isDeleting } = useApiMutation(
    ['deleteAddress', customerId],
    CustomerService.deleteAddress,
    {
      onSuccess: () => {
        refetch();
        refetchAddress();
      },
    },
  );

  const addresses = useMemo(
    () => normalizeAddresses(data?.items),
    [data?.items],
  );

  const handleSubmitEdit = ({
    id,
    addressId,
    data,
    isUpdate,
  }: {
    id?: string;
    addressId?: string;
    data: AddressDto;
    isUpdate: boolean;
  }) => {
    const { date_of_birth, email, phone_number } = data;

    editAddress({
      id,
      addressId,
      data: {
        ...data,
        date_of_birth: dateInputOutputFormat(date_of_birth as string, 'output'),
        email: isEmpty(email) ? null : email,
        phone_number: isEmpty(phone_number) ? null : phone_number,
      },
      ...(isUpdate ? { update_orders: true } : {}),
    });
  };

  const deleteAndMakeDefaultNextAddress = async ({
    id,
    data,
    addressId,
    isUpdate,
    deleteAddressId,
  }: {
    id?: string;
    data: AddressDto;
    addressId?: string;
    deleteAddressId?: string;
    isUpdate?: boolean;
  }) => {
    const { date_of_birth, email, phone_number } = data;

    try {
      await editAddressAsync({
        id,
        addressId,
        data: {
          ...data,
          date_of_birth: dateInputOutputFormat(
            date_of_birth as string,
            'output',
          ),
          email: isEmpty(email) ? null : email,
          phone_number: isEmpty(phone_number) ? null : phone_number,
        },
        ...(isUpdate ? { update_orders: true } : {}),
      });

      deleteAddress({ id, addressId: deleteAddressId });
    } catch {
      //
    }
  };

  return {
    addresses,
    isLoading:
      isLoadingAddresses || isFetching || isSetting || isDeleting || isCreating,
    isAddAddress,
    setIsAddAddress,
    setIsNotVerify,
    openedVerifyAddressModal,
    close,
    addAddress,
    deleteAddress,
    deleteAndMakeDefaultNextAddress,
    editAddress,
    editAddressValues,
    setEditAddressValues,
    handleSubmitEdit,
  };
};
