import { PartnerService } from 'App/api';
import { useApiMutation, useApiQuery } from 'hooks';
import { CreatePartner } from 'shared';

interface Props {
  id: string;
  onDelete?: () => void;
  onCreate?: (id: string) => void;
}

const getPartnerKey = 'getPartner';

export const useEditPartners = ({
  id,
  onDelete,
  onCreate = () => null,
}: Props) => {
  const { data, isLoading, isError } = useApiQuery(
    [getPartnerKey, id],
    () => PartnerService.getOne({ id }),
    {
      cacheTime: 0,
    },
  );

  const { mutate: deletePartner, isLoading: isDeleting } = useApiMutation(
    [getPartnerKey, id],
    () => PartnerService.delete({ id }),
    {
      onSuccess: onDelete,
    },
  );

  const { mutate: updatePartner, isLoading: isUpdating } = useApiMutation(
    [getPartnerKey, id],
    PartnerService.update,
    {
      onSuccess: (data) => onCreate(data.id),
    },
  );

  const handleSubmit = (values: CreatePartner) => {
    const payload = {
      id,
      data: values,
    };

    updatePartner(payload);
  };

  const initialValue = data;

  return {
    data,
    initialValue,
    isLoading,
    isError,
    isUpdating,
    isDeleting,
    deletePartner,
    handleSubmit,
  };
};
