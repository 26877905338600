import { Flex, Switch, Button, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  CANCEL_BTN,
  CycleOrderPayload,
  SalesOrderPaymentStatus,
  SalesOrderStatus,
} from 'shared';
import { ActionButtons, ConfirmModal } from 'components';

type PropType = {
  form: UseFormReturnType<CycleOrderPayload>;
  orderNumber: number;
  orderPaymentStatus: SalesOrderPaymentStatus;
  handleSubmit: () => void;
  loading: boolean;
  isCycleOrder?: boolean;
  isPaying: boolean;
  isSkipped: boolean;
  isDisabled: boolean;
  isInShipping: boolean;
  isCorrection?: boolean;
  onCancel: () => void;
  isCancelWithSkip?: boolean;
  confirmAndPay: () => void;
};

export const BottomButtons = ({
  form,
  orderNumber,
  orderPaymentStatus,
  loading,
  isPaying,
  isSkipped,
  isDisabled,
  isInShipping,
  isCycleOrder,
  isCorrection = false,
  isCancelWithSkip,
  onCancel,
  handleSubmit,
  confirmAndPay,
}: PropType) => {
  const isVisibleConfirmAndPay = [
    SalesOrderPaymentStatus.UNPAID,
    SalesOrderPaymentStatus.ERROR,
  ].includes(orderPaymentStatus);

  const { status } = form.values;

  const isPaid = orderPaymentStatus === SalesOrderPaymentStatus.PAID;
  const isThreeDSConfirmation =
    SalesOrderPaymentStatus.THREE_D_SECURE_CONFIRMATION === orderPaymentStatus;
  const isUnpaid = SalesOrderPaymentStatus.UNPAID === orderPaymentStatus;

  const isProcessingOrder = SalesOrderStatus.PROCESSING === status;
  const isCanceled = status === SalesOrderStatus.CANCEL;

  const disableConfirmAndPay =
    !form.isValid() || isDisabled || isInShipping || !isVisibleConfirmAndPay;

  const { is_skipped } = form.values;
  const isMarkAsSkipped = is_skipped && isCancelWithSkip;
  const disabledSave =
    ((!form.isValid() || isDisabled || !form.isDirty()) && !isCorrection) ||
    ((isThreeDSConfirmation || isProcessingOrder) && !isUnpaid);

  const titlePopup = isMarkAsSkipped ? 'Skip Shipment?' : 'Cancel this order?';
  const descriptionPopup = isMarkAsSkipped
    ? 'This Club member has skipped shipment more than once within last 730 days.'
    : `You are about to cancel order #${orderNumber}. Do you want to proceed?`;

  return (
    <ActionButtons
      my="xl"
      type="button"
      label="Save"
      loading={loading}
      disabled={disabledSave}
      onClick={handleSubmit}
      secondary={
        !isCanceled &&
        !isPaid && (
          <Flex direction="column" align="center" gap="xl">
            <Button
              fullWidth
              variant="outline"
              data-testid="confirm-and-pay-btn"
              onClick={confirmAndPay}
              loading={isPaying}
              disabled={disableConfirmAndPay}
            >
              Confirm and Pay Now
            </Button>
            <Flex
              sx={{ width: !isSkipped && isCycleOrder ? 345 : '' }}
              align="center"
              justify="space-between"
            >
              {!isSkipped && isCycleOrder && (
                <Switch
                  mb={16}
                  data-testid="skip-switch"
                  label="Mark as Skipped"
                  labelPosition="right"
                  {...form.getInputProps('is_skipped')}
                />
              )}
              <ConfirmModal
                data-testid={CANCEL_BTN}
                type="button"
                variant="white"
                title={titlePopup}
                onConfirm={onCancel}
                label="Cancel Order"
                {...(isMarkAsSkipped && { confirmLabel: 'Skip anyway' })}
              >
                <Text>{descriptionPopup}</Text>
              </ConfirmModal>
            </Flex>
          </Flex>
        )
      }
    />
  );
};
