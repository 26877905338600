import { ReactNode } from 'react';
import {
  Container,
  Grid,
  Text,
  LoadingOverlay,
  Paper,
  Box,
  Loader,
  Group,
  Center,
} from '@mantine/core';
import { type } from 'rambda';
import { LOADING_OVERLAY } from 'shared';

interface Props {
  title?: string | ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  leftAction?: ReactNode;
  rightAction?: ReactNode;
  isLoading?: boolean;
  processing?: boolean;
}

export const ContentLayout = ({
  title,
  children,
  leftAction = null,
  rightAction = null,
  isLoading = false,
  processing = false,
  footer = null,
}: Props) => {
  if (isLoading) {
    return (
      <Center h="calc(100vh - 56px)">
        <Loader data-testid="loading-dots" size="lg" variant="dots" />
      </Center>
    );
  }
  return (
    <Container fluid py={0} px={8} m={0}>
      <Grid grow columns={16} align="center">
        <Grid.Col span={5}>
          <Group>{leftAction}</Group>
        </Grid.Col>
        <Grid.Col span={6}>
          {type(title) === 'String' ? (
            <Text
              data-testid="content-title"
              weight={700}
              size="lg"
              align="center"
            >
              {title}
            </Text>
          ) : (
            title
          )}
        </Grid.Col>
        <Grid.Col span={5}>
          <Group position="right">{rightAction}</Group>
        </Grid.Col>
      </Grid>
      {children && (
        <Paper p="lg" mt="md" sx={{ position: 'relative' }}>
          <LoadingOverlay
            data-testid={LOADING_OVERLAY}
            visible={processing}
            overlayBlur={2}
          />
          {children}
        </Paper>
      )}
      {footer && <Box mt="xl">{footer}</Box>}
    </Container>
  );
};
