import { useMemo, useState } from 'react';
import { CustomerService } from 'App/api';
import { useApiQuery } from 'hooks';
import { normalizeOrdersHistory } from '../../../../helpers';
import { DEFAULT_PAGE_SIZE } from 'shared';
import { normalizeOrderList } from '../helper';

export const useCustomerOrders = ({ id }: { id: string }) => {
  const [page, setPage] = useState(1);

  const params = useMemo(() => {
    return {
      page,
      page_size: DEFAULT_PAGE_SIZE,
    };
  }, [page]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getOrdersHistory', key, id], () =>
    CustomerService.getOrdersHistory({ id, params }),
  );

  const { data: upcomingShipment, isLoading: isLoadingUpcomingShipment } =
    useApiQuery(['getUpcomingShipment', key, id], () =>
      CustomerService.getUpcomingShipment({ id, params: { page_size: 5 } }),
    );

  const ordersHistory = useMemo(
    () => normalizeOrdersHistory(data?.items),
    [data],
  );

  const orderItems = useMemo(
    () => normalizeOrderList(upcomingShipment?.items),
    [upcomingShipment],
  );

  return {
    orderItems,
    ordersHistory,
    page,
    isLoading,
    isLoadingUpcomingShipment,
    total: data?.total ?? 0,
    setPage,
  };
};
