import { pipe, map, omit } from 'rambda';
import { randomId } from '@mantine/hooks';

import {
  ProductCategoryFormValues,
  ProductCategoryDto,
  ReorderPayload,
  sortByMenuPosition,
  FormCategoryProductOptions,
} from 'shared';

export const getOrderedCategories = (
  categories: ProductCategoryDto[],
): ProductCategoryDto[] =>
  pipe(
    (arr: ProductCategoryDto[]) => sortByMenuPosition(arr),
    map(({ subcategories, ...rest }: ProductCategoryDto) => ({
      ...rest,
      subcategories: sortByMenuPosition(subcategories),
    })),
  )(categories) as ProductCategoryDto[];

export const getReorderedCategories = (
  categories: ProductCategoryDto[],
): ReorderPayload => ({
  categories: map(({ id, subcategories }: ProductCategoryDto) => ({
    id,
    subcategories: map((s) => s.id, subcategories),
  }))(categories),
});

export const getCategoryPayload = ({
  is_main_category,
  product_options,
  parent_id,
  ...values
}: ProductCategoryFormValues) => ({
  ...values,
  parent_id: is_main_category ? null : parent_id,
  product_options: product_options.map(omit(['key'])),
});

export const getFormData = ({
  parent,
  description = '',
  product_options: additionalFields,
  ...data
}: ProductCategoryDto): ProductCategoryFormValues => ({
  ...data,
  parent_id: parent?.id ?? '',
  is_main_category: !parent,
  subcategories: parent ? undefined : data.subcategories,
  description,
  product_options: (additionalFields ?? []).map((a) => ({
    ...a,
    key: randomId(),
  })),
});

export const getNewProductOption = (): FormCategoryProductOptions => ({
  name: '',
  required: false,
  key: randomId(),
  order: 0,
});
