import { Routes, Route, Navigate } from 'react-router-dom';

import { LocationsProvider } from 'App/contexts';
import { Layout } from 'layout/authenticated';
import { generateRoutePath } from './helpers';
import { RouteConfig, RouteName } from './interfaces';
import { useAllowedRoutes } from 'hooks';

export function AuthenticatedRoutes(): JSX.Element {
  const routes = useAllowedRoutes();
  return (
    <LocationsProvider>
      <Routes>
        <Route element={<Layout />}>
          {routes.map(
            ({ label, component: Element, path, children }: RouteConfig) => (
              <Route
                key={label}
                element={
                  children?.length ? (
                    <Navigate
                      to={{
                        pathname: generateRoutePath({ name: children[0] }),
                      }}
                    />
                  ) : (
                    <Element />
                  )
                }
                path={path}
              />
            ),
          )}
          <Route
            key={-1}
            path="*"
            element={
              <Navigate
                to={{
                  pathname: generateRoutePath({ name: RouteName.Dashboard }),
                }}
              />
            }
          />
        </Route>
      </Routes>
    </LocationsProvider>
  );
}
