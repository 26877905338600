import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useSearchParams,
  useNavigate,
  generatePath,
  useLocation,
} from 'react-router-dom';
import { includes } from 'rambda';
import { Select, Container, Grid, Paper } from '@mantine/core';
import { useState } from 'react';

import { usePermissions } from 'hooks';
import { useLocations } from 'App/contexts';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { List, Create, Details } from './components';
import { LOCATION_DROPDOWN, NestedRoutes, Permissions } from 'shared';

const isDetailPageSign = '/edit';

export const Roles = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const disableLocationSelect = includes(isDetailPageSign, pathname);

  const paramsLocation = searchParams.get('location');
  const { currentLocation, options } = useLocations();

  const [location, setLocation] = useState<string>(
    paramsLocation ?? currentLocation,
  );

  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.Roles,
  });
  const canCreate = checkAccess(Permissions.admin_panel_roles_create);
  const canEdit = checkAccess(Permissions.admin_panel_roles_edit);
  const canDelete = checkAccess(Permissions.admin_panel_roles_delete);

  const onExit = useGotoRoute(RouteName.Roles);
  const onEdit = (id: string) =>
    navigate(generatePath(`${rootPath}/${NestedRoutes.Edit}`, { id }));

  const list = (
    <List
      onCreate={() => navigate(`${rootPath}/${NestedRoutes.Create}`)}
      onView={onEdit}
      location={location}
      title={options.find(({ value }) => value === location)?.label ?? ''}
      canCreate={canCreate}
    />
  );

  return (
    <Container size={'xl'} sx={{ position: 'relative' }}>
      <Grid>
        <Grid.Col md={6} lg={3}>
          <Select
            data={options}
            data-testid={LOCATION_DROPDOWN}
            label="Select Location"
            value={location}
            disabled={disableLocationSelect}
            onChange={(value: string) => {
              setLocation(value);
              setSearchParams({});
            }}
          />
        </Grid.Col>
      </Grid>
      <Paper data-testid="roles-list" p="lg" mt={'xl'}>
        <Routes>
          <Route index element={list} />
          <Route path={NestedRoutes.List} element={list} />
          {canCreate && (
            <Route
              path={NestedRoutes.Create}
              element={<Create location={location} onExit={onExit} />}
            />
          )}
          {canEdit && (
            <Route
              path={NestedRoutes.Edit}
              element={
                <Details
                  onExit={onExit}
                  canDelete={canDelete}
                  canEdit={canEdit}
                />
              }
            />
          )}
          <Route
            path="*"
            element={
              <Navigate
                to={{
                  pathname: rootPath,
                }}
              />
            }
          />
        </Routes>

        <Outlet />
      </Paper>
    </Container>
  );
};
