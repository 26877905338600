import { InventoryService } from 'App/api';
import { InventoryLocationPayload } from 'shared';
import { ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useApiMutation } from 'hooks';

const title = 'New Inventory Location';

const initialValues: InventoryLocationPayload = {
  name: '',
  location_id: '',
  show_in_pos: true,
};

export const Create = ({ onExit }: { onExit: () => void }): JSX.Element => {
  const { mutate, isLoading } = useApiMutation(
    ['create-inventory-location'],
    InventoryService.createLocation,
    {
      onSuccess: onExit,
    },
  );
  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      leftAction={<BackButton onClick={onExit} />}
    >
      <Form handleSubmit={(v) => mutate(v)} initialValues={initialValues} />
    </ContentLayout>
  );
};
