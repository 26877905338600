import { Switch, Textarea, TextInput, Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { CreateVendor } from 'shared';
import { AdditionalLocation } from './AdditionalLocation';

type PropType = {
  form: UseFormReturnType<CreateVendor, (values: CreateVendor) => CreateVendor>;
};

export const VendorProfile = ({ form }: PropType) => {
  return (
    <Grid gutter={'xl'}>
      <Grid.Col span={12}>
        <TextInput
          required
          label="Company Name"
          {...form.getInputProps('company_name')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TextInput
          required
          label="Website"
          {...form.getInputProps('website')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <AdditionalLocation form={form} />
      </Grid.Col>
      <Grid.Col span={12}>
        <Textarea label="Comment" {...form.getInputProps('comment')} />
      </Grid.Col>
      <Grid.Col span={12} pt={0}>
        <Switch
          m={0}
          p={0}
          label="Location Specific"
          labelPosition="right"
          {...form.getInputProps('is_location_specific', {
            type: 'checkbox',
          })}
        />
      </Grid.Col>
    </Grid>
  );
};
