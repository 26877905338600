import { Grid, Text } from '@mantine/core';
import { RefundedItem } from 'shared';
import { Item } from './Item';
import { ItemSkeleton } from './ItemSkeleton';

export const RefundItems = ({
  paymentId,
  refundItems,
  numberProducts,
  isLoadingRefundItemCost,
  handleCalculateRefund,
}: {
  paymentId: string;
  refundItems: RefundedItem[];
  numberProducts: number;
  isLoadingRefundItemCost: boolean;
  handleCalculateRefund: ({
    paymentId,
    item,
  }: {
    paymentId: string;
    item: {
      item_id?: string;
      item_quantity: number;
    };
  }) => void;
}) => {
  const pseudoProducts = [...Array(numberProducts).keys()];

  return (
    <>
      <Grid grow gutter="lg" my={10}>
        <Grid.Col span={1} />
        <Grid.Col span={3}>
          <Text size="sm" weight={600}>
            Product Name
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" weight={600} align="end">
            Qty
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" weight={600} align="end">
            Price
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" weight={600} align="end">
            Discount
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm" weight={600} align="end">
            Total
          </Text>
        </Grid.Col>
      </Grid>

      {isLoadingRefundItemCost
        ? pseudoProducts.map((i) => <ItemSkeleton key={i} />)
        : refundItems?.map((refundItem) => (
            <Item
              key={refundItem.item_id}
              paymentId={paymentId}
              refundItem={refundItem}
              handleCalculateRefund={handleCalculateRefund}
            />
          ))}
    </>
  );
};
