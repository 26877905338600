import { useCallback, useMemo } from 'react';
import { reject, isEmpty } from 'rambda';
import { CulinarySkuService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import { DEFAULT_PAGE_SIZE, SkuStatus, normalizeCulinarySku } from 'shared';

export const useCulinarySkus = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
    inventory_type: '',
    category: '',
    status: '',
    parent_category: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';
  const inventory_type = searchParams.get('inventory_type') ?? '';
  const category = searchParams.get('category') ?? '';
  const status = (searchParams.get('status') as SkuStatus) ?? '';
  const parent_category = searchParams.get('parent_category') ?? '';

  const params = useMemo(() => {
    return {
      page,
      page_size: DEFAULT_PAGE_SIZE,
      inventory_type,
      parent_category,
      category,
      status,
      search_string,
    };
  }, [category, inventory_type, page, parent_category, search_string, status]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getCulinarySkus', key], () =>
    CulinarySkuService.getCollection(
      reject(isEmpty, params as Record<string, unknown>),
    ),
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map(normalizeCulinarySku);
  }, [data]);

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ ...data, page: '1' });
    },
    [handleSetSearchParams],
  );

  return {
    page,
    output,
    isLoading,
    filters: {
      search_string,
      inventory_type,
      category,
      status,
      parent_category,
    },
    totalRecords: data?.total ?? 0,
    updateFilters,
    handleSetSearchParams,
  };
};
