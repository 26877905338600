import { Grid, Skeleton } from '@mantine/core';

export const ItemSkeleton = () => {
  return (
    <Grid grow gutter="lg" mb={12} sx={{ height: 50 }}>
      <Grid.Col span={1}>
        <Skeleton height={20} width={20} />
      </Grid.Col>
      <Grid.Col span={3}>
        <Skeleton height={20} />
      </Grid.Col>
      <Grid.Col span={2}>
        <Skeleton height={20} />
      </Grid.Col>
      <Grid.Col span={2}>
        <Skeleton height={20} />
      </Grid.Col>
      <Grid.Col span={2}>
        <Skeleton height={20} />
      </Grid.Col>
      <Grid.Col span={2}>
        <Skeleton height={20} />
      </Grid.Col>
    </Grid>
  );
};
