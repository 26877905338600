import { ReactNode } from 'react';
import {
  Button,
  Container,
  Grid,
  Loader,
  Select,
  Switch,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { CreateCulinaryMenu, LOADER, LOCATION_DROPDOWN } from 'shared';
import { useFormData } from '../../hooks';
import { AddProduct } from './AddProduct';
import { getNewItem } from './helpers';
import { culinaryMenuSchema } from './schema';
import { ConfirmModal } from 'components';

export type Props = {
  handleSubmit: (values: CreateCulinaryMenu) => void;
  initialValues?: CreateCulinaryMenu;
  action?: ReactNode;
  canEdit?: boolean;
  editMode?: boolean;
};

export const Form = ({
  handleSubmit,
  initialValues,
  action,
  canEdit,
  editMode,
}: Props): JSX.Element => {
  const form = useForm({
    initialValues,
    validate: yupResolver(culinaryMenuSchema),
    validateInputOnBlur: true,
  });
  const payload = {
    inventorySlug: form.values.location_id ?? initialValues?.location_id,
    inventoryLocationId:
      form.values.inventory_location_id || initialValues?.inventory_location_id,
  };

  const {
    actualLocationOptions,
    inventoryOptions,
    isLoadingInventory,
    isActive,
  } = useFormData(payload);

  const fullWidth = { width: '100%' };
  const isConfirm =
    isActive && form.values.is_active && !initialValues?.is_active;

  return (
    <Container size="sm">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid gutter="xl">
          <Grid.Col span={6}>
            <TextInput
              required
              sx={fullWidth}
              label="Menu Name"
              {...form.getInputProps('name')}
            />
          </Grid.Col>
          <Grid.Col span={6} mt="15px">
            <Switch
              label="Enable"
              labelPosition="right"
              sx={{ fontWeight: 600 }}
              {...form.getInputProps('is_active', { type: 'checkbox' })}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Select
              required
              label="Primary Location"
              data-testid={LOCATION_DROPDOWN}
              sx={fullWidth}
              rightSectionWidth={30}
              {...form.getInputProps('location_id')}
              onChange={(value) => {
                form.setFieldValue('location_id', value);
                form.setFieldValue('inventory_location_id', '');
              }}
              data={actualLocationOptions}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              required
              label="Inventory Location"
              sx={fullWidth}
              rightSectionWidth={30}
              icon={
                isLoadingInventory ? (
                  <Loader data-testid={LOADER} size={20} />
                ) : null
              }
              {...form.getInputProps('inventory_location_id')}
              data={inventoryOptions}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Select
              disabled
              label="Printer"
              sx={{ width: '50%', paddingRight: '10px' }}
              rightSectionWidth={30}
              data={[]}
            />
          </Grid.Col>
        </Grid>

        <AddProduct form={form} />

        <Button
          p="0"
          mb="24px"
          variant="white"
          onClick={() => form.insertListItem('items', getNewItem())}
        >
          + Add New Product
        </Button>

        <Textarea
          label="Menu Staff Comment"
          {...form.getInputProps('comment')}
        />

        <Container my="xl" size="xs">
          {(canEdit || !editMode) &&
            (isConfirm ? (
              <ConfirmModal
                fullWidth
                label="Save"
                type="button"
                loading={false}
                disabled={!form.isValid()}
                onConfirm={() => handleSubmit(form.values)}
              >
                <Text>
                  There is an active menu. Do you want to replace the current
                  menu?
                </Text>
              </ConfirmModal>
            ) : (
              <Button
                fullWidth
                type="submit"
                aria-label="save"
                disabled={!form.isValid()}
              >
                Save
              </Button>
            ))}

          {action}
        </Container>
      </form>
    </Container>
  );
};
