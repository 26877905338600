import { useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { reject, isEmpty } from 'rambda';

import { InventoryAdjustmentService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import {
  AdjustmentListItemOutput,
  AdjustmentListItemDto,
  getFullName,
  adjustmentTypeLabels,
  AdjustmentListRequest,
  DATE_FORMAT_SLASH,
} from 'shared';

const normalize = (data: AdjustmentListItemDto): AdjustmentListItemOutput => {
  const {
    created_by,
    created_at,
    location: { name: location },
    inventory_location: { name: inventory_location },
    adjustment_type,
    ...rest
  } = data;
  return {
    ...rest,
    creator: getFullName(created_by),
    location,
    inventory_location,
    created_at: dayjs(created_at).format(DATE_FORMAT_SLASH),
    adjustment_type: adjustmentTypeLabels[adjustment_type],
  };
};

export const useAdjustment = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
    location: [],
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';
  const location = searchParams.getAll('location') as string[];
  const adjustment_type = searchParams.get('adjustment_type') ?? undefined;

  const params: AdjustmentListRequest = useMemo(() => {
    return {
      page,
      ...reject(isEmpty, {
        search_string,
        adjustment_type,
        ...(isEmpty(location) ? {} : { location }),
      } as Record<string, unknown>),
    };
  }, [adjustment_type, location, page, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['get-adjustments', key], () =>
    InventoryAdjustmentService.getCollection(params),
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map(normalize);
  }, [data]);

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ ...data, page: '1' });
    },
    [handleSetSearchParams],
  );

  return {
    page,
    output,
    isLoading,
    filters: { page, search_string, adjustment_type, location },
    totalRecords: data?.total ?? 1,
    updateFilters,
    handleSetSearchParams,
  };
};
