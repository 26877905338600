import { useState, useEffect, ReactElement } from 'react';
import { map } from 'rambda';
import { Autocomplete, Libraries, useLoadScript } from '@react-google-maps/api';
import { AutocompleteAddress, removeAccentsDiacritics } from 'shared';

type Props = {
  children: ReactElement;
  setValues: (data: AutocompleteAddress) => void;
};

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const libraries = ['places'];

const getFillData = (data?: google.maps.GeocoderAddressComponent[]) => {
  const mapped = map(
    (i) => ({
      [i.types[0]]: {
        long: removeAccentsDiacritics(i.long_name),
        short: removeAccentsDiacritics(i.short_name),
      },
    }),
    data as google.maps.GeocoderAddressComponent[],
  );
  const fillData = Object.assign({}, ...mapped);

  return fillData;
};

export const GoogleAutocomplete = ({ children, setValues }: Props) => {
  const [searchResult, setSearchResult] =
    useState<google.maps.places.Autocomplete | null>(null);

  const [autocompleteData, setAutocompleteData] =
    useState<AutocompleteAddress | null>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY ?? '',
    libraries: libraries as Libraries,
    language: 'en',
  });

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const addressComponents = place.address_components;
      const addressData = getFillData(addressComponents);

      setAutocompleteData({ ...addressData });
    }
  };

  useEffect(() => {
    if (autocompleteData) {
      setValues(autocompleteData);
    }
  }, [autocompleteData, setValues]);

  if (!isLoaded) {
    return <>{children}</>;
  }

  return (
    <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
      {children}
    </Autocomplete>
  );
};
