import { useNavigate } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { getViewPath, Permissions } from 'shared';
import { Create, Details, Edit, List } from './components';
import { NestedRouteComponent } from 'components';

export const Skus = (): JSX.Element => {
  const navigate = useNavigate();
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.ProductSKUs,
  });

  const canCreate = checkAccess(Permissions.admin_panel_sku_create);
  const canEdit = checkAccess(Permissions.admin_panel_sku_edit);
  const canDelete = checkAccess(Permissions.admin_panel_sku_delete);

  const onExit = useGotoRoute(RouteName.ProductSKUs);
  const onCreate = (id: string) => navigate(getViewPath(rootPath, id));

  const DetailsComponent = <Details canEdit={canEdit} rootPath={rootPath} />;
  const CreateComponent = <Create onCreate={onCreate} rootPath={rootPath} />;
  const ListComponent = <List canCreate={canCreate} rootPath={rootPath} />;
  const EditComponent = (
    <Edit
      canEdit={canEdit}
      canDelete={canDelete}
      onExit={onExit}
      onCreate={onCreate}
    />
  );

  return (
    <NestedRouteComponent
      ListComponent={ListComponent}
      Create={CreateComponent}
      Edit={EditComponent}
      Details={DetailsComponent}
      showEdit={canEdit}
      showCreate={canCreate}
      rootPath={rootPath}
    />
  );
};
