import * as Yup from 'yup';

import { requiredStringValidationRule } from 'shared';

const MSG = 'Required';
const numberRule = Yup.number().required(MSG).min(0, MSG);

const feeSchema = Yup.object().shape({
  name: requiredStringValidationRule('Name'),
  value: numberRule,
});

export const taxesFeesSchema = Yup.object({
  value: numberRule,
  fees: Yup.array().of(feeSchema),
});
