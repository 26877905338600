import { Routes, Route, Outlet, useNavigate, Navigate } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { NestedRoutes, Permissions } from 'shared';
import { generateRoutePath, RouteName } from 'App/routes';
import { List, Create, Edit } from './components';

export const Locations = (): JSX.Element => {
  const navigate = useNavigate();
  const { checkAccess } = usePermissions();

  const rootPath = generateRoutePath({
    name: RouteName.Locations,
  });
  const onExit = () => navigate(rootPath);
  const canCreate = checkAccess(Permissions.admin_panel_locations_create);
  const canEdit = checkAccess(Permissions.admin_panel_locations_edit);
  const canDelete = checkAccess(Permissions.admin_panel_locations_delete);

  const ListElement = <List canCreate={canCreate} canEdit={canEdit} />;
  return (
    <>
      <Routes>
        <Route index element={ListElement} />
        <Route path={NestedRoutes.List} element={ListElement} />
        {canCreate && (
          <Route
            path={NestedRoutes.Create}
            element={<Create onExit={onExit} />}
          />
        )}
        {canEdit && (
          <Route
            path={NestedRoutes.Edit}
            element={<Edit onExit={onExit} canDelete={canDelete} />}
          />
        )}
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: rootPath,
              }}
            />
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};
