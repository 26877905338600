import { Button, ButtonProps } from '@mantine/core';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { BACK_BTN } from 'shared';

export const BackButton = (
  props: ButtonProps & {
    onClick?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: any;
    to?: string;
  },
) => (
  <Button
    data-testid={BACK_BTN}
    leftIcon={<IconArrowNarrowLeft />}
    variant="subtle"
    {...props}
  >
    Back
  </Button>
);
