import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Container, LoadingOverlay, Text } from '@mantine/core';

import { ContentLayout, BackButton, EditButton } from 'components';
import { LOADING_OVERLAY, getEditPath } from 'shared';
import { useEditVendors } from '../../hooks';
import { Location } from './detailsSection';
import { randomId } from '@mantine/hooks';

export const Details = ({
  canEdit,
  rootPath,
}: {
  canEdit: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams();

  const { vendor, isLoading } = useEditVendors({ id });

  const isContactDetails =
    vendor?.primary_contact_name ??
    vendor?.primary_contact_phone_number ??
    vendor?.primary_contact_email;

  return (
    <ContentLayout
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
      title={vendor?.company_name}
      rightAction={
        canEdit && (
          <EditButton component={Link} to={getEditPath(rootPath, id)} />
        )
      }
    >
      <LoadingOverlay
        data-testid={LOADING_OVERLAY}
        visible={isLoading}
        overlayBlur={2}
      />
      <Container size="sm">
        <Box mb={20}>
          <Text weight={600} size="sm" mb={10}>
            {vendor?.company_name}
          </Text>
          <Text weight={400} size="sm" mb={10}>
            {vendor?.website}
          </Text>
          <Text weight={400} size="sm" mb={10}>
            {`${vendor?.street_address}, ${vendor?.city}, ${vendor?.state}, ${vendor?.zip_code}`}
          </Text>
          <Text weight={400} size="sm" mb={20}>
            {vendor?.phone_number}
          </Text>
        </Box>

        {isContactDetails && (
          <Box mb={20}>
            <Text weight={600} size="sm" mb={10}>
              Primary Contact Info
            </Text>
            <Text weight={400} size="sm" mb={10}>
              {vendor?.primary_contact_name}
            </Text>
            <Text weight={400} size="sm" mb={10}>
              {vendor?.primary_contact_phone_number}
            </Text>
            <Text weight={400} size="sm" mb={20}>
              {vendor?.primary_contact_email}
            </Text>
          </Box>
        )}

        {vendor?.comment && (
          <Box mb={20}>
            <Text weight={600} size="sm" mb={10}>
              Comment
            </Text>
            <Text weight={400} size="sm" mb={10}>
              {vendor.comment}
            </Text>
          </Box>
        )}
        {vendor?.vendor_locations.map((vendorLocation) => (
          <Location key={randomId()} vendor={vendorLocation} />
        ))}
      </Container>
    </ContentLayout>
  );
};
