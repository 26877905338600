import { Button, Grid, Text, Textarea } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { ProductCategoryFormValues } from 'shared';
import { ProductOptions } from './ProductOptions';
import { getNewProductOption } from 'pages/products/categories/helpers';

type PropType = {
  form: UseFormReturnType<ProductCategoryFormValues>;
};

export const AdditionalFields = ({ form }: PropType) => {
  return (
    <Grid mt="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          Additional Information
        </Text>
      </Grid.Col>
      <Grid.Col>
        <Textarea
          label="Category Description"
          {...form.getInputProps('description')}
        />
      </Grid.Col>
      <Grid.Col pb={0}>
        <Text size={14} weight={600}>
          Product Description Blocks for Category
        </Text>
      </Grid.Col>
      <Grid.Col>
        <ProductOptions form={form}>
          <Button
            variant="white"
            p="0"
            onClick={() =>
              form.insertListItem('product_options', getNewProductOption())
            }
          >
            + Add Block
          </Button>
        </ProductOptions>
      </Grid.Col>
    </Grid>
  );
};
