import { randomId } from '@mantine/hooks';
import { omit, split } from 'rambda';
import {
  ShipmentCycleDto,
  ShipmentCyclePayload,
  ShipmentCycleSummaryItem,
  ShipmentCycleSummaryItemOutput,
  WineSelectionDto,
  concatDateTime,
  formatDate,
  getDateOutput,
  capitalizeText,
  ShipmentCycleDetailItemDto,
  SuggestionsDto,
  getFormatDate,
  ShipmentCycleEdit,
  convertToCaliforniaTZ,
  getDate,
} from 'shared';

export const normalizePayload = ({
  isStartAfterSave = false,
  cycleSetting,
  wineSelection,
  suggestion,
}: {
  isStartAfterSave?: boolean;
  cycleSetting: ShipmentCycleDto;
  wineSelection: WineSelectionDto;
  suggestion: SuggestionsDto;
}) => {
  const {
    cycle_start_date,
    cycle_start_time,
    billing_date,
    billing_time,
    re_billing_date,
    re_billing_time,
    member_list_close_date,
    end_of_customization_window,
    estimated_shipping_date,
    pick_up_deadline,
  } = cycleSetting;
  const { wine_selections } = wineSelection;
  const { suggestions } = suggestion;
  return {
    ...omit(
      [
        'cycle_start_date',
        'cycle_start_time',
        'billing_date',
        'billing_time',
        're_billing_date',
        're_billing_time',
      ],
      cycleSetting,
    ),
    cycle_start_datetime: isStartAfterSave
      ? convertToCaliforniaTZ(new Date().toString())
      : concatDateTime(cycle_start_date, cycle_start_time),
    billing_datetime: concatDateTime(billing_date, billing_time),
    re_billing_datetime: concatDateTime(re_billing_date, re_billing_time),
    member_list_close_date: formatDate(member_list_close_date),
    pick_up_deadline: formatDate(pick_up_deadline),
    end_of_customization_window: formatDate(end_of_customization_window),
    estimated_shipping_date: formatDate(estimated_shipping_date),
    wine_selections: wine_selections.filter((i) => !!i.sku_id),
    suggestions: suggestions.filter((i) => !!i.sku_id),
  } as ShipmentCyclePayload;
};

export const normalizeShipmentCycleSummary = (
  data: ShipmentCycleSummaryItem,
): ShipmentCycleSummaryItemOutput => {
  const {
    status,
    billing_cycle_start,
    pending,
    processed,
    unpaid,
    closed,
    ...rest
  } = data;
  return {
    ...rest,
    billing_cycle_start: getDateOutput(billing_cycle_start),
    status: capitalizeText(split('_', status)[0]),
    pending: pending ?? 0,
    processed: processed ?? 0,
    unpaid: unpaid ?? 0,
    closed: closed ?? 0,
  };
};

export const getAddOnLabel = (
  addOn: ShipmentCycleDetailItemDto['add_on'],
): string => {
  if (!addOn) {
    return '';
  }
  return ` + ${addOn.name}`;
};

export const getBottlesLabel = (qty?: number): string => {
  if (!qty) {
    return '';
  }
  return ` - ${qty}`;
};

export const normalizeShipmentCycle = (
  data: ShipmentCycleEdit,
): ShipmentCycleDto => {
  return {
    ...data,
    club_tier_id: data.club_tier.id,
    cycle_start_date: getFormatDate(data.cycle_start_datetime),
    cycle_start_time: getFormatDate(data.cycle_start_datetime),
    member_list_close_date: getDate(data.member_list_close_date),
    end_of_customization_window: getDate(data.end_of_customization_window),
    billing_date: getFormatDate(data.billing_datetime),
    billing_time: getFormatDate(data.billing_datetime),
    re_billing_date: getFormatDate(data.re_billing_datetime),
    re_billing_time: getFormatDate(data.re_billing_datetime),
    estimated_shipping_date: getDate(data.estimated_shipping_date),
    pick_up_deadline: getDate(data.pick_up_deadline),
    wine_selections: data.wine_selections.map((i) => ({
      ...i,
      key: randomId(),
    })),
    suggestions: data.suggestions.map((s) => ({
      ...s,
      key: randomId(),
    })),
  };
};
