import { Flex, Box, Text } from '@mantine/core';
import { isEmpty } from 'rambda';

import { ShipmentCycleSummaryItemOutput } from 'shared';

const getProps = (width: number) => ({
  py: 'sm',
  px: 'xs',
  style: {
    width: `${width}%`,
  },
});

export const Expand = ({
  data,
}: {
  data: ShipmentCycleSummaryItemOutput[];
}) => {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <Box>
      {data.map(
        (
          { name, active_members, processed, unpaid, pending, skipped, closed },
          i,
        ) => (
          <Flex
            key={name}
            style={
              i !== data.length - 1
                ? { borderBottom: '1px solid rgba(222, 226, 230, 0.65)' }
                : {}
            }
          >
            <Box {...getProps(46)}>
              <Text pl="lg">{name}</Text>
            </Box>
            <Box {...getProps(7)}>
              <Text>{active_members}</Text>
            </Box>
            <Box {...getProps(7)}>
              <Text>{skipped}</Text>
            </Box>
            <Box {...getProps(7)}>
              <Text>{processed}</Text>
            </Box>
            <Box {...getProps(7)}>
              <Text>{unpaid}</Text>
            </Box>
            <Box {...getProps(7)}>
              <Text>{pending}</Text>
            </Box>
            <Box {...getProps(8)}>
              <Text>{closed}</Text>
            </Box>
          </Flex>
        ),
      )}
    </Box>
  );
};
