import { useCallback } from 'react';

import { GeneralService } from 'App/api';
import { useApiMutation } from './useApiMutation';

const uploadFile = 'uploadFile';

export const useFileUpload = () => {
  const { isLoading, data, mutateAsync } = useApiMutation(
    [uploadFile],
    GeneralService.uploadDocument,
  );

  const { isLoading: isUpserting, mutateAsync: upsertAsync } = useApiMutation(
    [uploadFile],
    GeneralService.upsertDocument,
  );

  const handleFileUpload = useCallback(
    async (image_url: string, file: File | null) => {
      if (!image_url) {
        const { url } = await mutateAsync(file);
        return url;
      }
      if (file) {
        await upsertAsync({ document: file, url: image_url });
      }
      return image_url;
    },
    [mutateAsync, upsertAsync],
  );

  const handleMassUpload = useCallback(
    async (files: File[]) => {
      const urls = await Promise.all(files.map((f) => mutateAsync(f)));
      return urls;
    },
    [mutateAsync],
  );

  return {
    mutateAsync,
    isLoading,
    product_file: data?.url ?? null,
    upsertAsync,
    isUpserting,
    handleFileUpload,
    handleMassUpload,
  };
};
