import { Grid, Box, Text } from '@mantine/core';

export const Header = ({ shift = false }: { shift?: boolean }): JSX.Element => {
  return (
    <Grid grow gutter={'lg'} align="center">
      {shift && <Grid.Col span={1}> </Grid.Col>}
      <Grid.Col span={5}>
        <Box mx={'md'}>
          <Text size="md" weight={600}>
            Category
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={2}>
        <Text size="md" weight={600}>
          Active
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text size="md" weight={600}>
          Hide from POS
        </Text>
      </Grid.Col>
      {!shift && <Grid.Col span={2}> </Grid.Col>}
    </Grid>
  );
};
