import { Box, Text } from '@mantine/core';
import { VendorLocation } from 'shared';

export const Location = ({ vendor }: { vendor?: VendorLocation }) => {
  const {
    location,
    primary_contact_name,
    primary_contact_email,
    primary_contact_phone_number,
    street_address,
    city,
    state,
    zip_code,
  } = vendor ?? {};

  return (
    <Box mb={20}>
      <Text weight={600} size="sm" mb={10}>
        {location?.name}
      </Text>
      <Text weight={400} size="sm" mb={10}>
        {primary_contact_name}
      </Text>
      <Text weight={400} size="sm" mb={10}>
        {`${street_address}, ${city}, ${state}, ${zip_code}`}
      </Text>
      <Text weight={400} size="sm" mb={10}>
        {primary_contact_phone_number}
      </Text>
      <Text weight={400} size="sm" mb={10}>
        {primary_contact_email}
      </Text>
    </Box>
  );
};
