import { Container } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { ClubTierDto, Suggestion, SuggestionsDto } from 'shared';
import { SuggestionType } from './SuggestionType';
import { SuggestionItem } from './SuggestionItem';
import { useCopyPaste } from 'pages/membersClub/shipmentCycle/hooks';

export const SuggestionSection = ({
  tiers,
  form,
}: {
  tiers?: ClubTierDto[];
  form: UseFormReturnType<SuggestionsDto>;
}) => {
  const { copy, setCopy, paste } = useCopyPaste<Suggestion>();
  return (
    <Container size="xl">
      {tiers?.map((tier) => (
        <SuggestionType title={tier.name} key={tier.id}>
          <SuggestionItem
            form={form}
            tierId={tier.id}
            copy={copy}
            paste={paste}
            setCopy={setCopy}
          />
        </SuggestionType>
      ))}
    </Container>
  );
};
