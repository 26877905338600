import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '@mantine/core';

import { ConfirmModal, ContentLayout, BackButton } from 'components';
import { Form } from '../form';
import { useEditSkus } from '../../hooks';
import { DELETE_BTN, CreateSku } from 'shared';

type PropType = {
  canDelete: boolean;
  canEdit: boolean;
  onExit: () => void;
  onCreate?: (id: string) => void;
};

export const Edit = ({
  canDelete,
  onExit,
  onCreate,
  canEdit,
}: PropType): JSX.Element => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const {
    initialValue,
    isLoading,
    isDeleting,
    deleteSku,
    isUpdating,
    handleSubmit,
    processingUpload,
    vendorName,
  } = useEditSkus({
    id,
    onDelete: onExit,
    onCreate,
  });

  const goBack = () => navigate(-1);

  return (
    <ContentLayout
      title="Edit Product"
      isLoading={isLoading}
      leftAction={<BackButton onClick={goBack} />}
      processing={isUpdating || processingUpload}
    >
      <Form
        editMode
        handleSubmit={handleSubmit}
        initialValues={initialValue as CreateSku}
        canEdit={canEdit}
        vendorName={vendorName}
        processing={isUpdating || processingUpload}
        action={
          canDelete ? (
            <ConfirmModal
              type="button"
              variant="outline"
              fullWidth
              loading={isDeleting}
              onConfirm={() => deleteSku(id)}
              label="Delete"
              data-testid={DELETE_BTN}
            >
              <Text data-testid="delete-sku-btn">
                Are you sure you want to delete the SKU?
              </Text>
            </ConfirmModal>
          ) : null
        }
      />
    </ContentLayout>
  );
};
