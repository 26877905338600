export const SAVE_BTN = 'save-btn';
export const SEARCH_INPUT = 'search-input';
export const BACK_BTN = 'back-btn';
export const DELETE_BTN = 'delete-btn';
export const LOADING_OVERLAY = 'loading-overlay';
export const LOADER = 'loader';
export const PRODUCT_INPUT = 'product-input';
export const LOCATION_DROPDOWN = 'primary-location-dropdown';
export const SECONDARY_LOCATION_DROPDOWN = 'secondary-location-dropdown';
export const EMAIL_INPUT = 'email-input';
export const INVENTORY_LOCATION_DROPDOWN = 'inventory-location-dropdown';
export const RECEIVE_BTN = 'receive-btn';
export const CANCEL_BTN = 'cancel-btn';
export const CONFIRM_BTN = 'confirm-btn';
