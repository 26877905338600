import { useNavigate } from 'react-router-dom';
import { omit } from 'rambda';
import { Text, Container, Box } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import { ProductCategoryBaseDto, getEditPath, PROPS_DATA_TABLE } from 'shared';

export const Subcategories = ({
  data,
  rootPath,
}: {
  data: ProductCategoryBaseDto[];
  rootPath: string;
}) => {
  const navigate = useNavigate();
  const tableProps = omit(['recordsPerPage'], PROPS_DATA_TABLE);

  return (
    <Container size={'sm'}>
      <Box mb="lg">
        <Text align="center">
          This category contains sub-categories. To add products, please go to a
          needed sub-category.
        </Text>
      </Box>
      <Text size="md" weight={600} align="left" mb={'1rem'}>
        Sub-Categories:
      </Text>
      <DataTable
        {...tableProps}
        records={data}
        onRowClick={({ id }) => navigate(getEditPath(rootPath, id))}
        columns={[
          { accessor: 'name', title: 'Name' },
          {
            accessor: 'active',
            title: 'Active',
            render: ({ active }) => (active ? 'Yes' : 'No'),
          },
          {
            accessor: 'hide_from_pos',
            title: 'Hide from POS',
            render: ({ hide_from_pos }) => (hide_from_pos ? 'Yes' : 'No'),
          },
        ]}
      />
    </Container>
  );
};
