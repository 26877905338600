import { useCallback, useMemo, useState } from 'react';
import { isEmpty, reject } from 'rambda';

import {
  PaginatedRequest,
  ShipmentCycleCollectionItemDto,
  hasPages,
} from 'shared';
import { useApiQuery } from './useApiQuery';
import { ShipmentCycleService } from 'App/api';

type RequestFilter = { search_string: string };

const PAGE_SIZE = 50;

const normalizeShipmentCycleOptions = (
  data?: ShipmentCycleCollectionItemDto[],
) => {
  if (!data) return [];

  return data.map((i) => ({ value: i.id, label: i.summary.name }));
};

export const useShipmentCycleOptions = ({ enabled }: { enabled: boolean }) => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<RequestFilter>({
    search_string: '',
  });

  const params: PaginatedRequest = useMemo(() => {
    const { search_string } = filters;
    return {
      page,
      page_size: PAGE_SIZE,
      status: 'published',
      search_string,
    };
  }, [filters, page]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(
    ['getShipmentCycleOptions', key],
    () =>
      ShipmentCycleService.getCollection(
        reject(isEmpty, params as Record<string, unknown>),
      ),
    {
      enabled,
      cacheTime: 0,
    },
  );

  const updateFilters = useCallback((data: RequestFilter) => {
    setFilters((prev) => ({ ...prev, ...data }));
    setPage(1);
  }, []);

  const shipmentCycleOptions = normalizeShipmentCycleOptions(data?.items);

  return {
    shipmentCycleOptions,
    isLoading,
    setPage,
    updateFilters,
    hasMore: hasPages({
      total: data?.total,
      defaultSize: PAGE_SIZE,
      currentPage: page,
    }),
  };
};
