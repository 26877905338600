import request from '../../request';
import {
  ReorderPayload,
  ProductCategoryDto,
  ProductCategoryPayload,
  CategorySkuDto,
  AddCategorySKUs,
} from 'shared';
import { CATEGORY_API_PATH } from '../../endpoints';

export default class CategoryService {
  static readonly create = (params: ProductCategoryPayload) => {
    return request<ProductCategoryDto>({
      url: CATEGORY_API_PATH,
      method: 'post',
      data: params,
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<ProductCategoryDto>({
      url: `${CATEGORY_API_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly getAll = () => {
    return request<{ items: ProductCategoryDto[] }>({
      url: CATEGORY_API_PATH,
      method: 'get',
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id?: string;
    data: ProductCategoryPayload;
  }) => {
    return request<ProductCategoryDto>({
      url: `${CATEGORY_API_PATH}/${id}`,
      method: 'patch',
      data,
    });
  };

  static readonly delete = ({ id }: { id: string }) => {
    return request({
      url: `${CATEGORY_API_PATH}/${id}`,
      method: 'delete',
    });
  };

  static readonly reorder = (data: ReorderPayload) => {
    return request<{ items: ProductCategoryDto[] }>({
      url: `${CATEGORY_API_PATH}/reorder`,
      method: 'post',
      data,
    });
  };

  static readonly getFreeSKUs = () => {
    return request<{ items: CategorySkuDto[] }>({
      url: `${CATEGORY_API_PATH}/free-products`,
      method: 'get',
    });
  };

  static readonly removeSKU = ({
    category_id,
    sku_id,
  }: {
    category_id: string;
    sku_id: string;
  }) => {
    return request({
      url: `${CATEGORY_API_PATH}/${category_id}/remove-sku`,
      method: 'post',
      data: { sku_id },
    });
  };

  static readonly addSKUs = ({ category_id, skus }: AddCategorySKUs) => {
    return request({
      url: `${CATEGORY_API_PATH}/${category_id}/add-skus`,
      method: 'post',
      data: { skus },
    });
  };

  static readonly reorderSKUs = ({ category_id, skus }: AddCategorySKUs) => {
    return request({
      url: `${CATEGORY_API_PATH}/${category_id}/reorder-skus`,
      method: 'post',
      data: { skus },
    });
  };
}
