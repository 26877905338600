import { Box, Button, Container, Text } from '@mantine/core';
import { RequestAction } from 'pages/crm/customers/constants';
import { ClubMembershipChangeReason } from 'shared';

type Props = {
  isLoading: boolean;
  changeTierData: {
    changeTier: string;
    reason?: ClubMembershipChangeReason;
    fullName: string;
    addOn: string;
  };
  requestAction: ({ type }: { type: RequestAction }) => void;
};

export const ChangeWindow = ({
  changeTierData,
  requestAction,
  isLoading,
}: Props) => {
  const { changeTier, reason, fullName, addOn } = changeTierData;
  return (
    <Container size="xl">
      <Box p="md" mb="lg" sx={{ backgroundColor: 'red.1' }}>
        <Text size="lg" weight={700} mb="md">
          {changeTier}
        </Text>
        <Text size="lg" weight={700} mb="md">
          {addOn}
        </Text>
        <Box my="md">
          <Text size="sm" weight={600}>
            Reason:
          </Text>
          <Text size="sm">{reason}</Text>
        </Box>
        <Box my="md">
          <Text size="sm" weight={600}>
            Source:
          </Text>
          <Text size="sm">{fullName}</Text>
        </Box>
        <Container size="xs">
          <Button
            fullWidth
            mb="md"
            loading={isLoading}
            onClick={() => requestAction({ type: RequestAction.Approve })}
          >
            Approve Request
          </Button>
          <Button
            fullWidth
            variant="outline"
            loading={isLoading}
            onClick={() => requestAction({ type: RequestAction.Decline })}
          >
            Decline Request
          </Button>
        </Container>
      </Box>
    </Container>
  );
};
