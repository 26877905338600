import dayjs from 'dayjs';
import {
  DATE_FORMAT_SLASH,
  OrdersHistoryDto,
  salesOrderSourceTitle,
  getCurrencyString,
  salesOrderStatusLabels,
  OrdersHistoryList,
  ServiceClass,
} from 'shared';

export const normalizeOrdersHistory = (
  orders?: OrdersHistoryDto[],
): OrdersHistoryList[] => {
  if (!orders) return [];

  return orders.map(
    ({
      closed_at,
      total,
      delivery_method,
      status,
      processed_in,
      service_class,
      ...rest
    }) => {
      return {
        ...rest,
        note: service_class === ServiceClass.wine_club ? 'Wine Cycle' : '',
        service_class,
        delivery_method: delivery_method || '-',
        processed_in: salesOrderSourceTitle[processed_in],
        status: salesOrderStatusLabels[status],
        total: getCurrencyString(total),
        closed_at: dayjs(closed_at).format(DATE_FORMAT_SLASH),
      };
    },
  );
};
