import {
  AddressDto,
  AutocompleteAddress,
  CustomerDto,
  dateInputOutputFormat,
} from 'shared';
import { addressInitialValues } from './data';

export const normalizeAutocompleteData = ({
  postal_code,
  postal_code_suffix,
  administrative_area_level_1,
  country,
  locality,
  street_number,
  route,
}: AutocompleteAddress) => {
  const zipCode = postal_code_suffix
    ? `${postal_code?.short}-${postal_code_suffix?.short}`
    : postal_code?.long;

  return {
    country: country?.long,
    city: locality?.long,
    state: administrative_area_level_1?.long,
    zip_code: postal_code ? zipCode : '',
    street_address_one: `${street_number?.short ?? ''} ${route?.short ?? ''}`,
    address: `${street_number?.long ?? ''} ${route?.short ?? ''}`,
  };
};

export const normalizeAddresses = (addresses?: AddressDto[]) => {
  if (!addresses) return [];

  return addresses?.map((address) => {
    const { date_of_birth } = address;

    return {
      ...address,
      date_of_birth: dateInputOutputFormat(date_of_birth as string, 'input'),
    };
  });
};

export const getInitialAddressValue = (
  profileData?: CustomerDto['profile'],
) => {
  const { first_name, last_name, email, phone_number, date_of_birth } =
    profileData ?? {};

  return {
    ...addressInitialValues,
    first_name,
    last_name,
    email,
    phone_number,
    date_of_birth: dateInputOutputFormat(date_of_birth as string, 'input'),
  } as AddressDto;
};
