import { Badge, Flex, Space, Text, BadgeVariant } from '@mantine/core';

type Props = {
  title?: string;
  status?: string;
  color?: string;
  variant?: BadgeVariant;
};

export const TitleWithBadge = ({
  title,
  status,
  color = '#037EB1',
  variant = 'outline',
}: Props) => {
  return (
    <Flex justify={'center'} align="center">
      <Text data-testid="content-title-with-badge" weight={700} size="lg">
        {title}
      </Text>
      <Space w="sm" />

      {status && (
        <Badge data-testid="status-badge" variant={variant} color={color}>
          {status}
        </Badge>
      )}
    </Flex>
  );
};
