import { useCallback, useMemo } from 'react';
import { reject, isEmpty } from 'rambda';
import { PartnerService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import { PartnersRequest } from 'shared';

export const usePartners = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params: PartnersRequest = useMemo(() => {
    return {
      page,
      search_string,
    };
  }, [page, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getPartners', key], () =>
    PartnerService.getCollection(
      reject(isEmpty, params as Record<string, unknown>),
    ),
  );

  const output = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map((e) => e);
  }, [data]);

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ ...data, page: '1' });
    },
    [handleSetSearchParams],
  );

  return {
    page,
    output,
    isLoading,
    totalRecords: data?.total ?? 0,
    filters: { page, search_string },
    updateFilters,
    handleSetSearchParams,
  };
};
