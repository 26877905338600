import { Menu, UnstyledButton } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';

type Props = {
  editAction?: () => void;
  deleteAction?: () => void;
  defaultAction?: () => void;
};

export const CardAction = ({
  editAction,
  deleteAction,
  defaultAction,
}: Props) => {
  return (
    <Menu shadow="sm" width="320px" position="bottom-end">
      <Menu.Target>
        <UnstyledButton>
          <IconDotsVertical />
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        {defaultAction && (
          <Menu.Item onClick={defaultAction}>Set as Default</Menu.Item>
        )}
        {editAction && <Menu.Item onClick={editAction}>Edit</Menu.Item>}
        {deleteAction && <Menu.Item onClick={deleteAction}>Delete</Menu.Item>}
      </Menu.Dropdown>
    </Menu>
  );
};
