import { Button, Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { Suggestion, SuggestionsDto } from 'shared';
import { AddProduct } from './AddProduct';
import { getNewSuggestionItem } from 'pages/membersClub/shipmentCycle/helpers';

type Props = {
  form: UseFormReturnType<SuggestionsDto>;
  tierId: string;
  copy: Suggestion[] | null;
  setCopy: (payload: Suggestion[]) => void;
  paste: ({
    currentItems,
  }: {
    currentItems: Suggestion[];
  }) => { key: string; sku_id?: string }[];
};

export const SuggestionItem = ({
  form,
  tierId,
  copy,
  setCopy,
  paste,
}: Props) => {
  return (
    <Grid gutter="xl">
      <Grid.Col span={12}>
        <AddProduct
          form={form}
          tierId={tierId}
          copy={copy}
          paste={paste}
          setCopy={setCopy}
        >
          <Button
            variant="white"
            p="0"
            onClick={() =>
              form.insertListItem(
                'suggestions',
                getNewSuggestionItem({ tierId }),
              )
            }
          >
            + Add Product
          </Button>
        </AddProduct>
      </Grid.Col>
    </Grid>
  );
};
