import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { Permissions } from 'shared';
import { List, Create } from './components';
import { NestedRouteComponent } from 'components';
import { Edit } from './components/edit';

export const Menu = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.CulinaryMenu,
  });

  const canCreate = checkAccess(Permissions.admin_panel_culinary_menu_create);
  const canEdit = checkAccess(Permissions.admin_panel_culinary_menu_edit);
  const canDelete = checkAccess(Permissions.admin_panel_culinary_menu_delete);

  const onExit = useGotoRoute(RouteName.CulinaryMenu);

  const CreateComponent = <Create onExit={onExit} rootPath={rootPath} />;
  const ListComponent = <List canCreate={canCreate} rootPath={rootPath} />;
  const EditComponent = (
    <Edit onExit={onExit} canEdit={canEdit} canDelete={canDelete} />
  );

  return (
    <NestedRouteComponent
      showEdit
      ListComponent={ListComponent}
      Create={CreateComponent}
      Edit={EditComponent}
      showCreate={canCreate}
      rootPath={rootPath}
    />
  );
};
