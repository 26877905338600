import { randomId } from '@mantine/hooks';

import { Fee } from 'shared';

export const getNewFeeItem = (): Fee => ({
  name: '',
  value: 0,
  id: randomId(),
  unit: '%',
});
