import { Button, Container, Text } from '@mantine/core';
import { ShipmentCycleStatus } from 'shared';

type Props = {
  isValidDraft?: boolean;
  isValid?: boolean;
  handleSubmit: (status: ShipmentCycleStatus) => void;
  loading?: boolean;
};

export const EditButtons = ({
  isValidDraft,
  isValid,
  loading,
  handleSubmit,
}: Props) => {
  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', gap: 20 }}
      size="xs"
      my={48}
    >
      <Button
        disabled={!isValidDraft}
        type="submit"
        onClick={() => handleSubmit(ShipmentCycleStatus.DRAFT)}
        fullWidth
        loading={loading}
      >
        Save as Draft
      </Button>
      <Button
        disabled={!isValid}
        type="submit"
        onClick={() => handleSubmit(ShipmentCycleStatus.READY)}
        fullWidth
        loading={loading}
      >
        Save as Ready
      </Button>
      <Text color="gray" align="center" size="sm">
        To save as Ready you have to fill all fields on all tabs
      </Text>
    </Container>
  );
};
