import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { Button, Grid, Select, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  OrderDto,
  CycleOrderPayload,
  salesOrderSourceTitle,
  salesOrderStatusOptions,
  HALTER_RANCH_LOCATION_ID,
  orderPaymentStatusLabels,
  salesOrderStatusLabels,
  getClubMembership,
  SelectOption,
  channelOrderOptions,
  SalesOrderItemChannel,
  getTabEditPath,
} from 'shared';
import { useEmployees, useShipmentCycleOptions } from 'hooks';
import { SelectAsync } from 'components/selectAsync';
import { generateRoutePath, RouteName } from 'App/routes';

type PropType = {
  form: UseFormReturnType<CycleOrderPayload>;
  order: OrderDto;
  isBlocked?: boolean;
  isRefunded?: boolean;
  isCycleOrder?: boolean;
};

export const GeneralInfo = ({
  form,
  order,
  isBlocked = false,
  isRefunded = false,
  isCycleOrder = false,
}: PropType) => {
  const navigate = useNavigate();
  const {
    shipment_cycle,
    number,
    created_at,
    customer,
    payment_status,
    processed_in,
  } = order;
  const isWineClub = form.values.channel === SalesOrderItemChannel.WINE_CLUB;
  const {
    employeesOptions,
    isLoading,
    hasMore,
    setSearchStringSelectAsync,
    setPageSelectAsync,
  } = useEmployees({
    isSelectAsync: true,
    locationId: HALTER_RANCH_LOCATION_ID,
    include_system: true,
    page_size: 50,
    noCache: true,
  });
  const {
    shipmentCycleOptions,
    isLoading: isLoadingShipmentCycle,
    hasMore: hasMoreShipmentCycle,
    setPage: setShipmentCyclePage,
    updateFilters: updateFiltersShipmentCycle,
  } = useShipmentCycleOptions({
    enabled: isWineClub,
  });
  const clubMemberShip = getClubMembership(order);

  const defaultCycleName = {
    label: shipment_cycle?.name,
    value: shipment_cycle?.id,
  };

  const customerPath = generateRoutePath({
    name: RouteName.Customers,
  });

  const customerProfilePath = getTabEditPath(
    customerPath,
    customer.id,
    'profile',
  );

  return (
    <Grid mb="xl" gutter="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          General Information
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Order #
        </Text>
        <Text>{number}</Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Creation Date
        </Text>
        <Text>{created_at}</Text>
      </Grid.Col>
      <Grid.Col span={3}>
        {isCycleOrder && !isRefunded ? (
          <Select
            required
            label="Order Status"
            disabled={isBlocked}
            data={salesOrderStatusOptions}
            {...form.getInputProps('status')}
          />
        ) : (
          <>
            <Text weight={600} mb="sm">
              Order Status
            </Text>
            <Text>{salesOrderStatusLabels[form.values.status]}</Text>
          </>
        )}
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Payment Status
        </Text>
        <Text>{orderPaymentStatusLabels[payment_status]}</Text>
      </Grid.Col>
      <Grid.Col span={3}>
        {!isRefunded ? (
          <SelectAsync
            required
            isSearchable
            searchByString={({ search_string }) =>
              setSearchStringSelectAsync(search_string)
            }
            label="Updated By"
            setPage={setPageSelectAsync}
            hasMore={hasMore}
            isLoading={isLoading}
            options={employeesOptions}
            {...form.getInputProps('updated_by_id')}
            defaultValue={form.values.defaultWineAdvisor}
            onChange={(item: SingleValue<SelectOption>) => {
              if (item?.value) form.setFieldValue('updated_by_id', item.value);
            }}
          />
        ) : (
          <>
            <Text weight={600} mb="sm">
              Updated By
            </Text>
            <Text>{form.values.defaultWineAdvisor.label}</Text>
          </>
        )}
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Processed in
        </Text>
        <Text>{processed_in ? salesOrderSourceTitle[processed_in] : '-'}</Text>
      </Grid.Col>
      <Grid.Col span={3} />
      <Grid.Col span={3} />

      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Customer Name
        </Text>
        <Button
          p={0}
          variant="white"
          onClick={() => navigate(customerProfilePath)}
        >
          {customer.first_name} {customer.last_name}
        </Button>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Club Membership
        </Text>
        <Text>{clubMemberShip.text}</Text>
      </Grid.Col>
      <Grid.Col span={3} />
      <Grid.Col span={3} />

      <Grid.Col span={3}>
        <Select
          required
          width="100%"
          label="Channel"
          data={channelOrderOptions}
          {...form.getInputProps('channel')}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        {isWineClub && (
          <SelectAsync
            required
            isSearchable
            label="Cycle Name"
            searchByString={updateFiltersShipmentCycle}
            setPage={setShipmentCyclePage}
            hasMore={hasMoreShipmentCycle}
            isLoading={isLoadingShipmentCycle}
            options={shipmentCycleOptions}
            defaultValue={defaultCycleName}
            {...form.getInputProps(`shipment_cycle_id`)}
            onChange={(item: SingleValue<SelectOption>) => {
              if (item?.value)
                form.setFieldValue('shipment_cycle_id', item.value);
            }}
          />
        )}
      </Grid.Col>
    </Grid>
  );
};
