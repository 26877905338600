import {
  Box,
  Button,
  Container,
  NumberInput,
  Text,
  Textarea,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { LibraryListItemDto, ReleaseLibrary } from 'shared';
import { LibraryInfo } from './details';
import { releaseForSaleSchema } from './schema';

type Props = {
  handleSubmit: (values: ReleaseLibrary) => void;
  initialValues: ReleaseLibrary;
  data?: LibraryListItemDto;
  action?: JSX.Element | null;
};

export const ReleaseForSale = ({
  handleSubmit,
  data,
  initialValues,
  action,
}: Props): JSX.Element => {
  const form = useForm({
    initialValues,
    validate: yupResolver(releaseForSaleSchema),
    validateInputOnBlur: true,
  });

  return (
    <Container size="sm">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LibraryInfo data={data} />
        <NumberInput
          mb="xl"
          required
          label="Release Inventory Quantity"
          hideControls
          max={data?.quantity}
          data-testid="quantity_sku"
          {...form.getInputProps('quantity')}
        />
        <Textarea
          mb="xl"
          required
          label="Comment"
          sx={{ width: '100%' }}
          {...form.getInputProps('comment')}
        />
        <Text weight={400} size="sm" mb="xl">
          Make sure you have checked the price and sub-category for that SKU.
        </Text>
        <Box my="xl">
          <Button
            data-testid="release-for-sale-btn"
            disabled={!form.isValid()}
            type="submit"
            fullWidth
            aria-label="release"
          >
            Release for Sale
          </Button>
        </Box>
        {action}
      </form>
    </Container>
  );
};
