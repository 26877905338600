import { ActionIcon, Box, Grid, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { IconPencil } from '@tabler/icons-react';
import { isEmpty } from 'rambda';

import { ContentLayout } from 'components';
import { useLocalTaxes } from '../../hooks';
import { getCurrencyString, getPercentString, Fee } from 'shared';

const Fees = ({ data }: { data: Fee[] }) => {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <Box px={48} py="md" bg={'gray.0'}>
      {data.map(({ id, name, value, unit }) => (
        <Grid key={id} gutter="lg">
          <Grid.Col span={6} md={5} lg={4}>
            <Text>{name}</Text>
          </Grid.Col>
          <Grid.Col span={5}>
            <Text>
              {unit === '$'
                ? getCurrencyString(value)
                : getPercentString(value)}
            </Text>
          </Grid.Col>
        </Grid>
      ))}
    </Box>
  );
};
export const List = ({
  canEdit,
  goToEdit,
}: {
  canEdit: boolean;
  goToEdit: (id: string) => void;
}): JSX.Element => {
  const { output, isLoading } = useLocalTaxes();

  return (
    <ContentLayout title="Local Taxes and Fees" processing={isLoading}>
      <DataTable
        data-testid="local-taxes-and-fees-table"
        {...{
          noRecordsText: '',
          fontSize: 'md',
          verticalAlignment: 'top',
          verticalSpacing: 'sm',
          minHeight: 250,
        }}
        rowStyle={({ fees }) =>
          isEmpty(fees) ? undefined : { borderBottom: '1px solid red' }
        }
        records={output}
        columns={[
          { accessor: 'location', title: 'Location' },
          { accessor: 'address', title: 'Address' },
          {
            accessor: 'value',
            title: 'Local Tax',
            render: ({ value }) => `${value}%`,
          },
          {
            accessor: 'id',
            title: '',
            render: ({ id }) =>
              canEdit && (
                <ActionIcon
                  ml={'auto'}
                  size="sm"
                  onClick={() => goToEdit(id)}
                  aria-label="EditButton"
                  color="dark.9"
                >
                  <IconPencil stroke={1} />
                </ActionIcon>
              ),
          },
        ]}
        rowExpansion={{
          trigger: 'always',
          content: ({ record: { fees } }) => <Fees data={fees} />,
        }}
      />
    </ContentLayout>
  );
};
