import { Button, Center, Container, Grid, Text, Paper } from '@mantine/core';
import { ShipmentCycleInventoryDetails } from 'shared';

type Props = {
  inventoryDetails?: ShipmentCycleInventoryDetails;
  goBack: () => void;
};

export const Published = ({ goBack, inventoryDetails }: Props) => {
  const {
    fulfillment_shipment_quantity,
    pick_up_quantity,
    in_house_shipment_quantity,
    locations = [],
  } = inventoryDetails ?? {};
  return (
    <>
      <Paper p="lg" mt={-8} mb={'md'}>
        <Container fluid px={0}>
          <Grid gutter="xl">
            <Grid.Col span={6}>
              <Grid gutter="xl">
                <Grid.Col span={8}>
                  <Text weight={600} size={14}>
                    Shipment (Fulfillment), bottles
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text size={14}>
                    {(fulfillment_shipment_quantity ?? 0).toLocaleString()}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={6}>
              <Grid gutter="xl">
                <Grid.Col span={8}>
                  <Text weight={600} size={14}>
                    Pickup/Shipment (In house), bottles
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text size={14}>
                    {(
                      (pick_up_quantity ?? 0) +
                      (in_house_shipment_quantity ?? 0)
                    ).toLocaleString()}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Container>
      </Paper>

      <Container fluid px={0}>
        <Grid gutter="xl">
          {locations.map(
            ({
              location_id: id,
              location_name: name,
              total_quantity,
              items,
            }) => (
              <Grid.Col span={6} key={id}>
                <Paper p="lg">
                  <Grid gutter={'xl'}>
                    <Grid.Col>
                      <Grid gutter="xl" columns={18}>
                        <Grid.Col span={6}>
                          <Text size={14} weight={600}>
                            {name}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <Text size={14} align="right">
                            {total_quantity.toLocaleString()}
                          </Text>
                        </Grid.Col>
                      </Grid>
                    </Grid.Col>
                    <Grid.Col>
                      <Grid gutter="xl" columns={18}>
                        <Grid.Col span={6}>
                          <Text size={14} weight={600}>
                            Product Name
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <Text size={14} weight={600} align="right">
                            Committed Left
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <Text size={14} weight={600} align="right">
                            Committed Total
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <Text size={14} weight={600} align="right">
                            Inventory
                          </Text>
                        </Grid.Col>
                      </Grid>
                    </Grid.Col>

                    <Grid.Col>
                      {items.map(
                        ({
                          sku_id,
                          sku_product_name,
                          left_quantity,
                          committed_quantity,
                          inventory_quantity,
                        }) => (
                          <Grid gutter="xl" columns={18} key={sku_id}>
                            <Grid.Col span={6}>
                              <Text size={14}>{sku_product_name}</Text>
                            </Grid.Col>
                            <Grid.Col span={4}>
                              <Text size={14} align="right">
                                {left_quantity.toLocaleString()}
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={4}>
                              <Text size={14} align="right">
                                {committed_quantity.toLocaleString()}
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={4}>
                              <Text
                                size={14}
                                align="right"
                                color={
                                  inventory_quantity - committed_quantity < 60
                                    ? 'red'
                                    : undefined
                                }
                              >
                                {inventory_quantity.toLocaleString()}
                              </Text>
                            </Grid.Col>
                          </Grid>
                        ),
                      )}
                    </Grid.Col>
                  </Grid>
                </Paper>
              </Grid.Col>
            ),
          )}
        </Grid>
      </Container>

      <Center sx={{ width: '100%' }}>
        <Button mt={'lg'} variant="subtle" onClick={goBack}>
          Back to Cycle
        </Button>
      </Center>
    </>
  );
};
