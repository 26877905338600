import {
  InventoryTransferDto,
  InventoryTransferPayload,
  PaginatedRequest,
  PaginatedResponse,
} from 'shared';
import { PRODUCT_INVENTORY_TRANSFER_PATH } from '../../endpoints';
import request from '../../request';

export default class InventoryTransferService {
  static readonly create = (data: InventoryTransferPayload) => {
    return request<InventoryTransferDto>({
      url: `${PRODUCT_INVENTORY_TRANSFER_PATH}`,
      method: 'post',
      data,
    });
  };

  static readonly receive = ({
    id,
    data,
  }: {
    id?: string;
    data: InventoryTransferPayload;
  }) => {
    return request<InventoryTransferDto>({
      url: `${PRODUCT_INVENTORY_TRANSFER_PATH}/${id}/receive`,
      method: 'post',
      data,
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id: string;
    data: InventoryTransferPayload;
  }) => {
    return request<InventoryTransferDto>({
      url: `${PRODUCT_INVENTORY_TRANSFER_PATH}/${id}/update`,
      method: 'put',
      data,
    });
  };

  static readonly cancel = (id: string) => {
    return request({
      url: `${PRODUCT_INVENTORY_TRANSFER_PATH}/${id}/cancel`,
      method: 'post',
    });
  };

  static readonly getCollection = (params: PaginatedRequest) => {
    return request<PaginatedResponse<InventoryTransferDto>>({
      url: `${PRODUCT_INVENTORY_TRANSFER_PATH}`,
      params,
      method: 'get',
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<InventoryTransferDto>({
      url: `${PRODUCT_INVENTORY_TRANSFER_PATH}/${id}`,
      method: 'get',
    });
  };
}
