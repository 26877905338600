import { Button, Container } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { BACK_BTN, EditBillingAddress } from 'shared';
import { billingAddressSchema } from '../../forms/schemes';
import { EditBillingAddressForm } from '../../forms';

type Props = {
  initialValues: EditBillingAddress;
  isLoading: boolean;
  handleSubmit: (values: EditBillingAddress) => void;
  goBack?: () => void;
};

export const EditCard = ({
  initialValues,
  isLoading,
  handleSubmit,
  goBack,
}: Props) => {
  const form = useForm({
    initialValues: initialValues,
    validate: yupResolver(billingAddressSchema),
    validateInputOnBlur: true,
  });

  return (
    <Container size="sm">
      <EditBillingAddressForm
        form={form}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
      />

      <Container size="xs" my="xl">
        <Button
          fullWidth
          variant="white"
          mt={8}
          data-testid={BACK_BTN}
          onClick={goBack}
        >
          Back to Wallet
        </Button>
      </Container>
    </Container>
  );
};
