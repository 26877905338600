import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Box } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import {
  BackButton,
  ContentLayout,
  SearchInput,
  TitleWithBadge,
} from 'components';
import {
  PROPS_DATA_TABLE,
  ShipmentCycleTabValue,
  getTabEditPath,
} from 'shared';
import { useShipmentCycle } from 'pages/membersClub/shipmentCycle/hooks';
import { RouteName, generateRoutePath } from 'App/routes';

export const Details = ({ onExit }: { onExit: () => void }): JSX.Element => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const {
    isLoading,
    isError,
    output,
    page,
    totalRecords,
    setPage,
    filters,
    updateFilters,
    title,
    statusLabel,
  } = useShipmentCycle(id);

  const customerPath = generateRoutePath({
    name: RouteName.Customers,
  });

  if (isError) {
    onExit();
  }

  return (
    <ContentLayout
      title={
        <TitleWithBadge variant="filled" status={statusLabel} title={title} />
      }
      isLoading={isLoading}
      leftAction={<BackButton onClick={() => navigate(-1)} />}
    >
      <Grid mb="md">
        <Grid.Col span={5} offset={7}>
          <SearchInput
            handleSubmit={(v: string) => updateFilters({ search_string: v })}
            label="Search"
            value={filters.search_string}
          />
        </Grid.Col>
      </Grid>

      <DataTable
        {...PROPS_DATA_TABLE}
        records={output}
        page={page}
        onPageChange={setPage}
        totalRecords={totalRecords}
        columns={[
          {
            accessor: 'hasError',
            title: '',
            render: ({ has_errors }) => (
              <Box
                sx={(theme) => ({
                  backgroundColor: has_errors ? theme.colors.red[7] : undefined,
                  width: '2px',
                  height: '40px',
                  margin: '-8px 0',
                })}
              />
            ),
            width: '2%',
          },
          {
            accessor: 'member_name',
            title: 'Member Name',
          },
          { accessor: 'tier', title: 'Tier' },
          { accessor: 'choice', title: 'Choice' },
          { accessor: 'delivery_method', title: 'Delivery' },
          { accessor: 'unpaid', title: 'Unpaid' },
          { accessor: 'pending', title: 'Pending' },
          {
            accessor: 'id',
            title: '',
            render: ({ id }) => (
              <Button
                my={-12}
                variant="subtle"
                size="sm"
                onClick={() =>
                  navigate(
                    getTabEditPath(
                      customerPath,
                      id,
                      ShipmentCycleTabValue.orders,
                    ),
                  )
                }
              >
                Profile
              </Button>
            ),
          },
        ]}
      />
    </ContentLayout>
  );
};
