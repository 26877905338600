import { Box, Flex } from '@mantine/core';
import { normalizeModifiers } from 'pages/orders/posOrders/helpers';
import { Modifier } from 'shared';

export const Modifiers = ({
  modifiers,
  isRefunded = false,
}: {
  modifiers?: Modifier[];
  isRefunded?: boolean;
}) => {
  const normalize = normalizeModifiers({ modifiers });

  return (
    <Box sx={{ width: '100%' }} mt="-10px">
      {normalize?.map((i) => (
        <Flex key={i.id}>
          <Box p="0 8px" sx={{ width: '31.5%' }}>
            {i.name}
          </Box>
          <Box p="0 8px" sx={{ width: '14.2%' }}>
            {isRefunded ? `-${i.quantity}` : i.quantity}
          </Box>
          <Box p="0 8px" sx={{ width: '11%', textAlign: 'right' }}>
            {i.price}
          </Box>
        </Flex>
      ))}
    </Box>
  );
};
