import { Permissions } from 'shared';

export type Node = {
  value: string;
  label: string;
  children?: Node[];
};

export const nodes: Node[] = [
  {
    value: 'adminPanel',
    label: 'Admin Panel',
    children: [
      {
        value: 'locations',
        label: 'Locations',
        children: [
          {
            value: Permissions.admin_panel_locations_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_locations_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_locations_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_locations_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'roles',
        label: 'Roles',
        children: [
          {
            value: Permissions.admin_panel_roles_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_roles_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_roles_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_roles_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'local_taxes',
        label: 'Local Taxes',
        children: [
          {
            value: Permissions.admin_panel_local_taxes_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_local_taxes_edit,
            label: 'Edit',
          },
        ],
      },
      {
        value: 'customers',
        label: 'Customers',
        children: [
          {
            value: Permissions.admin_panel_customer_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_customer_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_customer_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_customer_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'skus',
        label: 'Product Skus',
        children: [
          {
            value: Permissions.admin_panel_sku_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_sku_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_sku_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_sku_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'vendors',
        label: 'Product Vendors',
        children: [
          {
            value: Permissions.admin_panel_vendor_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_vendor_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_vendor_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_vendor_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'categories',
        label: 'Product Categories',
        children: [
          {
            value: Permissions.admin_panel_categories_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_categories_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_categories_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_categories_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'inventory_list',
        label: 'Inventory List',
        children: [
          {
            value: Permissions.admin_panel_inventory_items_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_inventory_items_create,
            label: 'Create',
          },
        ],
      },
      {
        value: 'inventory_locations',
        label: 'Inventory Locations',
        children: [
          {
            value: Permissions.admin_panel_inventory_locations_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_inventory_locations_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_inventory_locations_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_inventory_locations_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'inventory_transfer',
        label: 'Inventory Transfers',
        children: [
          {
            value: Permissions.admin_panel_inventory_transfer_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_inventory_transfer_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_inventory_transfer_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_inventory_transfer_cancel,
            label: 'Cancel',
          },
          {
            value: Permissions.admin_panel_inventory_transfer_receive,
            label: 'Receive',
          },
        ],
      },
      {
        value: 'adjustment',
        label: 'Inventory Adjustments',
        children: [
          {
            value: Permissions.admin_panel_adjustments_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_adjustments_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_adjustments_cancel,
            label: 'Cancel',
          },
        ],
      },
      {
        value: 'library',
        label: 'Inventory Library',
        children: [
          {
            value: Permissions.admin_panel_library_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_library_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_library_release,
            label: 'Release',
          },
        ],
      },
      {
        value: 'culinary_skus',
        label: 'Culinary Skus',
        children: [
          {
            value: Permissions.admin_panel_culinary_sku_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_culinary_sku_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_culinary_sku_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_culinary_sku_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'culinary_menu',
        label: 'Culinary Menu',
        children: [
          {
            value: Permissions.admin_panel_culinary_menu_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_culinary_menu_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_culinary_menu_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_culinary_menu_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'club_tier',
        label: 'Club Tier',
        children: [
          {
            value: Permissions.admin_panel_club_tier_view,
            label: 'View',
          },
        ],
      },
      {
        value: 'shipment_cycle',
        label: 'Shipment Cycle',
        children: [
          {
            value: Permissions.admin_panel_shipment_cycle_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_shipment_cycle_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_shipment_cycle_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_shipment_cycle_delete,
            label: 'Delete',
          },
          {
            value: Permissions.admin_panel_shipment_cycle_detail_view,
            label: 'Detail View',
          },
        ],
      },
      {
        value: 'shipment_sales_order',
        label: 'Sales Order',
        children: [
          {
            value: Permissions.admin_panel_shipment_sales_order_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_shipment_sales_order_edit,
            label: 'Edit',
          },
        ],
      },
      {
        value: 'cycle_orders',
        label: 'Cycle Orders',
        children: [
          {
            value: Permissions.admin_panel_shipment_cycle_sales_order_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_shipment_cycle_sales_order_edit,
            label: 'Edit',
          },
        ],
      },

      {
        value: 'partners',
        label: 'Partners',
        children: [
          {
            value: Permissions.admin_panel_partner_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_partner_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_partner_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_partner_delete,
            label: 'Delete',
          },
        ],
      },
      {
        value: 'employees',
        label: 'Employees',
        children: [
          {
            value: Permissions.admin_panel_employees_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_employees_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_employees_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_employees_activate,
            label: 'Activate',
          },
          {
            value: Permissions.admin_panel_employees_disable,
            label: 'Disable',
          },
        ],
      },
      {
        value: 'discounts',
        label: 'Discounts',
        children: [
          {
            value: Permissions.admin_panel_discounts_view,
            label: 'View',
          },
          {
            value: Permissions.admin_panel_discounts_create,
            label: 'Create',
          },
          {
            value: Permissions.admin_panel_discounts_edit,
            label: 'Edit',
          },
          {
            value: Permissions.admin_panel_discounts_delete,
            label: 'Delete',
          },
        ],
      },
    ],
  },
  {
    value: 'posSystem',
    label: 'POS',
    children: [
      {
        value: Permissions.pos_access,
        label: 'Access',
      },
      {
        value: Permissions.pos_menu_view,
        label: 'Menu View',
      },
      {
        value: Permissions.pos_order_create,
        label: 'Create Order',
      },
      {
        value: Permissions.pos_order_edit,
        label: 'Edit Order',
      },
      {
        value: Permissions.pos_order_view,
        label: 'View Order',
      },
      {
        value: Permissions.pos_order_delete_ordered_items,
        label: 'Delete Ordered Items',
      },
      {
        value: Permissions.pos_device_settings_management,
        label: 'Device Settings Management',
      },
      {
        value: Permissions.pos_table_map_read,
        label: 'Read Table Map',
      },
      {
        value: Permissions.pos_table_map_create,
        label: 'Create Table Map',
      },
      {
        value: Permissions.pos_table_map_edit,
        label: 'Edit Table Map',
      },
      {
        value: Permissions.pos_table_map_delete,
        label: 'Delete Table Map',
      },
      {
        value: Permissions.pos_sales_order_view,
        label: 'View Sales Order',
      },
      {
        value: Permissions.pos_sales_order_create,
        label: 'Create Sales Order',
      },
      {
        value: Permissions.pos_sales_order_edit,
        label: 'Edit Sales Order',
      },
      {
        value: Permissions.pos_order_add_remove_helper_wine_advisor,
        label: 'Add or Remove Helper Wine Advisor',
      },
      {
        value: Permissions.pos_order_change_default_wine_advisor,
        label: 'Change default wine advisor',
      },
    ],
  },
];
