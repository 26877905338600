import { Alert, Box, Image } from '@mantine/core';
import HalterRanchImg from 'assets/images/hr-login.png';
import { Logo as HRLogo } from 'assets/icons';
import { SignInForm } from './components';
import { Logo, Links, AuthFormWrapper } from 'components';
import { RouteName, generateRoutePath } from 'App/routes';
import { useSignInForm } from './hooks';
import { getMessage } from 'shared';

export const SignIn = (): JSX.Element => {
  const { handleSubmit, error, isLoading } = useSignInForm();
  const toForgotPassword = generateRoutePath({
    name: RouteName.ForgotPassword,
  });
  return (
    <AuthFormWrapper columns={2}>
      <Box>
        <Image
          data-testid="logo-login-screen"
          src={HalterRanchImg}
          alt=""
          width="100%"
          height="100vh"
        />
      </Box>
      <Box pl={'9rem'} pr={'9rem'} pt={'7.5rem'}>
        <Logo>
          <HRLogo />
        </Logo>
        {error && (
          <Alert
            sx={{
              marginBottom: '1.25rem',
              fontSize: '16px',
              lineHeight: '25px',
            }}
            color="red"
          >
            {getMessage(error)}
          </Alert>
        )}
        <SignInForm isLoading={isLoading} handleSubmit={handleSubmit} />
        <Links
          data-testid="forgot-password-link"
          textLink="Forgot password?"
          route={toForgotPassword}
        />
      </Box>
    </AuthFormWrapper>
  );
};
