import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mantine/core';

import { ContentLayout, SearchInput } from 'components';
import {
  PROPS_DATA_TABLE,
  getFullName,
  salesOrderStatusLabels,
  getEditPath,
  getCurrencyString,
} from 'shared';
import { usePosOrders } from '../../hooks';

export const List = ({ rootPath }: { rootPath: string }) => {
  const {
    output,
    isLoading,
    page,
    search_string,
    totalRecords,
    handleSetSearchParams,
    updateFilters,
  } = usePosOrders();
  const navigate = useNavigate();
  return (
    <ContentLayout title="POS Orders" processing={isLoading}>
      <Grid mb="md" justify="space-between">
        <Grid.Col span={5} offset={7}>
          <SearchInput
            handleSubmit={(value: string) =>
              updateFilters({ search_string: value })
            }
            label="Search"
            value={search_string}
          />
        </Grid.Col>
      </Grid>
      <DataTable
        {...PROPS_DATA_TABLE}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        records={output}
        totalRecords={totalRecords}
        onRowClick={({ id }) => navigate(getEditPath(rootPath, id))}
        columns={[
          {
            accessor: 'number',
            title: 'Order #',
            width: '9%',
          },
          {
            accessor: 'created_at',
            title: 'Creation Date',
          },
          {
            accessor: 'fully_paid_at',
            title: 'Payment Date',
          },
          {
            accessor: 'customers',
            title: 'Customers',
            width: '35%',
          },
          {
            accessor: 'default_wine_advisor',
            title: 'Wine Advisor',
            render: ({ default_wine_advisor }) =>
              getFullName({
                first_name: default_wine_advisor?.first_name,
                last_name: default_wine_advisor?.last_name,
              }),
          },
          {
            accessor: 'total',
            title: 'Total',
            render: ({ total }) => getCurrencyString(total),
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => salesOrderStatusLabels[status],
          },
        ]}
      />
    </ContentLayout>
  );
};
