import { Text, UnstyledButton, Menu } from '@mantine/core';
import { useLocations } from 'App/contexts';

export function LocationSwitch() {
  const { locations, selectLocation, currentLocation } = useLocations();
  const label = locations.find(({ id }) => id === currentLocation)?.name ?? '';

  return (
    <Menu shadow="sm" width={200} position="bottom-start" zIndex={999}>
      <Menu.Target>
        <UnstyledButton data-testid="header-locations-dropdown">
          <Text>{label}</Text>
        </UnstyledButton>
      </Menu.Target>

      {locations.length > 1 && (
        <Menu.Dropdown>
          {locations
            .filter(({ id }) => id !== currentLocation)
            .map(({ id, name }) => (
              <Menu.Item
                data-testid={name.replace(/\s+/g, '-').toLowerCase()}
                key={id}
                onClick={() => selectLocation(id)}
              >
                {name}
              </Menu.Item>
            ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
}
