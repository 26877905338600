import { useState, useCallback } from 'react';
import { isEmpty, pipe, filter, includes, without, uniq, find } from 'rambda';

import { Permissions, RoleValues } from 'shared';
import { useToast } from 'hooks';

interface Params {
  handleSubmit: (data: RoleValues) => void;
  roleName: string;
  roleDescription: string;
  initialChecked: string[];
}

const hasAdminPanelAccess = find(includes('admin_panel'));
const hasAdminAccess = includes(Permissions.admin);

const getPermissions = (permissions: string[]) =>
  pipe(
    filter(includes('_')),
    (res) =>
      hasAdminPanelAccess(res)
        ? [...res, Permissions.admin_panel_access]
        : without([Permissions.admin_panel_access], res),
    uniq,
  )(permissions) as Permissions[];

export const useRoleEdit = ({
  handleSubmit,
  roleName,
  roleDescription,
  initialChecked,
}: Params) => {
  const [checked, setChecked] = useState<string[]>(initialChecked);
  const [name, setName] = useState<string>(roleName);
  const [description, setDescription] = useState<string>(roleDescription);

  const addToast = useToast();

  const isSuperAdmin = hasAdminAccess(checked);

  const onSubmit = useCallback(() => {
    if (!name || isEmpty(checked)) {
      return addToast.error(
        `${!name ? 'Role Name' : 'At least one selected Action'} is required!`,
      );
    }

    handleSubmit({
      permissions: isSuperAdmin ? [Permissions.admin] : getPermissions(checked),
      name,
      description,
    });
  }, [name, checked, isSuperAdmin, handleSubmit, description, addToast]);

  const handleSuperAdmin = useCallback(() => {
    setChecked((prev) =>
      isSuperAdmin
        ? without([Permissions.admin], prev)
        : [Permissions.admin, ...prev],
    );
  }, [isSuperAdmin]);

  return {
    checked,
    setChecked,
    name,
    setName,
    description,
    setDescription,
    onSubmit,
    isSuperAdmin,
    handleSuperAdmin,
  };
};
