import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { filter, includes } from 'rambda';

import { OrdersService, PaymentsService, PosSalesOrdersService } from 'App/api';
import { useApiMutation, useApiQuery, useToast } from 'hooks';
import {
  PosSalesOrderPayload,
  RefundItemRequest,
  RefundType,
  SUCCESS_MESSAGE_GENERATION_RECEIPT,
  SendReceiptPayload,
  normalizePayment,
} from 'shared';
import { getRefundPosOrderType } from '../helpers';

type Props = {
  id: string;
  onExit: () => void;
};

const ORDER_QUERY_KEY = 'posOrder';
const ORDER_PAYMENT_QUERY_KEY = 'posOrderPayment';
const SEND_RECEIPT = 'sendReceipt';
const REFUND = 'refund';
const REGENERATE_RECEIPT = 'generate_receipt';

export const useEditPosOrder = ({ id, onExit }: Props) => {
  const addToast = useToast();
  const [generationPDFOrderId, setGenerationPDFOrderId] = useState<
    string | null
  >(null);

  const [generationPDFPaymentIds, setGenerationPDFPaymentIds] = useState<
    string[]
  >([]);

  const {
    data: order,
    isLoading,
    isFetching,
  } = useApiQuery(
    [ORDER_QUERY_KEY, id],
    () => PosSalesOrdersService.getOne(id, true),
    {
      onSuccess: ({ s3_receipt_path }) => {
        if (s3_receipt_path) {
          setGenerationPDFOrderId(null);
        }
      },
      refetchInterval: !!generationPDFOrderId && 10000,
    },
  );

  const {
    data: payment,
    isLoading: fetchingPayment,
    refetch: refetchPayment,
  } = useApiQuery(
    [ORDER_PAYMENT_QUERY_KEY, id],
    () => PaymentsService.getCollection(id),

    {
      onSuccess: ({ items }) => {
        const itemsWithGeneration = filter(
          ({ id }) => includes(id, generationPDFPaymentIds),
          items,
        );

        itemsWithGeneration.forEach(({ s3_receipt_path, id }) => {
          if (s3_receipt_path) {
            setGenerationPDFPaymentIds((prev) =>
              prev.filter((generateId) => generateId !== id),
            );
          }
        });
      },
      refetchInterval: !!generationPDFPaymentIds.length && 10000,
    },
  );

  const client = useQueryClient();
  const refetch = () => {
    client.invalidateQueries([ORDER_QUERY_KEY, id]);
  };

  const { mutateAsync: regeneratePartialReceipt } = useApiMutation(
    [REGENERATE_RECEIPT, id],
    PaymentsService.regenerateReceipt,
    {
      onSuccess: () => addToast.success(SUCCESS_MESSAGE_GENERATION_RECEIPT),
    },
  );

  const { mutateAsync: regenerateFullReceipt } = useApiMutation(
    [REGENERATE_RECEIPT, id],
    OrdersService.regenerateReceipt,
    {
      onSuccess: () => addToast.success(SUCCESS_MESSAGE_GENERATION_RECEIPT),
    },
  );

  const { mutate: sendReceipt, isLoading: isSendingReceipt } = useApiMutation(
    [SEND_RECEIPT, id],
    OrdersService.sendReceipt,
    {
      onSuccess: () => addToast.success('The receipt has been sent!'),
    },
  );

  const { mutate: refund, isLoading: isRefunding } = useApiMutation(
    [REFUND, id],
    OrdersService.refund,
    {
      onSuccess: () => {
        refetch();
        refetchPayment();

        addToast.success('Transaction has been refunded!');
      },
    },
  );

  const { mutate: updateOrder, isLoading: isUpdating } = useApiMutation(
    ['updateOrder', id],
    PosSalesOrdersService.update,
    {
      onSuccess: () => {
        onExit();
        refetch();
      },
    },
  );

  const handleSubmit = (data: PosSalesOrderPayload) => {
    updateOrder({ id, data });
  };

  const handleSendReceipt = (data: SendReceiptPayload) => {
    sendReceipt({ id, data });
  };

  const handleRefund = ({
    type,
    paymentData,
  }: {
    type: RefundType;
    paymentData?: {
      payment_id: string;
      items: RefundItemRequest[];
    };
  }) =>
    refund({
      id,
      data: {
        refund_type: getRefundPosOrderType({
          orderType: order?.pos_payment_type,
          refundType: type,
        }),
        ...(paymentData ? { payment: paymentData } : {}),
      },
    });

  const handleGeneratePartialReceipt = async (id: string) => {
    try {
      setGenerationPDFPaymentIds((prev) => [...prev, id]);
      await regeneratePartialReceipt({ id });
    } catch {
      setGenerationPDFPaymentIds((prev) => prev.filter((i) => i !== id));
    }
  };

  const handleGenerateFullReceipt = async (id: string) => {
    try {
      setGenerationPDFOrderId(id);
      await regenerateFullReceipt({ id });
    } catch {
      setGenerationPDFOrderId(null);
    }
  };

  return {
    order,
    isLoading: isLoading || fetchingPayment || isFetching,
    processing: isUpdating,
    payment: normalizePayment(payment?.items),
    generationPDFPaymentIds,
    isRefunding: isRefunding || isLoading || fetchingPayment,
    isSendingReceipt,
    isGeneratingFullReceipt: !!generationPDFOrderId,
    handleSubmit,
    handleRefund,
    handleSendReceipt,
    handleGeneratePartialReceipt,
    handleGenerateFullReceipt,
  };
};
