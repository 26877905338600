import { Loader, Center } from '@mantine/core';

import { useSession } from '../session';
import { UnauthenticatedRoutes } from './UnauthenticatedRoutes';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';

export const AppRoutes = (): JSX.Element => {
  const { authenticated, initializing } = useSession();

  if (initializing) {
    return (
      <Center sx={{ width: '100vw', height: '100vh' }}>
        <Loader data-testid="loading-bars" size="lg" variant="bars" />
      </Center>
    );
  }

  const RouteComponent = authenticated
    ? AuthenticatedRoutes
    : UnauthenticatedRoutes;

  return <RouteComponent />;
};
