import { CreatePayload, PaginatedRequest } from '../common';

export enum InventoryTypeSource {
  SHIPCOMPLAINT = 'shipcompliant',
  MANUAL = 'manual',
}

export interface InventoryTypeStateTaxListItemDto {
  id: string;
  name: string;
  source: InventoryTypeSource;
  state: string;
  default: number;
  override: number;
  food: number;
  shipping: number;
}

export interface StateTaxDto {
  state: string;
  default: number;
  override: number;
  food: number;
  shipping: number;
}

export interface InventoryTypeStateTaxesDto {
  id: string;
  name: string;
  state_taxes: StateTaxDto[];
}

export type StateTaxesCreatePayload = CreatePayload<InventoryTypeStateTaxesDto>;

export interface InventoryTypeStateTaxesRequest extends PaginatedRequest {
  search_string?: string;
  states?: string[];
}
