import { useMemo } from 'react';
import { InventoryService } from 'App/api';
import { useApiQuery } from 'hooks';
import { getSelectOptions, LocationTypes, SelectOption } from 'shared';
import { usePartnerLocations } from 'pages/inventory/hooks';

const KEY = 'inventory-location';

type TPayload = {
  inventorySlug?: string | null;
  currentInventoryId?: string | null;
  isLibrary?: boolean;
};

type SkuOptions = {
  id: string;
  name: string;
  quantities: {
    library: number;
    default: number;
  };
};

type ReturnValue = {
  skusOptions: SkuOptions[];
  actualLocationOptions: SelectOption[];
  inventoryOptions: SelectOption[];
  isLoadingSkus: boolean;
  isLoadingInventory: boolean;
};

export const useInitialForm = (payload: TPayload): ReturnValue => {
  const { locations } = usePartnerLocations();
  const { inventorySlug, currentInventoryId, isLibrary } = payload;

  const { data: inventory, isLoading: isLoadingInventory } = useApiQuery(
    [KEY, inventorySlug],
    () =>
      InventoryService.getLocations({
        location_id: inventorySlug ?? undefined,
        ...(isLibrary ? { show_in_pos: false } : null),
      }),
    {
      enabled: !!inventorySlug,
    },
  );

  const { data: skus, isLoading: isLoadingSkus } = useApiQuery(
    ['inventory-skus', currentInventoryId],
    () => InventoryService.getSkus({ id: currentInventoryId ?? '' }),
    {
      enabled: !!currentInventoryId,
    },
  );

  const inventoryOptions = useMemo(() => {
    if (!inventory?.items) return [];

    return getSelectOptions(inventory.items);
  }, [inventory]);

  const skusOptions = useMemo(() => {
    if (!skus?.items) return [];

    return skus.items.map(({ id, product_name, sku_number, quantities }) => ({
      id,
      name: `[${sku_number}] ${product_name}`,
      quantities,
    }));
  }, [skus]);

  const internalOrAll = useMemo(
    () =>
      locations.filter((l) => {
        if (isLibrary) return l.type === LocationTypes.INTERNAL;
        return l;
      }),
    [isLibrary, locations],
  );

  const actualLocationOptions = useMemo(
    () => getSelectOptions(internalOrAll),
    [internalOrAll],
  );

  return {
    skusOptions,
    actualLocationOptions,
    inventoryOptions,
    isLoadingSkus: isLoadingSkus && !!currentInventoryId,
    isLoadingInventory: isLoadingInventory && !!inventorySlug,
  };
};
