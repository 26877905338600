import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { NestedRoutes, Permissions } from 'shared';
import { List, Create, Edit, View } from './components';

export const Employees = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.Employees,
  });
  const canCreate = checkAccess(Permissions.admin_panel_employees_create);
  const canEdit = checkAccess(Permissions.admin_panel_employees_edit);
  const canDisable = checkAccess(Permissions.admin_panel_employees_disable);
  const canActivate = checkAccess(Permissions.admin_panel_employees_activate);

  const ListComponent = <List rootPath={rootPath} canCreate={canCreate} />;

  const onExit = useGotoRoute(RouteName.Employees);

  return (
    <>
      <Routes>
        <Route index element={ListComponent} />
        <Route path={NestedRoutes.List} element={ListComponent} />
        <Route
          path={NestedRoutes.View}
          element={
            <View rootPath={rootPath} canEdit={canEdit} onExit={onExit} />
          }
        />
        {canCreate && (
          <Route
            path={NestedRoutes.Create}
            element={<Create rootPath={rootPath} onExit={onExit} />}
          />
        )}
        {canEdit && (
          <Route
            path={NestedRoutes.Edit}
            element={
              <Edit
                onExit={onExit}
                canActivate={canActivate}
                canDisable={canDisable}
              />
            }
          />
        )}
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: rootPath,
              }}
            />
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};
