import { Link } from 'react-router-dom';
import { Container } from '@mantine/core';

import { BackButton, ContentLayout } from 'components';
import { useCreateVendors } from '../../hooks';
import { Form } from '../form';

export const initialValues = {
  company_name: '',
  website: '',
  street_address: '',
  city: '',
  state: '',
  zip_code: '',
  phone_number: '',
  primary_contact_name: '',
  primary_contact_phone_number: '',
  primary_contact_email: '',
  comment: '',
  is_location_specific: false,
  vendor_locations: [],
};

export const Create = ({
  onCreate,
  rootPath,
}: {
  onCreate: (id: string) => void;
  rootPath: string;
}): JSX.Element => {
  const { handleSubmit, isLoading } = useCreateVendors({ onSuccess: onCreate });

  return (
    <ContentLayout
      title="New Vendor"
      leftAction={<BackButton component={Link} to={rootPath} />}
    >
      <Container size={'sm'}>
        <Form
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          processing={isLoading}
        />
      </Container>
    </ContentLayout>
  );
};
