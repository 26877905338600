import { CategoryOption } from 'shared';

export const getCategoryOptions = (data?: CategoryOption[] | null) => {
  if (!data?.length) {
    return null;
  }
  return data
    .map(({ id, value }) => ({ id, value }))
    .filter(({ value }) => !!value);
};
