import { omit } from 'rambda';

import { useApiMutation, useApiQuery, useFileUpload } from 'hooks';
import { useVendors } from 'pages/products/hooks';
import {
  CreateSku,
  getInventoryType,
  getSkuType,
  getSpecificInformation,
  normalizeSkuDetails,
  normalizeInitialValue,
  SkuFormImages,
} from 'shared';
import { ProductSkuService } from 'App/api';
import { getCategoryOptions } from './helpers';

type Props = {
  id: string;
  onDelete?: () => void;
  onCreate?: (id: string) => void;
};

const getProductSkusKey = 'getProductSkus';

export const useEditSkus = ({ id, onDelete, onCreate = () => null }: Props) => {
  const {
    mutateAsync,
    isLoading: processingUpload,
    handleFileUpload,
  } = useFileUpload();
  const {
    data: sku,
    isLoading,
    isError,
  } = useApiQuery(
    [getProductSkusKey, id],
    () => ProductSkuService.getOne({ id }),
    {
      cacheTime: 0,
    },
  );

  const { mutate: deleteSku, isLoading: isDeleting } = useApiMutation(
    [getProductSkusKey, id],
    () => ProductSkuService.delete({ id }),
    {
      onSuccess: onDelete,
    },
  );

  const { mutate: updateSku, isLoading: isUpdating } = useApiMutation(
    [getProductSkusKey, id],
    ProductSkuService.update,
    {
      onSuccess: (data) => onCreate(data.id),
    },
  );

  const { vendors } = useVendors();

  const getImageUrl = async (
    url: string | null | undefined,
    image: File | null | undefined,
  ): Promise<Partial<string | null>> => {
    if (!image && !url) {
      return null;
    }

    return await handleFileUpload(url ?? '', image ?? null);
  };

  const handleSubmit = async (
    values: CreateSku,
    file: File | null,
    images?: SkuFormImages,
  ) => {
    const editValue = omit(['is_kit'], values);
    const type = values?.inventory_type;
    const isKit = values?.is_kit ?? false;
    const isNonInventory = editValue?.is_non_inventory ?? false;
    const isBtg = (values.specific_information?.is_available_by_glass ??
      values.specific_information?.is_available_only_by_glass) as boolean;

    const web_image_url = await getImageUrl(
      values.web_image_url,
      images?.webImage,
    );
    const web_hover_image_url = await getImageUrl(
      values.web_hover_image_url,
      images?.webHoverImage,
    );
    const mobile_image_url = await getImageUrl(
      values.mobile_image_url,
      images?.mobileImage,
    );
    const payload = {
      id,
      data: {
        ...getInventoryType(editValue.inventory_type, isBtg, isKit, editValue),
        sku_type: getSkuType(editValue.inventory_type, isKit),
        price: editValue.price ?? null,
        specific_information: getSpecificInformation({
          type,
          isKit,
          isNonInventory,
          value: editValue.specific_information,
        }),
        category_options: getCategoryOptions(values.category_options),
        web_image_url,
        web_hover_image_url,
        mobile_image_url,
      },
    };

    if (!file) return updateSku(payload);

    await mutateAsync(file, {
      onSuccess: ({ url }) => {
        updateSku({
          ...payload,
          data: { ...payload.data, product_file: url || null },
        });
      },
    });
  };

  const getVendorNameById = (id: string) =>
    vendors.find((vendor) => vendor.id === id)?.company_name;

  const initialValue = sku && normalizeInitialValue(sku);

  return {
    sku: sku && normalizeSkuDetails(sku),
    initialValue,
    isLoading,
    isError,
    deleteSku,
    isDeleting,
    handleSubmit,
    isUpdating,
    processingUpload,
    getVendorNameById,
    vendorName: sku?.vendor?.company_name,
  };
};
