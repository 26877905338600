import { IconPointFilled, IconAlertCircle } from '@tabler/icons-react';

export const FREIGHT_COMPANY = [
  { label: 'FedEx', value: 'fedex' },
  { label: 'UPS', value: 'ups' },
];
export const SHIPMENT_COST = [
  { label: 'Free', value: 'free' },
  { label: 'Fixed Value', value: 'fixedvalue' },
];
export const FREIGHT_METHOD = [{ label: 'Ground', value: 'ground' }];

export const SUCCESS_MESSAGE_GENERATION_RECEIPT =
  'The receipt generation request was successful! It may take some time for the receipt to become available';

export const orderHintOptions = [
  {
    title: 'Order error',
    icon: <IconPointFilled size="20px" color="#FF6B6B" />,
  },
  {
    title: 'Order passed compliance check',
    icon: <IconPointFilled size="20px" color="#3EA8D5" />,
  },
  {
    title: 'Freight sales tax rate is 0',
    icon: <IconAlertCircle size="20px" color="#909296" />,
  },
];
