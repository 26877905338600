import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tabs, Text } from '@mantine/core';
import { ContentLayout, BackButton, TitleWithBadge } from 'components';
import { useEditShipmentCycle } from '../../hooks';
import { useClubTier } from 'hooks';
import { CycleTabs } from './cycleTabs';
import { InventoryCheckDetails } from '../inventoryCheck';
import { CycleStatus } from '../../constants';

type PropType = {
  onExit: () => void;
  canDelete: boolean;
};

export const Edit = ({ onExit, canDelete }: PropType): JSX.Element => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string | null>('settings');
  const { id = '' } = useParams<{ id: string }>();
  const {
    data,
    initialValue,
    handleSubmit,
    isLoading,
    isUpdatingCycle,
    isInventoryCheck,
    setIsInventoryCheck,
    isStartAfterSave,
    setIsStartAfterSave,
    inventoryDetails,
    isLoadingInventoryCheck,
    deleteCycle,
    canBeDeleted,
    deleting,
  } = useEditShipmentCycle({ id, onExit });

  const tierId = initialValue?.club_tier_id;
  const { options: tierOptions } = useClubTier({});

  const { tiers, isLoading: isLoadingAdditional } = useClubTier({
    tierId,
    enabled: !!tierId,
  });

  const { name, status } = data ?? {};
  const inventoryCheckName = `Inventory Check - ${name}`;
  const cycleStatus = status && CycleStatus[status];

  const goBack = () => setIsInventoryCheck(false);

  return isInventoryCheck ? (
    <InventoryCheckDetails
      title={inventoryCheckName}
      inventoryDetails={inventoryDetails}
      isLoading={isLoadingInventoryCheck}
      goBack={goBack}
    />
  ) : (
    <ContentLayout
      title={
        <TitleWithBadge
          variant="filled"
          title={name}
          status={cycleStatus}
          color="halterBlue.4"
        />
      }
      processing={isLoading || deleting}
      isLoading={isLoading || isLoadingAdditional}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
    >
      <Tabs keepMounted={false} value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List position="center">
          <Tabs.Tab px={12} value="settings">
            <Text size="md">Cycle Setting</Text>
          </Tabs.Tab>
          <Tabs.Tab px={12} value="wineSelection">
            <Text size="md">Wine Selection</Text>
          </Tabs.Tab>
          <Tabs.Tab px={12} value="suggestions">
            <Text size="md">Suggestions</Text>
          </Tabs.Tab>
          <Tabs.Tab px={12} value="shippingDetails">
            <Text size="md">Shipping Details</Text>
          </Tabs.Tab>
        </Tabs.List>
        <CycleTabs
          addonTiers={tiers}
          tierOptions={tierOptions}
          processing={isUpdatingCycle || deleting}
          initialValues={initialValue}
          handleSubmit={handleSubmit}
          deleteCycle={deleteCycle}
          canBeDeleted={canBeDeleted && canDelete}
          setIsStartAfterSave={setIsStartAfterSave}
          isStartAfterSave={isStartAfterSave}
          setIsInventoryCheck={setIsInventoryCheck}
        />
      </Tabs>
    </ContentLayout>
  );
};
