import { useEffect, useState } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import {
  Box,
  Button,
  Container,
  Loader,
  Select,
  Grid,
  Textarea,
  Space,
} from '@mantine/core';
import {
  AdjustmentPayload,
  AdjustmentType,
  adjustmentTypeOptions,
  INVENTORY_LOCATION_DROPDOWN,
  LOADER,
  LOCATION_DROPDOWN,
  LocationTypes,
} from 'shared';
import { ActionButtons } from 'components';
import { useInventorySkus, usePartnerLocations } from 'pages/inventory/hooks';
import { getNewSkuItem } from 'pages/inventory/helpers';
import { AddProduct } from './AddProduct';
import { adjustmentSchema } from './schema';

export type Props = {
  handleSubmit: (values: AdjustmentPayload) => void;
  initialValues: AdjustmentPayload;
};

export const Form = ({ handleSubmit, initialValues }: Props): JSX.Element => {
  const [locationId, setLocationId] = useState('');

  const { locations, options } = usePartnerLocations();
  const form = useForm({
    initialValues,
    validate: yupResolver(adjustmentSchema),
    validateInputOnBlur: true,
  });

  const selectedLocation = locations.find(
    (location) => location.id === locationId,
  );

  const inventoryLocationId = form.values.inventory_location_id;
  const isSubtraction =
    form.values.adjustment_type !== AdjustmentType.BALANCING;

  const { skusData, isLoading, fetchingSkus, inventoryLocationOptions } =
    useInventorySkus({
      locationId,
      inventoryLocationId,
    });

  const fullWidth = { width: '100%' };

  useEffect(() => {
    if (
      selectedLocation?.type !== LocationTypes.INTERNAL &&
      inventoryLocationOptions.length
    )
      form.setFieldValue(
        'inventory_location_id',
        inventoryLocationOptions[0].value,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryLocationOptions, selectedLocation]);

  return (
    <Container size="sm">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid gutter={'xl'}>
          <Grid.Col span={6}>
            <Select
              required
              data-testid={LOCATION_DROPDOWN}
              label="Primary Location"
              sx={fullWidth}
              rightSectionWidth={30}
              onChange={(value) => {
                setLocationId(value ?? '');
                form.setFieldValue('inventory_location_id', '');
                form.setFieldValue('items', [getNewSkuItem()]);
              }}
              data={options}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              required
              label="Inventory Location"
              data-testid={INVENTORY_LOCATION_DROPDOWN}
              sx={fullWidth}
              rightSectionWidth={30}
              icon={
                isLoading && locationId ? (
                  <Loader data-testid={LOADER} size={20} />
                ) : null
              }
              {...form.getInputProps('inventory_location_id')}
              onChange={(value) => {
                form.setFieldValue('inventory_location_id', value ?? '');
                form.setFieldValue('items', [getNewSkuItem()]);
              }}
              data={inventoryLocationOptions}
              disabled={!locationId}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              required
              label="Adjustment Type"
              data-testid="adjustment-type-dropdown"
              sx={fullWidth}
              rightSectionWidth={30}
              {...form.getInputProps('adjustment_type')}
              data={adjustmentTypeOptions}
              onChange={(value: unknown) => {
                form.setFieldValue('adjustment_type', value as AdjustmentType);
                form.setFieldValue('items', [getNewSkuItem()]);
              }}
            />
          </Grid.Col>
        </Grid>

        <AddProduct
          form={form}
          skusData={skusData}
          isLoadingSkus={fetchingSkus && !!inventoryLocationId}
          isSubtraction={isSubtraction}
        />

        <Button
          variant="white"
          data-testid="add-new-product-btn"
          p="0"
          onClick={() => form.insertListItem('items', getNewSkuItem())}
        >
          + Add New Product
        </Button>
        <Space h={'lg'} />
        <Box>
          <Textarea
            data-testid="comment-input"
            label="Comment"
            {...form.getInputProps('comment')}
          />
        </Box>
        <Box my="xl">
          <ActionButtons
            disabled={!form.isValid()}
            label="Save"
            aria-label="save"
          />
        </Box>
      </form>
    </Container>
  );
};
