import {
  Button,
  Container,
  Flex,
  Grid,
  Modal,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { openModal } from '@mantine/modals';
import { UseFormReturnType } from '@mantine/form';

import { AddressDto, CANCEL_BTN, SAVE_BTN } from 'shared';
import { AddressFields } from './AddressFields';
import { ConfirmData, MaskedInput } from 'components';
import { getChangeInformationMessage } from '../../helpers';
import { SHIPPING_ADDRESS } from '../../constants';

export type Props = {
  form: UseFormReturnType<AddressDto>;
  isUnpaid?: boolean;
  isEdit?: boolean;
  handleSubmit?: (values: AddressDto, update_orders?: boolean) => void;
  processing?: boolean;
  opened: boolean;
  setIsNotVerify: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
};

export const AddressForm = ({
  form,
  isEdit = false,
  handleSubmit,
  isUnpaid = false,
  processing = false,
  opened,
  setIsNotVerify,
  close,
}: Props): JSX.Element => {
  const fullWidth = { width: '100%' };
  const { is_default } = form.values;

  return (
    <Container size="sm">
      <form>
        <Grid gutter="xl">
          <Grid.Col>
            <TextInput
              sx={fullWidth}
              label="Address Name"
              data-testid="address-name-input"
              {...form.getInputProps('address_name')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              sx={fullWidth}
              data-testid="firstname-input"
              label="First Name"
              {...form.getInputProps('first_name')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              sx={fullWidth}
              data-testid="lastname-input"
              label="Last Name"
              {...form.getInputProps('last_name')}
            />
          </Grid.Col>
          <Grid.Col>
            <MaskedInput
              required
              mask="99.99.9999"
              label="Date of Birth"
              placeholder="08.31.1989"
              maskChar={null}
              {...form.getInputProps('date_of_birth')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              required
              sx={fullWidth}
              placeholder="your@email.com"
              label="Email Address"
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MaskedInput
              required
              sx={fullWidth}
              testId="phoneNumber"
              label="Phone Number"
              mask="(999) 999-9999"
              {...form.getInputProps('phone_number')}
            />
          </Grid.Col>

          <AddressFields<AddressDto> form={form} />

          {!isEdit && (
            <Grid.Col span={4}>
              <Switch
                label="Set as Default"
                data-testid="set-default-switch"
                labelPosition="right"
                {...form.getInputProps('is_default', { type: 'checkbox' })}
              />
            </Grid.Col>
          )}
        </Grid>

        <Modal
          opened={opened}
          onClose={close}
          title={
            <Text weight={600}>
              There`s a problem with the address provided!
            </Text>
          }
        >
          <Text>Do you want to save it?</Text>
          <Flex mt={8} gap={8} justify="flex-end">
            <Button
              onClick={() => {
                setIsNotVerify(true);
                close();
              }}
              data-testid={SAVE_BTN}
            >
              Save
            </Button>
            <Button data-testid={CANCEL_BTN} onClick={close}>
              Cancel
            </Button>
          </Flex>
        </Modal>
      </form>
      {handleSubmit && (
        <Container size="xs" mb="xl" mt={48}>
          <Button
            fullWidth
            type="submit"
            aria-label="save"
            loading={processing}
            disabled={!form.isValid()}
            onClick={() => {
              if ((isEdit && isUnpaid) || (is_default && isUnpaid)) {
                return openModal({
                  size: 'lg',
                  title: (
                    <Text size="md" weight={600}>
                      Update Upcoming Order?
                    </Text>
                  ),
                  children: (
                    <ConfirmData
                      message={getChangeInformationMessage(SHIPPING_ADDRESS)}
                      approve={() => handleSubmit(form.values, true)}
                      reject={() => handleSubmit(form.values)}
                    />
                  ),
                });
              }
              handleSubmit(form.values);
            }}
          >
            Save
          </Button>
        </Container>
      )}
    </Container>
  );
};
