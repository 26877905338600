import { Skeleton } from '@mantine/core';

export const CardSkeleton = () => {
  return (
    <>
      <Skeleton height={142} p="lg" mb="xl" />
      <Skeleton height={142} p="lg" mb="xl" />
      <Skeleton height={142} p="lg" mb="xl" />
    </>
  );
};
