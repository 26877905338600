import { useParams, useNavigate, Link } from 'react-router-dom';
import { Text } from '@mantine/core';
import { useEmployee } from 'hooks';
import { ContentLayout, BackButton, ConfirmModal } from 'components';

import { Form } from '../form';
import { useEditEmployee } from '../../hooks';
import { EmployeeStatus } from 'shared';

export const Edit = ({
  onExit,
  canActivate,
  canDisable,
}: {
  canDisable?: boolean;
  canActivate: boolean;
  onExit: () => void;
}): JSX.Element => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError, output } = useEmployee(id);
  const {
    handleSubmit,
    formData,
    isLoading: processing,
    disableEmployee,
  } = useEditEmployee({
    data,
    onSuccess: () => navigate(-1),
  });

  if (isError) {
    onExit();
  }

  const canBeActivated = canActivate && data?.status !== EmployeeStatus.ACTIVE;
  const canBeDeactivated = canDisable && data?.status === EmployeeStatus.ACTIVE;

  return (
    <ContentLayout
      title={`Edit ${output?.name ?? 'Employee'}`}
      isLoading={isLoading}
      processing={processing}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
    >
      {formData && (
        <Form
          handleSubmit={handleSubmit}
          canBeActivated={canBeActivated}
          initialValues={formData}
        >
          {canBeDeactivated && (
            <ConfirmModal
              type="button"
              variant="outline"
              fullWidth
              loading={processing}
              onConfirm={disableEmployee}
              label="Deactivate Employee"
            >
              <Text>Are you sure you want to deactivate the employee?</Text>
            </ConfirmModal>
          )}
        </Form>
      )}
    </ContentLayout>
  );
};
