import { useCallback } from 'react';
import {
  RefundItemRequest,
  RefundedItem,
  getSummaryRefundedItem,
} from 'shared';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { useApiMutation } from 'hooks/useApiMutation';
import { SalesOrderItemsService } from 'App/api';

const normalizeRefundItems = (productItems?: RefundedItem[]) => {
  if (!productItems) return [];

  return productItems?.map(
    ({
      product_name,
      price,
      sub_total,
      item_id,
      discount,
      quantity,
      chosen_quantity,
      refunded_quantity,
      membership_discount,
    }) => ({
      product_name,
      price,
      sub_total,
      item_id,
      discount,
      membership_discount,
      quantity,
      chosen_quantity,
      refunded_quantity,
      isSelected: !!chosen_quantity,
    }),
  );
};

export const useCalculateRefundItems = () => {
  const {
    isLoading: isLoadingRefundItemCost,
    data,
    mutate,
  } = useApiMutation(
    ['getRefundedItemCost'],
    SalesOrderItemsService.getRefundedItemCost,
  );

  const getCalculate = useCallback(
    (arg: { paymentId: string; items: RefundItemRequest[] }) => mutate(arg),
    [mutate],
  );

  const debounce = useDebouncedCallback<{
    paymentId: string;
    items: RefundItemRequest[];
  }>({
    func: getCalculate,
    wait: 300,
  });

  const summary = getSummaryRefundedItem(data);

  const handleCalculateRefund = ({
    paymentId,
    item,
  }: {
    paymentId: string;
    item: { item_id?: string; item_quantity: number };
  }) => {
    const refundItems =
      data?.items.map(({ item_id, chosen_quantity }) => {
        if (item_id === item?.item_id) {
          return { ...item };
        }
        return { item_id, item_quantity: chosen_quantity };
      }) ?? [];

    debounce({
      paymentId,
      items: refundItems,
    });
  };

  return {
    refundItems: normalizeRefundItems(data?.items),
    summary,
    isLoadingRefundItemCost,
    handleCalculateRefund,
    getCalculate,
  };
};
