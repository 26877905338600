import { useMemo } from 'react';
import { CulinaryMenuService, InventoryService } from 'App/api';
import { useApiQuery } from 'hooks';
import { getSelectOptions } from 'shared';
import { usePartnerLocations } from 'pages/inventory/hooks';

type TPayload = {
  inventorySlug?: string | null;
  inventoryLocationId?: string;
};

export const useFormData = (payload: TPayload) => {
  const { locations } = usePartnerLocations();
  const { inventorySlug, inventoryLocationId } = payload;

  const { data: inventory, isLoading: isLoadingInventory } = useApiQuery(
    ['inventory-location', inventorySlug],
    () =>
      InventoryService.getLocations({
        location_id: inventorySlug ?? undefined,
        show_in_pos: true,
      }),
    {
      enabled: !!inventorySlug,
    },
  );

  const { data } = useApiQuery(
    ['culinary-menu'],
    () =>
      CulinaryMenuService.getCollection({
        inventory_location_id: inventoryLocationId,
        status: 'active',
      }),
    {
      enabled: !!inventoryLocationId,
    },
  );

  const inventoryOptions = useMemo(() => {
    if (!inventory?.items) return [];

    return getSelectOptions(inventory.items);
  }, [inventory]);

  const actualLocationOptions = useMemo(
    () => getSelectOptions(locations),
    [locations],
  );

  return {
    actualLocationOptions,
    inventoryOptions,
    isActive: !!data?.total,
    isLoadingInventory: isLoadingInventory && !!inventorySlug,
  };
};
