import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { NestedRoutes, Permissions } from 'shared';
import { List, Create, Details } from './components';

export const Catalog = (): JSX.Element => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.InventoryCatalog,
  });
  const canCreate = checkAccess(Permissions.admin_panel_inventory_items_create);

  const ListComponent = <List canCreate={canCreate} rootPath={rootPath} />;

  const onExit = useGotoRoute(RouteName.InventoryCatalog);

  return (
    <>
      <Routes>
        <Route index element={ListComponent} />
        <Route path={NestedRoutes.List} element={ListComponent} />
        {canCreate && (
          <Route
            path={NestedRoutes.Create}
            element={<Create onExit={onExit} />}
          />
        )}
        <Route
          path={NestedRoutes.View}
          element={
            <Details
              onExit={onExit}
              rootPath={rootPath}
              canCreate={canCreate}
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: rootPath,
              }}
            />
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};
