import { useCallback, useState } from 'react';
import { GeneralService } from 'App/api';
import { useApiMutation } from './useApiMutation';
import { PrivateDocumentDto } from 'shared';

export const usePrivateDocument = () => {
  const [document, setDocument] = useState<PrivateDocumentDto | null>(null);

  const { isLoading, mutateAsync } = useApiMutation(
    ['getPrivateDocumentUrl'],
    GeneralService.getPrivateDocumentUrl,
  );

  const getDocument = useCallback(
    async (url: string) => {
      const data = await mutateAsync({
        document_name: url,
      });
      setDocument(data);
    },
    [mutateAsync],
  );

  return { isLoading, document, getDocument };
};
