import { Grid, TextInput, Button } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';

import { VendorsRequest } from 'shared';

interface Props {
  handleChange: (v: { [key: string]: string | string[] }) => void;
  values: VendorsRequest;
}

export const Filters = ({ handleChange, values }: Props): JSX.Element => {
  const { company_name } = values;
  const [text, setText] = useState(company_name);

  return (
    <Grid justify={'flex-end'}>
      <Grid.Col md={5} lg={4}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleChange({ company_name: text ?? '' });
          }}
        >
          <Grid align={'flex-end'} gutter="sm" justify={'flex-end'}>
            <Grid.Col span={8}>
              <TextInput
                value={text}
                placeholder="Search..."
                icon={<IconSearch stroke={1} size={14} />}
                onChange={({ target: { value } }) => setText(value)}
              />
            </Grid.Col>
            <Grid.Col span={'content'}>
              <Button type="submit">
                <IconSearch stroke={1} size={24} />
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </Grid.Col>
    </Grid>
  );
};
