import { Link } from 'react-router-dom';

import { ContentLayout, BackButton } from 'components';
import { TaxRate } from 'shared';
import { useCreateCulinarySkus } from '../../hooks';
import { Form } from '../form';

export const initialValues = {
  product_name: '',
  is_enabled: true,
  hide_from_pos: false,
  inventory_type: undefined,
  category_id: null,
  subcategory_id: null,
  cost: null,
  margin: null,
  price: '',
  sku_type: 'culinary',
  tax_rate: TaxRate.DEFAULT_RATE,
  vendor_id: null,
  processing_time: 'not_assigned',
  pickup_only: false,
  members_only: false,
  ships_separately: false,
  width: null,
  height: null,
  depth: null,
  weight: null,
  per_order_limit: '',
  product_file: '',
  e_gift_card: true,
  product_description: '',
  comment: '',
  saved_as_draft: false,
  show_in_ecommerce: false,
  sku_number: null,
  specific_information: {
    items: [],
  },
};

export const Create = ({
  onExit,
  rootPath,
}: {
  onExit: () => void;
  rootPath: string;
}): JSX.Element => {
  const { handleSubmit, isLoading, processingUpload } = useCreateCulinarySkus({
    onExit,
  });

  return (
    <ContentLayout
      title="New SKU"
      leftAction={<BackButton component={Link} to={rootPath} />}
    >
      <Form
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        processing={isLoading || processingUpload}
        isCreate
      />
    </ContentLayout>
  );
};
