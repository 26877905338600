import { useNavigate } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { getViewPath, NestedRoutes, Permissions } from 'shared';
import { List, Create, Details, Edit, Receive } from './components';
import { NestedRouteComponent } from 'components';

export const Transfer = (): JSX.Element => {
  const navigate = useNavigate();
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.InventoryTransfer,
  });
  const canCreate = checkAccess(
    Permissions.admin_panel_inventory_transfer_create,
  );
  const canEdit = checkAccess(Permissions.admin_panel_inventory_transfer_edit);
  const canReceive = checkAccess(
    Permissions.admin_panel_inventory_transfer_receive,
  );
  const canCancel = checkAccess(
    Permissions.admin_panel_inventory_transfer_cancel,
  );

  const onExit = useGotoRoute(RouteName.InventoryTransfer);
  const onCreate = (id: string) => navigate(getViewPath(rootPath, id));

  const ListComponent = <List canCreate={canCreate} rootPath={rootPath} />;
  const CreateComponent = <Create onExit={onExit} onCreate={onCreate} />;
  const EditComponent = (
    <Edit canCancel={canCancel} onExit={onExit} onCreate={onCreate} />
  );
  const DetailsComponent = (
    <Details canEdit={canEdit} canReceive={canReceive} rootPath={rootPath} />
  );
  const ReceiveComponent = <Receive onReceive={onCreate} />;

  return (
    <NestedRouteComponent
      showEdit={canEdit}
      showCreate={canCreate}
      showExtraRoute={canReceive}
      rootPath={rootPath}
      ListComponent={ListComponent}
      Create={CreateComponent}
      Edit={EditComponent}
      Details={DetailsComponent}
      extraRoute={{ path: NestedRoutes.Receive, element: ReceiveComponent }}
    />
  );
};
