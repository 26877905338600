import {
  CreateCulinaryMenu,
  CulinaryMenu,
  CulinaryMenuDto,
  PaginatedResponse,
} from 'shared';
import { FilterMenuValue } from 'shared/types/culinary';
import { CULINARY_MENU } from '../../endpoints';
import request from '../../request';

export default class CulinaryMenuService {
  static readonly getCollection = (params: FilterMenuValue) => {
    return request<PaginatedResponse<CulinaryMenuDto>>({
      url: `${CULINARY_MENU}`,
      params,
      method: 'get',
    });
  };

  static readonly create = (data: CreateCulinaryMenu) => {
    return request<CulinaryMenu>({
      url: `${CULINARY_MENU}`,
      method: 'post',
      data,
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<CulinaryMenu>({
      url: `${CULINARY_MENU}/${id}`,
      method: 'get',
    });
  };

  static readonly delete = ({ id }: { id: string }) => {
    return request({
      url: `${CULINARY_MENU}/${id}`,
      method: 'delete',
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id?: string;
    data: CreateCulinaryMenu;
  }) => {
    return request<CulinaryMenu>({
      url: `${CULINARY_MENU}/${id}`,
      method: 'put',
      data,
    });
  };
}
