import { useQueryClient } from '@tanstack/react-query';

import { useApiQuery, useApiMutation } from 'hooks';
import { InventoryService } from 'App/api';

const KEY = 'inventory-location';

export const useInventoryLocation = (id: string) => {
  const { data, isLoading, isError } = useApiQuery([KEY, id], () =>
    InventoryService.getLocation(id),
  );

  const client = useQueryClient();

  const refetch = () => {
    client.invalidateQueries([KEY, id]);
  };

  const {
    mutate: updateLocation,
    isLoading: updating,
    isSuccess: isUpdated,
  } = useApiMutation(['update', KEY, id], InventoryService.updateLocation);

  const {
    mutate: deleteLocation,
    isLoading: deleting,
    isSuccess: isDeleted,
  } = useApiMutation(['delete', KEY, id], InventoryService.deleteLocation);

  return {
    isError,
    data,
    isLoading,
    refetch,
    isDeleted,
    processing: deleting || updating,
    deleteLocation,
    updateLocation,
    isUpdated,
  };
};
