import { Routes, Route, Outlet, Navigate, useNavigate } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { NestedRoutes, Permissions, getViewPath } from 'shared';
import { List, Create, Details } from './components';

export function Adjustment(): JSX.Element {
  const navigate = useNavigate();
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.InventoryAdjustment,
  });
  const canCreate = checkAccess(Permissions.admin_panel_adjustments_create);
  const canCancel = checkAccess(Permissions.admin_panel_adjustments_cancel);

  const ListComponent = <List canCreate={canCreate} rootPath={rootPath} />;

  const onExit = useGotoRoute(RouteName.InventoryAdjustment);
  const onCreate = (id: string) => navigate(getViewPath(rootPath, id));

  return (
    <>
      <Routes>
        <Route index element={ListComponent} />
        <Route path={NestedRoutes.List} element={ListComponent} />
        {canCreate && (
          <Route
            path={NestedRoutes.Create}
            element={<Create onExit={onExit} onCreate={onCreate} />}
          />
        )}
        <Route
          path={NestedRoutes.View}
          element={<Details onExit={onExit} canCancel={canCancel} />}
        />
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: rootPath,
              }}
            />
          }
        />
      </Routes>

      <Outlet />
    </>
  );
}
